export const ITEM_ROWS_OBJ = [
  {
    class: 'item-col-xsrr',
    text: 'Order ID',
    noOverflow: false,
  },
  {
    class: 'item-col-lgr',
    text: 'Customer',
    noOverflow: true,
  },
  {
    class: 'item-col-lgr',
    text: 'Date',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Amount',
    noOverflow: false,
  },
  {
    class: '',
    text: 'Payout date',
    noOverflow: true,
  },
  {
    class: 'item-col-amount',
    text: 'Payout amount',
    noOverflow: false,
  },
];
