export const overviewTabs = [
  {
    value: 'last_day',
    text: 'Day',
  },
  {
    value: 'last_week',
    text: 'Week',
  },
  {
    value: 'last_month',
    text: 'Month',
  },
  {
    value: 'last_year',
    text: 'Year',
  },
];
