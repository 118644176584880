export const getCustomizations = (isLaddrB2C: boolean) => {
  let labels = {
    orderDetailsTitle: 'Payment details',
  };

  if (isLaddrB2C) {
    labels = {
      ...labels,
      orderDetailsTitle: 'Order details',
    };
  }

  return labels;
};
