import React, { useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import getMerchantName from './utils/getMerchantName';

interface PrivateRouteProps extends RouteProps {
  isLoggedIn: boolean;
  component: any;
  allowAccess?: boolean;
}

const PrivateRoute = ({ isLoggedIn, allowAccess, component: Component, ...rest }: PrivateRouteProps) => {
  const [merchantName] = useState<string>(() => getMerchantName());

  return (
    <Route
      {...rest}
      render={(props) => {
        const allowComponentAccess = isLoggedIn && allowAccess !== false;

        return allowComponentAccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/${merchantName}/${isLoggedIn ? `home` : `login`}`, state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
