import { logout } from 'APIs/identity';
import LogoLaddrPay from 'assets/images/LaddrPayLogo.png';
import { ReactComponent as SidebarLogo } from 'assets/svg/april-sidebar-logo.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setPublicInfo } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import { hasPayoutAccess } from 'utils/userPermissions';

import { getLaddrLinks, getSideBarLinks } from './Constants';
import { DisabledLink, MenuLi, Wrapper } from './styles';

const Sidebar = () => {
  const [showAccordion, setShowAccordion] = useState<boolean>(false);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [merchantName] = useState(() => getMerchantName());

  const isDesktop = useMediaQuery({ query: '(min-width: 961px)' });
  const history = useHistory();

  const {
    merchantId,
    isLaddrB2C,
    apiBaseUri,
    businessName,
    brandName,
    initials,
    showSidebar,
    isMerchantActivated,
    merchantDashboardBranding,
    isAccountSet,
    userPermissions,
    userRole,
  } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
    apiBaseUri: state.apiBaseUri,
    businessName: state.publicInfo?.businessName ?? '',
    brandName: state.publicInfo?.brandName ?? '',
    initials: state.initials,
    showSidebar: state.showSidebar,
    isMerchantActivated: state.isMerchantActivated,
    merchantDashboardBranding: state.merchantDashboardBranding,
    branding: state.branding,
    isAccountSet: state.isAccountSet,
    userPermissions: state.userPermissions,
    userRole: state.userRole,
  }));
  const dispatch: Dispatch<any> = useDispatch();
  const setPublicInfoCB = useCallback((p) => dispatch(setPublicInfo(p)), [dispatch]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }
    const fetchData = async () => {
      const options = await getFetchOptions();
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;

      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((res) => {
          setPublicInfoCB(res);
        })
        .catch((e) => {
          console.error(e);
        });
    };
    fetchData();
  }, [apiBaseUri, merchantId, setPublicInfoCB]);

  const toggleAccordion = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowAccordion((show) => !show);
  };

  const handleSignOut = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    logout()
      .then(() => {
        window.location.href = `/${merchantName}/login`;
      })
      .catch(() => {
        console.error('error to logout');
      });
  };

  const handleShowSidebar = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setOpenSidebar(true);
  };

  const handleHideSidebar = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setOpenSidebar(false);
  };

  const handleClickOption = (e: MouseEvent<HTMLAnchorElement>, tab: string): void => {
    e.preventDefault();
    history.push(tab);
    !isDesktop && setOpenSidebar(false);
  };

  return (
    <Wrapper>
      {showSidebar && (
        <button className="collapse-btn" onClick={handleShowSidebar}>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      )}
      <aside className={`sidebar ${openSidebar ? 'open' : ''}`}>
        <div className="sidebar-container">
          <div className="sidebar-header">
            <div className="brand">
              <NavLink to={isLaddrB2C ? `/${merchantName}/payment-requests` : `/${merchantName}/home`}>
                {isLaddrB2C && <img src={LogoLaddrPay} alt={'LaddrPay'} className="sidebar-logo" />}
                {merchantDashboardBranding?.logoUri && !isLaddrB2C && (
                  <img
                    src={merchantDashboardBranding.logoUri}
                    alt={merchantDashboardBranding.logoAltText || 'logo'}
                    className="sidebar-logo"
                  />
                )}
                {(!merchantDashboardBranding || !merchantDashboardBranding.logoUri) && !isLaddrB2C && (
                  <SidebarLogo className="sidebar-logo" />
                )}
              </NavLink>
            </div>
            <button className="close-btn" onClick={handleHideSidebar}>
              <CloseIcon className="close-icon" />
            </button>
          </div>
          <nav className="menu">
            <ul className="sidebar-menu">
              {isMerchantActivated &&
                isLaddrB2C === false &&
                getSideBarLinks(merchantName).map((link, index) => {
                  const showMenu =
                    link.label.toUpperCase() === 'TRANSFERS' ? hasPayoutAccess(userRole, userPermissions) : true;

                  return (
                    showMenu && (
                      <MenuLi key={index}>
                        <NavLink
                          className="menu-link"
                          to={link.href}
                          activeClassName="selected"
                          onClick={(e) => handleClickOption(e, link.href)}
                        >
                          {link.label}
                        </NavLink>
                      </MenuLi>
                    )
                  );
                })}
              {isMerchantActivated &&
                isLaddrB2C &&
                getLaddrLinks(merchantName).map((link, index) => (
                  <MenuLi key={index}>
                    <NavLink
                      className="menu-link"
                      to={link.href}
                      activeClassName="selected"
                      onClick={(e) => handleClickOption(e, link.href)}
                    >
                      {link.label}
                    </NavLink>
                  </MenuLi>
                ))}
              {isMerchantActivated === false && (
                <>
                  <MenuLi>
                    <NavLink className="menu-link" to={`/${merchantName}/account-setup`} activeClassName="selected">
                      Account Set-up
                    </NavLink>
                  </MenuLi>
                  <MenuLi>
                    {isAccountSet && (
                      <NavLink className="menu-link" to={`/${merchantName}/bank-details`} activeClassName="selected">
                        Bank Details
                      </NavLink>
                    )}
                    {!isAccountSet && <DisabledLink>Bank details</DisabledLink>}
                  </MenuLi>
                </>
              )}
            </ul>
          </nav>
        </div>
        <footer className={`sidebar-footer profile ${showAccordion ? 'profile-menu-active' : ''}`}>
          {initials && (
            <div className="user-img">
              <div className="img-text">{initials}</div>
            </div>
          )}
          <div className="accordion">
            <div className="card">
              <button
                className="nav-link"
                aria-expanded={showAccordion}
                aria-controls="collapseOne"
                onClick={toggleAccordion}
              >
                <div>
                  <div className="business-name">{businessName}</div>
                  <div className="brand-name">{brandName}</div>
                </div>
                <ArrowDownIcon className={`arrow-down ${showAccordion ? 'up' : ''}`} />
              </button>
              <div className={`collapse ${showAccordion ? 'show' : ''} ${isMerchantActivated ? 'activated' : ''}`}>
                <div className="card-body">
                  <div className="profile-dropdown-menu">
                    {isMerchantActivated && (
                      <NavLink className="dropdown-item" to={`/${merchantName}/account`}>
                        My account
                      </NavLink>
                    )}
                    <button className="dropdown-item" onClick={handleSignOut}>
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </aside>
    </Wrapper>
  );
};

export default Sidebar;
