import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';

import styled from '@emotion/styled';

export const ErrorMsg = styled.small`
  display: block;
  color: red;
  text-align: right;
`;

export const Input = styled.input<{ invalid?: boolean }>`
  background-color: #fff;
  border: 1px solid ${({ invalid }) => (invalid ? 'red' : '#dce3eb')};
  border-radius: 3px;
  width: 100%;
  color: #000;
  max-height: 44px;
  outline: none;
  padding: 17px 17px 12px;

  &:focus {
    border: 1px solid ${({ invalid }) => (invalid ? 'red' : '#505bcc')};
    box-shadow: none;
  }
`;

export const ArrowDown = styled(ArrowDownIcon)`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out 0s;
  margin-left: auto;
  &.up {
    transform: rotate(180deg);
  }
`;

export const Select = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  cursor: pointer;
  position: relative;
  height: 45px;
  background-color: #fff;
  padding: 11px 7px;
  border: 1px solid #dce3eb;
  border-radius: 3px;
  width: 100%;
  color: #000;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 30%);
  box-sizing: border-box;
`;

export const DropdownMenu = styled.div`
  padding: 0;
  list-style: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  top: 100%;
  box-shadow: 0 1px 2px #ccc;
  border-radius: 0 1px 5px 5px;
  border: 1px solid rgba(126, 142, 159, 0.1);
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: hidden;
  z-index: 9;
  min-width: 160px;
  margin: 2px 0 0;
  font-size: 16px;
  color: #212529;
  &.open {
    display: block;
  }
`;

export const FormItem = styled.div`
  padding-bottom: 14px;
  font-weight: 300;
  color: #6c7488;
  position: relative;

  label {
    cursor: default;
    padding-left: 1px;
    display: block;
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 18px;
    color: #6c7488;
    font-weight: 300;
  }
`;

export const DropdownOption = styled.li`
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;

export const CurrencyText = styled.div`
  line-height: 20px;
  padding-left: 8px;
`;
