import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setPersonalInfo } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getApiErrorMsg from 'utils/getApiErrorMsg';
import getFetchOptions from 'utils/getFetchOptions';
import setBodyOverflow from 'utils/setBodyOverflow';

import { EditBtn, FormField, FormItem } from './styles';

const YourDetails = (): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [localFirstName, setLocalFirstName] = useState<string>('');
  const [localLastName, setLocalLastName] = useState<string>('');

  const { merchantEmail, personalInfo, apiBaseUri, merchantId } = useSelector((state: ReduxStateType) => ({
    merchantEmail: state.merchantEmail,
    personalInfo: state.personalInfo,
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
  }));

  const dispatch: Dispatch<any> = useDispatch();

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('hidden');
    setShowModal(true);
    setModalErrorMsg('');
    setModalIsLoading(false);
    setLocalFirstName(personalInfo?.firstName ?? '');
    setLocalLastName(personalInfo?.lastName ?? '');
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const body = JSON.stringify({
      firstName: localFirstName,
      lastName: localLastName,
    });
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
    const options = await getFetchOptions('PATCH', body);
    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.text();
          let msg = text;
          try {
            msg = getApiErrorMsg(JSON.parse(text));
          } finally {
            throw Error(msg);
          }
        }
        setBodyOverflow('auto');
        setShowModal(false);
        dispatch(
          setPersonalInfo({
            ...personalInfo,
            lastName: localLastName,
            firstName: localFirstName,
          }),
        );
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to update personal information');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalFirstName(e.target.value);
  };

  const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>): void => {
    setLocalLastName(e.target.value);
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2>Your details</H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          <EditBtn onClick={toggleModal}>Edit your details</EditBtn>
        </div>
      </div>
      <LpDetails>
        <div className="row pt-4 pb-0 pb-md-2">
          <div className="col-sm-3 col-xl-2">
            <Label className="mb-0">Login email</Label>
          </div>
          <div className="col-sm-9 col-xl-10">{merchantEmail}</div>
        </div>
        <div className="row pt-4 pb-0 pb-md-2">
          <div className="col-sm-3 col-xl-2">
            <Label className="mb-0">Name</Label>
          </div>
          <div className="col-sm-9 col-xl-10">
            {personalInfo?.firstName ?? ''} {personalInfo?.lastName ?? ''}
          </div>
        </div>
      </LpDetails>
      {showModal && (
        <Modal
          title="Edit your details"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && modalErrorMsg.length === 0 && (
            <LpDetails className="pt-2 pb-0">
              <FormItem>
                <FormField>
                  <Label>First name</Label>
                  <Input
                    value={localFirstName ?? ''}
                    onChange={handleChangeFirstName}
                    placeholder="First name"
                    required
                  />
                </FormField>
              </FormItem>
              <FormItem>
                <FormField>
                  <Label>Last name</Label>
                  <Input value={localLastName ?? ''} onChange={handleChangeLastName} placeholder="Last name" required />
                </FormField>
              </FormItem>
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default YourDetails;
