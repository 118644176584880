import React, { ReactElement } from 'react';

import { Title, Wrapper } from './EmptyList.style';

const EmptyList = (): ReactElement => (
  <Wrapper>
    <Title>No results found</Title>
  </Wrapper>
);

export default EmptyList;
