export type CustomContentType = {
  apiBaseUri: string;
  merchantId: string;
};

export enum Visibility {
  visible = 'visible',
  hidden = 'hidden',
}

export enum Condition {
  required = 'required',
  optional = 'optional',
}

export enum ItemAction {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
}

export const ItemModalTitle = {
  [ItemAction.add]: 'Add new item',
  [ItemAction.edit]: 'Edit item',
  [ItemAction.delete]: '',
};

export const ItemModalConfirmBtn = {
  [ItemAction.add]: 'Save',
  [ItemAction.edit]: 'Save change',
  [ItemAction.delete]: 'Confirm and delete',
};

export const ItemModalConfirmBtnColour = {
  [ItemAction.add]: '',
  [ItemAction.edit]: '',
  [ItemAction.delete]: '#FF6E44',
};
