import { login } from 'APIs/identity';
import Spinner from 'Components/Spinner/Spinner';
import LogoLaddrPay from 'assets/images/LaddrPayLogo.png';
import { ReactComponent as LogoIcon } from 'assets/svg/april-logo.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/email.svg';
import { ReactComponent as PasswordIcon } from 'assets/svg/password.svg';
import { Location } from 'history';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import compareTwoStrings from 'utils/compareTwoStrings';
import getCurrentUser from 'utils/getCurrentUser';
import getMerchantName from 'utils/getMerchantName';
import useFavicon from 'utils/useFavicon';
import { clearZendeskWidget } from 'utils/zendesk';

import { Btn, ErrorBar, LoadingWrapper, SpinnerWrapper, Wrapper } from './styles';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchantName] = useState(() => getMerchantName());

  const { tenantId, merchantId, isLaddrB2C, branding } = useSelector((state: ReduxStateType) => ({
    tenantId: state.tenantId,
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
    branding: state.branding,
  }));

  const location: Location<{ from?: Location } | null | undefined> = useLocation();

  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);
  useFavicon(branding, isLaddrB2C);

  useEffect(() => {
    document.title = isLaddrB2C ? 'Sign in - Laddr' : 'Sign in - April';
    setLoginPendingCB(true);
  }, [setLoginPendingCB, isLaddrB2C]);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  useEffect(() => {
    clearZendeskWidget();
  }, []);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(tenantId, email, password);
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        throw Error('Failed to retrieve user details from firebase');
      }
      const isMatched = compareTwoStrings(currentUser.claims.limepay.merchantId, merchantId);
      if (isMatched) {
        window.location.href = location.state?.from?.pathname || `/${merchantName}`;
      } else {
        throw Error(`You are attempting to access the ${merchantName} dashboard. <br />
        Please check your account URL is correct and enter a valid email`);
      }
    } catch (error) {
      setErrorMessage(error?.message || 'Login failed!');
      setIsLoading(false);
    }
  };

  if (isPageLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <header className="header">
        <nav className="nav">
          <div className="logo-wrapper">
            {merchantId && isLaddrB2C && <img src={LogoLaddrPay} alt="LaddrPay" className="logo" />}
            {merchantId && !isLaddrB2C && <LogoIcon className="logo" />}
          </div>
        </nav>
      </header>
      <div className="content">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-header">
            <h3 className="form-title">Log in to your account</h3>
            {errorMessage.length > 0 && <ErrorBar dangerouslySetInnerHTML={{ __html: errorMessage }} />}
          </div>
          <div className="input-wrapper">
            <label className="label" htmlFor="email">
              <div className="icon-wrapper">
                <EmailIcon className="email-icon" />
              </div>
              <input
                className="input"
                type="email"
                autoFocus
                id="email"
                name="email"
                value={email}
                placeholder="EMAIL ADDRESS"
                onChange={handleChangeEmail}
                autoComplete="username"
              />
            </label>
          </div>
          <div className="input-wrapper">
            <label className="label" htmlFor="password">
              <div className="icon-wrapper">
                <PasswordIcon className="password-icon" />
              </div>
              <input
                className="input"
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="PASSWORD"
                onChange={handleChangePassword}
                autoComplete="current-password"
              />
            </label>
          </div>
          <div className="input-submit">
            <small className="small">
              <NavLink className="forget-password" to={`/${merchantName}/forget-password`}>
                Forgot your password?
              </NavLink>
              <Btn type="submit" disabled={email.length === 0 || password.length === 0 || isLoading}>
                Log in
                {isLoading && (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                )}
              </Btn>
            </small>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default Login;
