export enum CountryType {
  AU = 'AU',
  NZ = 'NZ',
}

export enum PayoutType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}
