import './Overview.scss';

import { CHART_CUSTOMERS, CHART_TRANSACTIONS } from 'Constants/Constants';
import { Content } from 'Constants/styles';
import React, { MouseEvent, ReactElement } from 'react';

import Chart from '../Chart/Chart';
import { LinkBtn } from './Overview.styles';
import { overviewTabs } from './overviewTabs';
import { ChartDataType } from './types';

type OverviewType = {
  data: ChartDataType;
  activeTabIndex: number;
  handleClickTab(e: MouseEvent<HTMLButtonElement>, i: number): void;
};

const Overview = ({ data, activeTabIndex, handleClickTab }: OverviewType): ReactElement => (
  <div className="charts-morris-page">
    <div className="lp-nav charts-morris-page">
      <ul role="tablist" className="nav">
        <li className="nav-item compare-by">Compare by</li>
        {overviewTabs.map((tab, index) => (
          <li key={tab.text} className="nav-item">
            <LinkBtn
              role="tab"
              isActive={activeTabIndex === index}
              aria-selected={activeTabIndex === index}
              onClick={(e) => handleClickTab(e, index)}
            >
              {tab.text}
            </LinkBtn>
          </li>
        ))}
      </ul>
    </div>
    <Content>
      <div className="row">
        {data && (
          <>
            <Chart
              totalAmount={data.transactionSum}
              todayAmount={data.transactionsForToday}
              aggregatedArray={data.transactionsAggregatedSum}
              element={CHART_TRANSACTIONS}
              title="Total Transactions"
              chartHeader="TRANSACTIONS OVER TIME"
            />
            <Chart
              totalAmount={data.customerCount}
              todayAmount={data.customersForToday}
              aggregatedArray={data.customerAggregatedCount}
              element={CHART_CUSTOMERS}
              title="Total Customers"
              chartHeader="CUSTOMERS OVER TIME"
            />
          </>
        )}
      </div>
    </Content>
  </div>
);

export default Overview;
