import { CustomerAPIResponseType } from 'Components/Customers/types';
import { PaymentAPIResponseType } from 'Components/Payments/types';
import { CountryType } from 'Components/SelectCountry';
import {
  BrandingAPIResponse,
  PersonalInfoSettingsAPIResponse,
  PublicInfoAPIResponseType,
} from 'Components/Settings/types';
import { PayoutAPIResponseType } from 'Components/Transfer/types';

import * as Actions from '../Constants/actionTypes';
import { ActionType, UserPermissionType, UserRoleType } from '../Constants/types';

export const updateSearchDate = (startDate: string, endDate: string): ActionType => ({
  type: Actions.UPDATE_SEARCH_DATE,
  payload: {
    startDate,
    endDate,
  },
});

export const setMerchantEmail = (merchantEmail: string): ActionType => ({
  type: Actions.SET_MERCHANT_EMAIL,
  payload: {
    merchantEmail,
  },
});

export const updateLoggedIn = (isLoggedIn: boolean): ActionType => ({
  type: Actions.UPDATE_LOGGED_IN,
  payload: {
    isLoggedIn,
  },
});

export const setLoginPending = (isLoginPending: boolean): ActionType => ({
  type: Actions.SET_LOGIN_PENDING,
  payload: {
    isLoginPending,
  },
});

export const setConfig = (
  apiBaseUri: string,
  tenantId: string,
  merchantId: string,
  orderBaseUri: string,
  branding: BrandingAPIResponse,
  merchantTaxCountry: CountryType,
  merchantTradingCountry: CountryType,
  marketplaceTags: Array<string>,
  merchantTags: Array<string>,
  merchantPublicKey: string,
  abacusEnabled: boolean,
  sourceVerificationEnabled: boolean,
): ActionType => ({
  type: Actions.SET_CONFIG,
  payload: {
    apiBaseUri,
    tenantId,
    merchantId,
    orderBaseUri,
    branding,
    merchantTaxCountry,
    merchantTradingCountry,
    marketplaceTags,
    merchantTags,
    merchantPublicKey,
    abacusEnabled,
    sourceVerificationEnabled,
  },
});

export const setPaymentDetails = (
  showPaymentDetailsView: boolean,
  activePayment: PaymentAPIResponseType | null,
): ActionType => ({
  type: Actions.SET_PAYMENT_DETAILS,
  payload: {
    showPaymentDetailsView,
    activePayment,
  },
});

export const setCustomerDetails = (
  showCustomerDetailsView: boolean,
  activeCustomer: CustomerAPIResponseType | null,
): ActionType => ({
  type: Actions.SET_CUSTOMER_DETAILS,
  payload: {
    showCustomerDetailsView,
    activeCustomer,
  },
});

export const setTransferDetails = (
  showTransferDetailsView: boolean,
  activeTransfer: PayoutAPIResponseType,
): ActionType => ({
  type: Actions.SET_TRANSFER_DETAILS,
  payload: {
    showTransferDetailsView,
    activeTransfer,
  },
});

export const setFilterPeriod = (filterPeriod: string): ActionType => ({
  type: Actions.SET_FILTER_PERIOD,
  payload: {
    filterPeriod,
  },
});

export const setPublicInfo = (publicInfo: PublicInfoAPIResponseType): ActionType => ({
  type: Actions.SET_PUBLIC_INFO,
  payload: {
    publicInfo,
  },
});

export const setFetchTimestamp = (timestamp: number): ActionType => ({
  type: Actions.SET_FETCH_TIMESTAMP,
  payload: {
    timestamp,
  },
});

export const setMerchantActivated = (isMerchantActivated: boolean): ActionType => ({
  type: Actions.SET_MERCHANT_ACTIVATED,
  payload: {
    isMerchantActivated,
  },
});

export const setBranding = (branding: BrandingAPIResponse): ActionType => ({
  type: Actions.SET_BRANDING,
  payload: {
    branding,
  },
});

export const setPersonalInfo = (personalInfo: PersonalInfoSettingsAPIResponse): ActionType => ({
  type: Actions.SET_PERSONAL_INFO,
  payload: {
    personalInfo,
  },
});

export const setAccount = (isAccountSet: boolean): ActionType => ({
  type: Actions.SET_ACCOUNT,
  payload: {
    isAccountSet,
  },
});

export const setUserPermissions = (userPermissions: Array<UserPermissionType>, userRole: UserRoleType): ActionType => ({
  type: Actions.SET_USER_PERMISSION,
  payload: {
    userPermissions,
    userRole,
  },
});

export const setTaxCountry = (taxCountry: CountryType) => ({
  type: Actions.SET_TAX_COUNTRY,
  payload: {
    taxCountry,
  },
});
