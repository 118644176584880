import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/ico-search.svg';
import { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import * as s from './Search.styles';

export enum OrderSearchType {
  name = 'name',
  email = 'email',
}

export const OrderSearchOptions = [
  {
    text: 'Name',
    value: OrderSearchType.name,
  },
  {
    text: 'Email',
    value: OrderSearchType.email,
  },
];

export type SearchOptionType = {
  text: string;
  value: string;
};

type Props = {
  selectedSearchOption: SearchOptionType;
  setSelectedSearchOption(option: SearchOptionType): void;
  selectedSearch: string;
  handleSearch(search: string): void;
};

const OrderSearch = ({ selectedSearchOption, setSelectedSearchOption, selectedSearch, handleSearch }: Props) => {
  const [showSearchOptions, setShowSearchOptions] = useState(false);

  const ref = useRef<HTMLUListElement>(null);

  const toggleSearchOptions = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowSearchOptions((prev) => !prev);
  };

  const onChangeDropdown = (e: ChangeEvent<HTMLInputElement>): void => {
    const index = OrderSearchOptions.findIndex((option) => option.value === e.target.value) ?? 0;
    setSelectedSearchOption(OrderSearchOptions[index]);
    handleSearch('');
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    handleSearch(e.target.value);
  };

  const handleClickOutside = () => {
    setShowSearchOptions(false);
  };

  useClickOutside(ref, handleClickOutside, ['search-option-btn', 'option-text', 'arrow-down']);

  return (
    <s.Wrapper>
      <s.InputGroup>
        <s.FormControl
          type="text"
          name="searchQuery"
          placeholder="Search"
          value={selectedSearch}
          onChange={onChangeSearch}
        />
        <s.SearchIconWrapper>
          <SearchIcon />
        </s.SearchIconWrapper>
        <s.DownSearch>
          <s.SearchOptionBtn className="search-option-btn" onClick={toggleSearchOptions}>
            <s.OptionText className="option-text">{selectedSearchOption.text}</s.OptionText>
            <ArrowDownIcon className={`arrow-down ${showSearchOptions ? 'up' : ''}`} />
          </s.SearchOptionBtn>
          {showSearchOptions && (
            <s.Ul ref={ref}>
              {OrderSearchOptions.map((option) => (
                <s.Li key={option.value}>
                  <s.LpRadio>
                    <s.Cno>{option.text}</s.Cno>
                    <input
                      type="radio"
                      name="searchType"
                      value={option.value}
                      onChange={onChangeDropdown}
                      checked={selectedSearchOption.value === option.value}
                    />
                    <span className="check-mark" />
                  </s.LpRadio>
                </s.Li>
              ))}
            </s.Ul>
          )}
        </s.DownSearch>
      </s.InputGroup>
    </s.Wrapper>
  );
};

export default OrderSearch;
