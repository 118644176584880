import styled from '@emotion/styled';

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-family: Arial;

  .password-icon {
    width: 22px;
    height: 22px;
  }
`;

export const Header = styled.header`
  position: absolute;
  width: 100%;
  margin-bottom: 50px;
  z-index: 2;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px auto;
  max-width: 1600px;
  padding: 50px 100px;
`;

export const LogoWrapper = styled.div`
  min-width: 142px;
  max-width: 142px;
  margin-right: auto;
  flex: 0 1 0%;
  z-index: 1;
  .logo {
    max-width: 142px;
  }
`;

export const MessageBar = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: #fef0f4;
  color: #8d2e49;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.38px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  max-width: 1600px;
  padding: 90px 100px 80px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 90px 20px 80px;
  }
`;

export const ResetPwdForm = styled.form`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  max-width: 550px;
  width: 100%;
  height: auto;
  z-index: 2;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 136px;
  border-bottom: 1px solid rgb(218, 227, 237);
`;

export const FormTitle = styled.h3`
  margin: 0;
  padding: 30px;
  display: block;
  color: rgb(85, 91, 125);
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.38px;
`;

export const FormDescription = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.32px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.label`
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid rgb(218, 227, 237);
  box-sizing: border-box;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
`;

export const Input = styled.input`
  appearance: none;
  background: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
  padding: 0 20px 0 8px;
  border: none;
  color: rgb(34, 34, 48);
  font-size: 18px;

  &:focus {
    outline: none;
  }
`;

export const Small = styled.small`
  font-size: 80%;
`;

export const HelpLink = styled.a`
  color: #0a173a;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
`;

export const SubmitBtn = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 64px;
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#5EFF00' : 'var(--lp-colors-medium-blue-600)')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#5EFF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 7px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  font-size: 19px;
  text-align: center;
  letter-spacing: 0.38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const InputSubmit = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const SpinnerWrapper = styled.div`
  margin-left: 6px;
  padding-top: 3px;
  height: 40px;
  width: 40px;
`;
