export const UPDATE_SEARCH_DATE = 'UPDATE_SEARCH_DATE';
export const SET_MERCHANT_EMAIL = 'SET_MERCHANT_EMAIL';
export const UPDATE_LOGGED_IN = 'UPDATE_LOGGED_IN';
export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_PUBLIC_INFO = 'SET_PUBLIC_INFO';
export const SET_FETCH_TIMESTAMP = 'SET_FETCH_TIMESTAMP';

// for detail pages
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const SET_TRANSFER_DETAILS = 'SET_TRANSFER_DETAILS';

// for overview page
export const SET_FILTER_PERIOD = 'SET_FILTER_PERIOD';

export const SET_MERCHANT_ACTIVATED = 'SET_MERCHANT_ACTIVATED';
export const SET_BRANDING = 'SET_BRANDING';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';

export const SET_ACCOUNT = 'SET_ACCOUNT';

// set role & permission
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION';

// set tax country to support New Zealand when onboarding
export const SET_TAX_COUNTRY = 'SET_TAX_COUNTRY';
