import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Badges = styled.div`
  padding: 17px 40px 14px;
  background-color: #fff;
  border: 1px solid #dce3eb;
  border-radius: 3px;
  display: inline-block;
  box-shadow: 0.5px 6px 10px 0 rgba(0, 0, 0, 0.1);
  margin: 11px 0;
  color: #1a1a1a;
  font-size: 15px;
  line-height: 19px;
  position: relative;
`;

type SymbolType = {
  success: boolean;
};

export const Symbol = styled.div<SymbolType>`
  position: relative;
  margin: 0;
  color: #1a1a1a;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: ${(props) => (props.success ? '#82d275' : 'red')};
  }
`;
