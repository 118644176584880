import isNumeric from 'utils/isNumeric';

const validateAbn = (taxId: string): boolean => {
  // remove all the empty space
  const abn = taxId.replace(/ /g, '');

  // if the length is not 11, return false
  if (abn.length !== 11) {
    return false;
  }

  // if the string is not numeric, return false
  if (!isNumeric(abn)) {
    return false;
  }

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  // check if it is valid http://www.mathgen.ch/codes/abn.html
  let sum = 0;

  weights.forEach((weight, index) => {
    // minus 1 on the first digit
    const digit = Number.parseInt(abn.charAt(index), 10) - (index === 0 ? 1 : 0);
    sum += weight * digit;
  });

  return sum % 89 === 0;
};

export default validateAbn;
