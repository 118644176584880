export const YourAPIKeysHeaders = [
  {
    text: 'Key',
    classNames: 'item-col-header item-col-title col-md-3',
    noOverflow: false,
  },
  {
    text: 'Token',
    classNames: 'text-left item-col-header item-col-title',
    noOverflow: false,
  },
  {
    text: 'Created',
    classNames: 'text-left item-col-header item-col-title item-col-sm',
    noOverflow: true,
  },
];

export const InstallationList = [
  {
    platFormName: 'Magento 1',
    createdAt: '2021-02-01T12:11:32Z',
    link: 'https://docs.meetapril.io/developer-portal/checkout/magento-v1/',
    id: 'magento-1',
  },
  {
    platFormName: 'Magento 2',
    createdAt: '2021-02-02T12:13:32Z',
    link: 'https://docs.meetapril.io/developer-portal/checkout/magento-v2/',
    id: 'magento-2',
  },
  {
    platFormName: 'WooCommerce',
    createdAt: '2021-02-03T12:15:32Z',
    link: 'https://docs.meetapril.io/developer-portal/checkout/woocommerce/',
    id: 'WooCommerce',
  },
  {
    platFormName: 'Custom integrations',
    createdAt: '2021-02-04T12:17:32Z',
    link: 'https://docs.meetapril.io/developer-portal/direct-integration-landing/',
    id: 'CustomIntegrations',
  },
];
