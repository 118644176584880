import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, LpBox, P, Row } from 'Constants/styles';
import Jodit from 'jodit-react';
import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import isEven from 'utils/isEven';
import validateEmail from 'utils/validateEmail';

import * as s from './Emails.styles';
import { EmailValueType, getInstalmentNumber, setEmailInitValues } from './emailUtils';

type EmailsType = {
  apiBaseUri: string;
  merchantId: string;
};

const Emails = ({ apiBaseUri, merchantId }: EmailsType): ReactElement => {
  /* index 0 -> 2nd reminder, index 1 -> 2nd success
   * index 2 -> 3rd reminder, index 3 -> 3rd success
   * index 4 -> 4th reminder, index 5 -> 4th success
   */
  const [states, setStates] = useState<EmailValueType[]>(() => setEmailInitValues());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isPreviewSent, setIsPreviewSent] = useState<boolean>(false);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchEmailMessages = async () => {
      try {
        setIsLoading(true);
        const url = `${apiBaseUri}/merchants/${merchantId}/settings/messages/email`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json = await res.json();
        isMounted &&
          setStates((prev: EmailValueType[]) => {
            const _states = prev.map((a) => ({ ...a }));
            if (json.secondInstalmentReminder) {
              _states[0].leading = json.secondInstalmentReminder.lead;
              _states[0].trailing = json.secondInstalmentReminder.trail;
            }
            if (json.secondInstalmentSuccess) {
              _states[1].leading = json.secondInstalmentSuccess.lead;
              _states[1].trailing = json.secondInstalmentSuccess.trail;
            }
            if (json.thirdInstalmentReminder) {
              _states[2].leading = json.thirdInstalmentReminder.lead;
              _states[2].trailing = json.thirdInstalmentReminder.trail;
            }
            if (json.thirdInstalmentSuccess) {
              _states[3].leading = json.thirdInstalmentSuccess.lead;
              _states[3].trailing = json.thirdInstalmentSuccess.trail;
            }
            if (json.finalInstalmentReminder) {
              _states[4].leading = json.finalInstalmentReminder.lead;
              _states[4].trailing = json.finalInstalmentReminder.trail;
            }
            if (json.finalInstalmentSuccess) {
              _states[5].leading = json.finalInstalmentSuccess.lead;
              _states[5].trailing = json.finalInstalmentSuccess.trail;
            }
            return _states;
          });
      } catch (e) {
        isMounted && setErrorMsg(e.message || 'Failed to fetch email messages');
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    fetchEmailMessages();
    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId]);

  const setContent = (content: string, index: number, type: 'leading' | 'trailing') => {
    setStates((prev) => {
      const temp = { ...prev[index] };
      if (type === 'leading') {
        temp.leading = content;
      } else {
        temp.trailing = content;
      }
      return [...prev.slice(0, index), temp, ...prev.slice(index + 1)];
    });
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    const { value } = e.target;
    setStates((prev) => {
      const temp = { ...prev[index] };
      temp.email = value;
      temp.isEmailValid = validateEmail(value);

      return [...prev.slice(0, index), temp, ...prev.slice(index + 1)];
    });
  };

  const handleSaveChanges = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    const url = `${apiBaseUri}/merchants/${merchantId}/settings/messages/email`;
    const body = {
      secondInstalmentReminder: {
        lead: states[0].leading,
        trail: states[0].trailing,
      },
      secondInstalmentSuccess: {
        lead: states[1].leading,
        trail: states[1].trailing,
      },
      thirdInstalmentReminder: {
        lead: states[2].leading,
        trail: states[2].trailing,
      },
      thirdInstalmentSuccess: {
        lead: states[3].leading,
        trail: states[3].trailing,
      },
      finalInstalmentReminder: {
        lead: states[4].leading,
        trail: states[4].trailing,
      },
      finalInstalmentSuccess: {
        lead: states[5].leading,
        trail: states[5].trailing,
      },
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));

    setIsBtnLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setErrorMsg('');
        setIsSuccess(true);
        window.scrollTo({ top: 0 });
      })
      .catch((e) => {
        setIsSuccess(false);
        setErrorMsg(e.message || 'Failed to save changes');
      })
      .finally(() => {
        setIsBtnLoading(false);
      });
  };

  const handleClickPreview = async (e: MouseEvent<HTMLButtonElement>, index: number): Promise<void> => {
    e.preventDefault();

    const base = `${apiBaseUri}/merchants/${merchantId}/settings/messages/email/preview/payplan/${
      isEven(index) ? 'reminder' : 'success'
    }`;
    const param = `?instalmentNumber=${getInstalmentNumber(index)}&emailAddress=${states[index].email}`;
    const options = await getFetchOptions('POST');

    setStates((prev) => {
      const temp = { ...prev[index] };
      temp.loading = true;
      return [...prev.slice(0, index), temp, ...prev.slice(index + 1)];
    });
    fetch(`${base}${param}`, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setErrorMsg('');
        setIsPreviewSent(true);
        window.scrollTo({ top: 0 });
      })
      .catch((e) => {
        setIsPreviewSent(false);
        setErrorMsg(e.message || 'Failed to send preview email');
      })
      .finally(() => {
        setStates((prev) => {
          const temp = { ...prev[index] };
          temp.loading = false;
          return [...prev.slice(0, index), temp, ...prev.slice(index + 1)];
        });
      });
  };

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleCloseSuccessMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsSuccess(false);
  };

  const handleClosePreviewMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsPreviewSent(false);
  };

  if (isLoading) {
    return (
      <s.FetchSpinner>
        <Spinner />
      </s.FetchSpinner>
    );
  }

  return (
    <>
      {errorMsg.length > 0 && <Message description={errorMsg} success={false} handleClose={handleCloseErrorMsg} />}
      {isSuccess && (
        <Message description="Your custom email content was successfully updated" handleClose={handleCloseSuccessMsg} />
      )}
      {isPreviewSent && (
        <Message description="Your preview email was successfully sent" handleClose={handleClosePreviewMsg} />
      )}
      <s.Wrapper>
        {states.map((state, index) => (
          <LpBox key={index}>
            <H2>{state.title}</H2>
            <P>{state.blob}</P>
            <Row>
              <div className="col-lg-6 col-12">
                <P className="mt-4">Leading text</P>
                <Jodit value={state.leading} onBlur={(c) => setContent(c, index, 'leading')} />
              </div>
              <div className="col-lg-6 col-12">
                <P className="mt-4">Trailing text</P>
                <Jodit value={state.trailing} onBlur={(c) => setContent(c, index, 'trailing')} />
              </div>
            </Row>
            <s.Preview>
              <s.PreviewEmail>
                <Input
                  placeholder="Email address"
                  value={state.email}
                  type="email"
                  onChange={(e) => handleChangeEmail(e, index)}
                />
                {!state.isEmailValid && !!state.email && (
                  <s.invalidEmail>Please enter a valid email address</s.invalidEmail>
                )}
              </s.PreviewEmail>
              <s.PreviewBtn
                disabled={state.loading || !state.isEmailValid}
                onClick={(e) => handleClickPreview(e, index)}
              >
                Preview
                {state.loading && (
                  <s.SpinnerWrapper>
                    <Spinner width="20px" height="20px" borderWidth="2px" />
                  </s.SpinnerWrapper>
                )}
              </s.PreviewBtn>
            </s.Preview>
          </LpBox>
        ))}
      </s.Wrapper>
      <s.BottomBar>
        <s.SubmitBtn onClick={handleSaveChanges} disabled={isBtnLoading}>
          Save changes
          {isBtnLoading && (
            <s.SpinnerWrapper>
              <Spinner width="20px" height="20px" borderWidth="2px" />
            </s.SpinnerWrapper>
          )}
        </s.SubmitBtn>
      </s.BottomBar>
    </>
  );
};

export default Emails;
