import styled from '@emotion/styled';

export const MenuLi = styled.li`
  padding: 8px 0;
  position: relative;
`;

export const DisabledLink = styled.button`
  padding: 0 20px 0 30px;
  background: transparent;
  border: none;
  color: #b3b8cc;
  cursor: default;
  font-size: 17px;
`;

export const Wrapper = styled.div`
  .collapse-btn {
    background: none;
    border: none;
    box-shadow: none;
    color: #505bcc;
    font-size: 24px;
    line-height: 40px;
    border-radius: 0;
    outline: none;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: initial;
    cursor: pointer;
    position: absolute;
    left: 10px;
    margin-top: 4px;
    top: 28px;
    z-index: 100;
  }

  .sidebar-header {
    position: relative;
    z-index: 100;
  }

  .icon-bar {
    display: block;
    width: 22px;
    height: 1px;
    margin-bottom: 6px;
    background-color: #6c7488;
    position: relative;
  }

  .sidebar {
    background-color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
    border-right: 1px #e7e7e7 solid;
    width: 240px;
    padding-bottom: 60px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    transition: left 0.3s ease;
    z-index: 3333;
  }

  .sidebar-container {
    position: absolute;
    top: 0;
    bottom: 51px;
    width: 100%;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .brand {
    padding: 35px 10px 9px 33px;
  }

  .sidebar-logo {
    max-width: 142px;
    max-height: ${({ theme }) => (theme.isLaddrB2C ? '60px' : '80px')};
  }

  .sidebar-menu {
    font-size: 17px;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 23px;
  }

  .menu-link {
    padding: 0 20px 0 30px;
    color: #fff;
    text-decoration: none;
    display: block;
    position: relative;

    &.selected:before {
      top: -2px;
      left: 0;
      content: '';
      position: absolute;
      width: 3px;
      height: 23px;
      background-color: ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-turquoise-600)')};
    }
  }

  .sidebar-footer {
    position: fixed;
    bottom: 0;
    width: 240px;
    padding: 21px 0 30px 29px;

    &.profile-menu-active {
      background-color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-800)')};
    }
  }

  .user-img {
    padding-bottom: 8px;
  }

  .img-text {
    padding: 15px 0 13px 1px;
    background-color: ${({ theme }) => (theme.isLaddrB2C ? 'lime' : 'var(--lp-colors-turquoise-600)')};
    color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : 'var(--lp-colors-medium-blue-800)')};
    font-size: 14px;
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 0;
    border: 0;
  }

  .nav-link {
    width: 100%;
    display: flex;
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    font-weight: 300;
    font-size: 17px;
    cursor: pointer;
    position: relative;
    outline: none;
    border: none;
    padding: 0;
  }

  .nav-link .business-name,
  .nav-link .brand-name {
    color: #fff;
    text-align: left;
  }

  .brand-name {
    font-size: 11px;
    line-height: 16px;
  }

  .arrow-down {
    width: 20px;
    fill: #fff;
    transition: transform 0.3s ease-in-out 0s;
    margin-left: auto;
    margin-right: 30px;

    &.up {
      transform: rotate(180deg);
    }
  }

  .profile-menu-active {
    .nav-link .business-name,
    .nav-link .brand-name {
      color: #fff;
    }
    .arrow-down {
      fill: #fff;
    }
  }

  .collapse {
    height: 0;
    opacity: 0;
    transition: height 0.3s ease-in-out 0s;
    &.show {
      height: 50px;
      opacity: 1;
      &.activated {
        height: 100px;
      }
    }
  }

  .profile-dropdown-menu {
    padding-top: 15px;
  }

  .dropdown-item {
    padding: 12px 0;
    width: 100%;
    color: #fff;
    display: block;
    text-decoration: none;
    text-align: left;
    background: transparent;
    border: 0;
    font-size: 16px;
    cursor: pointer;
  }

  .close-btn {
    display: none;
  }

  @media (max-width: 960px) {
    .collapse-btn {
      display: block;
    }

    .sidebar {
      width: 240px;
      left: -240px;

      &.open {
        left: 0;
      }
    }

    .brand {
      display: ${({ theme }) => (theme.isLaddrB2C ? 'block' : 'none')};
    }

    .close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: #fff;
      position: absolute;
      right: 25px;
      top: 25px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .close-icon {
      width: 10px;
      path {
        fill: var(--lp-colors-medium-blue-600) !important;
      }
    }

    .menu {
      padding-top: ${({ theme }) => (theme.isLaddrB2C ? '0' : '27px')};
      z-index: 99;
    }
  }
  @media (max-width: 768px) {
    .collapse-btn {
      top: 15px;
    }
  }
`;
