import './App.scss';
import './css/global.css';

import { loginWithToken, logout } from 'APIs/identity';
import APIKeys from 'Components/APIKeys/APIKeys';
import AccountSetup from 'Components/AccountSetup';
import Alert from 'Components/Alert/Alert';
import Balance from 'Components/Balance/';
import BankDetails from 'Components/BankDetails';
import Customers from 'Components/Customers/';
import ExpiredLink from 'Components/ExpiredLink';
import ForgetPwd from 'Components/ForgetPwd';
import Login from 'Components/Login/Login';
import ManualPayments from 'Components/ManualPayments';
import MyAccount from 'Components/MyAccount/MyAccount';
import Overview from 'Components/Overview';
import Payments from 'Components/Payments/';
import Refunds from 'Components/Refunds/';
import ResetPwd from 'Components/ResetPwd';
import { CountryType } from 'Components/SelectCountry';
import Settings from 'Components/Settings/Settings';
import { BrandingAPIResponse } from 'Components/Settings/types';
import Sidebar from 'Components/Sidebar/Sidebar';
import Spinner from 'Components/Spinner/Spinner';
import Transfers from 'Components/Transfer/Transfer';
import qs from 'query-string';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
  setConfig,
  setMerchantActivated,
  setMerchantEmail,
  setPersonalInfo,
  setUserPermissions,
  updateLoggedIn,
} from 'redux/Actions/actions';
import { ReduxStateType, UserPermissionType, UserRoleType } from 'redux/Constants/types';
import compareTwoStrings from 'utils/compareTwoStrings';
import { getLimepayApiHost } from 'utils/env';
import getCurrentUser from 'utils/getCurrentUser';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import useFavicon from 'utils/useFavicon';
import { clearZendeskWidget, configZendeskWidget, identifyZendeskUser, loadZendeskScript } from 'utils/zendesk';

import { ThemeProvider } from '@emotion/react';

import { firebaseApp } from './APIs/firebase';
import PrivateRoute from './PrivateRoute';
import { hasPayoutAccess } from './utils/userPermissions';

const App = (): ReactElement => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    apiBaseUri,
    isLoggedIn,
    isLoginPending,
    merchantId,
    isLaddrB2C,
    isMerchantActivated,
    firstName,
    lastName,
    merchantEmail,
    businessName,
    tenantId,
    userPermissions,
    userRole,
    branding,
  } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    isLoggedIn: state.isLoggedIn,
    isLoginPending: state.isLoginPending,
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
    isMerchantActivated: state.isMerchantActivated,
    firstName: state.personalInfo?.firstName ?? '',
    lastName: state.personalInfo?.lastName ?? '',
    merchantEmail: state.merchantEmail,
    businessName: state.publicInfo?.businessName ?? '',
    tenantId: state.tenantId,
    userPermissions: state.userPermissions,
    userRole: state.userRole,
    branding: state.branding,
  }));

  const [merchantName] = useState(() => getMerchantName());
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setMerchantEmailCB = useCallback((e: string) => dispatch(setMerchantEmail(e)), [dispatch]);
  const updateLoggedInCB = useCallback((isLoggedIn: boolean) => dispatch(updateLoggedIn(isLoggedIn)), [dispatch]);
  const setConfigCB = useCallback(
    (
      uri: string,
      id: string,
      mId: string,
      orderBaseUri: string,
      branding: BrandingAPIResponse,
      taxCountry: CountryType,
      merchantTradingCountry: CountryType,
      marketplaceTags: Array<string>,
      merchantTags: Array<string>,
      merchantPublicKey: string,
      abacusEnabled: boolean,
      sourceVerificationEnabled: boolean,
    ) =>
      dispatch(
        setConfig(
          uri,
          id,
          mId,
          orderBaseUri,
          branding,
          taxCountry,
          merchantTradingCountry,
          marketplaceTags,
          merchantTags,
          merchantPublicKey,
          abacusEnabled,
          sourceVerificationEnabled,
        ),
      ),
    [dispatch],
  );
  const setMerchantActivatedCB = useCallback((is: boolean) => dispatch(setMerchantActivated(is)), [dispatch]);
  const setMerchantUserPermissions = useCallback(
    (userRolePermissions: Array<UserPermissionType>, userRole: UserRoleType) =>
      dispatch(setUserPermissions(userRolePermissions, userRole)),
    [dispatch],
  );

  const { ref, token } = qs.parse(window.location.search);

  useFavicon(branding, isLaddrB2C);

  useEffect(() => {
    if (isLoggedIn !== false || !token || !tenantId) {
      return;
    }
    const login = async () => {
      await loginWithToken(tenantId, token as string);
      window.location.href = `/${merchantName}`;
    };
    login();
  }, [isLoggedIn, merchantName, token, tenantId]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await fetch(`${await getLimepayApiHost()}/config/merchant?m=${merchantName}`);
        if (!response.ok) {
          await handleApiError(response);
        }
        const {
          authApiKey,
          authDomain,
          apiBaseUri,
          tenantId,
          merchantId,
          orderBaseUri,
          branding,
          merchantTaxCountry,
          merchantTradingCountry,
          marketplaceTags,
          merchantTags,
          merchantPublicKey,
          abacusEnabled,
          sourceVerificationEnabled,
        } = await response.json();
        setConfigCB(
          apiBaseUri,
          tenantId,
          merchantId,
          orderBaseUri,
          branding,
          merchantTaxCountry,
          merchantTradingCountry,
          marketplaceTags,
          merchantTags,
          merchantPublicKey,
          abacusEnabled,
          sourceVerificationEnabled,
        );

        await loadZendeskScript(marketplaceTags.includes('Laddr')).catch(console.error);

        firebaseApp
          .initializeApp({ apiKey: authApiKey, authDomain })
          .auth()
          .onAuthStateChanged(async () => {
            const currentUser = await getCurrentUser();
            updateLoggedInCB(!!currentUser);
            if (!currentUser) {
              clearZendeskWidget();
              return;
            }
            if (compareTwoStrings(merchantId, currentUser.claims.limepay.merchantId)) {
              setMerchantEmailCB(currentUser.claims.email);
              configZendeskWidget();
            } else {
              await logout();
              if (!window.location.href.includes('/login')) {
                window.location.href = `/${merchantName}/login`;
              }
            }
          });
      } catch (error) {
        setErrorMsg(error?.message || 'Failed to fetch config');
      }
    };
    initialize();
  }, [merchantName, setConfigCB, setMerchantEmailCB, updateLoggedInCB]);

  useEffect(() => {
    if (!ref || !apiBaseUri || !merchantName || isLoggedIn || window.location.pathname.includes('set-password')) {
      return;
    }
    setIsLoading(true);
    const url = `${apiBaseUri}/authn/validate-token`;

    const options = {
      method: 'POST',
      headers: {
        'Limepay-Token': ref as string,
        'Content-Type': 'application/json',
      },
    };
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      })
      .then(({ hasLastLogin, isExpired }) => {
        setErrorMsg('');
        let href = `/${merchantName}`;
        if (hasLastLogin) {
          href += '/login';
        } else if (!hasLastLogin && isExpired) {
          href += '/expired-link';
        } else {
          href += `/set-password?ref=${ref}`;
        }
        window.location.href = href;
      })
      .catch((e) => {
        setErrorMsg(e?.message || 'Failed to validate token status');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiBaseUri, isLoggedIn, merchantName, ref]);

  useEffect(() => {
    if (!isLoggedIn || isLoginPending || !apiBaseUri || !merchantId) {
      return;
    }

    const fetchStatus = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/status`;
      const options = await getFetchOptions();
      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then(({ isActivated }) => {
          setMerchantActivatedCB(isActivated);
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Failed to fetch merchant status');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const fetchMerchantUserPermissions = async () => {
      // Getting merchant User Permission
      const url = `${apiBaseUri}/authn/get-merchant-user-permissions`;
      const options = await getFetchOptions();

      await fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          const response = await res.json();
          setMerchantUserPermissions(response.permissions, response.role);
        })
        .catch((e) => {
          setErrorMsg(e?.message || 'Error fetching permissions');
        });
    };

    fetchStatus();
    fetchMerchantUserPermissions();
  }, [apiBaseUri, isLoggedIn, isLoginPending, merchantId, setMerchantActivatedCB, setMerchantUserPermissions]);

  useEffect(() => {
    if (!isLoggedIn || isLoginPending || !apiBaseUri || !merchantId) {
      return;
    }
    const fetchPersonalInfo = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          dispatch(setPersonalInfo(response));
        })
        .catch((e) => {
          console.error(e.message || 'Failed to get personal information');
        });
    };
    fetchPersonalInfo();
  }, [apiBaseUri, dispatch, isLoggedIn, isLoginPending, merchantId]);

  useEffect(() => {
    if (!merchantEmail || !businessName) {
      return;
    }
    const name = `${firstName} ${lastName}`;
    identifyZendeskUser(name, merchantEmail, businessName);
  }, [businessName, firstName, lastName, merchantEmail]);

  return (
    <ThemeProvider theme={{ isLaddrB2C }}>
      <div className={`App ${!isLoggedIn || isLoginPending ? 'no-sidebar' : ''}`}>
        {errorMsg.length > 0 && <Alert message={errorMsg} />}
        {isLoading && errorMsg.length === 0 && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
        {!isLoading && errorMsg.length === 0 && (
          <BrowserRouter>
            {!isLoginPending && isLoggedIn && <Sidebar />}
            <Switch>
              <Route path="/:merchantName/login" component={Login} />
              <Route path="/:merchantName/forget-password" component={ForgetPwd} />
              {!isLoggedIn && (
                <>
                  <Route path="/:merchantName/expired-link" component={ExpiredLink} />
                  <Route path="/:merchantName/set-password" component={ResetPwd} />
                  <Route path="/:merchantName/reset-password" component={ResetPwd} />
                </>
              )}
              {isLoggedIn !== null && isLaddrB2C === false && (
                <PrivateRoute exact isLoggedIn={isLoggedIn} path="/:merchantName/home" component={Overview} />
              )}
              {isLoggedIn !== null && isLaddrB2C && (
                <PrivateRoute
                  isLoggedIn={isLoggedIn}
                  path="/:merchantName/payment-requests"
                  component={ManualPayments}
                />
              )}
              {isLoggedIn !== null && isMerchantActivated === false && (
                <>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/account-setup" component={AccountSetup} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/bank-details" component={BankDetails} />
                </>
              )}
              {isLoggedIn !== null && isMerchantActivated && isLaddrB2C === false && (
                <>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/payments" component={Payments} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/refunds" component={Refunds} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/balance" component={Balance} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/customers" component={Customers} />
                  <PrivateRoute
                    isLoggedIn={isLoggedIn}
                    allowAccess={hasPayoutAccess(userRole, userPermissions)}
                    path="/:merchantName/payouts"
                    component={Transfers}
                  />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/api" component={APIKeys} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/settings" component={Settings} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/account" component={MyAccount} />
                  <PrivateRoute
                    isLoggedIn={isLoggedIn}
                    path="/:merchantName/virtual-terminal"
                    component={ManualPayments}
                  />
                </>
              )}
              {isLoggedIn !== null && isMerchantActivated && isLaddrB2C && (
                <>
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/payments" component={Payments} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/payouts" component={Transfers} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/refunds" component={Refunds} />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/settings" component={Settings} />
                  <PrivateRoute
                    isLoggedIn={isLoggedIn}
                    path="/:merchantName/payment-requests"
                    component={ManualPayments}
                  />
                  <PrivateRoute isLoggedIn={isLoggedIn} path="/:merchantName/account" component={MyAccount} />
                </>
              )}
              {isLoggedIn !== null && isLaddrB2C === false && (
                <>
                  {!ref && (
                    <Route
                      path="/:merchantName"
                      exact
                      render={(props) => <Redirect to={`/${merchantName}/home`} {...props} />}
                    />
                  )}
                </>
              )}
              {isLoggedIn !== null && isLaddrB2C && (
                <>
                  {!ref && (
                    <Route
                      path="/:merchantName"
                      exact
                      render={(props) => <Redirect to={`/${merchantName}/payment-requests`} {...props} />}
                    />
                  )}
                </>
              )}
            </Switch>
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
