import styled from '@emotion/styled';

export const ExportDataWrapper = styled.div`
  position: relative;
  padding: 30px 20px 0;

  @media (min-width: 1200px) {
    padding: 16px 60px 11px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OrderIdBtn = styled.button`
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const MessageBar = styled.div`
  padding: 0 20px;
  @media (min-width: 1200px) {
    padding: 0 60px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  .header-wrapper {
    display: flex;
    padding: 0 22px 0 0;
    align-items: center;
    .calendar-wrapper {
      margin-left: auto;
    }
    .header-calendar {
      display: flex;
      align-items: center;
      width: calc(100% - 320px);
    }
  }
  .refund-export-data {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px #e7e7e7 solid;
    margin: 0;
  }

  .spinner-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    .header-wrapper {
      padding: 0 60px 0 0;
    }
  }
  @media (max-width: 960px) {
    .header-title.lp-dashboard-top {
      padding-left: 45px;
    }
    .header-wrapper {
      .calendar-wrapper,
      .order-search {
        padding-top: 12px;
        padding-bottom: 11px;
      }
    }
  }
  @media (max-width: 576px) {
    .header-wrapper {
      flex-direction: column;
      height: auto;
      .header-calendar {
        width: 100%;
      }
      .order-search {
        padding-top: 0;
      }
    }
  }
`;
