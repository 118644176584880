import Alert from 'Components/Alert/Alert';
import SelectCountry from 'Components/SelectCountry';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox, LpDetails } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setAccount, setTaxCountry } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import validateEmail from 'utils/validateEmail';

import * as s from './styles';
import tradeTypes from './tradeTypes';
import {
  BusinessInfoAPIResponse,
  BusinessTypeEnum,
  CountryType,
  MetadataAPIResponse,
  PersonalInfoAPIResponse,
  PublicInfoAPIResponse,
} from './types';
import validateAbn from './validateAbn';
import validateNzbn from './validateNzbn';

const AccountSetup = (): ReactElement => {
  const [website, setWebsite] = useState<string>('');
  const [businessType, setBusinessType] = useState<BusinessTypeEnum>(BusinessTypeEnum.Company);
  const [businessName, setBusinessName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [brandName, setBrandName] = useState<string>('');
  const [phoneNo, setPhoneNo] = useState<string>('');
  const [country, setCountry] = useState<CountryType>(CountryType.AU);
  const [taxId, setTaxId] = useState<string>('');
  const [cardStatementName, setCardStatementName] = useState<string>('');
  const [validPhoneNo, setValidPhoneNo] = useState<boolean>(true);
  const [isValidContactEmail, setIsValidContactEmail] = useState<boolean>(true);
  const [isValidTaxId, setIsValidTaxId] = useState<boolean>(true);
  const [isValidCardStatementName, setIsValidCardStatementName] = useState<boolean>(true);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [merchantName] = useState(() => getMerchantName());
  const [redirectToNextStep, setRedirectToNextStep] = useState<boolean>(false);
  const [tradeType, setTradeType] = useState<string>('');
  const [tradeLicence, setTradeLicence] = useState<string>('');
  const [isValidTradeType, setIsValidTradeType] = useState<boolean>(true);

  const { apiBaseUri, merchantId, taxCountry, isLaddrB2C } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
    taxCountry: state.taxCountry,
    isLaddrB2C: state.isLaddrB2C,
  }));

  const platformName = isLaddrB2C ? 'LaddrPay' : 'April';
  const platformEmail = isLaddrB2C ? 'support@limepayladdr.zendesk.com' : 'support@meetapril.com';

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    const fetchData = async (url: string) => {
      const options = await getFetchOptions();
      return fetch(url, options).then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      });
    };

    const fetchBusinessInfo = (): Promise<BusinessInfoAPIResponse> => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/business`;
      return fetchData(url);
    };

    const fetchPersonalInfo = (): Promise<PersonalInfoAPIResponse> => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
      return fetchData(url);
    };

    const fetchPublicInfo = (): Promise<PublicInfoAPIResponse> => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
      return fetchData(url);
    };

    const fetchMetadata = async (): Promise<MetadataAPIResponse> => {
      const [tradeType, tradeLicence] = await Promise.all([
        fetchData(`${apiBaseUri}/merchants/${merchantId}/meta-data/kvp/TradeType`),
        fetchData(`${apiBaseUri}/merchants/${merchantId}/meta-data/kvp/TradeLicence`),
      ]);
      return {
        tradeType: tradeType?.value ?? '',
        tradeLicence: tradeLicence?.value ?? '',
      };
    };

    const getAllData = async () => {
      try {
        setFetchLoading(true);
        const [businessInfo, personalInfo, publicInfo, metadata] = await Promise.all([
          fetchBusinessInfo(),
          fetchPersonalInfo(),
          fetchPublicInfo(),
          fetchMetadata(),
        ]);
        setTaxId(businessInfo.taxId ?? '');
        dispatch(setTaxCountry(businessInfo.taxCountry ?? CountryType.AU));
        setCountry(businessInfo.country ?? CountryType.AU);
        setBusinessType(businessInfo.businessType ?? BusinessTypeEnum.Company);
        setFirstName(personalInfo.firstName ?? '');
        setLastName(personalInfo.lastName ?? '');
        setBusinessName(publicInfo.businessName ?? '');
        setBrandName(publicInfo.brandName ?? '');
        setWebsite(publicInfo.website ?? '');
        setCardStatementName(publicInfo.cardStatementName ?? '');
        setContactEmail(publicInfo.contactEmail ?? '');
        setPhoneNo(publicInfo.phoneNo ?? '');
        setTradeType(metadata.tradeType ?? '');
        setTradeLicence(metadata.tradeLicence ?? '');
      } catch (e) {
        console.error(e.message);
      } finally {
        setFetchLoading(false);
      }
    };
    getAllData();
  }, [apiBaseUri, dispatch, merchantId]);

  const setPublicInfo = async () => {
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
    const body = {
      businessName,
      brandName,
      website: isLaddrB2C ? 'https://laddr.com.au' : website,
      cardStatementName,
      supportSite: isLaddrB2C ? 'https://laddr.com.au' : website,
      contactEmail,
      supportEmail: contactEmail,
      phoneNo,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));
    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
  };

  const setPersonalInfo = async () => {
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
    const body = {
      firstName,
      lastName,
    };
    const options = await getFetchOptions('PATCH', JSON.stringify(body));
    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
  };

  const setBusinessInfo = async () => {
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/business`;
    const body = {
      typeOfProduct: '',
      country,
      taxCountry,
      taxId: taxId.trim(),
      businessType,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));
    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
  };

  const setMetadataKey = async (key: 'TradeType' | 'TradeLicence', value: string) => {
    const url = `${apiBaseUri}/merchants/${merchantId}/meta-data/kvp`;
    const body = {
      key,
      value,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));
    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
  };

  const setMetadata = async () => {
    if (isLaddrB2C) {
      await setMetadataKey('TradeType', tradeType);
      await setMetadataKey('TradeLicence', tradeLicence || 'N/A');
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    let isReady = true;
    if (!validateEmail(contactEmail)) {
      setIsValidContactEmail(false);
      isReady = false;
    }

    if (taxCountry === CountryType.AU && !validateAbn(taxId)) {
      setIsValidTaxId(false);
      isReady = false;
    }

    if (taxCountry === CountryType.NZ && !validateNzbn(taxId)) {
      setIsValidTaxId(false);
      isReady = false;
    }

    if (cardStatementName.length < 5 || cardStatementName.length > 13 || !/^[a-zA-Z]+$/.test(cardStatementName)) {
      setIsValidCardStatementName(false);
      isReady = false;
    }

    const parsedPhoneNo = parsePhoneNumber(phoneNo ?? '');
    if (!parsedPhoneNo || !parsedPhoneNo.country) {
      setValidPhoneNo(false);
      isReady = false;
    }

    if (isLaddrB2C && !tradeType) {
      setIsValidTradeType(false);
      isReady = false;
    }

    if (!apiBaseUri || !merchantId) {
      isReady = false;
    }

    if (!isReady) return;

    try {
      setSubmitLoading(true);
      await Promise.all([setPublicInfo(), setPersonalInfo(), setBusinessInfo(), setMetadata()]);

      const url = `${apiBaseUri}/onboarding/${merchantId}/onboard`;
      const options = await getFetchOptions('POST');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }

      dispatch(setAccount(true));
      setRedirectToNextStep(true);
      window.scrollTo({ top: 0 });
    } catch (e) {
      setErrorMsg(e?.message || 'Failed to setup account');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleChangeContactEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setIsValidContactEmail(validateEmail(value));
    setContactEmail(value);
  };

  const handleChangeTaxId = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (taxCountry === CountryType.AU) {
      setIsValidTaxId(validateAbn(value));
    }
    if (taxCountry === CountryType.NZ) {
      setIsValidTaxId(validateNzbn(value));
    }
    setTaxId(value);
  };

  const handleChangeCardStatementName = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const { length } = value;
    setIsValidCardStatementName(length >= 5 && length <= 13 && /^[a-zA-Z]+$/.test(value));
    setCardStatementName(value);
  };

  const handleChangePhoneNo = (value: string = ''): void => {
    setValidPhoneNo(isPossiblePhoneNumber(value));
    setPhoneNo(value);
  };

  const handleResetData = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setWebsite('');
    setBusinessType(BusinessTypeEnum.Company);
    setContactEmail('');
    setFirstName('');
    setLastName('');
    setBrandName('');
    setCountry(CountryType.AU);
    setTaxId('');
    dispatch(setTaxCountry(CountryType.AU));
    setCardStatementName('');
    setIsValidContactEmail(true);
    setIsValidTaxId(true);
    setIsValidCardStatementName(true);
    setErrorMsg('');
    setPhoneNo('');
    setValidPhoneNo(true);
    setIsValidTradeType(true);
  };

  const handleSetTaxCountry = (c: CountryType) => {
    dispatch(setTaxCountry(c));
  };

  if (redirectToNextStep) {
    return <Redirect to={`/${merchantName}/bank-details`} push />;
  }

  return (
    <Content>
      <s.HeaderWrapper>
        <s.Title>Account Set-up</s.Title>
        <s.Description>
          Please complete the information below to proceed with the final steps of setting up your {platformName}{' '}
          account.
        </s.Description>
        <s.Description className="mt-1">
          Reach out to our Customer Success team at{' '}
          <s.SupportLink href={`mailto:${platformEmail}`}>{platformEmail}</s.SupportLink> if you have any questions.
        </s.Description>
      </s.HeaderWrapper>

      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {fetchLoading && (
        <s.FetchSpinner>
          <Spinner />
        </s.FetchSpinner>
      )}

      {!fetchLoading && (
        <LpBox>
          <s.SubTitle>Business information</s.SubTitle>

          <form onSubmit={handleSubmit}>
            <LpDetails>
              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Business name (as linked to {taxCountry === CountryType.AU ? 'ABN/ACN' : 'NZBN'})</s.Label>
                <s.Input
                  type="text"
                  data-testid="businessName"
                  required
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Brand name</s.Label>
                <s.InputDescription>This is the customer facing name of your business.</s.InputDescription>
                <s.Input
                  type="text"
                  data-testid="brandName"
                  required
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>{taxCountry === CountryType.AU ? 'ABN' : 'NZBN'}</s.Label>
                <s.Input
                  data-testid="taxId"
                  type="text"
                  required
                  isValid={isValidTaxId}
                  value={taxId}
                  onChange={handleChangeTaxId}
                />
                {!isValidTaxId && (
                  <s.ErrorMsg>Please enter a valid {taxCountry === CountryType.AU ? 'ABN' : 'NZBN'}</s.ErrorMsg>
                )}
              </div>

              {!isLaddrB2C && (
                <div className="pt-4 pb-0 pb-md-2">
                  <s.Label>eCommerce website URL</s.Label>
                  <s.InputDescription>
                    This is the URL that the {platformName} payment gateway will be implemented on.
                  </s.InputDescription>
                  <s.Input
                    data-testid="website"
                    type="text"
                    required
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
              )}

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Card statement name</s.Label>
                <s.InputDescription>
                  The Card statement name is the label that will appear on your customer's credit or debit card
                  statements. Make sure this is easily recognisable to help your customers remember where they shopped
                  and to avoid unintended disputes. Card statement names can be:
                  <s.CardStatementLi>Between 5-13 characters</s.CardStatementLi>
                  <s.CardStatementLi>Upper or lowercase</s.CardStatementLi>
                  <s.CardStatementLi>
                    No spaces, numbers or special characters such as ! @ # & () - [] : ; , ? /.
                  </s.CardStatementLi>
                </s.InputDescription>

                <s.Input
                  data-testid="cardStatementName"
                  type="text"
                  required
                  value={cardStatementName}
                  onChange={handleChangeCardStatementName}
                  isValid={isValidCardStatementName}
                />
                {!isValidCardStatementName && (
                  <s.ErrorMsg>Please enter a valid name following the instructions above</s.ErrorMsg>
                )}
              </div>

              <s.PhoneNoWrapper className="pt-4 pb-0 pb-md-2">
                <s.Label>Phone number</s.Label>
                <PhoneInput
                  className={validPhoneNo ? '' : 'invalid-phone-no'}
                  defaultCountry="AU"
                  international
                  value={phoneNo ?? ''}
                  onChange={handleChangePhoneNo}
                />
                {!validPhoneNo && <s.ErrorMsg>Please enter a valid phone number</s.ErrorMsg>}
              </s.PhoneNoWrapper>

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Business type</s.Label>
                <s.Select
                  data-testid="businessType"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value as BusinessTypeEnum)}
                >
                  <option data-testid="businessTypeOption1" value={BusinessTypeEnum.Company}>
                    Company
                  </option>
                  <option data-testid="businessTypeOption2" value={BusinessTypeEnum.Individual}>
                    Individual
                  </option>
                </s.Select>
              </div>

              <SelectCountry
                className="pt-4 pb-0 pb-md-2"
                label="Country"
                country={country}
                setCountry={setCountry}
                selectTestId="country"
                option1TestId="countryOption1"
                option2TestId="countryOption2"
              />

              <SelectCountry
                className="pt-4 pb-0 pb-md-2"
                label="Tax country"
                country={taxCountry}
                setCountry={handleSetTaxCountry}
                selectTestId="taxCountry"
                option1TestId="taxCountryOption1"
                option2TestId="taxCountryOption2"
              />

              {isLaddrB2C && (
                <>
                  <div className="pt-4 pb-0 pb-md-2">
                    <s.Label>Primary trade type</s.Label>
                    <s.Select
                      data-testid="tradeType"
                      value={tradeType}
                      onChange={(e) => {
                        setIsValidTradeType(!!e.target.value);
                        setTradeType(e.target.value);
                      }}
                      required
                    >
                      <option data-testid="tradeTypeOption-select" value="">
                        Select trade type
                      </option>
                      {tradeTypes.map((tradeType) => (
                        <option key={tradeType} data-testid={`tradeTypeOption-${tradeType}`} value={tradeType}>
                          {tradeType}
                        </option>
                      ))}
                    </s.Select>
                    {!isValidTradeType && <s.ErrorMsg>Please enter a valid trade type</s.ErrorMsg>}
                  </div>

                  <div className="pt-4 pb-0 pb-md-2">
                    <s.Label>Trade license no.</s.Label>
                    <s.InputDescription>(if applicable)</s.InputDescription>
                    <s.Input
                      data-testid="TradeLicence"
                      type="text"
                      value={tradeLicence}
                      onChange={(e) => setTradeLicence(e.target.value)}
                    />
                  </div>
                </>
              )}

              <s.Hr />
              <s.SubTitle>Primary business contact</s.SubTitle>
              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>First name</s.Label>
                <s.Input
                  type="text"
                  data-testid="firstName"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Last name</s.Label>
                <s.Input
                  type="text"
                  data-testid="lastName"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>Company contact email</s.Label>
                <s.Input
                  data-testid="contactEmail"
                  type="email"
                  required
                  isValid={isValidContactEmail}
                  value={contactEmail}
                  onChange={handleChangeContactEmail}
                  placeholder="name@domain.com"
                />
                {!isValidContactEmail && <s.ErrorMsg>Please enter a valid email address</s.ErrorMsg>}
              </div>

              <div className="pt-4 pb-0 pb-md-2">
                <s.BtnWrapper>
                  <s.CancelBtn onClick={handleResetData}>Reset all data</s.CancelBtn>
                  <s.SubmitBtn data-testid="submitBtn" type="submit" disabled={submitLoading}>
                    Save and continue
                    {submitLoading && (
                      <s.SpinnerWrapper>
                        <Spinner height="20px" width="20px" borderWidth="2px" />
                      </s.SpinnerWrapper>
                    )}
                  </s.SubmitBtn>
                </s.BtnWrapper>
              </div>
            </LpDetails>
          </form>
        </LpBox>
      )}
    </Content>
  );
};

export default AccountSetup;
