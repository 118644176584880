import { Row } from 'Constants/styles';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 17px;
    padding-bottom: 15px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #6c7488;
  padding: 0 65px 20px 22px;
  margin-top: -5px;
  @media (min-width: 1200px) {
    padding: 0 65px 30px 60px;
    margin-top: -13px;
  }
  @media (max-width: 960px) {
    padding-left: 63px;
    margin-top: 17px;
  }
  .link {
    color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
    margin: 0 4px;
    &:active {
      color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
    }
  }
`;

export const CreateBtn = styled.button`
  background: ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 45px;
  width: 224px;
  min-width: 224px;
  color: ${({ theme }) => (theme.isLaddrB2C ? 'black' : 'white')};
  font-size: 14px;
  font-weight: ${({ theme }) => (theme.isLaddrB2C ? 'bold' : 'normal')};
  line-height: 24px;
  margin-top: 40px;
  margin-right: 22px;
  margin-left: auto;
  cursor: pointer;
  @media (min-width: 1200px) {
    margin-right: 60px;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const ModalRow = styled(Row)<{ top: string; mobileTop?: string }>`
  margin-top: ${({ top }) => top};
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: ${({ top, mobileTop }) => mobileTop || top};
  }
`;

export const Input = styled.input`
  padding: 12px 8px;
  height: 40px;
  outline: none;
  background: #fff;
  border: 1px solid #dce3eb;
  border-radius: 3px;
  width: 100%;
  color: #000;
  margin: 0;
`;

export const AmountField = styled.div`
  position: relative;
  display: block;
  &:before {
    left: 1px;
    top: 1px;
    content: '$';
    position: absolute;
    line-height: 38px;
    border-right: 1px #dce3eb solid;
    background: #f8f9fb;
    width: 40px;
    height: 38px;
    text-align: center;
    box-sizing: border-box;
  }
`;

export const AmountInput = styled.input<{ bgColor?: string }>`
  padding: 12px 8px 12px 48px;
  height: 40px;
  outline: none;
  background: ${({ bgColor }) => bgColor ?? '#fff'};
  border: 1px solid #dce3eb;
  border-radius: 3px;
  width: 100%;
  color: #000;
  margin: 0;
  -webkit-text-fill-color: #000;
  opacity: 1;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 18px;
  color: #6c7488;
  margin-bottom: 10px;
  display: block;
`;

export const FieldDescription = styled.div`
  font-size: 12px;
  margin-top: 6px;
  color: #6c7488;
`;

export const Textarea = styled.textarea`
  background: #ffffff;
  border: 1px solid #dce3eb;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.3);
  border-radius: 3px;
  width: 100%;
  height: 80px;
  padding: 12px 8px;
  outline: none;
`;

export const EditViewWrapper = styled.div`
  padding: 35px 0 23px;

  .PhoneInputInput {
    padding: 17px 17px 12px;
    height: 40px;
    outline: none;
    background: #fff;
    border: 1px solid #dce3eb;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
    margin: 0;
  }
  .PhoneInputCountry {
    background: #f8f9fb;
    width: 60px;
    margin: 0;
    padding: 0 12px;
    border: 1px solid #dce3eb;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 40px;
  }

  .PhoneInputCountrySelectArrow {
    width: 6px;
    height: 6px;
    padding-left: 0px;
    position: absolute;
    right: 8px;
    opacity: 1;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }
`;

export const ConfirmViewWrapper = styled.div`
  padding-bottom: 34px;
`;

export const BtnGroup = styled.div`
  display: flex;
  padding: 26px 45px 26px;
  background-color: #f9f9f9;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0 -43px;
  @media (max-width: 768px) {
    margin: 0 -20px;
    padding: 15px 16px;
  }
`;

export const CancelBtn = styled.button`
  font-size: 14px;
  line-height: 24px;
  color: #5f697c;
  padding: 12px 29px 10px;
  background: #ffffff;
  border: 1px solid #cacdd1;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
`;

export const ConfirmBtn = styled.button`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  padding: 12px 29px 10px;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

export const SendBtn = styled.button`
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 12px 16px 10px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  display: flex;
  cursor: pointer;
  margin-left: auto;
  height: 48px;
  &:disabled {
    opacity: 0.7;
  }
`;

export const TickBtn = styled.div`
  margin-left: 4px;
  width: 24px;
  height: 24px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
  }
  path {
    stroke: #fff;
  }
`;

export const ProcessBtn = styled.button`
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 12px 16px 10px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  height: 48px;
  &:disabled {
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 24px;
  margin-right: 7px;
`;

export const BtnText = styled.div`
  padding-top: 2px;
  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  padding-top: 4px;
`;

export const ErrorMsg = styled.div`
  color: red;
  text-align: right;
  margin-top: 4px;
  font-size: 14px;
  height: 17px;
`;

export const ConfirmTitle = styled.h3<{ top: string }>`
  font-size: 15px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0;
  margin-top: ${(props) => props.top};
`;

export const ConfirmValue = styled.div<{ top?: string }>`
  font-size: 15px;
  line-height: 21px;
  color: #6c7488;
  margin-top: ${(props) => props.top};
`;

export const OrderAmount = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-top: 3px;
`;

export const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #000;
  margin-top: 15px;
`;

export const SkipWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;

export const SkipToDetails = styled.button`
  background: #fff;
  border: 1px solid #cacdd1;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 46px;
  width: 167px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const AddItemWrapper = styled.div`
  padding: 32px 0 60px;
`;

export const AddItemBtn = styled.button`
  margin-top: 27px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
`;

export const AddItemText = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  margin-right: 5px;
`;

export const ItemWrapper = styled.div<{ editItem: boolean }>`
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 30px 24px 26px;
  margin-bottom: 9px;
  background-color: ${({ editItem }) => (editItem ? '#fff' : '#f8f8f8')};
  overflow: hidden;
  position: relative;
`;

export const ItemBtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const SaveItemBtn = styled.button`
  background: #ffffff;
  border: 1px solid #bababa;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: var(--lp-colors-medium-blue-600);
  cursor: pointer;
  height: 40px;
  width: 77px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const RemoveItemBtn = styled.button`
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  color: #ff3333;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  margin-right: 29px;
  height: 40px;
`;

export const Relative = styled.div`
  position: relative;
`;

export const ItemErrorMsg = styled.div`
  color: red;
  text-align: right;
  margin-top: 4px;
  font-size: 14px;
  height: 17px;
  position: absolute;
  right: 15px;
`;

export const BreakdownTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  height: 28px;
  color: #000000;
  margin: 0 0 13px;
  padding: 0;
  font-family: 'Acumin Regular';
`;

export const ItemRow = styled.div`
  display: flex;
  min-height: 30px;
  margin-top: 10px;
  align-items: center;
  &:first-of-type {
    margin-top: 0;
  }
  .edit-icon,
  .delete-icon {
    margin-left: 30px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const BreakdownIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 30px;
  align-items: center;
  position: absolute;
  right: 24px;
  z-index: 1;
`;

export const ConfirmDeleteBtn = styled.button<{ active: boolean }>`
  padding: 3px 6px 0;
  height: 30px;
  color: #fff;
  font-size: 14px;
  background-color: #d3354a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: ${({ active }) => (active ? '3px' : '-100px')};
  transition: right 0.3s ease-in-out;
  z-index: 3;
`;

export const ItemName = styled.div`
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  font-size: 14px;
  word-break: break-all;
  @media (max-width: 768px) {
    width: auto;
    max-width: 200px;
  }
`;

export const ItemPrice = styled.div<{ left: string; hiddenOnMobile?: boolean }>`
  margin-left: ${({ left }) => left};
  line-height: 30px;
  font-size: 14px;
  padding-top: 2px;
  @media (max-width: 768px) {
    display: ${({ hiddenOnMobile }) => (hiddenOnMobile ? 'none' : 'block')};
  }
`;

export const MobilePrice = styled.div`
  line-height: 30px;
  font-size: 14px;
  display: none;
  @media (max-width: 768px) {
    display: block;
    line-height: 18px;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const EditViewEditBtn = styled.button`
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  height: 28px;
  margin: -8px 0 0 auto;
`;

export const BoldText = styled.div`
  font-family: 'Acumin Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
`;

export const ItemHr = styled.hr`
  border-top: 1px solid #bababa;
  margin: 15px 0;
`;

export const ItemNamePrice = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

export const CustomFieldsWrapper = styled.div`
  margin-top: -13px;
`;
