import './Balance.scss';

import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import React, { ReactElement } from 'react';
import { toCurrency } from 'utils/currency';

import { BalanceType } from './types';

const currencyLabel = {
  AUD: 'Australian dollars',
  NZD: 'New Zealand dollars',
  ZAR: 'South African rands',
};

export type BalanceProps = {
  balances: BalanceType[];
};

const Balance = ({ balances }: BalanceProps): ReactElement => (
  <div className="balance-page">
    <article className="content items-list-page reset-lr">
      {balances.map((balance, index) => (
        <div className="lp-box" key={index}>
          <div className="lp-details custom-mb-card">
            <div className="row">
              <div className="col-sm-8">
                <div className="media align-items-center">
                  <div className="feature-icon media-icon wicons">
                    <BankIcon className="bank-icon" />
                  </div>
                  <h3 className="pt-2 mb-0 pb-0 pb-md-0 pb-xl-0 pt-xl-1">Available</h3>
                </div>
              </div>
              <div className="col-sm-4 amount-wrap">
                <h3 className="pb-2 pb-md-0 pb-xl-0 pt-md-1 pt-xl-1 lp-color-purple balance-f custom-pt amount">
                  {toCurrency(balance.available, balance.currency)}
                </h3>
              </div>
            </div>
            <div className="row pt-0 pt-md-3 custom-ml-text">
              <div className="col-sm-8">
                <h2>Pending</h2>
              </div>
              <div className="col-sm-4 amount-wrap custom-ml-balance">
                <h3 className="amount">{toCurrency(balance.pending, balance.currency)}</h3>
              </div>
            </div>
            <div className="row">
              <p className="row-p">All payments received have been converted to {currencyLabel[balance.currency]}.</p>
            </div>
          </div>
        </div>
      ))}
    </article>
  </div>
);

export default Balance;
