export const getCustomizations = (isLaddrB2C: boolean) => {
  let labels = {
    paymentDetailTitle: 'Payment details',
  };

  if (isLaddrB2C) {
    labels = {
      ...labels,
      paymentDetailTitle: 'Order details',
    };
  }

  return labels;
};
