import Alert from 'Components/Alert/Alert';
import Calendar from 'Components/Calendar/Calendar';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Spinner from 'Components/Spinner/Spinner';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setFilterPeriod } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import getApiErrorMsg from 'utils/getApiErrorMsg';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';

import Overview from './Overview';
import { HeaderWrapper, SpinnerWrapper, Wrapper } from './Overview.styles';
import constructChartData from './constructChartData';
import { overviewTabs } from './overviewTabs';
import { ChartDataType } from './types';

const OverviewContainer = (): ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeZone] = useState(() => Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Sydney');
  const [charData, setCharData] = useState<ChartDataType | null>(null);
  const {
    merchantId,
    apiBaseUri,
    searchStartDate,
    searchEndDate,
    filterPeriod,
    searchTriggeredBy,
    isMerchantActivated,
  } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    searchStartDate: state.searchStartDate,
    searchEndDate: state.searchEndDate,
    filterPeriod: state.filterPeriod,
    searchTriggeredBy: state.searchTriggeredBy,
    isMerchantActivated: state.isMerchantActivated,
  }));

  const [merchantName] = useState(() => getMerchantName());
  const dispatch: Dispatch<any> = useDispatch();

  const setFilterPeriodCB = useCallback((f) => dispatch(setFilterPeriod(f)), [dispatch]);

  useEffect(() => {
    document.title = 'Overview - April';
  }, []);

  useEffect(() => {
    if (
      !searchStartDate ||
      !searchEndDate ||
      !filterPeriod ||
      searchTriggeredBy === 'overview' ||
      isMerchantActivated !== true ||
      !apiBaseUri ||
      !merchantId
    ) {
      return;
    }
    setIsLoading(true);

    const getData = async (url: string) => {
      const options = await getFetchOptions();
      return fetch(url, options).then(async (res) => {
        if (!res.ok) {
          const text = await res.text();
          let msg = text;
          try {
            msg = getApiErrorMsg(JSON.parse(text));
          } finally {
            throw Error(msg);
          }
        }
        return res.json();
      });
    };

    const localStartDate = convertUTCtoLocal(searchStartDate);
    const localEndDate = convertUTCtoLocal(searchEndDate);
    const timeParams = `createdAfter=${localStartDate}&createdBefore=${localEndDate}&timeZone=${timeZone}`;

    const getTransactions = () => {
      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/daily-transaction-count?${timeParams}`;
      return getData(url);
    };

    const getCustomers = () => {
      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/daily-customer-count?${timeParams}`;
      return getData(url);
    };

    const getAllData = async () => {
      try {
        setIsLoading(true);
        const [transactions, customers] = await Promise.all([getTransactions(), getCustomers()]);
        setCharData(constructChartData(transactions, customers));
      } catch (e) {
        setErrorMsg(e?.message || 'Failed to get customer or transaction counts');
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getAllData();
  }, [
    apiBaseUri,
    filterPeriod,
    isMerchantActivated,
    merchantId,
    searchEndDate,
    searchStartDate,
    searchTriggeredBy,
    timeZone,
  ]);

  const handleClickTab = (e: MouseEvent<HTMLButtonElement>, index: number): void => {
    e.preventDefault();
    const { value } = overviewTabs[index];
    setActiveTabIndex(index);
    setFilterPeriodCB(value);
  };

  if (isMerchantActivated === null) {
    return <></>;
  }

  if (isMerchantActivated === false) {
    return <Redirect to={`/${merchantName}/account-setup`} push />;
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle title="Overview" />
        <Calendar />
      </HeaderWrapper>
      {hasError && <Alert message={errorMsg} />}
      {isLoading && !hasError && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!isLoading && !hasError && (
        <Overview data={charData as ChartDataType} activeTabIndex={activeTabIndex} handleClickTab={handleClickTab} />
      )}
    </Wrapper>
  );
};

export default OverviewContainer;
