import styled from '@emotion/styled';

export const OrderIdBtn = styled.button`
  color: var(--lp-colors-medium-blue-600);
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const LinkBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
  color: #6c7488;
  display: block;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ListActionsLi = styled.li`
  padding: 3px 0;
  margin: 0;
  &:first-of-type {
    border: 0;
  }
`;

export const ListActionsUl = styled.ul`
  list-style: none;
  white-space: nowrap;
  padding: 0;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
