import { Content } from 'Constants/styles';

import styled from '@emotion/styled';

export const Wrapper = styled(Content)`
  h2 {
    font-family: 'Acumin Regular', Arial, sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  p {
    color: #6c7488;
    line-height: 24px;
    margin-top: 14px;
  }

  .lp-details {
    color: #000;
    font-weight: 500;

    .lp-radio input[type='radio'],
    .lp-option input[type='radio'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .check-mark {
      top: -1px;
      left: 0;
      position: absolute;
      height: 19px;
      width: 19px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #e7e7e7;
      box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

      &:after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #fff;
        border-radius: 50%;
      }
    }

    .lp-radio input:checked ~ .check-mark,
    .lp-option input:checked ~ .check-mark {
      background-color: #27cbcc;
      border: 1px solid #13bcbe;
      font-weight: bold;
      border-radius: 50%;

      &:after {
        display: block;
      }
    }

    .lp-radio {
      position: relative;
      padding-left: 28px;
    }

    label {
      color: #6c7488;
      font-weight: 300;
      margin-bottom: 8px;
    }

    .payout-menu-visible-mob {
      display: none;
    }

    .nav-automatic {
      display: flex;
      height: 28px;
    }

    .tclick {
      color: #6c7488;
      position: relative;
    }

    .capitalization {
      text-transform: capitalize;
    }

    .payout-schedule-menu {
      display: none;
      padding: 11px 20px 10px;
      margin: 0;
      width: 230px;
      list-style: none;
      position: absolute;
      top: 30px;
      left: 0;
      background-color: #fff;
      box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
      border: 1px solid rgba(126, 142, 159, 0.1);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 88;
      font-family: 'Acumin Pro';
      line-height: 18px;

      &.open {
        display: block;
      }

      li {
        padding: 7px 0;
        font-size: 15px;
        margin: 0;
      }
    }

    .payout-freq {
      margin-left: 5px;
      cursor: pointer;
      display: flex;
      height: 100%;

      &:hover {
        color: #0056b3;
        .arrow-down {
          fill: #0056b3;
        }
      }
    }

    .arrow-down {
      width: 20px;
      fill: #6c7488;
      transition: transform 0.3s ease-in-out 0s;
      &.up {
        transform: rotate(180deg);
      }
    }

    .lp-option {
      color: #000;
      display: block;
      position: relative;
      padding-left: 29px;
      margin-bottom: 4px;
      cursor: pointer;
      user-select: none;
    }
  }

  .input-email {
    padding: 17px 17px 12px 17px;
    outline: none;
    max-height: 44px;
    background-color: #fff;
    border: 1px solid #dce3eb;
    border-radius: 3px;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  }

  .invalid-email {
    color: red;
    font-size: 13px;
    text-align: right;
  }
  @media (max-width: 768px) {
    .lp-details {
      .hide-m-b {
        display: none;
      }

      .nav-automatic {
        display: block;
        height: auto;
      }

      .payout-schedule-menu {
        display: block;
        position: relative;
        top: 0;
        margin-top: 0;
        margin-bottom: 16px;
        width: 100%;
        border: 0;
        box-shadow: none;
        padding-top: 8px;
      }

      .invalid-email {
        position: absolute;
        right: 20px;
      }
    }
  }
`;
