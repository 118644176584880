import styled from '@emotion/styled';

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FeatureIcon = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;

  .user-icon {
    width: 16px;
    height: 16px;
  }
`;
