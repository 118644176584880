import Back from 'Components/Back/Back';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import React, { MouseEvent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';

import { ArrowLeftBtn, BackWrapper, Flex, Title, TitleBlock, TitleText, Wrapper } from './HeaderTitle.styles';

export type HeaderTitleType = {
  title: string;
  showArrowLeft?: boolean;
  showBackButton?: boolean;
  backTitle?: string;
  handleClickBack?(e: MouseEvent<HTMLButtonElement>): void;
};

export const HeaderTitle = ({
  title,
  showArrowLeft,
  showBackButton,
  backTitle,
  handleClickBack,
}: HeaderTitleType): ReactElement => {
  const showSidebar = useSelector((state: ReduxStateType) => state.showSidebar);

  return (
    <Wrapper showSidebar={showSidebar} showArrowLeft={showArrowLeft ?? false}>
      <TitleBlock>
        <div className="row">
          <Title className="col-md-12">
            <Flex>
              {showArrowLeft && (
                <ArrowLeftBtn style={{ marginRight: '5px' }} onClick={handleClickBack}>
                  <ArrowLeft className="arrow-left" />
                </ArrowLeftBtn>
              )}
              <TitleText>{title}</TitleText>
            </Flex>
            {showBackButton && (
              <BackWrapper>
                <Back title={backTitle ?? ''} handleClickBack={handleClickBack} />
              </BackWrapper>
            )}
          </Title>
        </div>
      </TitleBlock>
    </Wrapper>
  );
};

export default HeaderTitle;
