import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import { APPLE_DOMAIN_ASSOCIATION_FILE_URL } from 'Constants/Constants';
import { LpDetails } from 'Constants/styles';
import { FormEvent, MouseEvent, ReactElement } from 'react';

import { HelperTextBG, LongWord } from './styles';

type CheckoutPaymentProps = {
  apiBaseUri: string;
  merchantId: string;
  handleApplePayConfigureSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleApplePayConfigureCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  modalErrorMsg: string;
  modalIsLoading: boolean;
};

const ApplepayConfigurationModal = ({
  apiBaseUri,
  merchantId,
  handleApplePayConfigureCancel,
  handleApplePayConfigureSubmit,
  modalErrorMsg,
  modalIsLoading,
}: CheckoutPaymentProps): ReactElement => {
  return (
    <Modal
      title="Apple Pay configuration"
      cancelBtnText="Close"
      confirmBtnText="Domain verification file"
      handleCancel={handleApplePayConfigureCancel}
      handleSubmit={handleApplePayConfigureSubmit}
      isLoading={modalIsLoading}
      disableConfirmBtn={modalIsLoading}
    >
      {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}

      <LpDetails className="pt-2 pb-0">
        <div className="form-item mt-3">
          <div className="form-field">
            <LongWord>
              Merchant are required to host a domain verification file for each registered domain at the following path:
            </LongWord>
          </div>
        </div>

        <div className="form-item">
          <div className="form-field">
            <HelperTextBG>
              https://[DOMAIN_NAME]/.well-known/apple-developer-merchantid-domain-association{' '}
            </HelperTextBG>
          </div>
        </div>

        <div className="form-item pt-2">
          <div className="form-field">
            <LongWord>
              For example, if the checkout is hosted at <b>https://store.example.com</b>, the domain verification file
              would need to be accessible at{' '}
              <b>https://store.example.com/.well-known/apple-developer-merchantid-domain-association</b>.
            </LongWord>
          </div>
        </div>

        <div className="form-item pt-2">
          <div className="form-field">
            <LongWord>
              <b>Domain verification files are NOT required for the Virtual Terminal.</b>
            </LongWord>
          </div>
        </div>

        <div className="form-item pt-2">
          <div className="form-field">
            <LongWord>
              Click the button below to download the domain verification file or visit:{' '}
              <a href={APPLE_DOMAIN_ASSOCIATION_FILE_URL} target="`_blank" rel="noreferrer">
                apple-developer-merchantid-domain-association
              </a>
            </LongWord>
          </div>
        </div>

        <div className="form-item pt-2">
          <div className="form-field">
            <LongWord>
              For more information, please read our{' '}
              <a href="https://docs.limepay.com.au/developer-portal/apple-pay/" target="_blank" rel="noreferrer">
                developer documentation
              </a>{' '}
              or visit our{' '}
              <a
                href="https://limepay.zendesk.com/hc/en-us/articles/5004612298511-Digital-Wallets-"
                target="_blank"
                rel="noreferrer"
              >
                support hub
              </a>
              .
            </LongWord>
          </div>
        </div>
      </LpDetails>
    </Modal>
  );
};

export default ApplepayConfigurationModal;
