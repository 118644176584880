export const ITEM_ROWS_OBJ = [
  {
    class: '',
    text: 'Order ID',
    noOverflow: false,
  },
  {
    class: 'item-col-xsrr',
    text: 'Date',
    noOverflow: false,
  },
  {
    class: 'item-col-amount',
    text: 'Amount',
    noOverflow: false,
  },
  {
    class: '',
    text: 'Type',
    noOverflow: true,
  },
  {
    class: '',
    text: 'Order type',
    noOverflow: true,
  },
  {
    class: 'item-col-lgr',
    text: 'Customer',
    noOverflow: true,
  },
  {
    class: 'item-col-lgr',
    text: 'Customer ID',
    noOverflow: true,
  },
  {
    class: 'item-col-cxl',
    text: 'Status',
    noOverflow: true,
  },
];
