export enum BusinessTypeEnum {
  Company = 'company',
  Individual = 'individual',
}

export enum CountryType {
  AU = 'AU',
  NZ = 'NZ',
}

export interface BusinessInfoAPIResponse {
  businessType: BusinessTypeEnum | null;
  taxId: Nullable<string>;
  typeOfProduct: Nullable<string>;
  taxCountry: CountryType;
  country: CountryType;
}

export interface PersonalInfoAPIResponse {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
}

export interface PublicInfoAPIResponse {
  businessName: Nullable<string>;
  brandName: Nullable<string>;
  website: Nullable<string>;
  cardStatementName: Nullable<string>;
  contactEmail: Nullable<string>;
  phoneNo: Nullable<string>;
}

export interface MetadataAPIResponse {
  tradeType: Nullable<string>;
  tradeLicence: Nullable<string>;
}
