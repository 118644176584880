import styled from '@emotion/styled';

export const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0 0 auto;

  .export-data {
    display: none;
    &.open {
      display: block;
    }
  }

  p {
    color: #6c7488;
    line-height: 24px;
    margin-top: 14px;
    font-size: 15px;
  }

  .date-color {
    color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  }

  .nav-item {
    position: relative;
  }

  .outer-btn {
    display: flex;
    outline: none;
    border: none;
    background: #fff;
    cursor: pointer;
    align-items: center;
  }
  .arrow-down {
    width: 20px;
    fill: #6c7488;
    transition: transform 0.3s ease-in-out 0s;
    &.up {
      transform: rotate(180deg);
    }
  }

  .tclick,
  .tclick-inside {
    color: #6c7488;
    position: relative;
  }
  [class^='nav'] li > a,
  [class*=' nav'] li > a {
    display: block;
  }
  .payout-schedule:hover,
  .payout-schedule:focus,
  .payout-schedule {
    text-decoration: none;
    outline: none;
    border: 0;
  }

  .payout-schedule-menu-inside,
  .payout-schedule-menu {
    padding: 11px 20px 10px;
    width: 230px;
    list-style: none;
    position: absolute;
    top: 30px;
    background-color: #fff;
    -webkit-box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
    box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
    border: 1px solid rgba(126, 142, 159, 0.1);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 88;
    left: 0;
    &.export-data {
      left: auto;
      right: 0;
      width: 359px;
      padding: 27px;
    }
  }

  .payout-schedule-menu li {
    padding: 7px 0;
    font-size: 15px;
  }
`;
