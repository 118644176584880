import Alert from 'Components/Alert/Alert';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Spinner from 'Components/Spinner/Spinner';
import { Content, H2, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { readString } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setTransferDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { CountryCurrency, toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { SpinnerWrapper } from './PayoutDetails.styles';
import { getCustomizations } from './TransferUtils';
import { Card, DownloadBtn, FeatureIcon, Media } from './styles';
import { PayoutAPIResponseType, TransactionAPIResponse } from './types';

type PayoutDetailsType = {
  payout: Nullable<PayoutAPIResponseType>;
};

const PayoutDetails = ({ payout }: PayoutDetailsType): ReactElement => {
  const [transactions, setTransactions] = useState<TransactionAPIResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [downloadedData, setDownloadedData] = useState<any>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const { merchantId, apiBaseUri, isLaddrB2C, merchantTradingCountry } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    isLaddrB2C: state.isLaddrB2C,
    merchantTradingCountry: state.merchantTradingCountry,
  }));

  const dispatch: Dispatch<any> = useDispatch();
  const setTransferDetailsCB = useCallback((s, d) => dispatch(setTransferDetails(s, d)), [dispatch]);
  const customizations = useMemo(() => getCustomizations(isLaddrB2C ?? false), [isLaddrB2C]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId || !payout || !payout.payoutId) {
      return;
    }
    let isMounted = true;

    const fetchData = async () => {
      const options = await getFetchOptions();
      const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/${payout.payoutId}`;

      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          isMounted && setTransactions(response.transactionSummaries);
        })
        .catch(() => {
          isMounted && setHasError(true);
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, payout]);

  useEffect(() => {
    if (csvRef?.current && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setTransferDetailsCB(false, null);
  };

  const handleClickDownload = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (!payout) {
      return;
    }

    const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/csv/${payout.payoutId}`;
    const options = await getFetchOptions();

    setDownloadedData(null);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.text();
      })
      .then((res) => {
        readString(res, {
          worker: true,
          complete: ({ data }) => {
            const noneEmptyData = data.filter((d) => Array.isArray(d) && d.length > 1);
            setDownloadedData(noneEmptyData);
            setDownloadReady(true);
          },
        });
      })
      .catch(() => {
        console.error('Failed to download');
      });
  };

  if (!payout) {
    return <></>;
  }

  return (
    <>
      <HeaderTitle
        title={`${customizations.paymentDetailTitle} for #${payout.payoutId}`}
        showArrowLeft
        showBackButton
        backTitle="Back to payouts"
        handleClickBack={handleClickBack}
      />
      <Content>
        <LpBox className="lp-box">
          <H2 className="mb-4">Payout</H2>
          <LpDetails className="lp-details">
            <Row className="pt-3">
              <div className="col-sm-3 col-xl-2">
                <Label>Date paid</Label>
              </div>
              <div className="col-sm-9 col-xl-10">
                {
                  getDateFormat({
                    time: payout.createdAt,
                    showDaySuffix: false,
                    showFullYear: true,
                    showShortMonth: false,
                  }).date
                }
              </div>
            </Row>
            <Row className="pt-3">
              <div className="col-sm-3 col-xl-2">
                <Label>Arrival date</Label>
              </div>
              <div className="col-sm-9 col-xl-10">
                {!!payout.arrivalDate
                  ? getDateFormat({
                      time: payout.arrivalDate,
                      showDaySuffix: false,
                      showFullYear: true,
                      showShortMonth: false,
                    }).date
                  : '-'}
              </div>
            </Row>
            <Row className="pt-3">
              <div className="col-sm-3 col-xl-2">
                <Label>Amount</Label>
              </div>
              <div className="col-sm-6 col-xl-8">
                {typeof payout.amount === 'number'
                  ? toCurrency(payout.amount, payout.payments?.[0]?.currency ?? CountryCurrency[merchantTradingCountry])
                  : '-'}
              </div>
              <div className="col-sm-3 col-xl-2">
                <DownloadBtn onClick={handleClickDownload}>Download CSV</DownloadBtn>
              </div>
            </Row>
          </LpDetails>
        </LpBox>

        <LpBox className="lp-box">
          <H2 className="mb-4">Transactions</H2>
          <Card>
            <ul className="item-list striped">
              <li className="item item-list-header">
                <div className="item-row">
                  <div className="item-col item-col-header item-col-title">
                    <div>
                      <span>Order Id</span>
                    </div>
                  </div>
                  <div className="item-col item-col-header item-col-title">
                    <div>
                      <span>Amount</span>
                    </div>
                  </div>
                  <div className="item-col item-col-header item-col-title">
                    <div>
                      <span>Type</span>
                    </div>
                  </div>
                  <div className="item-col item-col-header item-col-title">
                    <div>
                      <span>Fee</span>
                    </div>
                  </div>
                  <div className="item-col item-col-header item-col-title">
                    <div>
                      <span>Date</span>
                    </div>
                  </div>
                </div>
              </li>
              {isLoading && !hasError && (
                <SpinnerWrapper>
                  <Spinner />
                </SpinnerWrapper>
              )}
              {!isLoading && hasError && <Alert message="Failed to get transactions" />}
              {!isLoading && !hasError && transactions.length === 0 && <EmptyList />}
              {!isLoading &&
                !hasError &&
                transactions.length > 0 &&
                transactions.map((t, index) => (
                  <li className="item active-item" key={index}>
                    <div className="item-row">
                      <div className="item-col item-col-sales">
                        <Media>
                          <FeatureIcon>
                            <OrderIdIcon className="order-id-icon" />
                          </FeatureIcon>
                          <div className="order-id">
                            <h2 className="price order-id-text">{t.orderId}</h2>
                          </div>
                        </Media>
                      </div>
                      <div className="item-col item-col-sales">
                        <div className="item text-left mt-1 custom-da">
                          <H2 className="price">
                            {typeof t.amount === 'number' ? toCurrency(t.amount, t.currency) : '-'}
                          </H2>
                        </div>
                      </div>
                      <div className="item-col item-col-sales">
                        <div className="item text-left mt-1 custom-transform">
                          <H2 className="price">{t.transactionType}</H2>
                        </div>
                      </div>
                      <div className="item-col item-col-sales">
                        <div className="item text-left mt-1 custom-da">
                          <H2 className="price">{typeof t.fee === 'number' ? toCurrency(t.fee, t.currency) : '-'}</H2>
                        </div>
                      </div>
                      <div className="item-col item-col-sales">
                        <div className="item text-left mt-1 custom-da">
                          <H2 className="price">
                            {
                              getDateFormat({
                                time: t.paymentDate,
                                showDaySuffix: false,
                                showFullYear: true,
                                showShortMonth: false,
                              }).date
                            }
                          </H2>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </Card>
        </LpBox>
      </Content>
      {downloadedData ? (
        <CSVLink ref={csvRef} data={downloadedData} target="_self" filename={Date.now() + '.csv'} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PayoutDetails;
