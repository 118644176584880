export enum OrderSearchType {
  order = 'order',
  customer = 'internalCustomerId',
  email = 'email',
  amount = 'amount',
}

export enum OrderSearchRefundType {
  order = 'order',
  email = 'email',
  amount = 'amount',
}

export const OrderSearchOptions = [
  {
    text: 'Order ID',
    value: OrderSearchType.order,
  },
  {
    text: 'Customer ID',
    value: OrderSearchType.customer,
  },
  {
    text: 'Email',
    value: OrderSearchType.email,
  },
  {
    text: 'Amount',
    value: OrderSearchType.amount,
  },
];

export const OrderSearchRefundOptions = [
  {
    text: 'Order ID',
    value: OrderSearchRefundType.order,
  },
  {
    text: 'Email',
    value: OrderSearchRefundType.email,
  },
  {
    text: 'Amount',
    value: OrderSearchRefundType.amount,
  },
];
