import Alert from 'Components/Alert/Alert';
import MessageWithModal from 'Components/MessageWithModal';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import Switch from 'Components/Switch';
import { APPLE_DOMAIN_ASSOCIATION_FILE_URL } from 'Constants/Constants';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import { ChangeEvent, FormEvent, Fragment, MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import ApplepayConfigurationModal from './ApplepayConfigurationModal';
import { getInitialCheckoutInfo } from './getInitialState';
import { EditBtn, HelperText, LongWord } from './styles';
import { CheckoutApplePayAPIResponse } from './types';

type CheckoutPaymentProps = {
  apiBaseUri: string;
  merchantId: string;
  setSuccessMsg: (val: boolean) => void;
};

const CheckoutPaymentOptions = ({ apiBaseUri, merchantId, setSuccessMsg }: CheckoutPaymentProps): ReactElement => {
  const [checkoutPaymentInfo, setCheckoutPaymentInfo] = useState<CheckoutApplePayAPIResponse>(() =>
    getInitialCheckoutInfo(),
  );
  const [applePayDomainsList, setApplePayDomainsList] = useState<Array<string>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showApplepayConfigurationModal, setShowApplepayConfigurationModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [applePayEnabled, setApplePayEnabled] = useState<boolean>(false);
  const [applePayAdded, setApplePayAdded] = useState<boolean>(false);

  const fetchApplePayDetail = useCallback(async () => {
    setIsLoading(true);

    const url = `${apiBaseUri}/payments/apple/web-merchant-details-jwt/${merchantId}`;
    const options = await getFetchOptions();
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      })
      .then((response: CheckoutApplePayAPIResponse) => {
        setCheckoutPaymentInfo(response);
        setApplePayEnabled(response.domainNames.length > 0);
        setApplePayDomainsList(response.domainNames);
        setErrorMsg('');
      })
      .catch((e) => {
        // treat any failure as disabled
        setApplePayEnabled(false);
        setCheckoutPaymentInfo(getInitialCheckoutInfo());
        setApplePayDomainsList([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    fetchApplePayDetail();
  }, [apiBaseUri, merchantId, fetchApplePayDetail]);

  const handleApplePayConfigureCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowApplepayConfigurationModal(false);
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const handleApplePayConfigureSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    window.open(APPLE_DOMAIN_ASSOCIATION_FILE_URL, '_blank');
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    setCheckoutPaymentInfo((prev) => ({
      ...prev,
      domainNames: applePayDomainsList,
    }));
    setShowModal(false);
    setApplePayEnabled(applePayDomainsList.length ? true : false);
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    let isReady = true;
    if (applePayEnabled && checkoutPaymentInfo.domainNames.length < 1) {
      setModalErrorMsg('Please enter atleast one valid domain to enable Apple Pay');
      isReady = false;
    }

    if (applePayEnabled && !checkoutPaymentInfo.domainNames.join(',').trim()) {
      setModalErrorMsg('Please enter atleast one valid domain to enable Apple Pay');
      isReady = false;
    }

    if (!isReady) {
      return;
    }

    // filter all list which is deleted so we can un-register.
    const deletedDomainsList = applePayEnabled
      ? applePayDomainsList.filter((x) => !checkoutPaymentInfo.domainNames.includes(x))
      : [];

    const url = `${apiBaseUri}/payments/apple/web-merchant-details-jwt/${merchantId}`;

    let requestCompleted = false;

    if (!applePayEnabled || deletedDomainsList.length > 0) {
      const deleteBody = JSON.stringify(deletedDomainsList);
      const deleteOptions = await getFetchOptions('DELETE', deleteBody);

      setModalIsLoading(true);
      // un-register deleted domains
      await fetch(url, deleteOptions)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          requestCompleted = true;
        })
        .catch((e) => {
          setModalErrorMsg(e.message);
        })
        .finally(() => {
          setModalIsLoading(false);
        });
    }

    if (applePayEnabled && checkoutPaymentInfo.domainNames.length > 0) {
      const body = JSON.stringify(checkoutPaymentInfo.domainNames);
      const options = await getFetchOptions('POST', body);

      setModalIsLoading(true);
      // register new domains
      await fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }

          requestCompleted = true;
        })
        .catch((e) => {
          setModalErrorMsg(e.message);
        })
        .finally(() => {
          setModalIsLoading(false);
        });
    }

    if (requestCompleted) {
      await fetchApplePayDetail();

      setSuccessMsg(true);
      setApplePayAdded(true);
      setBodyOverflow('auto');
      setShowModal(false);
      if (!applePayEnabled) {
        setCheckoutPaymentInfo((prev) => ({
          ...prev,
          domainNames: [],
        }));
      }
    }
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalErrorMsg('');
  };

  const handleDomainNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setCheckoutPaymentInfo((prev) => ({
      ...prev,
      [name]: value.split(','),
    }));
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="BrandingSettings">
            Checkout payment options
          </H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {errorMsg.length === 0 && (
            <EditBtn data-testid="edit-branding-btn" onClick={toggleModal}>
              Edit checkout payment options
            </EditBtn>
          )}
        </div>
      </div>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <LpDetails>
          <div className="row pt-4">
            <div className="col-sm-4 col-xl-3">
              <Label>Credit/debit card</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">Enabled</LongWord>
          </div>
          <div className="row pt-2">
            <div className="col-sm-4 col-xl-3">
              <Label>Apple Pay</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">
              {applePayDomainsList.length > 0 ? (
                <Fragment>Enabled on {applePayDomainsList.join(', ')}</Fragment>
              ) : (
                `Disabled`
              )}

              {applePayAdded && applePayDomainsList.length > 0 && (
                <MessageWithModal
                  description={`Merchant are required to host an Apple Pay domain verification file for each registered domain.`}
                  success={false}
                  hasClose={false}
                  backgroundColor="#dddee5"
                  borderColor="#555c7c"
                  modalText="Learn more"
                  modalToggle={setShowApplepayConfigurationModal}
                  className="mt-2 mb-1 new-checkout-modal"
                  svgFillColor="#555c7c"
                />
              )}
            </LongWord>
          </div>
          <div className="row pt-2">
            <div className="col-sm-4 col-xl-3">
              <Label>Google Pay</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">Enabled</LongWord>
          </div>
        </LpDetails>
      )}

      {showModal && (
        <Modal
          title="Edit checkout payment options"
          cancelBtnText="Cancel"
          confirmBtnText="Save"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}

          <LpDetails className="pt-2 pb-0">
            <div className="form-item">
              <div className="form-field">
                <Switch
                  testid="creditDebitCard"
                  data-testid="creditDebitCard"
                  checked={true}
                  handleChange={() => {}}
                  text="Credit/debit card"
                  disabled={true}
                />
              </div>
            </div>

            <div className="form-item pt-2">
              <div className="form-field">
                <Switch
                  testid="applePay"
                  data-testid="applePay"
                  checked={applePayEnabled}
                  handleChange={() => setApplePayEnabled(!applePayEnabled)}
                  text="Apple Pay - Compatible devices only (e.g. Safari web browsers, iOS devices)"
                />
              </div>
              <div className="form-field">
                <div className="pl-5 mt-3">
                  <Label>Enable on the following domains and sub-domains:</Label>
                  <Input
                    type="text"
                    name="domainNames"
                    value={checkoutPaymentInfo.domainNames.join(',')}
                    onChange={handleDomainNameChange}
                    required
                    placeholder="shop.example.com"
                    data-testid="domainNames"
                    disabled={applePayEnabled ? false : true}
                  />
                  <HelperText className="pt-2">Multiple domains can be added, seperated by comma.</HelperText>
                </div>
              </div>
            </div>

            <div className="form-item pt-2">
              <div className="form-field">
                <Switch
                  testid="googlePay"
                  data-testid="googlePay"
                  checked={true}
                  disabled={true}
                  handleChange={() => {}}
                  text="Google pay - Compatible devices only (e.g. Google Chrome web browser, Android devices)"
                />
              </div>
            </div>
          </LpDetails>
        </Modal>
      )}

      {showApplepayConfigurationModal && (
        <ApplepayConfigurationModal
          apiBaseUri={apiBaseUri}
          merchantId={merchantId}
          handleApplePayConfigureSubmit={handleApplePayConfigureSubmit}
          handleApplePayConfigureCancel={handleApplePayConfigureCancel}
          modalErrorMsg={modalErrorMsg}
          modalIsLoading={modalIsLoading}
        />
      )}
    </LpBox>
  );
};

export default CheckoutPaymentOptions;
