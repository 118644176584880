import { Currency } from 'utils/currency';

import { CustomSettings } from './types';

export enum StepViewType {
  AddItemsView = 'AddItemsView',
  EditView = 'EditView',
  ConfirmView = 'ConfirmView',
  SuccessView = 'SuccessView',
}

export enum ChangeType {
  OrderNumber = 'orderNumber',
  OrderAmount = 'orderAmount',
  TotalAmount = 'totalAmount',
  GST = 'gstAmount',
  Description = 'description',
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
}

export type ItemType = {
  quantity: string;
  name: string;
  cost: string;
  createdAt: number;
  editItem: boolean;
  validCost: boolean;
  validQuantity: boolean;
  showConfirmDeleteBtn: boolean;
};

export interface ItemInterface {
  amount: number;
  currency: Currency;
  description: string;
  sku: string;
  quantity: number;
  imageUrl: string;
}

// support string only atm
type CustomFieldsKey = {
  required: boolean;
  value: string;
};

export type CustomFieldsObj = {
  [key: string]: CustomFieldsKey;
};

export type MetadataType = {
  [key: string]: string;
};

export type StatesType = {
  showModal: boolean;
  isLoading: boolean;
  sendLoading: boolean;
  errorMsg: string;
  stepView: StepViewType;
  modalTitle: string;
  orderNumber: string;
  // totalAmount === orderAmount + gstAmount
  totalAmount: string;
  orderAmount: string;
  gstAmount: string;
  description: string;
  name: string;
  email: string;
  phone: string;
  validEmail: boolean;
  validPhone: boolean;
  validAmount: boolean;
  sentToCustomer: boolean;
  items: ItemType[];
  /**
   * displayGst is only related to show/hide GST amount on page
   * it is not related to includeGST/excludeGST
   */
  displayGst: boolean;
  request3ds: boolean;
  customFieldsObj: CustomFieldsObj | null;
};

export type HeaderType = {
  apiBaseUri: string;
  merchantId: string;
  isLaddrB2C: boolean;
  isLaddrTF: boolean;
  manualPaymentIncludeGst: boolean;
  orderBaseUri: string;
  merchantTradingCountry: 'AU' | 'NZ';
  setNow(now: number): void;
  customSettings: CustomSettings | null;
  merchantPublicKey: string;
};
