import Alert from 'Components/Alert/Alert';
import Calendar from 'Components/Calendar/Calendar';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import OrderSearch, { SearchOptionType } from 'Components/OrderSearch/OrderSearch';
import { OrderSearchOptions } from 'Components/OrderSearch/OrderSearchOptions';
import { OrderSearchType } from 'Components/OrderSearch/OrderSearchOptions';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import { fromCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import { FilterStatus, filterTypeArray } from './FilterConstants';
import OrderFilter from './OrderFilter';
import OrderList from './OrderList';
import { PaymentAPIResponseType } from './types';

const SearchOptionObj = {
  [OrderSearchType.order]: 'internalOrderId',
  [OrderSearchType.customer]: 'internalCustomerId',
  [OrderSearchType.email]: 'customerEmail',
  [OrderSearchType.amount]: 'amount',
};

type PaymentsProps = {
  pageCount: number;
  setPageCount(count: number): void;
  activePage: number;
  setActivePage(page: number): void;
  pageStartIndex: number;
  setPageStartIndex(page: number | Function): void;
  startDate: string;
  endDate: string;
};

const Payments = ({
  pageCount,
  setPageCount,
  activePage,
  setActivePage,
  pageStartIndex,
  setPageStartIndex,
  startDate,
  endDate,
}: PaymentsProps) => {
  const [orders, setOrders] = useState<PaymentAPIResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<PaymentAPIResponseType[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<FilterStatus>(FilterStatus.allStatuses);
  const [typeOptions, setTypeOptions] = useState<boolean[]>(new Array(filterTypeArray.length).fill(false));
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');
  const [showPagination, setShowPagination] = useState<boolean>(true);

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { apiBaseUri, merchantId } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!startDate || !endDate || !apiBaseUri || !merchantId || debouncedSearch !== selectedSearch) {
      return;
    }

    const fetchData = async () => {
      const localStartDate = convertUTCtoLocal(startDate);
      const localEndDate = convertUTCtoLocal(endDate);
      let url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?sort=-createdAt&page=${activePage}&createdAfter=${localStartDate}&createdBefore=${localEndDate}`;
      if (debouncedSearch.length > 0) {
        let tempSearch: number | string = debouncedSearch;
        if (selectedSearchOption.value === OrderSearchType.amount) {
          tempSearch = fromCurrency(tempSearch.replace(',', ''));
        }
        url += `&${SearchOptionObj[selectedSearchOption.value as OrderSearchType]}=${encodeURIComponent(tempSearch)}`;
      }

      if (selectedStatus !== FilterStatus.allStatuses) {
        url += `&status=${selectedStatus as string}`;
      }

      const options = await getFetchOptions();

      setIsLoading(true);
      return fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('limepay-page-count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          const tempOrders = response.filter(
            (r: PaymentAPIResponseType) => r.transactionPayType.payPlanId || r.transactionPayType.payCardId,
          );
          setOrders(tempOrders);
          setPageCount(pages);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e.message || 'Failed to fetch payments');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, [
    merchantId,
    activePage,
    apiBaseUri,
    startDate,
    endDate,
    setPageCount,
    debouncedSearch,
    selectedSearchOption,
    selectedSearch,
    selectedStatus,
  ]);

  useEffect(() => {
    if (orders.length === 0) {
      return;
    }
    let tempOrders = orders.map((o) => ({ ...o }));

    /* filter type options only when one of them is checked */
    const typeCheckedLength = typeOptions.filter((option) => option).length;
    if (typeCheckedLength === 1) {
      if (typeOptions[0]) {
        tempOrders = orders.filter((order) => !!order.transactionPayType.payCardId);
      }
      if (typeOptions[1]) {
        tempOrders = orders.filter((order) => !!order.transactionPayType.payPlanId);
      }
    }

    setFilteredOrders(tempOrders);
  }, [typeOptions, orders]);

  const handleStatusOptionClick = (e: MouseEvent<HTMLElement>, status: FilterStatus): void => {
    e.preventDefault();
    setSelectedStatus(status);
  };

  const handleTypeOptionClick = (e: MouseEvent<HTMLElement>, index: number): void => {
    e.preventDefault();
    setTypeOptions((prev) => [...prev.slice(0, index), !prev[index], ...prev.slice(index + 1)]);
  };

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    setShowPagination(orders.length === filteredOrders.length);
  }, [orders, filteredOrders]);

  return (
    <>
      <div className="header-wrapper">
        <div className="header-calendar">
          <HeaderTitle title="Payments" />
          <Calendar />
        </div>
        <OrderSearch
          selectedSearchOption={selectedSearchOption}
          setSelectedSearchOption={setSelectedSearchOption}
          selectedSearch={selectedSearch}
          handleSearch={handleSearch}
          section="payment"
        />
      </div>
      <OrderFilter
        selectedStatus={selectedStatus}
        typeOptions={typeOptions}
        handleStatusOptionClick={handleStatusOptionClick}
        handleTypeOptionClick={handleTypeOptionClick}
      />
      {isLoading && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {!isLoading && errorMsg.length === 0 && (
        <>
          {filteredOrders.length > 0 && <OrderList orders={filteredOrders} />}
          {filteredOrders.length === 0 && <EmptyList />}
          {showPagination && (
            <Pagination
              pageCount={pageCount}
              activePage={activePage}
              setActivePage={setActivePage}
              pageStartIndex={pageStartIndex}
              setPageStartIndex={setPageStartIndex}
            />
          )}
        </>
      )}
    </>
  );
};

export default Payments;
