import { logout } from 'APIs/identity';
import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, LpBox, LpDetails } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getApiErrorMessage from 'utils/getApiErrorMsg';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import setBodyOverflow from 'utils/setBodyOverflow';

import { MIN_LENGTH_ERROR, NO_MATCH_ERROR } from './Constants';
import { FormInput, FormItem, FormLabel, LogOutBtn, UpdatePasswordBtn, ValidateError } from './styles';

type SecurityProps = {
  setConfirmMsg(s: string): void;
};

const Security = ({ setConfirmMsg }: SecurityProps): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [existPW, setExistPW] = useState<string>('');
  const [newPW, setNewPW] = useState<string>('');
  const [repeatPW, setRepeatPW] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [merchantName] = useState(() => getMerchantName());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>('');

  const apiBaseUri = useSelector((state: ReduxStateType) => state.apiBaseUri);

  const handleShowModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowModal(false);
    setIsLoading(false);
    setHasError(false);
    setErrorMsg('');
    setExistPW('');
    setNewPW('');
    setRepeatPW('');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!apiBaseUri) {
      return;
    }

    if (newPW.length < 8 || repeatPW.length < 8) {
      setErrorMsg(MIN_LENGTH_ERROR);
      return;
    }

    if (newPW !== repeatPW) {
      setErrorMsg(NO_MATCH_ERROR);
      return;
    }

    const body = JSON.stringify({
      password: existPW,
      newPassword: newPW,
    });

    const options = await getFetchOptions('POST', body);
    const url = `${apiBaseUri}/authn/change-password`;

    setIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.text();
          let msg = text;
          try {
            msg = getApiErrorMessage(JSON.parse(text));
          } finally {
            throw Error(msg);
          }
        }
        setErrorMsg('');
        setBodyOverflow('auto');
        setShowModal(false);
        setConfirmMsg('Update password successfully!');
        setExistPW('');
        setNewPW('');
        setRepeatPW('');
      })
      .catch((e) => {
        setHasError(true);
        setApiErrorMsg(e.message || 'Failed to update password');
        setConfirmMsg('');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLogout = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    logout()
      .then(() => {
        window.location.href = `/${merchantName}/login`;
      })
      .catch(() => {
        console.error('error to logout');
      });
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;
    switch (name) {
      case 'existPW':
        setExistPW(value);
        break;
      case 'newPW':
        setNewPW(value);
        break;
      case 'repeatPW':
        setRepeatPW(value);
        break;
    }
  };

  return (
    <>
      <LpBox>
        <H2>Security</H2>
        <LpDetails>
          <div className="row pt-2">
            <div className="col-sm-3 col-xl-2" />
            <div className="col-sm-9 col-xl-10">
              <form>
                <div className="option pb-3">
                  <UpdatePasswordBtn onClick={handleShowModal}>Update your password</UpdatePasswordBtn>
                </div>
                <div className="option pb-3">
                  <LogOutBtn onClick={handleLogout}>Sign out</LogOutBtn>
                </div>
              </form>
            </div>
          </div>
        </LpDetails>
      </LpBox>
      {showModal && (
        <Modal
          title="Update your password"
          cancelBtnText="Cancel"
          confirmBtnText="Save"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          disableConfirmBtn={hasError || isLoading}
        >
          {hasError && <Alert message={apiErrorMsg} />}
          {isLoading && !hasError && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!isLoading && !hasError && (
            <>
              {errorMsg.length > 0 && <ValidateError>{errorMsg}</ValidateError>}
              <FormItem>
                <FormLabel>Current password*</FormLabel>
                <FormInput
                  type="password"
                  name="existPW"
                  placeholder="Enter existing password"
                  required
                  value={existPW}
                  onChange={handleChangePassword}
                />
              </FormItem>
              <FormItem>
                <FormLabel>New password*</FormLabel>
                <FormInput
                  type="password"
                  name="newPW"
                  placeholder="New password"
                  required
                  value={newPW}
                  onChange={handleChangePassword}
                />
              </FormItem>
              <FormItem>
                <FormLabel>Repeat password*</FormLabel>
                <FormInput
                  type="password"
                  name="repeatPW"
                  placeholder="Repeat password"
                  required
                  value={repeatPW}
                  onChange={handleChangePassword}
                />
                <div className="row pt-2 pb-3 validate-pass">
                  <div className="pl-3 pl-md-4">
                    <span>•</span> At least 8 characters.
                  </div>
                </div>
              </FormItem>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default Security;
