import Alert from 'Components/Alert/Alert';
import Checkbox from 'Components/Checkbox';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { Row } from 'Constants/styles';
import { ReactComponent as CCIcon } from 'assets/svg/Filled-CC.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/Filled-Email.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete-order.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit-order.svg';
import { ReactComponent as LaddrPlusIcon } from 'assets/svg/plus-laddr.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ChangeEvent, FormEvent, Fragment, MouseEvent, useEffect, useMemo, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { NavLink } from 'react-router-dom';
import { CountryCurrency, fromCurrency, multiply } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import isNumeroDecimal from 'utils/isNumeroDecimal';
import setBodyOverflow from 'utils/setBodyOverflow';
import validateAmount from 'utils/validateAmount';
import validateEmail from 'utils/validateEmail';

import * as s from './Header.styles';
import {
  ChangeType,
  CustomFieldsObj,
  HeaderType,
  ItemType,
  MetadataType,
  StatesType,
  StepViewType,
} from './HeaderTypes';
import {
  getAmountsBasedOnInput,
  getAmountsBasedOnItems,
  getCustomizations,
  getInitialStates,
  getModalTitle,
} from './HeaderUtils';
import SuccessView from './SuccessView';
import { convertItems } from './utils';

const Header = ({
  apiBaseUri,
  merchantId,
  isLaddrB2C,
  isLaddrTF,
  manualPaymentIncludeGst,
  orderBaseUri,
  merchantTradingCountry,
  setNow,
  customSettings,
  merchantPublicKey,
}: HeaderType) => {
  const [merchantName] = useState(() => getMerchantName());
  const [states, setStates] = useState<StatesType>(() => getInitialStates(false, isLaddrB2C));
  const [savedItems, setSavedItems] = useState<ItemType[]>([]);
  const [editedItems, setEditedItems] = useState<ItemType[]>([]);

  const displayOrderItemsGst = isLaddrTF ? false : isLaddrB2C;
  const customizations = useMemo(() => getCustomizations(isLaddrB2C, isLaddrTF), [isLaddrB2C, isLaddrTF]);

  useEffect(() => {
    if (!customSettings || !customSettings.customFields || customSettings.customFields.length === 0) {
      setStates((prev) => ({
        ...prev,
        customFieldsObj: null,
      }));
      return;
    }

    let customFieldsObj: CustomFieldsObj = {};
    customSettings.customFields.forEach((field) => {
      customFieldsObj[field.key] = {
        required: field.required,
        value: '',
      };
    });

    setStates((prev) => ({
      ...prev,
      customFieldsObj,
    }));
  }, [customSettings]);

  useEffect(() => {
    const saved = states.items.filter((item) => !item.editItem);
    const edited = states.items.filter((item) => item.editItem);
    setSavedItems(saved);
    setEditedItems(edited);
  }, [states.items]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates((prev) => ({ ...prev, showModal: false }));
    setBodyOverflow('auto');
  };

  const handleConfirmManualPayment = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    switch (states.stepView) {
      case StepViewType.AddItemsView:
        setStates((prev) => {
          const { total, order, gst } = getAmountsBasedOnItems(states, manualPaymentIncludeGst, merchantTradingCountry);
          const _items: Array<ItemType> = JSON.parse(JSON.stringify(prev.items));
          _items.forEach((item) => {
            item.showConfirmDeleteBtn = false;
          });
          return {
            ...prev,
            validAmount: true,
            modalTitle: getModalTitle(isLaddrB2C, StepViewType.EditView),
            stepView: StepViewType.EditView,
            totalAmount: total,
            orderAmount: order,
            gstAmount: gst,
            items: _items,
          };
        });
        break;

      case StepViewType.EditView:
        if (states.validEmail === false || states.email.length === 0) {
          return;
        }
        setStates((prev) => ({
          ...prev,
          modalTitle: `${getModalTitle(isLaddrB2C, StepViewType.ConfirmView)} #${prev.orderNumber}`,
          stepView: StepViewType.ConfirmView,
        }));
        break;
      default:
        break;
    }
  };

  const handleClickCreate = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    /** clear the custom field input */
    setStates((prev) => {
      const customFieldsObj = JSON.parse(JSON.stringify(prev.customFieldsObj));
      if (customFieldsObj) {
        for (const key in customFieldsObj) {
          customFieldsObj[key].value = '';
        }
      }
      return {
        ...getInitialStates(true, isLaddrB2C),
        customFieldsObj,
      };
    });
    setStates((prev) => ({
      ...getInitialStates(true, isLaddrB2C),
      customFieldsObj: prev.customFieldsObj,
    }));
    setBodyOverflow('hidden');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: ChangeType): void => {
    const { value } = e.target;
    switch (type) {
      case ChangeType.OrderAmount:
        const isValidAmount = validateAmount(value);

        if (manualPaymentIncludeGst) {
          let order = '';
          let gst = '';
          if (isValidAmount) {
            const amountsObj = getAmountsBasedOnInput(value, true, merchantTradingCountry);
            order = amountsObj.order;
            gst = amountsObj.gst;
          }
          setStates((prev) => ({
            ...prev,
            [ChangeType.TotalAmount]: value,
            [ChangeType.OrderAmount]: order,
            validAmount: isValidAmount,
            gstAmount: gst,
          }));
        } else {
          let total = '';
          let gst = '';
          if (isValidAmount) {
            const amountsObj = getAmountsBasedOnInput(value, false, merchantTradingCountry);
            total = amountsObj.total;
            gst = amountsObj.gst;
          }
          setStates((prev) => ({
            ...prev,
            [ChangeType.TotalAmount]: total,
            [ChangeType.OrderAmount]: value,
            validAmount: isValidAmount,
            gstAmount: gst,
          }));
        }
        break;

      case ChangeType.OrderNumber:
      case ChangeType.Description:
      case ChangeType.Name:
        setStates((prev) => ({ ...prev, [type as string]: value }));
        break;

      case ChangeType.Email:
        setStates((prev) => ({ ...prev, email: value, validEmail: validateEmail(value) }));
        break;

      default:
        break;
    }
  };

  const handleChangeCustomField = (e: ChangeEvent<HTMLInputElement>, key: string): void => {
    setStates((prev) => {
      if (prev.customFieldsObj) {
        return {
          ...prev,
          customFieldsObj: {
            ...prev.customFieldsObj,
            [key]: {
              ...prev.customFieldsObj[key],
              value: e.target.value,
            },
          },
        };
      }
      return prev;
    });
  };

  const getCustomFieldValue = (key: string) => {
    const { customFieldsObj } = states;
    if (!customFieldsObj || Object(customFieldsObj).length === 0) {
      return '';
    }

    return customFieldsObj[key].value;
  };

  const handleChangePhone = (value: string = ''): void => {
    const valid = value.length > 0 ? isPossiblePhoneNumber(value) : true;
    setStates((prev) => ({ ...prev, phone: value, validPhone: valid }));
  };

  const handleClickEditOnEditView = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates((prev) => ({
      ...prev,
      stepView: StepViewType.EditView,
      modalTitle: getModalTitle(isLaddrB2C, StepViewType.EditView),
    }));
  };

  const getMetaData = () => {
    const { customFieldsObj } = states;

    if (!customFieldsObj) {
      return null;
    }
    let metadata: MetadataType = {};
    for (const key of Object.keys(customFieldsObj)) {
      if (customFieldsObj[key].required || (!customFieldsObj[key].required && customFieldsObj[key].value)) {
        metadata[key] = customFieldsObj[key].value;
      }
    }
    return metadata;
  };

  const getOrderRequest = async () => {
    /**
     * for laddr, taxIncluded should always be true (27/04/2022, might need to change later)
     * for other merchants, taxIncluded is determined by display GST checkbox
     * so if display GST checkbox is not ticked, we hide the GST on instore page
     */

    const currency = CountryCurrency[merchantTradingCountry];
    const items = convertItems(states.items, currency);

    return {
      orderType: 'manual',
      internalOrderId: states.orderNumber,
      paymentAmount: {
        amount: fromCurrency(states.totalAmount),
        currency,
      },
      customerEmail: states.email,
      phoneNo: states.phone.length > 0 ? states.phone : null,
      description: states.description,
      items,
      taxIncluded: displayOrderItemsGst || states.displayGst,
      request3DSOnPayment: states.request3ds,
      discount: null,
      shipping: null,
      billing: {
        phone: states.phone.length > 0 ? states.phone : null,
        name: states.name,
        address: {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postalCode: '',
          state: '',
        },
      },
      metadata: getMetaData(),
      emailCustomerReceipt: (!isLaddrTF && isLaddrB2C) || null,
    };
  };

  const handleProcessNow = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setStates((prev) => ({
        ...prev,
        isLoading: true,
      }));
      const url = `${apiBaseUri}/merchants/${merchantId}/orders/invoices`;
      const body = {
        sendSms: false,
        sendEmail: false,
        orderRequest: await getOrderRequest(),
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const json = await res.json();
      setStates((prev) => ({
        ...prev,
        modalTitle: getModalTitle(isLaddrB2C, StepViewType.SuccessView),
        stepView: StepViewType.SuccessView,
        sentToCustomer: false,
        isLoading: false,
        errorMsg: '',
      }));
      setNow(Date.now());
      const newUrl = `${orderBaseUri}/${merchantName}/invoice/${json.order.merchantOrderId}?token=${json.token}`;
      window.open(newUrl, '_blank');
    } catch (e) {
      setStates((prev) => ({
        ...prev,
        errorMsg: e.message || 'Failed to process.',
        isLoading: false,
      }));
    }
  };

  const handleSendToCustomer = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setStates((prev) => ({
        ...prev,
        sendLoading: true,
      }));
      const url = `${apiBaseUri}/merchants/${merchantId}/orders/invoices`;
      const body = {
        sendSms: states.phone.length > 0,
        sendEmail: true,
        orderRequest: await getOrderRequest(),
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setStates((prev) => ({
        ...prev,
        modalTitle: getModalTitle(isLaddrB2C, StepViewType.SuccessView),
        stepView: StepViewType.SuccessView,
        sentToCustomer: true,
        sendLoading: false,
        errorMsg: '',
      }));
      setNow(Date.now());
    } catch (e) {
      setStates((prev) => ({
        ...prev,
        errorMsg: e.message || 'Failed to send to customer.',
        sendLoading: false,
      }));
    }
  };

  const handleSkipToDetails = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates((prev) => ({
      ...prev,
      modalTitle: getModalTitle(isLaddrB2C, StepViewType.EditView),
      stepView: StepViewType.EditView,
    }));
  };

  const handleAddItem = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const item = {
      quantity: '',
      name: '',
      cost: '',
      createdAt: Date.now(),
      editItem: true,
      validCost: true,
      validQuantity: true,
      showConfirmDeleteBtn: false,
    };
    setStates((prev) => ({
      ...prev,
      items: prev.items.concat([item]),
    }));
  };

  const handleChangeItem = (e: ChangeEvent<HTMLInputElement>, activeItem: ItemType) => {
    const { name, value } = e.target;

    const index = states.items.findIndex((item) => item.createdAt === activeItem.createdAt);

    const item = { ...states.items[index], [name]: value };

    if (name === 'cost') {
      const valid = value.length === 0 || validateAmount(value);
      item.validCost = valid;
    } else if (name === 'quantity') {
      const valid = value.length === 0 || isNumeroDecimal(value);
      item.validQuantity = valid;
    }

    setStates((prev) => ({
      ...prev,
      items: [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)],
    }));
  };

  const handleDeleteOrder = (e: MouseEvent<HTMLButtonElement | HTMLOrSVGElement>, createdAt: number): void => {
    e.preventDefault();
    const index = states.items.findIndex((item) => item.createdAt === createdAt);
    setStates((prev) => ({
      ...prev,
      items: [...prev.items.slice(0, index), ...prev.items.slice(index + 1)],
    }));
  };

  const handleShowConfirmDeleteBtn = (e: MouseEvent<HTMLOrSVGElement>, createdAt: number) => {
    e.preventDefault();
    const index = states.items.findIndex((item) => item.createdAt === createdAt);
    const item = { ...states.items[index], showConfirmDeleteBtn: true };
    setStates((prev) => ({
      ...prev,
      items: [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)],
    }));
  };

  const handleSaveOrder = (e: MouseEvent<HTMLButtonElement>, createdAt: number): void => {
    e.preventDefault();
    const index = states.items.findIndex((item) => item.createdAt === createdAt);
    const item = { ...states.items[index], editItem: false, showConfirmDeleteBtn: false };
    setStates((prev) => ({
      ...prev,
      items: [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)],
    }));
  };

  const handleEditItem = (e: MouseEvent<HTMLOrSVGElement>, createdAt: number): void => {
    e.preventDefault();
    const index = states.items.findIndex((item) => item.createdAt === createdAt);
    const item = { ...states.items[index], editItem: true };
    setStates((prev) => ({
      ...prev,
      items: [...prev.items.slice(0, index), item, ...prev.items.slice(index + 1)],
    }));
  };

  const handleEditView = (e: MouseEvent<HTMLButtonElement>): void => {
    switch (states.stepView) {
      case StepViewType.EditView:
        setStates((prev) => ({
          ...prev,
          modalTitle: getModalTitle(isLaddrB2C, StepViewType.AddItemsView),
          stepView: StepViewType.AddItemsView,
        }));
        break;

      case StepViewType.ConfirmView:
        if (states.validEmail === false || states.email.length === 0) {
          return;
        }
        setStates((prev) => ({
          ...prev,
          modalTitle: getModalTitle(isLaddrB2C, StepViewType.EditView),
          stepView: StepViewType.EditView,
        }));
        break;
      default:
        break;
    }
  };

  const handleToggleIncludeGst = () => {
    setStates((prev) => ({
      ...prev,
      displayGst: !prev.displayGst,
    }));
  };

  const handleToggleRequest3ds = () => {
    setStates((prev) => ({
      ...prev,
      request3ds: !prev.request3ds,
    }));
  };

  return (
    <s.Wrapper>
      <div>
        <HeaderTitle title={customizations.tabTitle} />
        {isLaddrB2C && (
          <s.Description>
            Create an order and send it directly to your customer for them to pay online.
            <div className="mt-2">
              The payment details will move to the
              <NavLink className="link" to={`/${merchantName}/payments`}>
                Completed payments
              </NavLink>
              tab of your dashboard once a full or partial payment has been made.
            </div>
          </s.Description>
        )}
        {isLaddrB2C === false && (
          <s.Description>
            Create an order on behalf of your customer.
            <div className="mt-2">
              Order details will move to the
              <NavLink className="link" to={`/${merchantName}/payments`}>
                Payments
              </NavLink>
              tab of your dashboard after a full or partial payment has been made.
            </div>
          </s.Description>
        )}
      </div>
      <s.CreateBtn onClick={handleClickCreate} data-testid="CreateManualPayment">
        {customizations.createOrderBtnText}
      </s.CreateBtn>
      {states.showModal && (
        <Modal
          title={states.modalTitle}
          cancelBtnText=""
          confirmBtnText=""
          disableConfirmBtn={states.isLoading}
          isLoading={states.isLoading}
          handleCancel={handleCancel}
          handleSubmit={handleConfirmManualPayment}
        >
          {states.stepView === StepViewType.AddItemsView && (
            <>
              <s.ModalDescription dangerouslySetInnerHTML={{ __html: customizations.addItemModalDescription }} />
              <s.AddItemWrapper>
                {savedItems.length > 0 && (
                  <s.ItemWrapper editItem={false}>
                    <s.BreakdownTitle>{customizations.breakdownTitle}</s.BreakdownTitle>
                    {savedItems.map((item) => (
                      <s.ItemRow key={item.createdAt}>
                        <s.ItemNamePrice>
                          <s.ItemName>
                            {item.name} ({item.quantity})
                          </s.ItemName>
                          <s.MobilePrice>
                            {multiply(item.cost, item.quantity, CountryCurrency[merchantTradingCountry])}
                          </s.MobilePrice>
                        </s.ItemNamePrice>

                        <s.ItemPrice left="50px" hiddenOnMobile>
                          {multiply(item.cost, item.quantity, CountryCurrency[merchantTradingCountry])}
                        </s.ItemPrice>
                        <s.BreakdownIcons>
                          <EditIcon onClick={(e) => handleEditItem(e, item.createdAt)} className="edit-icon" />
                          <DeleteIcon
                            onClick={(e) => handleShowConfirmDeleteBtn(e, item.createdAt)}
                            className="delete-icon"
                          />
                        </s.BreakdownIcons>
                        <s.ConfirmDeleteBtn
                          onClick={(e) => handleDeleteOrder(e, item.createdAt)}
                          active={item.showConfirmDeleteBtn}
                        >
                          Delete?
                        </s.ConfirmDeleteBtn>
                      </s.ItemRow>
                    ))}
                  </s.ItemWrapper>
                )}
                {editedItems.map((item) => (
                  <s.ItemWrapper editItem={item.editItem} key={item.createdAt}>
                    <Row>
                      <div className="col-12 col-sm-6">
                        <s.Label>Item Name</s.Label>
                        <s.Input
                          autoComplete="off"
                          data-testid="item-name"
                          name="name"
                          value={item.name}
                          onChange={(e) => handleChangeItem(e, item)}
                          required
                        />
                      </div>
                      <s.Relative className="col-12 col-sm-6 mt-3 mt-sm-0">
                        <s.Label>{customizations.addViewItemCost}</s.Label>
                        <s.AmountField>
                          <s.AmountInput
                            autoComplete="off"
                            data-testid="item-cost"
                            name="cost"
                            value={item.cost}
                            onChange={(e) => handleChangeItem(e, item)}
                            required
                          />
                        </s.AmountField>

                        {!item.validCost && <s.ItemErrorMsg>Please enter valid cost</s.ItemErrorMsg>}
                      </s.Relative>
                    </Row>
                    <Row className="mt-3">
                      <s.Relative className="col-4 col-sm-6">
                        <s.Label>Quantity</s.Label>
                        <s.Input
                          autoComplete="off"
                          data-testid="item-quantity"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleChangeItem(e, item)}
                          required
                        />
                        {!item.validQuantity && <s.ItemErrorMsg>Please enter valid quantity</s.ItemErrorMsg>}
                      </s.Relative>
                      <s.ItemBtnGroup className="col-8 col-sm-6">
                        <s.RemoveItemBtn
                          data-testid="remove-item-btn"
                          onClick={(e) => handleDeleteOrder(e, item.createdAt)}
                        >
                          Remove
                        </s.RemoveItemBtn>
                        <s.SaveItemBtn
                          data-testid="save-item-btn"
                          onClick={(e) => handleSaveOrder(e, item.createdAt)}
                          disabled={
                            item.cost.length === 0 ||
                            item.quantity.length === 0 ||
                            item.name.length === 0 ||
                            !item.validQuantity ||
                            !item.validCost
                          }
                        >
                          Save
                        </s.SaveItemBtn>
                      </s.ItemBtnGroup>
                    </Row>
                  </s.ItemWrapper>
                ))}
                <s.AddItemBtn onClick={handleAddItem} data-testid="addItemBtn">
                  <s.AddItemText>Add new item</s.AddItemText>
                  {!isLaddrB2C && <PlusIcon />}
                  {isLaddrB2C && <LaddrPlusIcon />}
                </s.AddItemBtn>
                {states.items.length === 0 && (
                  <s.SkipWrapper>
                    <s.SkipToDetails data-testid="skipButton" onClick={handleSkipToDetails}>
                      {customizations.addViewSkipBtn}
                    </s.SkipToDetails>
                  </s.SkipWrapper>
                )}
              </s.AddItemWrapper>
              <s.BtnGroup>
                <s.CancelBtn data-testid="EditViewCancelBtn" onClick={handleCancel}>
                  Cancel
                </s.CancelBtn>
                <s.ConfirmBtn
                  data-testid="EditViewConfirmBtn"
                  type="submit"
                  disabled={editedItems.length > 0 || savedItems.length === 0}
                >
                  Next
                </s.ConfirmBtn>
              </s.BtnGroup>
            </>
          )}
          {states.stepView === StepViewType.EditView && (
            <>
              <s.EditViewWrapper>
                <s.ModalRow top="0">
                  <div className="col-12 col-md-6">
                    <s.Label>{customizations.editViewOrderNumber}</s.Label>
                    <s.Input
                      data-testid={ChangeType.OrderNumber}
                      value={states.orderNumber}
                      onChange={(e) => handleChange(e, ChangeType.OrderNumber)}
                      required
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-0 mt-4">
                    <s.Label>{customizations.editViewOrderAmount}</s.Label>
                    <s.AmountField>
                      <s.AmountInput
                        data-testid={ChangeType.OrderAmount}
                        value={manualPaymentIncludeGst ? states.totalAmount : states.orderAmount}
                        onChange={(e) => handleChange(e, ChangeType.OrderAmount)}
                        bgColor={states.items.length > 0 ? '#f8f9fb' : '#fff'}
                        disabled={states.items.length > 0}
                        required
                        autoComplete="new-password"
                      />
                    </s.AmountField>
                    {!!customizations.editViewOrderAmountDescription && (
                      <s.FieldDescription>{customizations.editViewOrderAmountDescription}</s.FieldDescription>
                    )}
                    {!states.validAmount && <s.ErrorMsg>Please enter a valid amount</s.ErrorMsg>}
                  </div>
                </s.ModalRow>
                {displayOrderItemsGst && (
                  <s.ModalRow top="-4px" mobileTop="24px">
                    <div className="col-12 col-md-6">
                      <s.Label>{customizations.editViewGst}</s.Label>
                      <s.AmountField>
                        <s.AmountInput
                          data-testid={ChangeType.GST}
                          value={states.gstAmount}
                          bgColor="#f8f9fb"
                          disabled
                        />
                      </s.AmountField>
                    </div>
                  </s.ModalRow>
                )}
                <s.ModalRow top="24px">
                  <div className="col-12">
                    <s.Label>{customizations.editViewDescription}</s.Label>
                    <s.Textarea
                      data-testid={ChangeType.Description}
                      value={states.description}
                      onChange={(e) => handleChange(e, ChangeType.Description)}
                    />
                  </div>
                </s.ModalRow>
                <s.ModalRow top="24px">
                  <div className="col-12">
                    <s.Label>Customer Name</s.Label>
                    <s.Input
                      data-testid={ChangeType.Name}
                      value={states.name}
                      onChange={(e) => handleChange(e, ChangeType.Name)}
                      required
                      autoComplete="new-password"
                    />
                  </div>
                </s.ModalRow>
                <s.ModalRow top="24px">
                  <div className="col-12 col-md-6">
                    <s.Label>Customer Email</s.Label>
                    <s.Input
                      data-testid={ChangeType.Email}
                      type="email"
                      value={states.email}
                      onChange={(e) => handleChange(e, ChangeType.Email)}
                      required
                      autoComplete="new-password"
                    />
                    <s.ErrorMsg>{!states.validEmail && 'Please enter a valid email'}</s.ErrorMsg>
                  </div>
                  <div className="col-12 col-md-6">
                    <s.Label>Customer Phone (optional)</s.Label>
                    <PhoneInput
                      data-testid={ChangeType.Phone}
                      defaultCountry={merchantTradingCountry}
                      international
                      value={states.phone}
                      onChange={handleChangePhone}
                      autoComplete="new-password"
                    />
                    <s.ErrorMsg>{!states.validPhone && 'Please enter a valid phone number'}</s.ErrorMsg>
                  </div>
                </s.ModalRow>
                {customSettings?.customFields && customSettings.customFields.length > 0 && (
                  <s.CustomFieldsWrapper>
                    {customSettings.customFields.map((field) => (
                      <s.ModalRow top="24px" key={field.key}>
                        <div className="col-12">
                          <s.Label>{field.label}</s.Label>
                          <s.Input
                            data-testid={`custom-field-${field.key}`}
                            value={getCustomFieldValue(field.key)}
                            onChange={(e) => handleChangeCustomField(e, field.key)}
                            required={field.required}
                            autoComplete="new-password"
                          />
                        </div>
                      </s.ModalRow>
                    ))}
                  </s.CustomFieldsWrapper>
                )}
              </s.EditViewWrapper>
              {states.items.length > 0 && (
                <s.ItemWrapper editItem={false}>
                  <s.Flex>
                    <s.BreakdownTitle>{customizations.breakdownTitle}</s.BreakdownTitle>
                    <s.EditViewEditBtn onClick={handleEditView}>
                      <EditIcon className="edit-icon" />
                    </s.EditViewEditBtn>
                  </s.Flex>

                  {states.items.map((item) => (
                    <Fragment key={item.createdAt}>
                      <s.ItemRow>
                        <s.ItemName>
                          {item.name} ({item.quantity})
                        </s.ItemName>
                        <s.ItemPrice left="auto">
                          {multiply(item.cost, item.quantity, CountryCurrency[merchantTradingCountry])}
                        </s.ItemPrice>
                      </s.ItemRow>
                    </Fragment>
                  ))}
                  {displayOrderItemsGst && (
                    <>
                      <s.ItemRow>
                        <s.ItemName>GST</s.ItemName>
                        <s.ItemPrice left="auto">${states.gstAmount}</s.ItemPrice>
                      </s.ItemRow>

                      <s.ItemHr />
                      <s.ItemRow>
                        <s.BoldText>Total</s.BoldText>
                        <s.ItemPrice left="auto">${states.totalAmount}</s.ItemPrice>
                      </s.ItemRow>
                    </>
                  )}
                </s.ItemWrapper>
              )}
              <s.BtnGroup>
                <s.CancelBtn data-testid="EditViewCancelBtn" onClick={handleEditView}>
                  Back
                </s.CancelBtn>
                <s.ConfirmBtn
                  data-testid="EditViewConfirmBtn"
                  type="submit"
                  disabled={!states.validEmail || !states.validPhone || !states.validAmount}
                >
                  Next
                </s.ConfirmBtn>
              </s.BtnGroup>
            </>
          )}
          {states.stepView === StepViewType.ConfirmView && (
            <>
              {states.errorMsg.length > 0 && <Alert message={states.errorMsg} />}
              <s.ConfirmViewWrapper>
                <s.ConfirmTitle top="24px">{customizations.confirmViewOrderAmount}</s.ConfirmTitle>
                <s.OrderAmount>${states.totalAmount}</s.OrderAmount>
                <s.ConfirmTitle top="14px">{customizations.confirmViewDescription}</s.ConfirmTitle>
                <s.ConfirmValue top="3px">{states.description}</s.ConfirmValue>
                <s.ConfirmTitle top="22px">Customer Details</s.ConfirmTitle>
                {states.name && <s.ConfirmValue>{states.name}</s.ConfirmValue>}
                <s.ConfirmValue>{states.email}</s.ConfirmValue>
                {states.phone && <s.ConfirmValue>{states.phone}</s.ConfirmValue>}
                {states.items.length > 0 && (
                  <>
                    <s.ItemWrapper editItem={false} className="mt-4">
                      <s.Flex>
                        <s.BreakdownTitle>{customizations.breakdownTitle}</s.BreakdownTitle>
                        {isLaddrB2C && (
                          <s.EditViewEditBtn onClick={handleEditView}>
                            <EditIcon className="edit-icon" />
                          </s.EditViewEditBtn>
                        )}
                      </s.Flex>

                      {states.items.map((item) => (
                        <s.ItemRow key={item.createdAt}>
                          <s.ItemName>
                            {item.name} ({item.quantity})
                          </s.ItemName>
                          <s.ItemPrice left="auto">
                            {multiply(item.cost, item.quantity, CountryCurrency[merchantTradingCountry])}
                          </s.ItemPrice>
                        </s.ItemRow>
                      ))}

                      {(displayOrderItemsGst || states.displayGst) && (
                        <s.ItemRow>
                          <s.ItemName>GST</s.ItemName>
                          <s.ItemPrice left="auto">${states.gstAmount}</s.ItemPrice>
                        </s.ItemRow>
                      )}

                      <s.ItemHr />
                      <s.ItemRow>
                        <s.BoldText>Total</s.BoldText>
                        <s.ItemPrice left="auto">${states.totalAmount}</s.ItemPrice>
                      </s.ItemRow>
                    </s.ItemWrapper>
                    {merchantTradingCountry === 'AU' && !isLaddrB2C && (
                      <Checkbox
                        data-testid="displayGstCheckbox"
                        className="pt-2"
                        checked={states.displayGst}
                        handleChange={handleToggleIncludeGst}
                        text="Display GST"
                      />
                    )}
                  </>
                )}
                {!isLaddrB2C && (
                  <Checkbox
                    data-testid="request3dsOnPaymentCheckbox"
                    className="pt-2"
                    checked={states.request3ds}
                    handleChange={handleToggleRequest3ds}
                    text="Request 3DS on payment"
                  />
                )}
              </s.ConfirmViewWrapper>
              <s.BtnGroup>
                <s.CancelBtn onClick={handleClickEditOnEditView} data-testid="ConfirmViewEditBtn">
                  Back
                </s.CancelBtn>
                <s.SendBtn
                  disabled={states.sendLoading}
                  data-testid="ConfirmViewSendToCustomerBtn"
                  onClick={handleSendToCustomer}
                >
                  <s.IconWrapper>
                    <EmailIcon />
                  </s.IconWrapper>
                  <s.BtnText data-testid="ConfirmViewSendToCustomerBtnText">
                    Send <span className="desktop-only">to customer</span>
                  </s.BtnText>
                  {states.sendLoading && (
                    <s.SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.SendBtn>
                <s.ProcessBtn
                  disabled={states.isLoading}
                  data-testid="ConfirmViewProcessNowBtn"
                  onClick={handleProcessNow}
                >
                  <s.IconWrapper>
                    <CCIcon />
                  </s.IconWrapper>
                  <s.BtnText data-testid="ConfirmViewProcessNowBtnText">
                    Process <span className="desktop-only">now</span>
                  </s.BtnText>
                  {states.isLoading && (
                    <s.SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.ProcessBtn>
              </s.BtnGroup>
            </>
          )}
          {states.stepView === StepViewType.SuccessView && (
            <SuccessView
              email={states.email}
              phone={states.phone}
              sentToCustomer={states.sentToCustomer}
              successText={customizations.successViewText}
            />
          )}
        </Modal>
      )}
    </s.Wrapper>
  );
};

export default Header;
