import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import React, { ReactElement } from 'react';

import ReactTippy from '@tippyjs/react';

import { Button } from './Tooltip.styles';

export const Tippy = ReactTippy;

export interface TooltipProps extends React.HTMLAttributes<HTMLElement> {
  placement?: string;
}

const Tooltip = ({ children, placement = 'right' }: TooltipProps): ReactElement => {
  return (
    <Tippy
      content={children}
      trigger="click"
      //@ts-ignore
      placement={placement}
      interactive
      distance={4}
    >
      <Button onClick={(e: any) => e.stopPropagation()}>
        <InfoIcon />
      </Button>
    </Tippy>
  );
};

export default Tooltip;
