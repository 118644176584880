import numeral from 'numeral';

import { StatesType, StepViewType } from './HeaderTypes';

const stepViewModalTitle = {
  [StepViewType.AddItemsView]: 'Build your order',
  [StepViewType.EditView]: 'Order details',
  [StepViewType.ConfirmView]: 'Confirm order',
  [StepViewType.SuccessView]: '',
};

const laddrStepViewModalTitle = { ...stepViewModalTitle };

export const getModalTitle = (isLaddrB2C: boolean, stepView: StepViewType) => {
  if (isLaddrB2C) {
    return laddrStepViewModalTitle[stepView];
  }

  return stepViewModalTitle[stepView];
};

export const getCustomizations = (isLaddrB2C: boolean, isLaddrTF: boolean) => {
  if (isLaddrTF) {
    return {
      tabTitle: 'Orders',
      createOrderBtnText: 'Create new order +',
      addItemModalDescription: 'Create an itemised order for your customer by adding line items.',
      addViewSkipBtn: 'Skip to order details',
      breakdownTitle: 'Items in order',
      addViewItemCost: 'Item Cost',
      editViewOrderNumber: 'Order ID',
      editViewOrderAmount: 'Order amount',
      editViewOrderAmountDescription: '',
      editViewGst: '',
      editViewDescription: 'Description (optional)',
      confirmViewOrderAmount: 'Order Total',
      confirmViewDescription: 'Description',
      successViewText: 'Order has been created',
    };
  }
  if (isLaddrB2C) {
    return {
      tabTitle: 'Orders',
      createOrderBtnText: 'Create new order +',
      addItemModalDescription:
        'Create an itemised order for your customer by adding line items. <div class="mt-2">Ensure all item costs are exclusive of GST as it will be calculated based on the total at the end.</div>',
      addViewSkipBtn: 'Skip to order details',
      breakdownTitle: 'Items in order',
      addViewItemCost: 'Item Cost (exclusive GST)',
      editViewOrderNumber: 'Order ID',
      editViewOrderAmount: 'Order amount (exclusive of GST)',
      editViewOrderAmountDescription: 'Automatically calculated based on added items',
      editViewGst: 'GST',
      editViewDescription: 'Description (optional)',
      confirmViewOrderAmount: 'Order Total (includes GST)',
      confirmViewDescription: 'Description',
      successViewText: 'Order has been created',
    };
  }
  return {
    tabTitle: 'Virtual Terminal',
    createOrderBtnText: 'Create new order',
    addItemModalDescription: "Create a breakdown of a customer's order by adding line items",
    addViewSkipBtn: 'Skip to order details',
    breakdownTitle: 'Item breakdown',
    addViewItemCost: 'Item Cost',
    editViewOrderNumber: 'Order Number',
    editViewOrderAmount: 'Order Amount',
    editViewOrderAmountDescription: '',
    editViewGst: '',
    editViewDescription: 'Description of Goods/Services (optional)',
    confirmViewOrderAmount: 'Order Amount',
    confirmViewDescription: 'Description of Goods/Services',
    successViewText: 'Manual payment has been created',
  };
};

export const getInitialStates = (showModal: boolean, isLaddrB2C: boolean): StatesType => ({
  showModal,
  isLoading: false,
  sendLoading: false,
  errorMsg: '',
  stepView: StepViewType.AddItemsView,
  modalTitle: getModalTitle(isLaddrB2C, StepViewType.AddItemsView),
  orderNumber: '',
  totalAmount: '',
  orderAmount: '',
  gstAmount: '',
  description: '',
  name: '',
  email: '',
  phone: '',
  validEmail: true,
  validPhone: true,
  validAmount: true,
  sentToCustomer: false,
  items: [],
  displayGst: false,
  request3ds: false,
  customFieldsObj: null,
});

/**
 * calculate totalAmount, orderAmount and gstAmount based on added line items
 * totalAmount === orderAmount + gstAmount
 * if gst is included, the sum of line items is totalAmount
 * otherwise, the sum of line items is orderAmount
 */
export const getAmountsBasedOnItems = (states: StatesType, includeGst: boolean, country: 'AU' | 'NZ') => {
  const { items } = states;

  if (items.length === 0) {
    return {
      total: '',
      order: '',
      gst: '',
    };
  }

  const total = items.reduce((acc, item) => {
    const subTotal = numeral(item.cost).multiply(item.quantity).value() ?? 0;
    return numeral(acc).add(subTotal).value() ?? 0;
  }, 0);

  if (includeGst === false) {
    /**
     * for laddr.
     * GST in NZ is 0.15
     */
    const numeralTotal = country === 'AU' ? numeral(total).multiply(1.1) : numeral(total).multiply(1.15);
    const numeralGst = country === 'AU' ? numeral(total).multiply(0.1) : numeral(total).multiply(0.15);

    return {
      total: numeralTotal.format('0,0.00'),
      order: numeral(total).format('0,0.00'),
      gst: numeralGst.format('0,0.00'),
    };
  } else {
    /**
     * for general merchants.
     * in NZ, GST is 3/23 of total amount
     */
    const numeralGst = country === 'AU' ? numeral(total).divide(11) : numeral(total).multiply(3).divide(23);
    const numeralOrder =
      country === 'AU' ? numeral(total).multiply(10).divide(11) : numeral(total).multiply(20).divide(23);

    return {
      total: numeral(total).format('0,0.00'),
      order: numeralOrder.format('0,0.00'),
      gst: numeralGst.format('0,0.00'),
    };
  }
};

/**
 * calculate the amounts based on the input change.
 * totalAmount === orderAmount + gstAmount
 * if GST is included, the input amount is total amount
 * otherwise, the input amount is order amount
 */
export const getAmountsBasedOnInput = (value: string, includeGst: boolean, country: 'AU' | 'NZ') => {
  if (includeGst === false) {
    const numeralGst = country === 'AU' ? numeral(value).multiply(0.1) : numeral(value).multiply(0.15);
    const numeralTotal = country === 'AU' ? numeral(value).multiply(1.1) : numeral(value).multiply(1.15);
    return {
      total: numeralTotal.format('0,0.00'),
      order: numeral(value).format('0,0.00'),
      gst: numeralGst.format('0,0.00'),
    };
  } else {
    const numeralGst = country === 'AU' ? numeral(value).divide(11) : numeral(value).multiply(3).divide(23);
    const numeralOrder =
      country === 'AU' ? numeral(value).multiply(10).divide(11) : numeral(value).multiply(20).divide(23);
    return {
      total: numeral(value).format('0,0.00'),
      order: numeralOrder.format('0,0.00'),
      gst: numeralGst.format('0,0.00'),
    };
  }
};
