import { CustomSettings } from 'Components/ManualPayments/types';
import Message from 'Components/Message';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails, Row } from 'Constants/styles';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import * as s from './CustomContent.styles';
import {
  Condition,
  CustomContentType,
  ItemAction,
  ItemModalConfirmBtn,
  ItemModalConfirmBtnColour,
  ItemModalTitle,
  Visibility,
} from './CustomContent.types';
import RadioButtons from './RadioButtons';

const CustomContent = ({ apiBaseUri, merchantId }: CustomContentType) => {
  const [customSettings, setCustomSettings] = useState<CustomSettings | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [now, setNow] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [showActions, setShowActions] = useState<boolean>(false);

  // item modal states
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [condition, setCondition] = useState<Condition>(Condition.required);
  const [visibility, setVisibility] = useState<Visibility>(Visibility.visible);
  const [itemAction, setItemAction] = useState<ItemAction>(ItemAction.add);
  const [itemKey, setItemKey] = useState<string>('');
  const [itemLabel, setItemLabel] = useState<string>('');

  // common modal states
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }
    let isMounted = true;
    const fetchCustomSettings = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/merchants/${merchantId}/settings/order-custom-settings`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        if (isMounted) {
          setCustomSettings(await res.json());
          setErrorMsg('');
        }
      } catch (e) {
        isMounted && setErrorMsg(e.message || 'Failed to fetch custom settings');
      } finally {
        isMounted && setLoading(false);
      }
    };

    fetchCustomSettings();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, now]);

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleCloseModalErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setModalErrorMsg('');
  };

  const resetHideModalStates = () => {
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalLoading(false);
    setShowItemModal(false);
  };

  const handleCancelModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    resetHideModalStates();
  };

  const handleSubmitItem = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    if (!customSettings || !apiBaseUri || !merchantId) {
      return;
    }
    try {
      e.preventDefault();
      setModalLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/order-custom-settings`;
      let body = {};

      const { customFields } = customSettings;
      /** customSettings.customFields might be null */
      const _customFields = customFields ?? [];

      if (itemAction === ItemAction.delete) {
        body = {
          ...customSettings,
          customFields: [..._customFields.slice(0, activeIndex), ..._customFields.slice(activeIndex + 1)],
        };
      } else {
        const customField = {
          key: itemAction === ItemAction.add ? itemLabel : itemKey,
          label: itemLabel,
          required: Condition.required === condition,
          visibleForCustomer: Visibility.visible === visibility,
        };
        if (itemAction === ItemAction.add) {
          body = {
            ...customSettings,
            customFields: _customFields.concat([customField]),
          };
        } else if (itemAction === ItemAction.edit) {
          body = {
            ...customSettings,
            customFields: [
              ..._customFields.slice(0, activeIndex),
              customField,
              ..._customFields.slice(activeIndex + 1),
            ],
          };
        }
      }
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      resetHideModalStates();
      setNow(Date.now());
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to save changes');
      setModalLoading(false);
    }
  };

  const onChangeCondition = (e: ChangeEvent<HTMLInputElement>): void => {
    setCondition(e.target.value as Condition);
  };

  const onChangeVisibility = (e: ChangeEvent<HTMLInputElement>): void => {
    setVisibility(e.target.value as Visibility);
  };

  const onChangeItemKey = (e: ChangeEvent<HTMLInputElement>): void => {
    setItemKey(e.target.value);
  };

  const onChangeItemLabel = (e: ChangeEvent<HTMLInputElement>): void => {
    setItemLabel(e.target.value);
  };

  const toggleActionsDropdown = (e: MouseEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    setActiveIndex(index);
    setShowActions(true);
  };

  const resetShowModalStates = () => {
    setBodyOverflow('hidden');
    setModalErrorMsg('');
    setModalLoading(false);
    setShowActions(false);
  };

  const toggleAddItemModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setItemAction(ItemAction.add);
    setShowItemModal(true);
    resetShowModalStates();

    setCondition(Condition.required);
    setVisibility(Visibility.visible);
    setItemKey('');
    setItemLabel('');
  };

  const toggleEditItemModal = (e: MouseEvent<HTMLButtonElement>, index: number): void => {
    e.preventDefault();
    setItemAction(ItemAction.edit);
    setShowItemModal(true);
    resetShowModalStates();
    setActiveIndex(index);

    if (customSettings?.customFields) {
      const customField = customSettings.customFields[activeIndex];
      const _condition = customField?.required ? Condition.required : Condition.optional;
      const _visibility = customField?.visibleForCustomer ? Visibility.visible : Visibility.hidden;
      const _itemKey = customField?.key ?? '';
      const _itemLabel = customField?.label ?? '';
      setCondition(_condition);
      setVisibility(_visibility);
      setItemKey(_itemKey);
      setItemLabel(_itemLabel);
    } else {
      setCondition(Condition.required);
      setVisibility(Visibility.visible);
      setItemKey('');
      setItemLabel('');
    }
  };

  const toggleDeleteItemModal = (e: MouseEvent<HTMLButtonElement>, index: number): void => {
    e.preventDefault();
    setItemAction(ItemAction.delete);
    setShowItemModal(true);
    resetShowModalStates();
    setActiveIndex(index);
  };

  const handleClickOutside = () => {
    setShowActions(false);
  };

  useClickOutsideWithoutRef(handleClickOutside, ['actions-block', 'actions-dropdown', 'field-link-btn', 'dots-icon']);

  if (loading) {
    return (
      <s.SpinnerWrapper>
        <Spinner />
      </s.SpinnerWrapper>
    );
  }

  return (
    <>
      {!!errorMsg && <Message description={errorMsg} success={false} handleClose={handleCloseErrorMsg} />}
      <LpBox>
        <Row>
          <div className="col-sm-6">
            <H2>Configure your virtual terminal</H2>
          </div>
          <div className="col-sm-6 text-left text-sm-right">
            <s.AddItemBtn data-testid="add-new-item-btn" onClick={toggleAddItemModal}>
              Add a new item
            </s.AddItemBtn>
          </div>
        </Row>
        <LpDetails>
          <s.ItemList>
            <s.ItemRow className="row-header">
              <s.ItemCol data-testid="header-item-label">Item label</s.ItemCol>
              <s.ItemCol data-testid="header-condition" width="210px" mobileWidth="90px">
                Condition
              </s.ItemCol>
              <s.ItemCol data-testid="header-visibility" width="210px" mobileWidth="90px">
                Visibility
              </s.ItemCol>
            </s.ItemRow>
            {customSettings?.customFields?.map((field, index) => (
              <s.ItemRow key={field.key}>
                <s.ItemCol data-testid={`column-label-${index}`}>{field.label}</s.ItemCol>
                <s.ItemCol data-testid={`column-required-${index}`} width="210px" mobileWidth="90px">
                  {field.required ? 'Required' : 'Optional'}
                </s.ItemCol>
                <s.ItemCol data-testid={`column-visible-${index}`} width="210px" mobileWidth="90px">
                  <s.Visibility isVisible={field.visibleForCustomer}>
                    {field.visibleForCustomer ? 'Visible' : 'Hidden'}
                  </s.Visibility>
                </s.ItemCol>
                <s.Actions>
                  <s.ActionsDropdown
                    data-testid={`actions-dropdown-${index}`}
                    className="actions-dropdown"
                    onClick={(e) => toggleActionsDropdown(e, index)}
                  >
                    <DotsIcon className="dots-icon" />
                  </s.ActionsDropdown>
                  <s.ActionsBlock className="actions-block" active={index === activeIndex && showActions}>
                    <s.ActionBtn
                      data-testid={`actions-edit-${index}`}
                      className="field-link-btn"
                      onClick={(e) => toggleEditItemModal(e, index)}
                    >
                      Edit field
                    </s.ActionBtn>
                    <s.ActionBtn
                      data-testid={`actions-delete-${index}`}
                      className="field-link-btn"
                      onClick={(e) => toggleDeleteItemModal(e, index)}
                    >
                      Delete
                    </s.ActionBtn>
                  </s.ActionsBlock>
                </s.Actions>
              </s.ItemRow>
            ))}
            {(!customSettings || !customSettings.customFields || customSettings.customFields.length === 0) && (
              <div data-testid="no-data-available">No custom fields available</div>
            )}
          </s.ItemList>
        </LpDetails>
        {showItemModal && (
          <Modal
            title={ItemModalTitle[itemAction]}
            cancelBtnText="Cancel"
            confirmBtnText={ItemModalConfirmBtn[itemAction]}
            confirmBtnColour={ItemModalConfirmBtnColour[itemAction]}
            handleCancel={handleCancelModal}
            handleSubmit={handleSubmitItem}
            isLoading={modalLoading}
            disableConfirmBtn={modalLoading}
          >
            {!!modalErrorMsg && (
              <Message
                className="mt-4"
                description={modalErrorMsg}
                success={false}
                handleClose={handleCloseModalErrorMsg}
              />
            )}
            {(itemAction === ItemAction.add || itemAction === ItemAction.edit) && (
              <>
                {itemAction === ItemAction.edit && (
                  <>
                    <Label className="mt-4" data-testid="modal-item-key">
                      Item key
                    </Label>
                    <Input
                      data-testid="modal-key-input"
                      placeholder="Please enter item key"
                      required
                      value={itemKey}
                      onChange={onChangeItemKey}
                      disabled
                      readOnly
                    />
                  </>
                )}
                <Label className="mt-4" data-testid="modal-item-label">
                  Item label
                </Label>
                <Input
                  data-testid="modal-label-input"
                  placeholder="Please enter item label"
                  required
                  value={itemLabel}
                  onChange={onChangeItemLabel}
                />
                <Label className="mt-4" data-testid="modal-condition">
                  Condition
                </Label>
                <RadioButtons
                  className="mt-0 mb-0"
                  boldTitles={['Required field', 'Optional field']}
                  lightTitles={[' - This field must be completed before order can be sent to customer', '']}
                  name="ConditionField"
                  values={[Condition.required, Condition.optional]}
                  variable={condition}
                  onChange={onChangeCondition}
                />
                <Label className="mt-4">Visibility</Label>
                <RadioButtons
                  className="mt-0 mb-0"
                  boldTitles={['Visible', 'Hidden']}
                  lightTitles={[
                    ' - Customers will see this field on their order',
                    ' - Used for internal reporting purposes only',
                  ]}
                  name="VisibilityField"
                  values={[Visibility.visible, Visibility.hidden]}
                  variable={visibility}
                  onChange={onChangeVisibility}
                />
              </>
            )}
            {itemAction === ItemAction.delete && (
              <s.WarningText data-testid="modal-delete-warning">
                Are you sure you want to delete this item?
              </s.WarningText>
            )}
          </Modal>
        )}
      </LpBox>
    </>
  );
};

export default CustomContent;
