import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageBar = styled.div`
  padding: 0 22px;

  @media (min-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;
