import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import React, { MouseEvent, ReactElement } from 'react';

import { Button, Title } from './Back.styles';

export type BackProps = {
  title: string;
  handleClickBack?(e: MouseEvent<HTMLButtonElement>): void;
};

const Back = ({ title, handleClickBack }: BackProps): ReactElement => (
  <Button onClick={handleClickBack}>
    <ChevronLeft className="left" />
    <Title>{title}</Title>
  </Button>
);

export default Back;
