import { AggregatedType, ChartDataType } from './types';

//format 2021-04-06
const getToday = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();

  return `${year}-${String(month).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
};

const constructChartData = (transactions: [string, number][], customers: [string, number][]): ChartDataType => {
  const today = getToday();

  const transactionsAggregatedSum: AggregatedType[] = [];
  let transactionsForToday = 0;
  let transactionSum = 0;
  transactions.forEach((t) => {
    const obj = {
      date: t[0],
      value: t[1],
    };
    transactionsAggregatedSum.push({ ...obj });
    if (t[0] === today) {
      transactionsForToday = t[1];
    }
    transactionSum += t[1];
  });

  const customerAggregatedCount: AggregatedType[] = [];
  let customersForToday = 0;
  let customerCount = 0;
  customers.forEach((c) => {
    const obj = {
      date: c[0],
      value: c[1],
    };
    customerAggregatedCount.push({ ...obj });
    if (c[0] === today) {
      customersForToday = c[1];
    }
    customerCount += c[1];
  });

  return {
    transactionSum,
    transactionsForToday,
    transactionsAggregatedSum,
    customerCount,
    customersForToday,
    customerAggregatedCount,
  };
};

export default constructChartData;
