import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;

  .lp-details {
    color: #000;
    font-weight: 500;

    .d-none {
      display: none;
      @media (min-width: 768px) {
        &.d-md-inline {
          display: inline;
        }
      }
    }
  }

  .custom-icon-width {
    min-width: 36px;
  }

  .bank-icon {
    width: 15px;
    height: 15px;
  }

  p {
    color: #6c7488;
    line-height: 24px;
    margin-top: 14px;
    margin-bottom: 16px;
    font-size: 15px;
  }

  .card {
    background-color: transparent;
    margin-bottom: 10px;
    border-radius: 0;
    border: none;
    padding: 0 7px;
  }

  .item-list {
    list-style: none;
    margin: 0;
    line-height: 20px;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;

      &.active-item {
        .item-col-xl {
          padding-left: 15px;
        }
      }
    }

    .item-row {
      display: flex;
      align-items: stretch;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      min-width: 100%;
    }

    > li {
      border-top: 1px solid #e6eaee;

      &:first-of-type {
        border: 0;
      }

      &:nth-of-type(2) {
        border: 0;
      }
    }

    .item-transaction-id {
      word-break: break-all;
    }

    .item-col {
      align-items: center;
      display: flex;
      padding: 10px 10px 10px 0;
      flex-basis: 0;
      flex-grow: 3;
      flex-shrink: 3;
      margin-left: auto;
      margin-right: auto;
      min-width: 0;

      &.item-col-xl {
        max-width: 210px;
      }
      &.item-col-cxl {
        max-width: 180px;
      }
      &.item-col-lgr {
        max-width: 250px;
      }
      &.fixed {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
      }
      &.item-col-actions-dropdown {
        padding-right: 0;
        width: 15px;

        .item-actions-block {
          display: none;
          max-width: 450px;
          min-width: 238px;
          line-height: 30px;
          overflow: hidden;
          position: absolute;
          top: 0;
          right: 32px;
          background-color: #fff;
          padding: 15px 20px;
          border: 1px solid #e7e7e7;
          box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
          border-radius: 3px;
          text-align: left;
          z-index: 1;

          &.active {
            display: block;
          }

          .item-actions-list {
            list-style: none;
            white-space: nowrap;
            padding: 0;
            margin: 0;

            li {
              padding: 3px 0;
              margin: 0;
            }

            a {
              color: #6c7488;
              display: block;
              font-size: 15px;
              line-height: 24px;
            }
          }
        }
      }
      .item-actions-dropdown {
        position: relative;
        display: inline-block;
        font-size: 17px;
        right: 10px;
        top: -1px;
        width: 15px;
        cursor: pointer;
      }

      a {
        color: var(--lp-colors-medium-blue-600);
      }
    }

    .item-list-header {
      .item-col {
        padding: 4px 10px 6px 0;
      }

      span {
        font-size: 15px;
        color: #6c7488;
      }
    }

    .no-overflow {
      overflow: hidden;
    }

    .price {
      margin-bottom: 0;
      margin-top: 0;
      font-family: 'Acumin Regular';
      font-size: 16px;
      line-height: 20px;
      color: #354052;
    }
  }

  .pagination {
    display: flex;
    margin: 16px 0;
    .pagination-prev,
    .pagination-next {
      cursor: pointer;
      color: var(--lp-colors-medium-blue-600);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .pagination-prev {
      margin-right: 15px;
    }
  }

  .dots-icon {
    display: block;
  }

  .arrow-right {
    display: none;
  }

  .order-id-text {
    padding-top: 10px;
  }

  @media (max-width: 1400px) {
    .order-id {
      font-size: 13px;
    }
    .item-list .price {
      font-size: 13px;
    }
  }

  @media (max-width: 768px) {
    .item-list {
      .item {
        font-size: 14px;
        margin-bottom: 3px;

        .item-row-reset-m {
          min-width: 0;
          max-width: 321px;

          .item-col-actions-dropdown {
            position: absolute;
            top: 45%;
            right: 19px;

            .arrow-right {
              width: 20px;
              height: 20px;
              transform: rotate(270deg);
              display: block;
            }
          }
        }

        &.item-list-header {
          display: none;
        }

        .item-col {
          padding: 0;
          flex-basis: 100%;
          &.item-col-sales {
            text-align: left;
            order: -2;
            flex-basis: 100%;
            position: relative;
          }
          &.item-col-xl {
            max-width: 100%;
          }
          &.item-col-actions-dropdown {
            border: none;
            padding-bottom: 0;
            order: -3;
            flex-basis: 40px;
            padding-right: 10px;
          }
        }

        &.active-item {
          .item-col-xl {
            padding-left: 0;
          }
        }
      }
      > li {
        padding: 16px 0 16px;
      }
      .price {
        font-size: 16px;
      }
    }
    .order-id {
      font-size: 16px;
    }
    .dots-icon {
      display: none;
    }
  }
  @media (max-width: 380px) {
    .order-id {
      font-size: 14px;
    }
    .item-list .price {
      font-size: 14px;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalSpinner = styled.div`
  text-align: center;
`;

export const LinkBtn = styled.button`
  padding: 2px 0;
  background-color: transparent;
  border: none;
  color: #6c7488;
  display: block;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Acumin Pro', Arial, sans-serif;

  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const DownloadBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding-left: 50px;
  }
`;

export const OrderIdBtn = styled.button`
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const PrimaryBtn = styled.button`
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#00ff00' : 'var(--lp-colors-medium-blue-600)')};
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00ff00' : 'var(--lp-colors-medium-blue-600)')};
  height: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 40px;
  font-size: 14px;

  &:disabled {
    opacity: 0.65;
    cursor: default;
  }

  .save-text {
    padding-top: 6px;
  }
`;

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const Select = styled.select`
  height: 44px;
  font-size: 15px;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dce3eb;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
`;

export const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export const PayoutBtnSpinner = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 4px;
  padding-top: 2px;
`;

export const FeatureIcon = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  border: 1px solid #989fb2;
  width: 36px;
  height: 36px;
  min-width: 36px;
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;
