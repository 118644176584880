import styled from '@emotion/styled';

export const EditBtn = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-size: 15px;
  font-weight: 300;
  padding: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : '#0056b3')};
  }
`;

export const SettingsTab = styled.div`
  .modal {
    .form-item {
      padding-bottom: 14px;
      font-weight: 300;
      color: #6c7488;
      position: relative;
    }
    .form-field {
      position: relative;
    }
    input {
      padding: 17px 17px 12px;
      max-height: 44px;
      outline: none;
      background: #fff;
      border: 1px solid #dce3eb;
      border-radius: 3px;
      width: 100%;
      color: #000;
      box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
    }
  }
`;

export const InvalidText = styled.div`
  font-size: 14px;
  color: red;
  position: absolute;
  right: 0;
`;

export const HelperText = styled.div`
  font-size: 14px;
`;

export const HelperTextBG = styled.div`
  font-size: 14px;
  background: #e8e9ee;
  padding: 15px;
  border-radius: 10px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
`;

export const PhoneInputWrapper = styled.div`
  .PhoneInputInput {
    padding: 17px 17px 12px;
    max-height: 44px;
    outline: none;
    background: #fff;
    border: 1px solid #dce3eb;
    border-radius: 3px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100%;
    color: #000;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  }
  .PhoneInputCountry {
    background: #f8f9fb;
    width: 85px;
    margin: 0;
    padding: 0 12px;
    border: 1px solid #dce3eb;
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .PhoneInputCountrySelectArrow {
    width: 6px;
    height: 6px;
    padding-left: 0px;
    position: absolute;
    right: 21px;
    opacity: 1;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }
`;

export const LongWord = styled.div`
  word-break: break-word;
`;

export const LpRadio = styled.label`
  position: relative;
  cursor: pointer;
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }
  input:checked ~ .check-mark {
    background-color: #27cbcc;
    border: 1px solid #13bebe;
    font-weight: bold;

    &:after {
      display: block;
    }
  }
  .check-mark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;

export const Cno = styled.div`
  padding-left: 30px;
  font-weight: 400;
  color: #000;
`;

export const RadioUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const RadioLi = styled.li`
  padding: 6px 0;
`;
