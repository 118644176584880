import './Chart.scss';

import React, { ReactElement, useEffect } from 'react';

type AggregatedType = {
  date: string;
  value: number;
};

export type ChartProps = {
  totalAmount: number;
  todayAmount: number;
  aggregatedArray: AggregatedType[];
  element: string;
  title: string;
  chartHeader: string;
};

const Chart = ({
  totalAmount,
  todayAmount,
  aggregatedArray,
  element,
  title,
  chartHeader,
}: ChartProps): ReactElement => {
  useEffect(() => {
    const morrisObj = {
      element,
      data: aggregatedArray,
      xkey: 'date',
      ykeys: ['value'],
      yLabelFormat: (y: number): number => y,
      labels: ['value'],
      hideHover: 'false',
      parseTime: true,
      resize: true,
      fillOpacity: 0.2,
      preUnits: '$',
      behaveLikeLine: false,
      smooth: false,
      lineColors: ['#27cbcc'],
      lineWidth: 2,
      pointSize: 3,
    };

    // @ts-ignore
    window.Morris.Area(morrisObj);
  }, [element, aggregatedArray]);

  return (
    <div className="col-md-6 chart-wrapper">
      <div className="lp-box">
        <div className="row">
          <div className="col-sm-7">
            <div className="title">{title}</div>
            <h1 className="transaction-sum">{totalAmount}</h1>
          </div>
          <div className="col-sm-5 text-right legnds">
            <span className="today">
              Today
              <span className="lp-color-black ml-1" id="transactionsForToday">
                {todayAmount}
              </span>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">{chartHeader}</div>
        </div>
        <div id={element} />
      </div>
    </div>
  );
};

export default Chart;
