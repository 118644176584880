import './CustomerPayments.scss';

import Alert from 'Components/Alert/Alert';
import EmptyList from 'Components/EmptyList/EmptyList';
import Pagination from 'Components/Pagination/Pagination';
import { getStatusClass, getStatusLabel } from 'Components/Payments/OrderList';
import { PaymentAPIResponseType } from 'Components/Payments/types';
import Spinner from 'Components/Spinner/Spinner';
import { H2 } from 'Constants/styles';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setPaymentDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ITEM_ROWS_OBJ } from './Constants';
import * as s from './CustomerPayments.style';

const CustomerPayments = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [activeTransactionId, setActiveTransactionId] = useState<string>('');
  const [payments, setPayments] = useState<PaymentAPIResponseType[]>([]);
  const [merchantName] = useState(() => getMerchantName());
  const [redirectToPayment, setRedirectToPayment] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);

  const dispatch: Dispatch<any> = useDispatch();

  const { activeCustomer, apiBaseUri, merchantId } = useSelector((state: ReduxStateType) => ({
    activeCustomer: state.activeCustomer,
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
  }));

  useEffect(() => {
    if (!activeCustomer || !apiBaseUri || !merchantId) {
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?sort=-createdAt&customerId=${activeCustomer.customerId}&page=${activePage}`;
      const options = await getFetchOptions();

      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch ${url}`);
          }
          return {
            pages: Number(res.headers.get('Limepay-Page-Count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setPayments(response);
          setPageCount(pages);
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, [activeCustomer, apiBaseUri, merchantId, activePage]);

  const setPaymentDetailsCB = useCallback((s, a) => dispatch(setPaymentDetails(s, a)), [dispatch]);

  const handleClickOutside = () => {
    setActiveTransactionId('');
  };

  const handleClickBtn = (e: MouseEvent<HTMLButtonElement>, transactionId: string): void => {
    e.preventDefault();
    const paymentDetails = payments.find((p) => p.transactionId === transactionId) as PaymentAPIResponseType;
    setPaymentDetailsCB(true, paymentDetails);
    setRedirectToPayment(true);
  };

  const handleClickIcon = (e: MouseEvent<HTMLDivElement>, transactionId: string): void => {
    e.preventDefault();
    setActiveTransactionId(transactionId);
  };

  useClickOutsideWithoutRef(handleClickOutside, [
    'item-actions-block',
    'item-actions-list-link',
    'dots-icon',
    'arrow-right-icon',
    'details-link-btn',
  ]);

  if (!activeCustomer) {
    return <></>;
  }

  if (redirectToPayment) {
    return <Redirect to={`/${merchantName}/payments`} push />;
  }

  return (
    <div className="customer-payments">
      <div className="lp-box">
        <H2>Payments</H2>
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {ITEM_ROWS_OBJ.map((row, rowIndex) => (
                  <div key={rowIndex} className={`item-col item-col-header item-col-title ${row.class}`}>
                    <div className={`${row.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{row.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {hasError && <Alert message="Failed to get payment list" />}
            {isLoading && !hasError && (
              <s.SpinnerWrapper>
                <Spinner />
              </s.SpinnerWrapper>
            )}
            {!isLoading && !hasError && payments.length === 0 && <EmptyList />}
            {!isLoading &&
              !hasError &&
              payments.map((payment) => (
                <li key={payment.transactionId} className="item active-item">
                  <div className="item-row item-row-reset-m">
                    <div className="item-col item-col-order">
                      <div className="media">
                        <div className="feature-icon wicons">
                          <OrderIdIcon className="order-id-icon" />
                        </div>
                        <div className="order-id mt-1">
                          <s.OrderIdBtn onClick={(e) => handleClickBtn(e, payment.transactionId)}>
                            {payment.merchantOrderInternalOrderId}
                          </s.OrderIdBtn>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-xsrr">
                      <div className="item text-left mt-1">
                        <div className="price">
                          {getDateFormat({ time: payment.createdAt }).date}
                          <span className="d-none d-md-inline time-span">
                            {getDateFormat({ time: payment.createdAt }).time}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-xsrr item-col-amount">
                      <div className="item text-left mt-1">
                        <div className="price">
                          {typeof payment.transactionAmount === 'number'
                            ? toCurrency(payment.transactionAmount, payment.currency)
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-xsrr">
                      <div className="item text-left mt-1">
                        <div className="price">{!!payment.transactionPayType.payCardId ? 'Full' : 'Split'} payment</div>
                      </div>
                    </div>
                    <div className="item-col item-col-xsrr">
                      <div className="item text-left mt-1">
                        <div className="price">{payment.merchantOrderType}</div>
                      </div>
                    </div>
                    <div className="item-col item-col-cxl item-col-status">
                      <div className="item text-left mt-1">
                        <div className={`price status ${getStatusClass(payment.transactionStatus)}`}>
                          {getStatusLabel(payment.transactionStatus)}
                        </div>
                      </div>
                    </div>
                    <div className="item-col fixed item-col-actions-dropdown">
                      <div className="item-actions-dropdown">
                        <div className="icon-wrapper" onClick={(e) => handleClickIcon(e, payment.transactionId)}>
                          <DotsIcon className="dots-icon" />
                          <ArrowDownIcon className="arrow-right-icon" />
                        </div>
                        <div
                          className={`item-actions-block ${
                            activeTransactionId === payment.transactionId ? 'active' : ''
                          }`}
                        >
                          <s.ListActionsUl>
                            <s.ListActionsLi>
                              <s.LinkBtn
                                className="details-link-btn"
                                onClick={(e) => handleClickBtn(e, payment.transactionId)}
                              >
                                View details
                              </s.LinkBtn>
                            </s.ListActionsLi>
                            <s.ListActionsLi>
                              <s.LinkBtn
                                className="details-link-btn"
                                onClick={(e) => handleClickBtn(e, payment.transactionId)}
                              >
                                Refund payment
                              </s.LinkBtn>
                            </s.ListActionsLi>
                          </s.ListActionsUl>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Pagination
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
        noPadding
        pageStartIndex={pageStartIndex}
        setPageStartIndex={setPageStartIndex}
      />
    </div>
  );
};

export default CustomerPayments;
