import { Currency } from 'utils/currency';

export enum ActionLinkType {
  ProcessPayment = 'ProcessPayment',
  SendToCustomer = 'SendToCustomer',
  Cancel = 'Cancel',
}

export type BillingType = {
  name: string;
};

export interface PaymentType {
  amount: number;
  createdAt: string;
  description: string;
  internalOrderId: string;
  currency: Currency;
  billing: Nullable<BillingType>;
  status: string;
  merchantOrderId: string;
  customerEmail: string;
  phoneNo: string | null;
}

type StandardFieldType = {
  customLabel: Nullable<string>;
  visibleForCustomer: boolean;
};

type StandardFields = {
  orderNumberField: StandardFieldType;
  descriptionField: StandardFieldType;
  customerNameField: StandardFieldType;
  customerPhoneField: StandardFieldType;
};

type CustomField = {
  key: string;
  label: string;
  required: boolean;
  visibleForCustomer: boolean;
};

export interface CustomSettings {
  standardFields: StandardFields;
  customFields: CustomField[] | null;
  orderItemsEnabled: boolean;
  gstIncluded: boolean;
}

export enum ActionStepView {
  Confirm = 'Confirm',
  Success = 'Success',
}

export type ActionsType = {
  isCancelled: boolean;
};

export type StatesType = {
  isLoading: boolean;
  errorMsg: string;
  showErrorMsg: boolean;
  modalLoading: boolean;
  modalErrorMsg: string;
  showSendModal: boolean;
  showCancelModal: boolean;
  showProcessModal: boolean;
  activeOrderId: string;
  canShowActionList: boolean;
  cancelStepView: ActionStepView;
  sendToCustomerStepView: ActionStepView;
  processPaymentStepView: ActionStepView;
};
