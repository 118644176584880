import Message from 'Components/Message';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails, P, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import useDebounce from 'utils/useDebounce';
import validateEmail from 'utils/validateEmail';

import * as s from './PayoutReports.styles';
import { Cno, EditBtn, LpRadio, RadioLi, RadioUl } from './styles';
import { PayoutReportsAPIResponse } from './types';

type PayoutReportsType = {
  apiBaseUri: string;
  merchantId: string;
};

enum Notifications {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

const PayoutReports = ({ apiBaseUri, merchantId }: PayoutReportsType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [payoutSettings, setPayoutSettings] = useState<PayoutReportsAPIResponse | null>(null);
  const [now, setNow] = useState<number>(0);

  // modal states
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');
  const [notifications, setNotifications] = useState<Notifications>(Notifications.Disabled);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [inputHasFocus, setInputHasFocus] = useState<boolean>(false);

  const debouncedEmails = useDebounce(emails, 500);

  useEffect(() => {
    if (emails !== debouncedEmails) {
      return;
    }
    const isValid = emails.split(',').every((e) => validateEmail(e.trim()));
    setValidEmail(isValid);
  }, [emails, debouncedEmails]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchPayouts = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        if (isMounted) {
          setPayoutSettings(await res.json());
          setErrorMsg('');
        }
      } catch (e) {
        isMounted && setErrorMsg(e.message || 'Failed to fetch payout reports');
      } finally {
        isMounted && setLoading(false);
      }
    };

    fetchPayouts();
    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, now]);

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleCloseModalErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setModalErrorMsg('');
  };

  const handleShowModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setModalLoading(false);
    setModalErrorMsg('');
    setBodyOverflow('hidden');
    setEmails(payoutSettings?.notificationEmails?.join(', ') || '');
    setValidEmail(true);
    const _notifications = payoutSettings?.notificationsEnabled || false;
    setNotifications(!!_notifications ? Notifications.Enabled : Notifications.Disabled);
    setInputHasFocus(false);
  };

  const handleCancelModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setModalLoading(false);
    setModalErrorMsg('');
    setBodyOverflow('auto');
    setShowModal(false);
    setValidEmail(true);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setModalLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout`;
      const body = {
        notificationEmails: emails.split(',').map((e) => e.trim()),
        notificationsEnabled: notifications === Notifications.Enabled ? true : false,
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setModalErrorMsg('');
      setBodyOverflow('auto');
      setShowModal(false);
      setNow(Date.now());
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to save payout report settings');
    } finally {
      setModalLoading(false);
    }
  };

  const onChangeNotification = (e: ChangeEvent<HTMLInputElement>): void => {
    setNotifications(e.target.value as Notifications);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmails(value);
  };

  const handleFocusEmail = () => {
    setInputHasFocus(true);
  };

  const handleBlurEmail = () => {
    setInputHasFocus(false);
  };

  return (
    <>
      {!!errorMsg && <Message description={errorMsg} success={false} handleClose={handleCloseErrorMsg} />}
      <LpBox>
        <Row>
          <div className="col-sm-6">
            <H2>Payout reports</H2>
          </div>
          <div className="col-sm-6 text-left text-sm-right">
            {!!payoutSettings && (
              <EditBtn data-testid="edit-payout-report-btn" onClick={handleShowModal}>
                Edit payout report settings
              </EditBtn>
            )}
          </div>
        </Row>
        <LpDetails>
          <Row>
            <div className="col-sm-12">
              <P className="mt-2 mb-0 mb-md-3">Receive a payout report via email after each payout is processed.</P>
            </div>
          </Row>
          {loading && (
            <s.SpinnerWrapper>
              <Spinner />
            </s.SpinnerWrapper>
          )}
          {!!payoutSettings && !loading && (
            <>
              <Row className="pt-4">
                <div className="col-sm-4 col-xl-3">
                  <Label data-testid="payout-report-notification-label">Notifications</Label>
                </div>
                <div data-testid="payout-report-notification-value" className="col-sm-8 col-xl-9">
                  {payoutSettings.notificationsEnabled ? 'Enabled' : 'Disabled'}
                </div>
              </Row>
              {!!payoutSettings.notificationsEnabled && (
                <Row className="pt-4">
                  <div className="col-sm-4 col-xl-3">
                    <Label data-testid="payout-report-recipients-label">Recipients</Label>
                  </div>
                  <div data-testid="payout-report-recipients-value" className="col-sm-8 col-xl-9">
                    {payoutSettings.notificationEmails?.join(', ')}
                  </div>
                </Row>
              )}
            </>
          )}
        </LpDetails>
        {showModal && (
          <Modal
            title="Edit payout report settings"
            cancelBtnText="Cancel"
            confirmBtnText="Save"
            handleCancel={handleCancelModal}
            handleSubmit={handleSubmit}
            isLoading={modalLoading}
            disableConfirmBtn={
              modalLoading || !validEmail || (notifications === Notifications.Enabled && emails.length === 0)
            }
          >
            {!!modalErrorMsg && (
              <Message
                className="mt-4"
                description={modalErrorMsg}
                success={false}
                handleClose={handleCloseModalErrorMsg}
              />
            )}
            <P>Receive a payout report via email after each payout is processed.</P>
            <RadioUl>
              <RadioLi>
                <LpRadio>
                  <Cno data-testid="modal-disable-notifications">Disable notifications</Cno>
                  <input
                    type="radio"
                    name="NotificationRadio"
                    value={Notifications.Disabled}
                    onChange={onChangeNotification}
                    checked={notifications === Notifications.Disabled}
                    data-testid="modal-disable-notifications-input"
                  />
                  <span className="check-mark" />
                </LpRadio>
              </RadioLi>
              <RadioLi>
                <LpRadio>
                  <Cno data-testid="modal-enable-notifications">Enable notifications</Cno>
                  <input
                    type="radio"
                    name="NotificationRadio"
                    value={Notifications.Enabled}
                    onChange={onChangeNotification}
                    checked={notifications === Notifications.Enabled}
                    data-testid="modal-enable-notifications-input"
                  />
                  <span className="check-mark" />
                </LpRadio>
                {notifications === Notifications.Enabled && (
                  <Row className="mt-3">
                    <div className="col-12">
                      <Label data-testid="modal-recipients-label">Recipients</Label>
                      <Input
                        autoComplete="off"
                        placeholder="name@domain.com, nametwo@domain.com"
                        value={emails}
                        onChange={handleChangeEmail}
                        onFocus={handleFocusEmail}
                        onBlur={handleBlurEmail}
                        data-testid="modal-recipients-input"
                      />
                      {validEmail && inputHasFocus && (
                        <s.Small data-testid="modal-recipients-valid-text" isValid>
                          Multiple email addresses can be added, separated by a comma.
                        </s.Small>
                      )}
                      {!validEmail && (
                        <s.Small data-testid="modal-recipients-invalid-text" isValid={false}>
                          Please enter a valid email
                        </s.Small>
                      )}
                    </div>
                  </Row>
                )}
              </RadioLi>
            </RadioUl>
          </Modal>
        )}
      </LpBox>
    </>
  );
};

export default PayoutReports;
