import styled from '@emotion/styled';

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-family: Arial;
  .header {
    position: absolute;
    width: 100%;
    margin-bottom: 50px;
    z-index: 2;
  }
  .nav {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px auto;
    max-width: 1600px;
    padding: 50px 100px;
  }
  .logo-wrapper {
    min-width: 142px;
    max-width: 142px;
    margin-right: auto;
    flex: 0 1 0%;
    z-index: 1;

    .logo {
      max-width: 142px;
    }
  }
  .content {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
    max-width: 1600px;
    padding: 90px 100px 80px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .login-form {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    max-width: 550px;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .form-header {
    display: flex;
    flex-direction: column;
    min-height: 136px;
    border-bottom: 1px solid rgb(218, 227, 237);
  }
  .form-title {
    margin: 0;
    padding: 30px;
    display: block;
    color: rgb(85, 91, 125);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.38px;
  }
  .input-wrapper {
    width: 100%;
    position: relative;
  }
  .label {
    padding: 0 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgb(218, 227, 237);
    box-sizing: border-box;
  }
  .icon-wrapper {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 70px;
  }
  .email-icon,
  .password-icon {
    width: 22px;
    height: 22px;
  }
  .input {
    appearance: none;
    background: rgba(0, 0, 0, 0);
    height: 100%;
    width: 100%;
    padding: 0 20px 0 8px;
    border: none;
    color: rgb(34, 34, 48);
    font-size: 18px;

    &:focus {
      outline: none;
    }
  }
  .input-submit {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
  .small {
    font-size: 80%;
  }
  .forget-password {
    color: #0a173a;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.19px;
    background-color: transparent;
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    .content {
      flex-direction: column;
      padding: 90px 20px 80px;
    }
  }
`;

export const ErrorBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: rgb(254, 240, 244);
  color: rgb(141, 46, 73);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.38px;
  box-sizing: border-box;
`;

export const Btn = styled.button`
  margin-top: 30px;
  padding: 0;
  width: 100%;
  height: 64px;
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#5EFF00' : 'var(--lp-colors-medium-blue-600)')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#5EFF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 7px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  font-size: 19px;
  text-align: center;
  letter-spacing: 0.38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const SpinnerWrapper = styled.div`
  margin-left: 6px;
  padding-top: 3px;
  height: 40px;
  width: 40px;
`;
