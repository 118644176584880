import Message from 'Components/Message/';
import Spinner from 'Components/Spinner/Spinner';
import { H2 } from 'Constants/styles';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useClickOutside from 'utils/useClickOutside';

import styled from '@emotion/styled';

import { AutomaticPayoutScheduleOptions } from './Constants';

const SpinnerWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  selectedPayoutFreqHandler?: (val: string) => void;
}

const PayoutSchedule = ({ selectedPayoutFreqHandler }: Props) => {
  const [payoutFreqMenuOpen, setPayoutFreqMenuOpen] = useState<boolean>(false);
  const [selectedPayoutFreq, setSelectedPayoutFreq] = useState<string>('daily');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout-schedule`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const freq = await res.json();
        if (isMounted) {
          setSelectedPayoutFreq(freq);
          setErrorMsg('');
        }
      } catch (e) {
        isMounted && setErrorMsg(e.message || 'Failed to fetch payout schedule');
      } finally {
        isMounted && setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId]);

  const ref = useRef(null);

  const togglePayoutFreqMenu = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setPayoutFreqMenuOpen((open) => !open);
  };

  const handleChangeFreqOption = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setSelectedPayoutFreq(e.target.value);
    setLoading(true);
    const options = await getFetchOptions('POST', JSON.stringify(e.target.value));
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout-schedule`;
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        selectedPayoutFreqHandler && selectedPayoutFreqHandler(e.target.value);
        setSuccessMsg(`Successfully saved payout schedule to ${e.target.value}`);
        setErrorMsg('');
        setPayoutFreqMenuOpen(false);
      })
      .catch((e) => {
        setSuccessMsg('');
        setErrorMsg(e.message || 'Failed to save payout schedule');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickOutsideFreq = () => {
    setPayoutFreqMenuOpen(false);
  };

  useClickOutside(ref, handleClickOutsideFreq, ['payout-freq', 'arrow-down']);

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  const handleCloseSuccessMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setSuccessMsg('');
  };

  return (
    <>
      {errorMsg.length > 0 && <Message success={false} description={errorMsg} handleClose={handleCloseErrorMsg} />}
      {successMsg.length > 0 && <Message success description={successMsg} handleClose={handleCloseSuccessMsg} />}
      <div className="lp-box">
        <H2 className="mb-0">Payout schedule</H2>
        <p className="pb-2">
          Set a schedule to automatically receive payouts, or send manual payouts via the API or dashboard
        </p>
        {loading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {!loading && (
          <div className="lp-details">
            <div className="pt-2 nav-item nav-automatic">
              <div className="cno">Automatic -</div>
              <div className="tclick capitalization">
                <div className="payout-freq hide-m-b" onClick={togglePayoutFreqMenu}>
                  <div>{selectedPayoutFreq}</div>
                  <ArrowDownIcon className={`arrow-down ${payoutFreqMenuOpen ? 'up' : ''}`} />
                </div>
                <ul ref={ref} className={`payout-schedule-menu ${payoutFreqMenuOpen ? 'open' : ''}`}>
                  {AutomaticPayoutScheduleOptions.map((option) => (
                    <li key={option.value}>
                      <label className="lp-option pb-1">
                        {option.text}
                        <input
                          type="radio"
                          name="paymentFreq"
                          value={option.value}
                          onChange={handleChangeFreqOption}
                          checked={selectedPayoutFreq === option.value}
                        />
                        <span className="check-mark" />
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PayoutSchedule;
