export const CHART_TRANSACTIONS = 'chart-transactions';
export const CHART_CUSTOMERS = 'chart-customers';

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ISO_START_TIME = 'T00:00:00Z';
export const ISO_END_TIME = 'T23:59:59Z';
export const FILTER_PERIOD_OBJ = {
  day: 'last_day',
  week: 'last_week',
  month: 'last_month',
  year: 'last_year',
};
export const APPLE_DOMAIN_ASSOCIATION_FILE_URL =
  'https://static.au.meetapril.com/.well-known/apple-developer-merchantid-domain-association';
