import { H2 } from 'Constants/styles';
import { ReactComponent as HelpWheel } from 'assets/svg/help-wheel.svg';
import React from 'react';
import getDateFormat from 'utils/getDateFormat';

import { InstallationList } from './Constants';

const InstallationGuides = () => (
  <div className="lp-box installation-guides">
    <H2 className="pl-2">Installation guides</H2>
    <div className="card items">
      <ul className="item-list striped">
        <li className="item item-list-header">
          <div className="item-row">
            <div className="item-col item-col-header item-col-title">
              <div>
                <span>Platform</span>
              </div>
            </div>
            <div className="item-col text-left item-col-header item-col-title item-col-xl">
              <div className="no-overflow">
                <span>Last updated</span>
              </div>
            </div>
          </div>
        </li>
        {InstallationList.map((item, index) => (
          <li className="item active-item" key={index}>
            <div className="item-row">
              <div className="item-col item-col-sales item-col-order">
                <div className="media">
                  <div className="feature-icon wicons">
                    <HelpWheel className="help-wheel" />
                  </div>
                  <div className="order-id pt-2 pt-md-2">
                    <h2 className="price lp-color-purple">
                      <a
                        href={item.link}
                        data-testid={item.id}
                        rel="noreferrer"
                        target="_blank"
                        className="platform-link"
                      >
                        {item.platFormName}
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="item-col item-col-sales item-col-xl">
                <div className="item text-left mt-1">
                  <h2 className="price pl-m">{getDateFormat({ time: item.createdAt, showSeconds: true }).formatted}</h2>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default InstallationGuides;
