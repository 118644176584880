import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import Balance from './Balance';
import { HeaderWrapper, MessageBar, SpinnerWrapper } from './styles';
import { APIResponseType } from './types';

const BalanceContainer = (): ReactElement => {
  const [data, setData] = useState<APIResponseType | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    document.title = 'Balance - April';
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/balances`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          setData(response);
        })
        .catch((e) => {
          setErrorMsg(e.message || 'Failed to fetch balance, please try again.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (!apiBaseUri || !merchantId) {
      return;
    }

    fetchData();
  }, [apiBaseUri, merchantId]);

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <div className="balance-tab">
      <HeaderWrapper>
        <HeaderTitle title="Balance" />
      </HeaderWrapper>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {errorMsg.length > 0 && (
        <MessageBar>
          <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />
        </MessageBar>
      )}
      {!isLoading && data && <Balance balances={data.balances} />}
    </div>
  );
};

export default BalanceContainer;
