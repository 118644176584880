import styled from '@emotion/styled';

export const Title = styled.h1`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0;
  color: #354052;
`;

export const CardIcon = styled.div`
  width: 48px;
  height: 32px;
  border: 1px solid #d1d3d7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 10px;
`;

export const Media = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  .card-text {
    line-height: 32px;
  }
  .label {
    padding: 6px 15px 1px 15px;
    text-align: center;
    text-transform: uppercase;
    width: fit-content;
  }
  .status {
    background-color: #e0e1ea;
    color: #191e33;

    &.pending {
      background-color: #b4dfea;
    }
    &.success {
      background-color: #bfe0d2;
    }
    &.error {
      background-color: #ff9273;
    }
    &.warning {
      background-color: #f8c079;
    }
    &.unpaid {
      background-color: #e0e1ea;
    }
  }
`;

export const FormItem = styled.div`
  position: relative;
  color: #6c7488;
  font-weight: 300;
`;

export const ChangeCard = styled.label`
  position: relative;
  padding: 15px 16px 15px 28px;
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 18px;
  color: #6c7488;
  font-weight: 300;
  background-color: #fff;
  border: 1px solid #d1d3d7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  cursor: pointer;
`;

export const AmountText = styled.span`
  color: #000;
`;

export const Cno = styled.span`
  top: 4px;
  padding-left: 16px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 24px;
  position: relative;
`;

export const FormInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ .check-mark {
    background-color: #27cbcc;
    border: 1px solid #13bcbe;
    font-weight: bold;
  }
`;

export const CheckMark = styled.span`
  border-radius: 50%;
  top: 19px;
  left: 17px;
  position: absolute;
  height: 19px;
  width: 19px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border: 1px solid #c0c0c0;

  &:after {
    display: block;
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    background-color: #fff;
  }
`;

export const PartialRefund = styled.span`
  display: block;
  padding-top: 15px;
`;

export const PartialRefundLabel = styled.span`
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 18px;
  color: #6c7488;
  font-weight: 300;
`;

export const PartialRefundInput = styled.input`
  padding: 12px 17px;
  outline: none;
  background-color: #fff;
  border: 1px #dce3eb solid;
  border-radius: 3px;
  width: 100%;
  color: #000;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  box-sizing: border-box;
`;

export const ValidateMsg = styled.p`
  color: red;
  height: 20px;
`;

export const RefundNotice = styled.p`
  color: #6c7488;
  line-height: 24px;
  margin-top: 14px;
  margin-bottom: 16px;
  font-size: 80%;
  font-weight: 400;
  padding-top: 4px;
`;

export const CaptureAuthBtn = styled.button`
  background-color: var(--lp-colors-medium-blue-600);
  color: #fff;
  border: 1px solid var(--lp-colors-medium-blue-600);
  border-radius: 4px;
  display: inline-block;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 13px 20px 10px;
  cursor: pointer;
`;

export const CancelAuthBtn = styled.button`
  background: #fff;
  border: 1px solid #cacdd1;
  border-radius: 4px;
  box-sizing: border-box;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 13px 20px 10px;
  min-width: 124px;
`;

export const ManualVerification = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .customer-details {
    font-color: ref;
  }
`;

export const ManualVerificationBtn = styled.div`
  display: flex;
`;

export const VerificationAmount = styled.p`
  color: #000;
  font-family: 'Acumin Regular';
  font-weight: 700;
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

export const CustomerDetails = styled.div`
  color: #6c7488;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const VerifyBtn = styled.button`
  border: 1px solid #d1d3d7;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.56);
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 35px 9px 40px;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

export const VerifyBtnInfo = styled.div`
  font-size: 12px;
`;

export const InfoMessage = styled.div`
  width: 335px;
  @media (max-width: 576px) {
    width: 200px;
  }
`;
