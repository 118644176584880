import { Currency } from 'utils/currency';

export type TransactionStatus =
  | 'accepted'
  | 'paid'
  | 'refunded'
  | 'partially_refunded'
  | 'pending'
  | 'failed'
  | 'cancelled'
  | 'disputed'
  | 'not_captured'
  | 'incomplete';

type TransactionPayType = {
  payPlanId?: string;
  payCardId?: string;
};

type AddressType = {
  city: Nullable<string>;
  country: Nullable<string>;
  line1: Nullable<string>;
  line2: Nullable<string>;
  postalCode: Nullable<string>;
  state: Nullable<string>;
};

type BillingType = {
  name: Nullable<string>;
  phone: Nullable<string>;
  address: AddressType;
};

type ShippingType = {
  name: Nullable<string>;
  phone: Nullable<string>;
  amount: Nullable<number>;
  carrier: Nullable<string>;
  trackingNumber: Nullable<string>;
  address: AddressType;
};

type InstalmentType = {
  amount: number;
  dueAt: string;
};

type RefundType = {
  amount: number;
  createdAt: string;
  currency: Currency;
  customerEmail: Nullable<string>;
  customerFullName: Nullable<string>;
  customerId: Nullable<string>;
  customerPhoneNumber: Nullable<string>;
  merchantOrderId: Nullable<string>;
  merchantOrderInternalOrderId: Nullable<string>;
  payoutAmount: Nullable<number>;
  payoutArrivalAt: Nullable<string>;
  payoutId: Nullable<string>;
  refundId: Nullable<string>;
  refundedAt: Nullable<string>;
  transactionId: Nullable<string>;
  updatedAt: Nullable<string>;
};

export type CardWalletType = 'apple_pay' | 'google_pay';

export const CardWalletLabel = {
  apple_pay: 'Apple Pay™️',
  google_pay: 'Google Pay™️',
};

export interface PaymentAPIResponseType {
  createdAt: string;
  currency: Currency;
  customerEmail: Nullable<string>;
  customerFullName: Nullable<string>;
  customerId: Nullable<string>;
  customerInternalCustomerId: Nullable<string>;
  customerPhoneNumber: Nullable<string>;
  lastChargeError: Nullable<string>;
  merchantOrderBilling: Nullable<BillingType>;
  merchantOrderShipping: Nullable<ShippingType>;
  merchantOrderId: string;
  merchantOrderInternalOrderId: string;
  merchantOrderType: string;
  payPlanInitialPaymentAmount: Nullable<number>;
  payPlanScheduledInstalments: InstalmentType[];
  payPlanState: Nullable<string>;
  paymentSourceBrand: Nullable<string>;
  paymentSourceLast4: Nullable<string>;
  payoutAmount: Nullable<number>;
  payoutArrivalAt: Nullable<string>;
  payoutId: Nullable<string>;
  payoutStatus: Nullable<string>;
  platformFee: Nullable<number>;
  refundedAmount: number;
  refundTransactionId: string;
  refunds?: RefundType[] | null;
  transactionAmount: Nullable<number>;
  transactionId: string;
  transactionPayType: TransactionPayType;
  payPlanId: Nullable<string>;
  transactionStatus: TransactionStatus;
  updatedAt: string;
  chargeAddressVerificationPassed: null | boolean;
  chargeCardCVCVerificationPassed: null | boolean;
  paymentSourceThreeDSecureVerificationPassed: null | boolean;
  chargeFailure: null | string;
  cardStatementName: null | string;
  sourceVerificationId?: Nullable<string>;
  cardWalletType?: CardWalletType;
}

export interface PaymentSourceVerificationResponseType {
  sourceVerificationId: string | undefined;
  chargedAmount: {
    amount: number;
    currency: Currency;
  };
  state: PaymentSourceVerificationStateType;
}

export enum PaymentSourceVerificationStateType {
  unverified = 'unverified',
  passed = 'passed',
  failed = 'failed',
  expired = 'expired',
}

export type Outcome3ds =
  | { FailedChallenge: { info?: string } }
  | { FailedFrictionless: { info?: string } }
  | { FlowNotAttempted: {} }
  | { IncompleteChallenge: {} }
  | { LogicError: { message: string } }
  | { NotSupported: { info?: string } }
  | { PassedChallenge: { info?: string } }
  | { PassedFrictionless: { info?: string } }
  | { ResultNotAvailableError: {} };

export type CardPaymentMethod = {
  paymentMethodType: 'Card';
  cardBrand?: string;
  cardLast4?: string;
  cardFunding?: string;
  cardIssuer?: string;
  cardExpiryDate?: string;
  cardCountry?: string;
  cardCVCVerificationPassed?: boolean;
  card3DSVerificationPassed?: boolean;
  info3DSVerification?: string;
  outcome3ds?: Outcome3ds;
  card3DSFlow?: string;
};

export type DirectDebitPaymentMethod = {
  paymentMethodType: 'DirectDebit';
  accountRoutingNumber?: string;
  accountLast4: string;
  directDebitStatus: 'created' | 'cleared' | 'failed';
  directDebitFailure?: {
    code: 'invalidbsb' | 'invalidaccount' | 'insufficientfunds' | 'other';
    message: string;
    detail: string;
  };
};

export type PayToPaymentMethod = {
  paymentMethodType: 'PayTo';
  accountHint: string;
  paymentStatus: 'Created' | 'Settled' | 'Failed';
  paymentFailure?: {
    code: 'other';
    message: string;
    detail: string;
  };
};

export type GetTransactionResponseType = {
  paymentMethod?: CardPaymentMethod | DirectDebitPaymentMethod | PayToPaymentMethod;
};
