import Alert from 'Components/Alert/Alert';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Modal, { DoubleActionModal } from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import Tooltip from 'Components/Tooltip/Tooltip';
import { Content, H2, H3, LpBox, LpDetails, P, PrimaryBtn } from 'Constants/styles';
import { Label } from 'Constants/styles';
import { ReactComponent as AmexIcon } from 'assets/svg/amex.svg';
import { ReactComponent as MasterCardIcon } from 'assets/svg/mastercard.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { ReactComponent as VisaIcon } from 'assets/svg/visa.svg';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setPaymentDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { fromCurrency, toCurrency } from 'utils/currency';
import { getAprilApiHost } from 'utils/env';
import formatChargeFailure from 'utils/formatChargeFailure';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import * as s from './OrderDetail.styles';
import { getStatusClass, getStatusLabel } from './OrderList';
import { getCustomizations } from './PaymentsUtils';
import { VerificationIcon, VerificationStatus } from './VerificationStatus';
import {
  CardWalletLabel,
  GetTransactionResponseType,
  PaymentSourceVerificationResponseType,
  PaymentSourceVerificationStateType,
} from './types';

const OrderDetail = (): ReactElement => {
  //refund states
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [refundType, setRefundType] = useState<'full' | 'partial'>('full');
  const [refundAmount, setRefundAmount] = useState<string>('');

  // capture and cancel auth states
  const [showCaptureAuthModal, setShowCaptureAuthModal] = useState<boolean>(false);
  const [showCancelAuthModal, setShowCancelAuthModal] = useState<boolean>(false);
  const [captureAmount, setCaptureAmount] = useState<string>('');
  const [captureType, setCaptureType] = useState<'full' | 'partial'>('full');

  // common states
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [validateAmountMsg, setValidateAmountMsg] = useState<string>('');

  //verify states
  const [verificationState, setVerificationState] = useState<boolean | null>(null);
  const [paymentSourceVerification, setPaymentSourceVerification] = useState<PaymentSourceVerificationResponseType>({
    sourceVerificationId: '',
    chargedAmount: {
      amount: 0,
      currency: 'AUD',
    },
    state: PaymentSourceVerificationStateType.unverified,
  });
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
  const [showResetVerifyModal, setShowResetVerifyModal] = useState<boolean>(false);

  // transaction state
  const [transaction, setTransaction] = useState<GetTransactionResponseType | null>(null);

  const dispatch: Dispatch<any> = useDispatch();

  const { merchantId, apiBaseUri, order, isLaddrB2C, sourceVerificationEnabled } = useSelector(
    (state: ReduxStateType) => ({
      merchantId: state.merchantId,
      apiBaseUri: state.apiBaseUri,
      order: state.activePayment,
      isLaddrB2C: state.isLaddrB2C,
      sourceVerificationEnabled: state.sourceVerificationEnabled,
    }),
  );

  const paymentMethodType = transaction?.paymentMethod?.paymentMethodType;
  const isCard = paymentMethodType === 'Card' || !!order?.cardWalletType;

  const outcome3dsInfo = useMemo(() => {
    if (transaction?.paymentMethod?.paymentMethodType !== 'Card') return null;

    const outcome3ds: { [key: string]: { info?: string; message?: string } } | null =
      transaction.paymentMethod.outcome3ds ?? null;

    if (!outcome3ds) return null;

    const key = Object.keys(outcome3ds)[0];
    const info = outcome3ds[key].info || outcome3ds[key].message || '';

    return `${key}${info ? `: ${info}` : ''}`;
  }, [transaction]);

  const directDebitPaymentMethod = useMemo(
    () => (transaction?.paymentMethod?.paymentMethodType === 'DirectDebit' ? transaction.paymentMethod : null),
    [transaction],
  );

  const isBsbValid = useMemo(
    () =>
      directDebitPaymentMethod?.directDebitFailure?.code === 'invalidbsb'
        ? false
        : directDebitPaymentMethod?.directDebitStatus === 'cleared' ||
          directDebitPaymentMethod?.directDebitFailure?.code === 'insufficientfunds'
        ? true
        : null,
    [directDebitPaymentMethod],
  );

  const isAccountValid = useMemo(
    () =>
      directDebitPaymentMethod?.directDebitFailure?.code === 'invalidaccount'
        ? false
        : directDebitPaymentMethod?.directDebitStatus === 'cleared' ||
          directDebitPaymentMethod?.directDebitFailure?.code === 'insufficientfunds'
        ? true
        : null,
    [directDebitPaymentMethod],
  );

  const payToPaymentMethod = useMemo(
    () => (transaction?.paymentMethod?.paymentMethodType === 'PayTo' ? transaction.paymentMethod : null),
    [transaction],
  );

  const getTransaction = useCallback(async (transactionId: string) => {
    const response = await fetch(
      `${await getAprilApiHost()}/transactions/${transactionId}`,
      await getFetchOptions('GET'),
    );

    if (!response.ok) await handleApiError(response);

    return response.json() as Promise<GetTransactionResponseType>;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setTransaction(order?.transactionId ? await getTransaction(order.transactionId) : null);
      } catch (e) {
        console.error(e.message);
      }
    })();
  }, [order, getTransaction]);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${apiBaseUri}/sources/verifications?transactionId=${order?.transactionId}`;
        const options = await getFetchOptions('GET');
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json = await res.json();
        setPaymentSourceVerification(json);
        setVerificationState(json.state === 'passed' ? true : json.state === 'failed' ? false : null);
      } catch (e) {
        console.error(e.message);
      }
    }
    isCard && order?.transactionId && fetchData();
  }, [apiBaseUri, isCard, order]);

  const setPaymentDetailsCB = useCallback((s, d) => dispatch(setPaymentDetails(s, d)), [dispatch]);
  const customizations = useMemo(() => getCustomizations(isLaddrB2C ?? false), [isLaddrB2C]);

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setPaymentDetailsCB(false, null);
  };

  const toggleRefundModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setRefundAmount('');
    setBodyOverflow('hidden');
    setRefundType('full');
    setValidateAmountMsg('');
    setShowRefundModal(true);
  };

  const toggleCaptureAuthModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setCaptureAmount('');
    setBodyOverflow('hidden');
    setCaptureType('full');
    setValidateAmountMsg('');
    setShowCaptureAuthModal(true);
  };

  const toggleCancelAuthModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('hidden');
    setShowCancelAuthModal(true);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowRefundModal(false);
    setShowCaptureAuthModal(false);
    setShowCancelAuthModal(false);
    setShowVerifyModal(false);
    setShowResetVerifyModal(false);
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const toggleVerifyModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    !paymentSourceVerification.sourceVerificationId && fetchPaymentSourceVerification(order?.transactionId);
    setShowVerifyModal(true);
  };

  const fetchPaymentSourceVerification = async (transactionId: string | undefined) => {
    if (!order || !transactionId) {
      return;
    }
    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/sources/verifications`;
      const body = {
        ForTransaction: {
          transactionId: transactionId,
        },
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const json = await res.json();
      setPaymentSourceVerification(json);
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to send auth amount');
    } finally {
      setModalIsLoading(false);
    }
  };

  const modifyPaymentSourceVerification = async (transactionId: string | undefined, status?: boolean) => {
    if (!order || !transactionId) {
      return;
    }
    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/sources/verifications/${
        order.sourceVerificationId || paymentSourceVerification.sourceVerificationId
      }`;
      const body =
        status === undefined
          ? {
              Reset: {},
            }
          : {
              UpdateOutcome: {
                outcome: status ? 'passed' : 'failed',
              },
            };
      const options = await getFetchOptions('PATCH', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setVerificationState(status === undefined ? null : status);
    } catch (e) {
      setModalErrorMsg(e.message);
    } finally {
      setModalIsLoading(false);
    }
  };

  const toggleResetVerifyModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowResetVerifyModal(true);
  };

  const fetchActiveOrder = async (transactionId: string) => {
    const paymentUrl = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?transactionIds=${transactionId}`;
    const paymentOptions = await getFetchOptions();
    const response = await fetch(paymentUrl, paymentOptions);
    if (!response.ok) {
      await handleApiError(response);
    }
    const json = await response.json();
    if (!Array.isArray(json) || json.length === 0) {
      throw Error('Failed to fetch payments');
    }
    setPaymentDetailsCB(true, json[0]);
    setBodyOverflow('auto');
    setShowCaptureAuthModal(false);
    setShowCancelAuthModal(false);
    setShowRefundModal(false);
  };

  const handleSubmitCancelAuth = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!order || !apiBaseUri || !merchantId) {
      return;
    }
    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/transactions/${order.transactionId}/authorisation`;
      const options = await getFetchOptions('DELETE');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      await fetchActiveOrder(order.transactionId);
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to cancel auth');
    } finally {
      setModalIsLoading(false);
    }
  };

  const handleSubmitCaptureAuth = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!order || !apiBaseUri || !merchantId) {
      return;
    }

    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/transactions/${order.transactionId}/authorisation:capture`;
      let amount = 0;
      if (captureType === 'full') {
        amount = order.transactionAmount || 0;
      } else {
        amount = fromCurrency(captureAmount);
      }
      const body = {
        captureAmount: amount,
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      await fetchActiveOrder(order.transactionId);
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to capture auth');
    } finally {
      setModalIsLoading(false);
    }
  };

  const handleSubmitRefund = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!order || !apiBaseUri || !merchantId) {
      return;
    }

    try {
      setModalIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/refunds`;
      let amount = 0;
      if (refundType === 'full') {
        amount = (order?.transactionAmount || 0) - (order?.refundedAmount || 0);
      } else {
        amount = fromCurrency(refundAmount);
      }
      const body = {
        transactionId: order.refundTransactionId,
        refundAmount: {
          amount,
          currency: order.currency,
        },
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      await fetchActiveOrder(order.transactionId);
    } catch (e) {
      setModalErrorMsg(e.message || 'Failed to refund');
    } finally {
      setModalIsLoading(false);
    }
  };

  const handleChangeRefundType = (e: ChangeEvent<HTMLInputElement>): void => {
    setRefundType(e.target.value as 'full' | 'partial');
    if (e.target.value === 'full') {
      const amount = (order?.transactionAmount || 0) - (order?.refundedAmount || 0);
      setRefundAmount(String(amount));
      setValidateAmountMsg('');
    } else {
      setRefundAmount('');
    }
  };

  const handleChangeCaptureType = (e: ChangeEvent<HTMLInputElement>): void => {
    setCaptureType(e.target.value as 'full' | 'partial');
    if (e.target.value === 'full') {
      const amount = order?.transactionAmount || 0;
      setCaptureAmount(String(amount));
      setValidateAmountMsg('');
    } else {
      setCaptureAmount('');
    }
  };

  const handleChangeCaptureAmount = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!order) {
      return;
    }

    const stringVal = e.target.value;
    setCaptureAmount(stringVal);
    const { length } = stringVal;

    const reg = /^\d{0,4}(\.\d{0,2})?$/;

    if (!reg.test(stringVal) || length === 0 || stringVal.charAt(length - 1) === '.') {
      setValidateAmountMsg('Please enter a valid amount');
      return;
    }

    const value = fromCurrency(stringVal);

    if (value > (order.transactionAmount || 0)) {
      setValidateAmountMsg('Amount is larger than transaction amount');
      return;
    }

    if (value <= 0) {
      setValidateAmountMsg('Incorrect capture amount');
      return;
    }

    setValidateAmountMsg('');
  };

  const handleChangeRefundAmount = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!order) {
      return;
    }

    const stringVal = e.target.value;
    setRefundAmount(stringVal);
    const { length } = stringVal;

    const reg = /^\d{0,4}(\.\d{0,2})?$/;

    if (!reg.test(stringVal) || length === 0 || stringVal.charAt(length - 1) === '.') {
      setValidateAmountMsg('Please enter a valid amount');
      return;
    }

    const value = fromCurrency(stringVal);

    if (value > (order.transactionAmount || 0) - (order.refundedAmount || 0)) {
      setValidateAmountMsg('Amount is larger than refundable amount');
      return;
    }

    if (value <= 0) {
      setValidateAmountMsg('Incorrect refund amount');
      return;
    }

    setValidateAmountMsg('');
  };

  const handleVerifyCard = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    modifyPaymentSourceVerification(order?.transactionId, true);
    setShowVerifyModal(false);
  };

  const handleCannotVerifyCard = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    modifyPaymentSourceVerification(order?.transactionId, false);
    setShowVerifyModal(false);
  };

  const handleResetVerification = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    modifyPaymentSourceVerification(order?.transactionId);
    setShowResetVerifyModal(false);
  };

  if (!order) {
    return <></>;
  }

  return (
    <>
      {showCaptureAuthModal && (
        <Modal
          title="Capture pre-authorised payment"
          cancelBtnText="Close"
          confirmBtnText="Capture"
          handleCancel={handleCancel}
          handleSubmit={handleSubmitCaptureAuth}
          isLoading={modalIsLoading}
          disableConfirmBtn={
            modalIsLoading || validateAmountMsg.length > 0 || (captureAmount === '' && captureType === 'partial')
          }
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && modalErrorMsg.length === 0 && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && (
            <div>
              <LpDetails>
                <s.ValidateMsg>{validateAmountMsg}</s.ValidateMsg>
                <s.FormItem>
                  <s.ChangeCard>
                    <s.Cno>Full capture</s.Cno>
                    <br />
                    <span className="pl-3">
                      Capture the full amount (
                      <s.AmountText>{toCurrency(order.transactionAmount || 0, order.currency)}</s.AmountText>)
                    </span>
                    <s.FormInput
                      type="radio"
                      name="captureType"
                      value="full"
                      data-testid="captureFullRadioBtn"
                      onChange={handleChangeCaptureType}
                      checked={captureType === 'full'}
                    />
                    <s.CheckMark className="check-mark" />
                  </s.ChangeCard>
                </s.FormItem>
                <s.FormItem>
                  <s.ChangeCard>
                    <s.Cno>Partial capture</s.Cno>
                    <br />
                    <span className="pl-3">Choose an amount to capture</span>
                    <s.FormInput
                      type="radio"
                      name="captureType"
                      value="partial"
                      data-testid="capturePartialRadioBtn"
                      onChange={handleChangeCaptureType}
                      checked={captureType === 'partial'}
                    />
                    <s.CheckMark className="check-mark" />
                    {captureType === 'partial' && (
                      <s.PartialRefund className="pl-3 pb-2 col-sm-5">
                        <s.PartialRefundLabel>Amount</s.PartialRefundLabel>
                        <s.PartialRefundInput
                          data-testid="captureAmountInput"
                          type="text"
                          autoComplete="off"
                          name="captureAmount"
                          value={captureAmount}
                          onChange={handleChangeCaptureAmount}
                        />
                      </s.PartialRefund>
                    )}
                  </s.ChangeCard>
                </s.FormItem>
              </LpDetails>
            </div>
          )}
        </Modal>
      )}
      {showCancelAuthModal && (
        <Modal
          title="Cancel pre-authorised payment"
          confirmBtnColour="#FF6E44"
          cancelBtnText="No"
          confirmBtnText="Yes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmitCancelAuth}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          <P>Are you sure you want to cancel this pre-authorised payment?</P>
        </Modal>
      )}
      {showRefundModal && (
        <Modal
          title={`Refund ${order.merchantOrderBilling?.name || order.customerFullName || 'Guest user'} ${
            order.customerEmail || ''
          }`}
          cancelBtnText="Close"
          confirmBtnText="Refund"
          handleCancel={handleCancel}
          handleSubmit={handleSubmitRefund}
          isLoading={modalIsLoading}
          disableConfirmBtn={
            modalIsLoading || validateAmountMsg.length > 0 || (refundAmount === '' && refundType === 'partial')
          }
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && modalErrorMsg.length === 0 && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {modalErrorMsg.length === 0 && !modalIsLoading && (
            <div>
              <P>This cannot be undone. Check details carefully.</P>
              <LpDetails>
                <s.ValidateMsg>{validateAmountMsg}</s.ValidateMsg>
                <s.FormItem>
                  <s.ChangeCard>
                    <s.Cno>Full refund</s.Cno>
                    <br />
                    <span className="pl-3">
                      Refund the full amount (
                      <s.AmountText>
                        {toCurrency((order.transactionAmount || 0) - (order.refundedAmount || 0), order.currency)}
                      </s.AmountText>
                      )
                    </span>
                    <s.FormInput
                      type="radio"
                      name="refundType"
                      value="full"
                      onChange={handleChangeRefundType}
                      checked={refundType === 'full'}
                    />
                    <s.CheckMark className="check-mark" />
                  </s.ChangeCard>
                </s.FormItem>
                <s.FormItem>
                  <s.ChangeCard>
                    <s.Cno>Partial refund</s.Cno>
                    <br />
                    <span className="pl-3">Choose how much we will refund</span>
                    <s.FormInput
                      type="radio"
                      name="refundType"
                      value="partial"
                      onChange={handleChangeRefundType}
                      checked={refundType === 'partial'}
                    />
                    <s.CheckMark className="check-mark" />
                    {refundType === 'partial' && (
                      <s.PartialRefund className="pl-3 pb-2 col-sm-5">
                        <s.PartialRefundLabel>Amount</s.PartialRefundLabel>
                        <s.PartialRefundInput
                          type="text"
                          autoComplete="off"
                          name="amount"
                          value={refundAmount}
                          onChange={handleChangeRefundAmount}
                        />
                      </s.PartialRefund>
                    )}
                  </s.ChangeCard>
                </s.FormItem>
                <s.RefundNotice>
                  It may take up to 10 days for the refund to appear on customer's statement
                </s.RefundNotice>
              </LpDetails>
            </div>
          )}
        </Modal>
      )}
      {showVerifyModal && (
        <DoubleActionModal
          title="Manual Verification of Card User"
          cancelBtnText="Go back"
          cta1BtnText="Cannot verify"
          cta2BtnText="Verify card user"
          cta2BtnIcon={<OrderIdIcon className="order-id-icon" />}
          cta1BtnColour="#D3354A"
          handleCancel={handleCancel}
          handleCTA1Submit={handleCannotVerifyCard}
          handleCTA2Submit={handleVerifyCard}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && modalErrorMsg.length === 0 && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {modalErrorMsg.length === 0 && !modalIsLoading && (
            <div>
              <p className="mb-1 mt-4">Verify amount:</p>
              <s.VerificationAmount>
                {toCurrency(
                  paymentSourceVerification.chargedAmount.amount,
                  paymentSourceVerification.chargedAmount.currency,
                )}
              </s.VerificationAmount>
              <p className="mt-3 mb-4">
                <b>Do not disclose this number to your customer.</b> Your customer should be able to verify to you the
                exact amount displayed above as it is displayed on their payment source's statement.
              </p>
              <p className="mb-1 mt-0">Customer Details</p>
              <s.CustomerDetails>
                <span>{order.merchantOrderBilling?.name || order.customerFullName || '-'}</span>
                <span>{order.customerEmail || 'Guest user'}</span>
                <span>{order.merchantOrderBilling?.phone || order.customerPhoneNumber || '-'}</span>
              </s.CustomerDetails>
            </div>
          )}
        </DoubleActionModal>
      )}
      {showResetVerifyModal && (
        <Modal
          title=""
          cancelBtnText="Cancel"
          confirmBtnText="Yes, reset status"
          confirmBtnColour="#D3354A"
          handleCancel={handleCancel}
          handleSubmit={handleResetVerification}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && modalErrorMsg.length === 0 && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {modalErrorMsg.length === 0 && !modalIsLoading && (
            <div>
              <P>Are you sure you wish to reset the verification status of this card user to "unverified"?</P>
            </div>
          )}
        </Modal>
      )}
      <s.Wrapper>
        <HeaderTitle
          title={`${customizations.orderDetailsTitle} for #${order.merchantOrderInternalOrderId}`}
          showArrowLeft
          showBackButton
          backTitle="Back to payments"
          handleClickBack={handleClickBack}
        />
        <Content>
          <div className="lp-box">
            <div className="row pt-3">
              <div className="col-sm-6">
                <H3>
                  {typeof order.transactionAmount === 'number'
                    ? toCurrency(order.transactionAmount, order.currency)
                    : '-'}
                </H3>
                <P className={`label status ${getStatusClass(order.transactionStatus)}`}>
                  {getStatusLabel(order.transactionStatus)}
                </P>
              </div>
              {order.transactionStatus !== 'refunded' &&
                order.transactionStatus !== 'failed' &&
                order.transactionStatus !== 'not_captured' &&
                order.transactionStatus !== 'incomplete' && (
                  <div className="col-sm-6 text-center text-md-right">
                    <PrimaryBtn data-testid="refundBtn" onClick={toggleRefundModal}>
                      Refund
                    </PrimaryBtn>
                  </div>
                )}
              {order.transactionStatus === 'not_captured' && (
                <div className="col-sm-6 text-center text-md-right">
                  <s.CaptureAuthBtn data-testid="captureAuthBtn" onClick={toggleCaptureAuthModal}>
                    Capture
                  </s.CaptureAuthBtn>
                  <s.CancelAuthBtn data-testid="cancelAuthBtn" className="ml-1" onClick={toggleCancelAuthModal}>
                    Cancel
                  </s.CancelAuthBtn>
                </div>
              )}
            </div>
          </div>

          <LpBox>
            <H2 className="mb-4">Payment</H2>
            <LpDetails>
              <div className="row pt-3">
                <div className="col-12 col-sm-3">
                  <Label>Date</Label>
                </div>
                <div className="col-sm-9">{getDateFormat({ time: order.createdAt }).formatted}</div>
              </div>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Amount</Label>
                </div>
                <div className="col-sm-9">
                  {typeof order.transactionAmount === 'number'
                    ? toCurrency(order.transactionAmount, order.currency)
                    : '-'}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12 col-sm-3">
                  <Label>Transaction status</Label>
                </div>
                <div className="col-sm-9">{order.transactionStatus}</div>
              </div>
              {!!directDebitPaymentMethod?.directDebitFailure && (
                <div className="row pt-3">
                  <div className="col-12 col-sm-3">
                    <Label>Status detail</Label>
                  </div>
                  <div className="col-sm-9">{directDebitPaymentMethod.directDebitFailure.message}</div>
                </div>
              )}
              {!!payToPaymentMethod?.paymentFailure && (
                <div className="row pt-3">
                  <div className="col-12 col-sm-3">
                    <Label>Status detail</Label>
                  </div>
                  <div className="col-sm-9">{payToPaymentMethod.paymentFailure.message}</div>
                </div>
              )}
              {isCard && order.transactionStatus === 'failed' && (
                <div className="row pt-3">
                  <div className="col-12 col-sm-3">
                    <Label>Charge failure</Label>
                  </div>
                  <div className="col-sm-9">{formatChargeFailure(order.chargeFailure)}</div>
                </div>
              )}
              {!!order.refundedAmount && order.refundedAmount > 0 && (
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label>Refunded</Label>
                  </div>
                  <div className="col-sm-9">
                    {toCurrency(order.refundedAmount, order.currency)}
                    <div className="mt-3 mb-1">
                      {order.refunds?.map((r) => (
                        <div className="mt-2" key={r.refundId}>
                          {getDateFormat({ time: r.createdAt }).date}
                          <span className="ml-1">({toCurrency(r.amount, r.currency)})</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Fee</Label>
                </div>
                <div className="col-sm-9">
                  {typeof order.platformFee === 'number' ? toCurrency(order.platformFee, order.currency) : '-'}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Type</Label>
                </div>
                <div className="col-sm-9">{!!order.transactionPayType.payCardId ? 'Full' : 'Split'} payment</div>
              </div>
              {!!directDebitPaymentMethod && (
                <>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>Payment method</Label>
                    </div>
                    <div className="col-sm-9">Direct Debit</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>BSB</Label>
                    </div>
                    <div className="col-sm-9">
                      <VerificationIcon status={isBsbValid} />
                      {directDebitPaymentMethod.accountRoutingNumber}
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>Account number</Label>
                    </div>
                    <div className="col-sm-9">
                      <VerificationIcon status={isAccountValid} />
                      *****{directDebitPaymentMethod.accountLast4}
                    </div>
                  </div>
                </>
              )}
              {!!payToPaymentMethod && (
                <>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>Payment method</Label>
                    </div>
                    <div className="col-sm-9">PayTo</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>PayTo account hint</Label>
                    </div>
                    <div className="col-sm-9">{payToPaymentMethod.accountHint}</div>
                  </div>
                </>
              )}
              {isCard && (
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label className="card-text">Card</Label>
                  </div>
                  <div className="col-sm-9">
                    <s.Media>
                      {(order.paymentSourceBrand === 'visa' ||
                        order.paymentSourceBrand === 'mastercard' ||
                        order.paymentSourceBrand === 'amex') && (
                        <s.CardIcon>
                          {order.paymentSourceBrand === 'visa' && <VisaIcon className="visa" />}
                          {order.paymentSourceBrand === 'mastercard' && <MasterCardIcon className="visa" />}
                          {order.paymentSourceBrand === 'amex' && <AmexIcon className="visa" />}
                        </s.CardIcon>
                      )}
                      <div className="card-text">
                        {order.paymentSourceLast4 ? `*************${order.paymentSourceLast4}` : '-'}
                      </div>
                    </s.Media>
                  </div>
                </div>
              )}
              {!!order.cardWalletType && (
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label>Digital wallet</Label>
                  </div>
                  <div className="col-sm-9">{CardWalletLabel[order.cardWalletType]}</div>
                </div>
              )}
              {isCard && (
                <>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>CVV verification</Label>
                    </div>
                    <div className="col-sm-9">
                      <VerificationStatus status={order.chargeCardCVCVerificationPassed} />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-sm-3">
                      <Label>3DS verification</Label>
                    </div>
                    <div className="col-sm-9">
                      <VerificationStatus status={order.paymentSourceThreeDSecureVerificationPassed} />
                    </div>
                  </div>
                  {!!outcome3dsInfo && (
                    <div className="row pt-3">
                      <div className="col-sm-3">
                        <Label>3DS outcome</Label>
                      </div>
                      <div className="col-sm-9">{outcome3dsInfo}</div>
                    </div>
                  )}
                </>
              )}
              {sourceVerificationEnabled && (
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label>Manual Verification</Label>
                  </div>
                  <div className="col-sm-9">
                    {!isCard ? (
                      <>{paymentMethodType ?? 'Payment method'} not supported</>
                    ) : (
                      <s.ManualVerification>
                        <span>
                          <VerificationStatus status={verificationState} />
                        </span>
                        <s.ManualVerificationBtn>
                          <s.VerifyBtn
                            data-testid="verifyBtn"
                            onClick={verificationState !== null ? toggleResetVerifyModal : toggleVerifyModal}
                          >
                            {verificationState !== null ? 'Reset verification' : 'Verify Manually'}
                          </s.VerifyBtn>
                          <div>
                            <Tooltip>
                              <s.VerifyBtnInfo>
                                {verificationState ? (
                                  <s.InfoMessage>
                                    If there has been a mistake in this card user's verification status, you can reset
                                    it back to “unverfied”
                                  </s.InfoMessage>
                                ) : (
                                  <s.InfoMessage>
                                    Once clicked, your customer will automatically be charged a small amount to their
                                    payment source. Please have them available and ready to read out this amount to
                                    complete verification
                                  </s.InfoMessage>
                                )}
                              </s.VerifyBtnInfo>
                            </Tooltip>
                          </div>
                        </s.ManualVerificationBtn>
                      </s.ManualVerification>
                    )}
                  </div>
                </div>
              )}
            </LpDetails>
          </LpBox>

          {(!!order.payoutArrivalAt || typeof order.payoutAmount === 'number') && (
            <LpBox>
              <H2 className="mb-4">Transfer</H2>
              <LpDetails>
                <div className="row pt-3">
                  <div className="col-12 col-sm-3">
                    <Label>Date</Label>
                  </div>
                  <div className="col-sm-9">
                    {order.payoutArrivalAt ? getDateFormat({ time: order.payoutArrivalAt }).date : '-'}
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label>Amount</Label>
                  </div>
                  <div className="col-sm-9">
                    {typeof order.payoutAmount === 'number' ? toCurrency(order.payoutAmount, order.currency) : '-'}
                  </div>
                </div>
              </LpDetails>
            </LpBox>
          )}

          {!!order.transactionPayType.payPlanId && (
            <LpBox>
              <H2 className="mb-4">Payment plan</H2>
              <LpDetails>
                <div className="row pt-3">
                  <div className="col-12 col-sm-3">
                    <Label>Initial payment</Label>
                  </div>
                  <div className="col-sm-9">
                    {typeof order.payPlanInitialPaymentAmount === 'number'
                      ? toCurrency(order.payPlanInitialPaymentAmount, order.currency)
                      : '-'}
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm-3">
                    <Label>Payments</Label>
                  </div>
                  <div className="col-sm-9">
                    {order.payPlanScheduledInstalments.map((instalment, index) => (
                      <div className="row mb-1" key={index}>
                        <div className="col-sm-3">{toCurrency(instalment.amount, order.currency)}</div>
                        <div className="col-sm-9">{getDateFormat({ time: instalment.dueAt }).date}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </LpDetails>
            </LpBox>
          )}

          <LpBox className="lp-box">
            <H2 className="mb-4">Order</H2>
            <LpDetails>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Type</Label>
                </div>
                <div className="col-sm-9">{order.merchantOrderType}</div>
              </div>
              <div className="row pt-3">
                <div className="col-12 col-sm-3">
                  <Label>Name</Label>
                </div>
                <div className="col-sm-9">{order.merchantOrderBilling?.name || order.customerFullName || '-'}</div>
              </div>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Billing address</Label>
                </div>
                <div className="col-sm-9">
                  <div>{order.merchantOrderBilling?.name}</div>
                  <div>{order.merchantOrderBilling?.address.line1}</div>
                  <div>{order.merchantOrderBilling?.address.line2}</div>
                  <div>{order.merchantOrderBilling?.address.city}</div>
                  <div>{order.merchantOrderBilling?.address.postalCode}</div>
                  <div>{order.merchantOrderBilling?.address.state}</div>
                  <div>{order.merchantOrderBilling?.phone}</div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <Label>Shipping address</Label>
                </div>
                <div className="col-sm-9">
                  <div>{order.merchantOrderShipping?.name}</div>
                  <div>{order.merchantOrderShipping?.address.line1}</div>
                  <div>{order.merchantOrderShipping?.address.line2}</div>
                  <div>{order.merchantOrderShipping?.address.city}</div>
                  <div>{order.merchantOrderShipping?.address.postalCode}</div>
                  <div>{order.merchantOrderShipping?.address.state}</div>
                  <div>{order.merchantOrderShipping?.phone}</div>
                </div>
              </div>
            </LpDetails>
          </LpBox>

          <LpBox>
            <H2 className="mb-4">Customer</H2>
            <LpDetails>
              <div className="row pt-3">
                <div className="col-12 col-sm-3">
                  <label>Name</label>
                </div>
                <div className="col-sm-9">{order.merchantOrderBilling?.name || order.customerFullName || '-'}</div>
              </div>
              <div className="row pt-3">
                <div className="col-12 col-sm-3">
                  <label>Email</label>
                </div>
                <div className="col-sm-9">{order.customerEmail || 'Guest user'}</div>
              </div>
              <div className="row pt-3">
                <div className="col-sm-3">
                  <label>Phone No</label>
                </div>
                <div className="col-sm-9">{order.merchantOrderBilling?.phone || order.customerPhoneNumber || '-'}</div>
              </div>
            </LpDetails>
          </LpBox>
        </Content>
      </s.Wrapper>
    </>
  );
};

export default OrderDetail;
