import { Label } from 'Constants/styles';
import { MouseEvent, ReactElement, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import {
  AUFlag,
  ArrowDown,
  CountryName,
  CountryOption,
  DropdownMenu,
  FlagBtn,
  FlagStrap,
  FlagWrap,
  NZFlag,
  Wrapper,
} from './styles';

export enum CountryType {
  AU = 'AU',
  NZ = 'NZ',
}

type SelectCountryType = {
  className?: string;
  label: string;
  country: CountryType;
  setCountry(c: CountryType): void;
  selectTestId: string;
  option1TestId: string;
  option2TestId: string;
};

const CountryNameObj = {
  [CountryType.AU]: 'Australia',
  [CountryType.NZ]: 'New Zealand',
};

const SelectCountry = ({
  className = '',
  label,
  country,
  setCountry,
  selectTestId,
  option1TestId,
  option2TestId,
}: SelectCountryType): ReactElement => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLUListElement>(null);

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useClickOutside(ref, handleClickOutside, [
    'flag-strap',
    'flag-btn',
    'flag-wrap',
    'flag',
    'arrow-down',
    'country-name',
  ]);

  const toggleDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowDropdown((s) => !s);
  };

  const handleClickOption = (e: MouseEvent<HTMLLIElement>, type: CountryType): void => {
    e.preventDefault();
    setShowDropdown(false);
    setCountry(type as CountryType);
  };

  return (
    <Wrapper className={className}>
      <Label className="mb-2">{label}</Label>
      <FlagStrap className="flag-strap">
        <FlagBtn className="flag-btn" onClick={toggleDropdown}>
          <FlagWrap className="flag-wrap">
            {country === 'AU' ? <AUFlag className="flag" /> : <NZFlag className="flag" />}
            <ArrowDown className={`arrow-down ${showDropdown ? 'up' : ''}`} />
          </FlagWrap>
          <CountryName data-testid={selectTestId} className="country-name">
            {CountryNameObj[country]}
          </CountryName>
        </FlagBtn>
      </FlagStrap>
      <DropdownMenu open={showDropdown} ref={ref}>
        <CountryOption data-testid={option1TestId} onClick={(e) => handleClickOption(e, CountryType.AU)}>
          <AUFlag />
          {CountryNameObj[CountryType.AU]}
        </CountryOption>
        <CountryOption data-testid={option2TestId} onClick={(e) => handleClickOption(e, CountryType.NZ)}>
          <NZFlag />
          {CountryNameObj[CountryType.NZ]}
        </CountryOption>
      </DropdownMenu>
    </Wrapper>
  );
};

export default SelectCountry;
