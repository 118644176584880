export const TabOptions = [
  {
    text: 'Settings',
    value: 'settings',
  },
];

export const AccountsEditOptions = [
  {
    text: 'Primary',
    value: 'primary',
  },
  {
    text: 'Secondary',
    value: 'secondary',
  },
];

export const CurrencyOptions = [
  {
    text: 'AUD',
    value: 'AUD',
  },
  {
    text: 'NZD',
    value: 'NZD',
  },
];

export type CountryMap = {
  [key: string]: string | undefined;
};

export const ISOCountries: CountryMap = {
  AU: 'Australia',
  NZ: 'New Zealand',
};

export const TransferListHeaders = [
  {
    className: 'item-col-order',
    noOverflow: false,
    text: 'Amount',
  },
  {
    className: 'item-col-xl',
    noOverflow: true,
    text: 'Date',
  },
  {
    className: 'item-col-xl',
    noOverflow: true,
    text: 'Download CSV',
  },
];

export const AllTransferListHeaders = [
  {
    className: '',
    noOverflow: false,
    text: 'Order ID',
  },
  {
    className: 'item-col-cxl',
    noOverflow: true,
    text: 'Date',
  },
  {
    className: 'item-col-cxl',
    noOverflow: true,
    text: 'Type',
  },
  {
    className: 'item-col-lgr',
    noOverflow: false,
    text: 'Transaction Type',
  },
  {
    className: '',
    noOverflow: true,
    text: 'Transaction ID',
  },
  {
    className: 'item-col-cxl item-col-amount',
    noOverflow: true,
    text: 'Gross Amount',
  },
  {
    className: 'item-col-cxl item-col-amount',
    noOverflow: true,
    text: 'Net Amount',
  },
];

export const AutomaticPayoutScheduleOptions = [
  {
    text: 'Daily',
    value: 'daily',
  },
  {
    text: 'Weekly',
    value: 'weekly',
  },
  {
    text: 'Monthly',
    value: 'monthly',
  },
];
