import './Tabs.scss';

import React, { ReactElement, useState } from 'react';

import { useTheme } from '@emotion/react';

type OptionProps = {
  text: string;
  value: string;
};

export type TabsProps = {
  children: ReactElement[];
  tabOptions: OptionProps[];
};

const Tabs = ({ children, tabOptions }: TabsProps): ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const theme = useTheme();

  return (
    <div className="fixed-tabs">
      <div className="lp-nav">
        <ul className="nav nav-pills" role="tablist">
          {tabOptions.map((option, index) => (
            <li className="nav-item" key={option.value} onClick={() => setActiveTabIndex(index)}>
              <div
                className={`nav-link show ${activeTabIndex === index ? 'active' : ''}`}
                role="tab"
                aria-selected={activeTabIndex === index}
                style={{ borderBottomColor: theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)' }}
              >
                {option.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {children[activeTabIndex]}
    </div>
  );
};

export default Tabs;
