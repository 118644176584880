import styled from '@emotion/styled';

export const SuccessViewWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
`;

export const Tick = styled.div`
  width: 70px;
  height: 70px;
  border: 1px solid #81d275;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const SuccessText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #81d275;
  margin-top: 22px;
  margin-bottom: 14px;
  text-align: center;
`;

export const SentText = styled.div`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #354052;
`;
