import { ReactComponent as AUFlagIcon } from 'assets/svg/AU-flag.svg';
import { ReactComponent as NZFlagIcon } from 'assets/svg/NZ-flag.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const ArrowDown = styled(ArrowDownIcon)`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease-in-out 0s;
  margin-left: auto;
  &.up {
    transform: rotate(180deg);
  }
`;

export const AUFlag = styled(AUFlagIcon)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const NZFlag = styled(NZFlagIcon)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const FlagStrap = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const FlagBtn = styled.button`
  padding: 0;
  border: 1px solid #dce3eb;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  background-color: transparent;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 3px;

  cursor: pointer;
  font-weight: 400;
  color: #212529;

  &:focus {
    outline: none;
  }
`;

export const FlagWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid #dce3eb;
  padding-right: 10px;
  background-color: #f8f9fb;
  padding: 0 12px;
`;

type DropdownMenuType = {
  open: boolean;
};

export const DropdownMenu = styled.ul<DropdownMenuType>`
  padding: 0;
  list-style: none;
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  top: 100%;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 5px 5px;
  border: 1px solid rgba(126, 142, 159, 0.1);
  overflow: hidden;
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow-y: hidden;
  z-index: 9;
  margin: 2px 0 0;
  font-size: 16px;
  color: #212529;
`;

export const CountryOption = styled.li`
  padding: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  align-items: center;
  &:hover {
    background-color: #dce3eb;
  }
`;

export const CountryName = styled.div`
  padding-left: 9px;
  font-size: 16px;
  line-height: 19px;
`;
