import EmptyList from 'Components/EmptyList/EmptyList';
import Spinner from 'Components/Spinner/Spinner';
import { H2, LpBox, P, Row } from 'Constants/styles';
import { ReactComponent as ArrowDownRightIcon } from 'assets/svg/arrow-down-right.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { readString } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setTransferDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { CountryCurrency, toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { TransferListHeaders } from './Constants';
import { SpinnerWrapper } from './TransferOverview.styles';
import { DownloadBtn, FeatureIcon, LinkBtn, Media, OrderIdBtn } from './styles';
import { PayoutAPIResponseType } from './types';

type Props = {
  totalOrders: PayoutAPIResponseType[];
  pageCount: number;
  activePage: number;
  handleClickPagination(e: MouseEvent<HTMLDivElement>, t: 'prev' | 'next'): void;
  isLoading: boolean;
  loading?: boolean;
  selectedPayoutFreq?: string;
};

const TransferList = ({
  totalOrders,
  pageCount,
  activePage,
  handleClickPagination,
  isLoading,
  loading,
  selectedPayoutFreq,
}: Props): ReactElement => {
  const [activeOrderId, setActiveOrderId] = useState<string>('');
  const [downloadedData, setDownloadedData] = useState<any>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const dispatch: Dispatch<any> = useDispatch();
  const setTransferDetailsCB = useCallback((s, d) => dispatch(setTransferDetails(s, d)), [dispatch]);

  const { merchantId, apiBaseUri, merchantTradingCountry } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    merchantTradingCountry: state.merchantTradingCountry,
  }));

  const handleClickOutside = () => {
    setActiveOrderId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, [
    'item-actions-block',
    'item-actions-list',
    'payout-link-btn',
    'dots-icon',
    'arrow-right',
  ]);

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, orderId: string): void => {
    e.preventDefault();
    setActiveOrderId(orderId);
  };

  const handleClickDownload = async (e: MouseEvent<HTMLButtonElement>, payoutId: string): Promise<void> => {
    e.preventDefault();
    if (!apiBaseUri || !merchantId || !payoutId) {
      return;
    }

    const url = `${apiBaseUri}/reports/merchants/${merchantId}/payout/csv/${payoutId}`;
    const options = await getFetchOptions();

    setDownloadedData(null);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.text();
      })
      .then((res) => {
        readString(res, {
          worker: true,
          complete: ({ data }) => {
            const noneEmptyData = data.filter((d) => Array.isArray(d) && d.length > 1);
            setDownloadedData(noneEmptyData);
            setDownloadReady(true);
          },
        });
      })
      .catch(() => {
        console.error('Failed to download');
      });
  };

  useEffect(() => {
    if (csvRef?.current && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const handleViewDetailsBtn = (e: MouseEvent<HTMLButtonElement>, order: PayoutAPIResponseType): void => {
    e.preventDefault();
    setTransferDetailsCB(true, order);
  };

  return (
    <>
      {selectedPayoutFreq && (
        <div className="col-sm-8">
          <P>Below are your {selectedPayoutFreq} totals. Click each to view more details.</P>
        </div>
      )}
      <LpBox>
        <Row className="mb-4">
          <div className="col-sm-8">
            <H2>Transfers</H2>
          </div>
        </Row>
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {TransferListHeaders.map((header, index) => (
                  <div key={index} className={`item-col item-col-header ${header.className}`}>
                    <div className={`${header.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{header.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {(isLoading || loading) && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            {!isLoading && !loading && totalOrders.length === 0 && <EmptyList />}
            {!isLoading &&
              !loading &&
              totalOrders.length > 0 &&
              totalOrders.map((order) => (
                <li key={order.payoutId} className="item active-item">
                  <div className="item-row item-row-reset-m">
                    <div className="item-col item-col-sales item-col-order">
                      <Media>
                        <FeatureIcon>
                          <ArrowDownRightIcon className="arrow-down-right" />
                        </FeatureIcon>
                        <div className="order-id pt-2 pt-md-2">
                          <OrderIdBtn onClick={(e) => handleViewDetailsBtn(e, order)}>
                            {typeof order.amount === 'number'
                              ? toCurrency(
                                  order.amount,
                                  order.payments?.[0]?.currency ?? CountryCurrency[merchantTradingCountry],
                                )
                              : '-'}
                          </OrderIdBtn>
                        </div>
                      </Media>
                    </div>
                    <div className="item-col item-col-sales item-col-xl">
                      <div className="item text-left mt-1">
                        <h2 className="price pl-m">
                          {!!order.arrivalDate
                            ? getDateFormat({
                                time: order.arrivalDate,
                                showDaySuffix: false,
                                showFullYear: true,
                                showShortMonth: false,
                              }).date
                            : '-'}
                        </h2>
                      </div>
                    </div>
                    <div className="item-col item-col-sales item-col-xl">
                      <div className="item text-left mt-1">
                        <DownloadBtn onClick={(e) => handleClickDownload(e, order.payoutId)}>Download</DownloadBtn>
                      </div>
                    </div>
                    <div className="item-col fixed item-col-actions-dropdown">
                      <div className="item-actions-dropdown" onClick={(e) => toggleDropdown(e, order.payoutId)}>
                        <DotsIcon className="dots-icon" />
                        <ArrowDownIcon className="arrow-right" />
                      </div>
                      <div className={`item-actions-block ${activeOrderId === order.payoutId ? 'active' : ''}`}>
                        <ul className="item-actions-list">
                          <li>
                            <LinkBtn className="payout-link-btn" onClick={(e) => handleViewDetailsBtn(e, order)}>
                              View details
                            </LinkBtn>
                            <LinkBtn
                              className="payout-link-btn"
                              onClick={(e) => handleClickDownload(e, order.payoutId)}
                            >
                              Download CSV
                            </LinkBtn>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {(activePage > 1 || pageCount > activePage) && (
            <div className="pagination">
              {activePage > 1 && (
                <div className="pagination-prev" onClick={(e) => handleClickPagination(e, 'prev')}>
                  Previous
                </div>
              )}
              {pageCount > activePage && (
                <div className="pagination-next" onClick={(e) => handleClickPagination(e, 'next')}>
                  Next
                </div>
              )}
            </div>
          )}
        </div>
      </LpBox>
      {downloadedData ? (
        <CSVLink ref={csvRef} data={downloadedData} target="_self" filename={Date.now() + '.csv'} />
      ) : (
        <></>
      )}
    </>
  );
};

export default TransferList;
