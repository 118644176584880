import styled from '@emotion/styled';

type WrapperProps = {
  showArrowLeft: boolean;
  showSidebar: boolean;
};
export const Wrapper = styled.header<WrapperProps>`
  padding: 33px 22px 20px;
  position: relative;

  @media (min-width: 1200px) {
    padding: 33px 60px 30px;
  }

  @media (max-width: 960px) {
    padding: ${({ showSidebar }) => (showSidebar ? '23px 22px 0 63px' : '23px 22px 0 22px')};
  }

  @media (max-width: 768px) {
    background-color: #fff;
    padding-top: ${({ showArrowLeft }) => (showArrowLeft ? '20px' : '0')};
    padding-bottom: ${({ showArrowLeft }) => (showArrowLeft ? '20px' : '0')};

    .arrow-left {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  position: relative;
`;

export const Flex = styled.div`
  display: flex;
`;

export const TitleText = styled.h1`
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  color: #000;

  @media (max-width: 768px) {
    font-size: 21px;
  }
`;

export const TitleBlock = styled.div`
  margin-bottom: 0;
`;

export const BackWrapper = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ArrowLeftBtn = styled.button`
  padding: 0;
  background-color: transparent;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;
