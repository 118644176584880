import Calendar from 'Components/Calendar/Calendar';
import EmptyList from 'Components/EmptyList/EmptyList';
import ExportData from 'Components/ExportData/ExportData';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message/';
import OrderSearch, { SearchOptionType } from 'Components/OrderSearch/OrderSearch';
import { OrderSearchRefundOptions, OrderSearchRefundType } from 'Components/OrderSearch/OrderSearchOptions';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import { fromCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import OrderList from './OrderList';
import { ExportDataWrapper, MessageBar, Wrapper } from './styles';
import { RefundAPIResponseType } from './types';

const SearchOptionObj = {
  [OrderSearchRefundType.order]: 'internalOrderId',
  [OrderSearchRefundType.email]: 'customerEmail',
  [OrderSearchRefundType.amount]: 'amount',
};

const RefundsContainer = () => {
  const [orders, setOrders] = useState<RefundAPIResponseType[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchRefundOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { merchantId, apiBaseUri, searchStartDate, searchEndDate } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    searchStartDate: state.searchStartDate,
    searchEndDate: state.searchEndDate,
  }));

  useEffect(() => {
    document.title = 'Refunds - April';
  }, []);

  /* when dates are changed, need to make sure activePage is 1 in the url */
  useEffect(() => {
    if (!searchStartDate || !searchEndDate) {
      return;
    }
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
    setActivePage(1);
  }, [searchStartDate, searchEndDate]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId || !startDate || !endDate || debouncedSearch !== selectedSearch) {
      return;
    }

    const fetchData = async () => {
      const localStartDate = convertUTCtoLocal(startDate);
      const localEndDate = convertUTCtoLocal(endDate);
      let url = `${apiBaseUri}/dashboard/merchant/${merchantId}/refunds/query?sort=-createdAt&page=${activePage}&createdAfter=${localStartDate}&createdBefore=${localEndDate}`;
      if (debouncedSearch.length > 0) {
        let tempSearch: number | string = debouncedSearch;
        if (selectedSearchOption.value === OrderSearchRefundType.amount) {
          tempSearch = fromCurrency(tempSearch.replace(',', ''));
        }
        url += `&${SearchOptionObj[selectedSearchOption.value as OrderSearchRefundType]}=${encodeURIComponent(
          tempSearch,
        )}`;
      }
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('Limepay-Page-Count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setOrders(response);
          setPageCount(pages);
          setErrorMsg('');
        })
        .catch((e) => {
          setErrorMsg(e.message || 'Failed to fetch refunds');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, [
    merchantId,
    activePage,
    apiBaseUri,
    startDate,
    endDate,
    debouncedSearch,
    selectedSearch,
    selectedSearchOption.value,
  ]);

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsg('');
  };

  return (
    <Wrapper>
      <div className="header-wrapper">
        <div className="header-calendar">
          <HeaderTitle title="Refunds" />
          <Calendar />
        </div>
        <OrderSearch
          selectedSearchOption={selectedSearchOption}
          setSelectedSearchOption={setSelectedSearchOption}
          selectedSearch={selectedSearch}
          handleSearch={handleSearch}
          section="refund"
        />
      </div>

      <ExportDataWrapper>
        <ExportData className="refund-export-data" pageSource="refunds" />
      </ExportDataWrapper>
      {errorMsg.length > 0 && (
        <MessageBar>
          <Message success={false} description={errorMsg} handleClose={handleCloseMsg} />
        </MessageBar>
      )}
      {isLoading && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          {orders.length > 0 && <OrderList orders={orders} />}
          {orders.length === 0 && <EmptyList />}
          <Pagination
            pageCount={pageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
    </Wrapper>
  );
};

export default RefundsContainer;
