const isValidCardStatementName = (value: string): boolean => {
  const { length } = value;
  if (length < 5 || length > 13) {
    return false;
  }

  return /^[a-zA-Z]+$/.test(value);
};

export default isValidCardStatementName;
