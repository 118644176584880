import { useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

function useClickOutsideWithoutRef(handler: (event: Event) => void, classArray: string[]) {
  useEffect(() => {
    const listener = (event: Event) => {
      const { classList } = event?.target as HTMLElement;

      const skip = classArray.some((c) => classList.contains(c));

      if (skip) {
        return;
      }

      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [classArray, handler]);
}
export default useClickOutsideWithoutRef;
