import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`;

export const LightText = styled.span`
  font-weight: 300;
  color: #6c7488;
`;

export const AddItemBtn = styled.button`
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  display: inline-block;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 56%);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 13px 38px 10px;
  cursor: pointer;
  margin-top: -16px;
  @media (max-width: 576px) {
    margin-top: 0;
  }
`;

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ItemRow = styled.li`
  position: relative;
  display: flex;
  border-top: 1px solid #e6eaee;
  min-width: 100%;
  :first-of-type,
  :nth-of-type(2) {
    border-top: 0;
  }
  &.row-header {
    font-size: 15px;
    color: #6c7488;
    margin-top: 36px;
  }
`;

export const ItemCol = styled.div<{ width?: string; mobileWidth?: string }>`
  display: flex;
  align-items: center;
  padding: 4px 10px 6px 0;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  max-width: ${({ width }) => width ?? 'none'};
  @media (max-width: 576px) {
    max-width: ${({ mobileWidth }) => mobileWidth ?? 'none'};
  }
`;

export const Visibility = styled.div<{ isVisible: boolean }>`
  background-color: ${({ isVisible }) => (isVisible ? '#F8C079' : '#B4DFEA')};
  display: inline-block;
  padding: 4px 8px;
  color: #191e33;
  font-size: 12px;
`;

export const Actions = styled.div`
  position: absolute;
  width: 15px;
  right: 20px;
  top: 7px;
  @media (max-width: 576px) {
    right: 0;
  }
`;

export const ActionsDropdown = styled.div`
  cursor: pointer;
`;

export const ActionsBlock = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 20px;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgb(220 227 235 / 56%);
  border-radius: 3px;
  text-align: left;
  z-index: 1;
  min-width: 150px;
`;

export const ActionBtn = styled.button`
  padding: 2px 0px;
  background-color: transparent;
  border: none;
  color: rgb(108, 116, 136);
  display: block;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Acumin Pro', Arial, sans-serif;
`;

export const WarningText = styled.div`
  margin: 16px 0;
  text-align: center;
  font-size: 16px;
`;
