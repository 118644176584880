import styled from '@emotion/styled';

export const FormItem = styled.div`
  font-weight: 300;
  color: #6c7488;
  position: relative;
  margin-top: 20px;
`;

export const FormLabel = styled.label`
  padding-left: 1px;
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  color: #6c7488;
  font-weight: 300;
`;

export const FormInput = styled.input`
  padding: 17px 17px 12px;
  outline: none;
  max-height: 44px;
  background-color: #fff;
  border: 1px solid #dce3eb;
  border-radius: 3px;
  width: 100%;
  color: #000;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  box-sizing: border-box;
`;

export const UpdatePasswordBtn = styled.button`
  border: none;
  background: none;
  color: var(--lp-colors-medium-blue-600);
  cursor: pointer;
  font-size: 15px;
  padding: 0;
  margin: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const LogOutBtn = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 15px;
  color: var(--lp-colors-medium-blue-600);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const ValidateError = styled.p`
  color: red;
  line-height: 24px;
  margin-top: 14px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
`;

export const EditBtn = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--lp-colors-medium-blue-600);
  font-size: 15px;
  font-weight: 300;
  padding: 0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
`;

export const FormField = styled.div`
  position: relative;
`;
