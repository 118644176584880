import Alert from 'Components/Alert/Alert';
import Spinner from 'Components/Spinner/Spinner';
import { H2 } from 'Constants/styles';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as ExternalIcon } from 'assets/svg/external-link.svg';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { YourAPIKeysHeaders } from './Constants';
import { SpinnerWrapper } from './styles';
import { KeysAPIResponseType } from './types';

const YourAPIKeys = () => {
  const [activeCopyIndex, setActiveCopyIndex] = useState<number>(-1);
  const [activeModalIndex, setActiveModalIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [keysInfo, setKeysInfo] = useState<KeysAPIResponseType[]>([]);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  const handleMouseEnter = (index: number): void => {
    setActiveCopyIndex(index);
  };

  const handleMouseLeave = (): void => {
    setActiveCopyIndex(-1);
  };

  const toggleShowModal = (e: MouseEvent<HTMLDivElement>, index: number): void => {
    e.preventDefault();
    setActiveModalIndex(index);
  };

  const handleClickOutside = () => {
    setActiveModalIndex(-1);
  };

  useClickOutsideWithoutRef(handleClickOutside, [
    'item-actions-block',
    'item-actions-list',
    'modal-copy-btn',
    'item-actions-li',
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const options = await getFetchOptions();
      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/tokens`;

      fetch(url, options)
        .then((res) => {
          if (!res.ok) {
            throw Error();
          }
          return res.json();
        })
        .then((res) => {
          setKeysInfo(res);
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (!apiBaseUri || !merchantId) {
      return;
    }
    fetchData();
  }, [apiBaseUri, merchantId]);

  return (
    <>
      <div className="lp-box">
        <div className="mb-4 pt-3">
          <H2>Your API Keys</H2>
        </div>
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {YourAPIKeysHeaders.map((header, index) => (
                  <div className={`item-col ${header.classNames}`} key={index}>
                    <div className={`${header.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{header.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {!isLoading && hasError && <Alert message="Failed to get API keys" />}
            {isLoading && !hasError && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            {!isLoading && !hasError && keysInfo.length > 0 && (
              <>
                <li className="item active-item apiKeyCont">
                  <div className="item-row">
                    <div className="item-col item-col-sales item-col-order col-md-3">
                      <div className="media">
                        <div className="feature-icon wicons">
                          <ExternalIcon className="external-icon" />
                        </div>
                        <div className="order-id">
                          <h2 className="price pt-1">Publishable key</h2>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-sales">
                      <div className="item text-left mt-1">
                        <div className="price pl-m">
                          <div
                            className="api-keys token-key token-data custom-key-mt"
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {keysInfo[0].publicToken}
                            <button
                              className={`copy-key ${activeCopyIndex === 0 ? 'active' : ''}`}
                              onClick={() => {
                                navigator.clipboard.writeText(keysInfo[0].publicToken);
                              }}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-sales item-col-sm d-none-copy">
                      <div className="item text-left mt-1">
                        <div className="price">
                          <span className="hide-m-b">{getDateFormat({ time: keysInfo[0].createdAt }).formatted}</span>
                          <div className="item-actions-dropdown pl-0 pl-md-5" onClick={(e) => toggleShowModal(e, 0)}>
                            <DotsIcon className="dots-icon" />
                            <ArrowRight className="arrow-right" />
                            <div className={`item-actions-block ${activeModalIndex === 0 ? 'active' : ''}`}>
                              <ul className="item-actions-list">
                                <li className="item-actions-li">
                                  <button
                                    className="modal-copy-btn apiKeyCopy lp-color-black"
                                    onClick={() => {
                                      navigator.clipboard.writeText(keysInfo[0].publicToken);
                                    }}
                                  >
                                    Copy Key
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="item active-item apiKeyCont">
                  <div className="item-row">
                    <div className="item-col item-col-sales item-col-order col-md-3">
                      <div className="media">
                        <div className="feature-icon wicons">
                          <ExternalIcon className="external-icon" />
                        </div>
                        <div className="order-id">
                          <h2 className="price pt-1">Secret key</h2>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-sales">
                      <div className="item text-left mt-1">
                        <div className="price pl-m">
                          <div
                            className="api-keys token-key token-data custom-key-mt"
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                          >
                            **************************
                            <button
                              className={`copy-key ${activeCopyIndex === 1 ? 'active' : ''}`}
                              onClick={() => {
                                navigator.clipboard.writeText(keysInfo[0].secretToken);
                              }}
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-sales item-col-sm d-none-copy">
                      <div className="item text-left mt-1">
                        <div className="price">
                          <span className="hide-m-b">{getDateFormat({ time: keysInfo[0].createdAt }).formatted}</span>
                          <div className="item-actions-dropdown pl-0 pl-md-5" onClick={(e) => toggleShowModal(e, 1)}>
                            <DotsIcon className="dots-icon" />
                            <ArrowRight className="arrow-right" />
                            <div className={`item-actions-block ${activeModalIndex === 1 ? 'active' : ''}`}>
                              <ul className="item-actions-list">
                                <li className="item-actions-li">
                                  <button
                                    className="modal-copy-btn apiKeyCopy lp-color-black"
                                    onClick={() => {
                                      navigator.clipboard.writeText(keysInfo[0].secretToken);
                                    }}
                                  >
                                    Copy Key
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      {!isLoading && !hasError && keysInfo.length > 0 && (
        <div className="form-submit-container">
          <div className="apiKeyCont">
            <button
              className="mobile-copy-btn"
              onClick={() => {
                navigator.clipboard.writeText(keysInfo[0].publicToken);
              }}
            >
              Copy publishable key
            </button>
          </div>

          <div className="apiKeyCont">
            <button
              className="mobile-copy-btn"
              onClick={() => {
                navigator.clipboard.writeText(keysInfo[0].secretToken);
              }}
            >
              Copy secret key
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default YourAPIKeys;
