import Alert from 'Components/Alert/Alert';
import Calendar from 'Components/Calendar/Calendar';
import EmptyList from 'Components/EmptyList/EmptyList';
import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import CustomerDetails from './CustomerDetails';
import Customers from './Customers';
import { HeaderCalendar, HeaderWrapper, SpinnerWrapper } from './Customers.styles';
import Search, { OrderSearchOptions, OrderSearchType, SearchOptionType } from './Search';
import { CustomerAPIResponseType } from './types';

const SearchOptionObj = {
  [OrderSearchType.name]: 'name',
  [OrderSearchType.email]: 'customerEmail',
};

const PaymentsContainer = () => {
  const [customerList, setCustomerList] = useState<CustomerAPIResponseType[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [hasError, setHasError] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { merchantId, apiBaseUri, showCustomerDetailsView, searchStartDate, searchEndDate } = useSelector(
    (state: ReduxStateType) => ({
      merchantId: state.merchantId,
      apiBaseUri: state.apiBaseUri,
      showCustomerDetailsView: state.showCustomerDetailsView,
      searchStartDate: state.searchStartDate,
      searchEndDate: state.searchEndDate,
    }),
  );

  useEffect(() => {
    document.title = 'Customers - April';
  }, []);

  // when dates are changed, reset active page
  useEffect(() => {
    if (!searchStartDate || !searchEndDate) {
      return;
    }
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
    setActivePage(1);
  }, [searchStartDate, searchEndDate]);

  useEffect(() => {
    if (!startDate || !endDate || !apiBaseUri || !merchantId || debouncedSearch !== selectedSearch) {
      return;
    }

    const fetchData = async () => {
      const localStartDate = convertUTCtoLocal(startDate);
      const localEndDate = convertUTCtoLocal(endDate);
      let url = `${apiBaseUri}/dashboard/merchant/${merchantId}/customers/query?sort=-createdAt&page=${activePage}&createdAfter=${localStartDate}&createdBefore=${localEndDate}`;
      if (debouncedSearch.length > 0) {
        url += `&${SearchOptionObj[selectedSearchOption.value as OrderSearchType]}=${encodeURIComponent(
          debouncedSearch,
        )}`;
      }
      const options = await getFetchOptions();
      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('limepay-page-count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          setCustomerList(response);
          setPageCount(pages);
        })
        .catch((e) => {
          setApiErrorMsg(e.message || 'Failed to fetch customers');
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [
    merchantId,
    activePage,
    apiBaseUri,
    startDate,
    endDate,
    debouncedSearch,
    selectedSearchOption.value,
    selectedSearch,
  ]);

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  return (
    <>
      {!showCustomerDetailsView && (
        <>
          <HeaderWrapper>
            <HeaderCalendar>
              <HeaderTitle title="Customers" />
              <Calendar />
            </HeaderCalendar>
            <Search
              selectedSearchOption={selectedSearchOption}
              setSelectedSearchOption={setSelectedSearchOption}
              selectedSearch={selectedSearch}
              handleSearch={handleSearch}
            />
          </HeaderWrapper>
          {isLoading && !hasError && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
          {hasError && <Alert message={apiErrorMsg} />}
          {!isLoading && !hasError && customerList.length > 0 && (
            <>
              <Customers customerList={customerList} />
              <Pagination
                pageCount={pageCount}
                activePage={activePage}
                setActivePage={setActivePage}
                pageStartIndex={pageStartIndex}
                setPageStartIndex={setPageStartIndex}
              />
            </>
          )}
          {!isLoading && !hasError && customerList.length === 0 && <EmptyList />}
        </>
      )}
      {showCustomerDetailsView && <CustomerDetails />}
    </>
  );
};

export default PaymentsContainer;
