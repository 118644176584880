import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NameBtn = styled.button`
  color: var(--lp-colors-medium-blue-600);
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const EmailBtn = styled.button`
  color: #354052;
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const ViewDetailsBtn = styled.button`
  color: #354052;
  font-size: 16px;
  font-family: 'Acumin Pro', Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  &:hover {
    text-decoration: underline;
    color: #354052;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 22px 0 0;
  align-items: center;
  height: 80px;
  .calendar-wrapper {
    margin-left: auto;
  }
  @media (min-width: 1200px) {
    padding: 0 60px 0 0;
  }
  @media (max-width: 960px) {
    height: 60px;
    .calendar-wrapper {
      padding-top: 23px;
    }
  }
  @media (max-width: 768px) {
    .calendar-wrapper {
      padding-top: 0;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
    height: 100px;
    padding-top: 10px;
  }
`;

export const HeaderCalendar = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 323px);
  @media (max-width: 576px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Content = styled.article`
  padding: 30px 22px 20px;
  @media (min-width: 1200px) {
    padding: 18px 60px 25px;
  }
  @media (max-width: 768px) {
    padding: 0 0 75px 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }
`;

export const Card = styled.div`
  background-color: transparent;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  padding: 0 7px;
  @media (max-width: 768px) {
    background: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;
  }
`;

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  line-height: 22px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 100%;
  position: relative;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6eaee;
  padding: 8px 0;

  &:first-of-type,
  &:nth-of-type(2) {
    border: 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 3px;
    padding: 10px 0;
    &.item-list-header {
      display: none;
    }
    &.active-item {
      padding: 16px 0 0;
    }
  }
`;

export const CustomEmailTruncate = styled.div`
  @media (max-width: 1700px) and (min-width: 768px) {
    margin-top: 3px;
    width: 90%;
    white-space: nowrap;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const OrderId = styled.div`
  font-size: 16px;
  padding-top: 10px;
  font-weight: bold;
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const FeatureIcon = styled.div`
  border: 1px #989fb2 solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

export const ItemActionsDropdown = styled.div`
  width: 100%;

  .dots-icon {
    display: block;
  }

  .arrow-right-icon {
    display: none;
  }

  @media (max-width: 768px) {
    .dots-icon {
      display: none;
    }

    .arrow-right-icon {
      display: block;
      transform: rotate(270deg);
      width: 24px;
      height: 24px;
    }
  }
`;

export const Price = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #354052;
  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

export const ItemActionsList = styled.ul`
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
`;

export const ItemActionsBlock = styled.div<{ isActive: boolean }>`
  min-width: 238px;
  max-width: 450px;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 24px;
  background-color: #fff;
  padding: 15px 20px;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  transition: all 0.15s ease-in-out;
  z-index: 1;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;

export const ItemCol = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 10px 6px 0;
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 3;
  margin-right: 0;
  min-width: 0;

  &.item-col-title {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    flex-basis: 0;
    flex-grow: 3;
  }
  &.item-col-lgr {
    max-width: 250px;
  }
  &.item-col-header span {
    font-size: 15px;
    color: #6c7488;
  }
  &.custom-col-xsr {
    max-width: 335px;

    .item {
      width: 100%;
    }
  }
  &.item-col-actions-dropdown {
    padding-right: 0;
    width: 15px;
    height: 56px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    position: absolute;
    right: 0;
  }

  @media (max-width: 1400px) {
    padding-right: 0;

    &.custom-col-xsrr {
      max-width: 270px;
    }
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    padding: 0;

    &.item-col-actions-dropdown {
      height: 24px;
      width: 24px;
      top: calc(50% - 12px);
    }
  }
`;
