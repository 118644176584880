import { ReactComponent as Close } from 'assets/svg/close-new.svg';
import { ReactComponent as Error } from 'assets/svg/error.svg';
import { ReactComponent as Tick } from 'assets/svg/tick.svg';
import { MouseEvent, ReactElement } from 'react';

import { CloseBtn, Content, Description, IconContainer, Title, Wrapper } from './styles';

export type MessageProps = {
  title?: string;
  description?: string;
  handleClose?(e: MouseEvent<HTMLButtonElement>): void;
  backgroundColor?: string;
  success?: boolean;
  hasClose?: boolean;
  className?: string;
  borderColor?: string;
  modalText?: string;
  svgFillColor?: string;
  modalToggle?: (val: boolean) => void;
};

const MessageWithModal = ({
  title = '',
  description = '',
  handleClose,
  backgroundColor = '',
  borderColor = '',
  success = true,
  hasClose = true,
  className = '',
  modalText = '',
  svgFillColor = '',
  modalToggle,
}: MessageProps): ReactElement => {
  if (title.length === 0 && description.length === 0) {
    return <></>;
  }

  return (
    <Wrapper className={className} success={success} backgroundColor={backgroundColor} borderColor={borderColor}>
      <IconContainer success={success} svgFillColor={svgFillColor}>
        {success && <Tick />}
        {!success && <Error />}
      </IconContainer>

      <Content success={success}>
        {title.length > 0 && <Title className="MessageTitle">{title}</Title>}
        {description.length > 0 && (
          <Description className={!title ? 'mt-0' : ''}>
            {description}{' '}
            {modalText && modalToggle && (
              <button type="button" onClick={() => modalToggle(true)} className="link-learn">
                {modalText}
              </button>
            )}
          </Description>
        )}
      </Content>
      {hasClose && (
        <CloseBtn success={success} onClick={handleClose}>
          <Close />
        </CloseBtn>
      )}
    </Wrapper>
  );
};

export default MessageWithModal;
