import Message from 'Components/Message';
import { Content } from 'Constants/styles';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import TransferList from './TransferList';
import { PayoutAPIResponseType } from './types';

interface Props {
  payoutFetchLoader: boolean;
  selectedPayoutFreq: string;
  payoutErrorMsg: string;
}

const TransferOverview = ({ payoutFetchLoader, selectedPayoutFreq, payoutErrorMsg }: Props): ReactElement => {
  const [payouts, setPayouts] = useState<PayoutAPIResponseType[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isPayoutsLoading, setIsPayoutsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    setIsPayoutsLoading(true);
    const fetchData = async () => {
      const options = await getFetchOptions();

      const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payouts/query?sort=-createdAt&page=${activePage}`;
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('Limepay-Page-Count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          if (isMounted) {
            setPayouts(response);
            setPageCount(pages);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to fetch payouts');
        })
        .finally(() => {
          isMounted && setIsPayoutsLoading(false);
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [activePage, apiBaseUri, merchantId]);

  const handleClickPagination = (e: MouseEvent<HTMLDivElement>, type: 'prev' | 'next'): void => {
    e.preventDefault();
    setActivePage((p) => (type === 'prev' ? p - 1 : p + 1));
  };

  return (
    <Content>
      {errorMsg.length > 0 && <Message description={errorMsg} success={false} hasClose={false} />}
      {payoutErrorMsg.length > 0 && <Message description={payoutErrorMsg} success={false} hasClose={false} />}
      <TransferList
        totalOrders={payouts}
        pageCount={pageCount}
        activePage={activePage}
        handleClickPagination={handleClickPagination}
        isLoading={isPayoutsLoading}
        loading={payoutFetchLoader}
        selectedPayoutFreq={selectedPayoutFreq}
      />
    </Content>
  );
};

export default TransferOverview;
