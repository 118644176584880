import { ReactComponent as FailedIcon } from 'assets/svg/failed.svg';
import { ReactComponent as PassedIcon } from 'assets/svg/passed.svg';
import { ReactComponent as UnverifiedIcon } from 'assets/svg/unverified.svg';
import { ReactElement } from 'react';

import { Icon, Text } from './VerificationStatus.styles';

type Props = {
  status: null | boolean;
};

export const VerificationIcon = ({ status }: Props) =>
  status === null ? null : <Icon>{status ? <PassedIcon /> : <FailedIcon />}</Icon>;

export const VerificationStatus = ({ status }: Props): ReactElement => {
  return (
    <>
      {status === true && (
        <>
          <Icon>
            <PassedIcon />
          </Icon>
          <Text>Passed</Text>
        </>
      )}
      {status === false && (
        <>
          <Icon>
            <FailedIcon />
          </Icon>
          <Text>Failed</Text>
        </>
      )}
      {status === null && (
        <>
          <Icon>
            <UnverifiedIcon />
          </Icon>
          <Text>Unverified</Text>
        </>
      )}
    </>
  );
};
