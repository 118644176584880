import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  text-align: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 960px) {
    height: 60px;
  }
`;
