import { Content, H2, H3, H4, Label } from 'Constants/styles';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import React, { MouseEvent, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setCustomerDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';

import HeaderTitle from '../HeaderTitle/HeaderTitle';
import { FeatureIcon, Media } from './CustomerDetails.styles';
import CustomerPayments from './CustomerPayments';

const CustomerDetails = (): ReactElement => {
  const dispatch: Dispatch<any> = useDispatch();

  const { activeCustomer } = useSelector((state: ReduxStateType) => ({
    activeCustomer: state.activeCustomer,
  }));

  const setCustomerDetailsCB = useCallback((s, a) => dispatch(setCustomerDetails(s, a)), [dispatch]);

  const handleClickBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setCustomerDetailsCB(false, null);
  };

  if (!activeCustomer) {
    return <></>;
  }

  return (
    <div>
      <HeaderTitle
        title={`Customer details for #${activeCustomer.customerId}`}
        showArrowLeft
        showBackButton
        backTitle="Back to customers"
        handleClickBack={handleClickBack}
      />

      <Content>
        <div className="lp-box">
          <div className="row">
            <Media className="col-12 col-xl-8">
              <FeatureIcon>
                <UserIcon className="user-icon" />
              </FeatureIcon>
              <div className="media-body">
                <H3 className="pt-2 msg-short">
                  {activeCustomer.firstName || activeCustomer.lastName
                    ? `${activeCustomer.firstName} ${activeCustomer.lastName}`
                    : 'Guest'}
                </H3>
              </div>
            </Media>
          </div>
          <div className="row pt-3">
            <div className="col-sm-12">
              <H3 className="mb-0">{activeCustomer.email}</H3>
              <H4 className="mb-0">#{activeCustomer.customerId}</H4>
            </div>
          </div>
        </div>

        <div className="lp-box">
          <H2 className="mb-4">Contact details</H2>
          <div className="lp-details">
            <div className="row pt-3">
              <div className="col-sm-3 col-xl-1">
                <Label>Phone</Label>
              </div>
              <div className="col-sm-9 col-xl-11">{activeCustomer.phoneNumber || '-'}</div>
            </div>
          </div>
        </div>

        <CustomerPayments />
      </Content>
    </div>
  );
};

export default CustomerDetails;
