let headers: Headers | null = null;

const getHeaders = async () => {
  if (headers) return headers;

  const response = await fetch('/');
  headers = response.headers;

  return headers;
};

const getHeader = async (key: string) => {
  const headers = await getHeaders();

  let value = headers.get(key) || '';

  if (!value && process.env.NODE_ENV === 'development') {
    value = process.env[`REACT_APP_${key.toUpperCase().replace(/-/g, '_')}`] || '';
  }

  if (!value) throw Error(`Header "${key}" not found`);

  return value;
};

export const getLimepayApiHost = () => getHeader('limepay-api-host');

export const getAprilApiHost = () => getHeader('april-api-host');
