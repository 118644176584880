import Message from 'Components/Message';
import { Content } from 'Constants/styles';
import { Fragment, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import { CountryCurrency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import useDebounce from 'utils/useDebounce';

import AllTransferList from './AllTransferList';
import { OrderSearchOptions, OrderSearchType, SearchOptionType } from './AllTransfersSearch';
import { TransactionObj } from './types';

const SearchOptionObj = {
  [OrderSearchType.orderId]: 'internalOrderId',
  [OrderSearchType.transactionId]: 'transactionIds',
};

const AllTransfers = () => {
  const [transactionList, setTransactionList] = useState<Array<TransactionObj>>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isTransactionListLoading, setIsTransactionListLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [selectedSearchOption, setSelectedSearchOption] = useState<SearchOptionType>(OrderSearchOptions[0]);
  const [selectedSearch, setSelectedSearch] = useState<string>('');

  const debouncedSearch = useDebounce(selectedSearch, 500);

  const { searchStartDate, searchEndDate, merchantTradingCountry } = useSelector((state: ReduxStateType) => ({
    searchStartDate: state.searchStartDate,
    searchEndDate: state.searchEndDate,
    merchantTradingCountry: state.merchantTradingCountry,
  }));
  const currency = CountryCurrency[merchantTradingCountry];

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  /* when dates are changed, need to make sure activePage is 1 in the url */
  useEffect(() => {
    if (!searchStartDate || !searchEndDate) {
      return;
    }
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
    setActivePage(1);
  }, [searchStartDate, searchEndDate]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId || !startDate || !endDate || debouncedSearch !== selectedSearch) {
      return;
    }

    let isMounted = true;
    setIsTransactionListLoading(true);
    const fetchData = async () => {
      const localStartDate = convertUTCtoLocal(startDate);
      const localEndDate = convertUTCtoLocal(endDate);
      const options = await getFetchOptions();

      let url = `${apiBaseUri}/reports/merchants/${merchantId}/settlement/${currency}?page=${activePage}&from=${localStartDate}&to=${localEndDate}`;

      if (debouncedSearch.length > 0) {
        const tempSearch: number | string = debouncedSearch;
        url += `&${SearchOptionObj[selectedSearchOption.value as OrderSearchType]}=${encodeURIComponent(tempSearch)}`;
      }

      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            response: await res.json(),
          };
        })
        .then(({ response }) => {
          if (isMounted) {
            setTransactionList(response.items);
            setPageCount(response.totalPages);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to fetch all transactions');
        })
        .finally(() => {
          isMounted && setIsTransactionListLoading(false);
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [
    activePage,
    apiBaseUri,
    merchantId,
    debouncedSearch,
    selectedSearchOption,
    selectedSearch,
    currency,
    startDate,
    endDate,
  ]);

  const handleClickPagination = (e: MouseEvent<HTMLDivElement>, type: 'prev' | 'next'): void => {
    e.preventDefault();
    setActivePage((p) => (type === 'prev' ? p - 1 : p + 1));
  };

  const handleSearch = (search: string): void => {
    setSelectedSearch(search);
    setActivePage(1);
  };

  return (
    <Fragment>
      <Content>
        {errorMsg.length > 0 && <Message description={errorMsg} success={false} hasClose={false} />}

        <AllTransferList
          transactionList={transactionList}
          pageCount={pageCount}
          activePage={activePage}
          handleClickPagination={handleClickPagination}
          isLoading={isTransactionListLoading}
          setActivePage={setActivePage}
          setSelectedSearchOption={setSelectedSearchOption}
          selectedSearchOption={selectedSearchOption}
          selectedSearch={selectedSearch}
          handleSearch={handleSearch}
        />
      </Content>
    </Fragment>
  );
};

export default AllTransfers;
