import Message from 'Components/Message/';
import Modal from 'Components/Modal/Modal';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { LpBox } from 'Constants/styles';
import { ReactComponent as OrderIcon } from 'assets/svg/order-id.svg';
import { FormEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ReduxStateType } from 'redux/Constants/types';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { TableHeaders } from './Constants';
import Header from './Header';
import SuccessView from './SuccessView';
import * as s from './styles';
import { ActionLinkType, ActionStepView, ActionsType, CustomSettings, PaymentType, StatesType } from './types';
import { getCustomizationsForList, getInitialStates } from './utils';

const ManualPayments = (): ReactElement => {
  const [states, setStates] = useState<StatesType>(() => getInitialStates({}));
  const [now, setNow] = useState<number>(0);
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [activePayment, setActivePayment] = useState<PaymentType | null>(null);
  const [pageCount, setPageCount] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [merchantName] = useState(() => getMerchantName());
  const [customSettings, setCustomSettings] = useState<CustomSettings | null>(null);

  const {
    apiBaseUri,
    merchantId,
    isLaddrB2C,
    isLaddrTF,
    orderBaseUri,
    merchantTradingCountry,
    manualPaymentIncludeGst,
    isMerchantActivated,
    merchantPublicKey,
  } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
    isLaddrB2C: !!state.isLaddrB2C,
    isLaddrTF: !!state.isLaddrTF,
    orderBaseUri: state.orderBaseUri,
    merchantTradingCountry: state.merchantTradingCountry,
    manualPaymentIncludeGst: state.manualPaymentIncludeGst,
    isMerchantActivated: state.isMerchantActivated,
    merchantPublicKey: state.merchantPublicKey,
  }));

  const customizations = useMemo(() => getCustomizationsForList(isLaddrB2C), [isLaddrB2C]);

  useEffect(() => {
    document.title = isLaddrB2C ? 'Payment Request - Laddr' : 'Manual Payments - April';
  }, [isLaddrB2C]);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const getUnpaidPayments = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/orders?sort=-createdAt&page=${activePage}&orderType=manual&orderStatus=created&orderStatus=cancelled`;
      const options = await getFetchOptions();
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const _payments = await res.json();
      return {
        _pageCount: Number(res.headers.get('limepay-page-count')),
        _payments,
      };
    };

    const getCustomSettings = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/order-custom-settings`;
      const options = await getFetchOptions();
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      return await res.json();
    };

    const getAllData = async () => {
      try {
        isMounted && setStates((prev) => ({ ...prev, isLoading: true }));
        const [{ _pageCount, _payments }, _customSettings] = await Promise.all([
          getUnpaidPayments(),
          getCustomSettings(),
        ]);
        if (isMounted) {
          setPageCount(_pageCount);
          setPayments(_payments);
          setCustomSettings(_customSettings);
        }
      } catch (e) {
        isMounted &&
          setStates((prev) => ({
            ...prev,
            errorMsg: e.message || 'Failed to fetch unpaid payments or custom settings',
            showErrorMsg: true,
          }));
      } finally {
        isMounted && setStates((prev) => ({ ...prev, isLoading: false }));
      }
    };

    getAllData();
    return () => {
      isMounted = false;
    };
  }, [activePage, apiBaseUri, merchantId, now]);

  const handleClickOutside = (): void => {
    setStates((prev) => ({ ...prev, activeOrderId: '', canShowActionList: false }));
  };

  useClickOutsideWithoutRef(handleClickOutside, ['action-link-btn', 'actions-ul', 'actions-li', 'dots', 'arrow-right']);

  const handleClickDots = (e: MouseEvent<HTMLOrSVGElement>, orderId: string): void => {
    e.preventDefault();
    setActivePayment(payments.find((p) => p.merchantOrderId === orderId) ?? null);
    setStates((prev) => ({ ...prev, activeOrderId: orderId, canShowActionList: true }));
  };

  const handleCancelOrder = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates(() => getInitialStates({ showCancelModal: true }));
    setBodyOverflow('hidden');
  };

  const handleCloseModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates(() => getInitialStates({ showSendModal: false, showCancelModal: false, showProcessModal: false }));
    setBodyOverflow('auto');
  };

  const handleConfirmCancelOrder = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setStates((prev) => ({ ...prev, modalLoading: true }));
    try {
      const url = `${apiBaseUri}/merchants/${merchantId}/orders/${activePayment?.merchantOrderId}`;
      const options = await getFetchOptions('DELETE');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setStates((prev) => ({ ...prev, cancelStepView: ActionStepView.Success }));
      setNow(Date.now());
    } catch (e) {
      setStates((prev) => ({
        ...prev,
        modalLoading: false,
        modalErrorMsg: e.message || 'Failed to cancel the order.',
      }));
    }
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleCloseErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates((prev) => ({ ...prev, showErrorMsg: false }));
  };

  const handleCloseModalErrorMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates((prev) => ({ ...prev, modalErrorMsg: '' }));
  };

  const handleProcessPayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates(() => getInitialStates({ showProcessModal: true }));
    setBodyOverflow('hidden');
  };

  const handleProcessInModal = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    try {
      setStates((prev) => ({ ...prev, modalLoading: true }));
      const url = `${apiBaseUri}/merchants/${merchantId}/orders/invoices/${activePayment?.merchantOrderId}/token`;
      const options = await getFetchOptions();
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      const json = await res.json();
      const newUrl = `${orderBaseUri}/${merchantName}/invoice/${activePayment?.merchantOrderId}?token=${json.invoiceToken}`;
      window.open(newUrl, '_blank');
      setStates((prev) => ({ ...prev, modalLoading: false, processPaymentStepView: ActionStepView.Success }));
      setNow(Date.now());
    } catch (e) {
      setStates((prev) => ({
        ...prev,
        modalErrorMsg: e.message || 'Failed to process the manual payment.',
        modalLoading: false,
      }));
    }
  };

  const handleResendInModal = async (): Promise<void> => {
    try {
      setStates((prev) => ({ ...prev, modalLoading: true }));
      const url = `${apiBaseUri}/merchants/${merchantId}/orders/invoices/${
        activePayment?.merchantOrderId
      }/resend-comms?sendSms=${!!activePayment?.phoneNo}&sendEmail=${!!activePayment?.customerEmail}`;
      const options = await getFetchOptions('POST');
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
      setStates((prev) => ({ ...prev, modalLoading: false, sendToCustomerStepView: ActionStepView.Success }));
    } catch (e) {
      setStates((prev) => ({
        ...prev,
        modalErrorMsg: e.message || 'Failed to process the manual payment.',
        modalLoading: false,
      }));
    }
  };

  const handleSendToCustomer = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setStates(() => getInitialStates({ showSendModal: true }));
    setBodyOverflow('hidden');
  };

  const Actions = ({ isCancelled }: ActionsType) => (
    <s.Ul className="actions-ul">
      <s.Li className="actions-li">
        <s.LinkBtn
          className="action-link-btn"
          disabled={isCancelled}
          data-testid={ActionLinkType.ProcessPayment}
          onClick={handleProcessPayment}
        >
          Process payment now
        </s.LinkBtn>
      </s.Li>
      <s.Li className="actions-li">
        <s.LinkBtn
          className="action-link-btn"
          disabled={isCancelled}
          data-testid={ActionLinkType.SendToCustomer}
          onClick={handleSendToCustomer}
        >
          Resend to customer
        </s.LinkBtn>
      </s.Li>
      <s.Li className="actions-li">
        <s.LinkBtn
          className="action-link-btn"
          disabled={isCancelled}
          data-testid={ActionLinkType.Cancel}
          onClick={handleCancelOrder}
        >
          Cancel
        </s.LinkBtn>
      </s.Li>
    </s.Ul>
  );

  if (isMerchantActivated === null) {
    return <></>;
  }

  if (isMerchantActivated === false) {
    return <Redirect to={`/${merchantName}/account-setup`} push />;
  }

  if (states.isLoading) {
    return (
      <s.ListSpinner>
        <Spinner />
      </s.ListSpinner>
    );
  }

  return (
    <s.Wrapper>
      <Header
        apiBaseUri={apiBaseUri}
        merchantId={merchantId}
        isLaddrB2C={isLaddrB2C}
        isLaddrTF={isLaddrTF}
        manualPaymentIncludeGst={manualPaymentIncludeGst}
        orderBaseUri={orderBaseUri}
        merchantTradingCountry={merchantTradingCountry}
        setNow={setNow}
        customSettings={customSettings}
        merchantPublicKey={merchantPublicKey}
      />
      <s.Content>
        {states.showErrorMsg && states.errorMsg.length > 0 && (
          <Message description={states.errorMsg} success={false} handleClose={handleCloseErrorMsg} />
        )}
        <LpBox>
          <s.SectionTitle>{customizations.listTitle}</s.SectionTitle>
          {states.errorMsg.length === 0 && payments.length === 0 && (
            <s.NoPayment className="mt-4">{customizations.emptyList}</s.NoPayment>
          )}
          {states.errorMsg.length === 0 && payments.length > 0 && (
            <>
              <s.TableRow desktopOnly top="25px" bottom="14px">
                {TableHeaders.map((c) => (
                  <s.Cell fontSize="15px" key={c.text} width={c.width}>
                    {c.text}
                  </s.Cell>
                ))}
              </s.TableRow>
              {payments.map((payment, index) => (
                <s.TableRow key={payment.merchantOrderId} top="18px" showBorder={index > 0}>
                  <s.OrderCell>
                    <s.FeatureIcon>
                      <OrderIcon className="order-icon" />
                    </s.FeatureIcon>
                    <s.OrderId>{payment.internalOrderId}</s.OrderId>
                  </s.OrderCell>
                  <s.Cell width="96px" color="#354052">
                    {getDateFormat({ time: payment.createdAt, showDaySuffix: false }).formatted}
                  </s.Cell>
                  <s.Cell width="60px" color="#354052">
                    {toCurrency(payment.amount, payment.currency)}
                  </s.Cell>
                  <s.Description>{payment.description}</s.Description>
                  <s.Cell width="96px" color="#354052">
                    {payment.billing?.name || '-'}
                  </s.Cell>
                  <s.Cell width="106px" color="#354052" order={-2}>
                    {payment.status === 'created' && <s.Created>created</s.Created>}
                    {payment.status === 'cancelled' && <s.Cancelled>cancelled</s.Cancelled>}
                  </s.Cell>
                  <s.Cell className="show-more" width="30px">
                    <s.Dots
                      className="dots"
                      data-testid={payment.merchantOrderId}
                      onClick={(e) => handleClickDots(e, payment.merchantOrderId)}
                    />
                    <s.ArrowRight
                      className="arrow-right"
                      data-testid={payment.merchantOrderId}
                      onClick={(e) => handleClickDots(e, payment.merchantOrderId)}
                    />
                    {states.canShowActionList && states.activeOrderId === payment.merchantOrderId && (
                      <Actions isCancelled={payment.status === 'cancelled'} />
                    )}
                  </s.Cell>
                </s.TableRow>
              ))}
            </>
          )}
        </LpBox>
      </s.Content>
      <Pagination
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
        pageStartIndex={pageStartIndex}
        setPageStartIndex={setPageStartIndex}
      />
      {states.showProcessModal && (
        <Modal
          title=""
          cancelBtnText=""
          confirmBtnText=""
          isLoading={states.modalLoading}
          handleCancel={handleCloseModal}
          handleSubmit={handleSubmitForm}
        >
          {states.processPaymentStepView === ActionStepView.Confirm && (
            <>
              {states.modalErrorMsg.length > 0 && (
                <Message
                  className="mt-2 mb-0"
                  description={states.modalErrorMsg}
                  success={false}
                  handleClose={handleCloseModalErrorMsg}
                />
              )}
              <s.ModalText>{customizations.processModalTitle}</s.ModalText>
              <s.BtnGroup>
                <s.CancelBtn onClick={handleCloseModal}>{customizations.modalCancelButton}</s.CancelBtn>
                <s.ConfirmBtn onClick={handleProcessInModal} disabled={states.modalLoading}>
                  {customizations.processModalButton}
                  {states.modalLoading && (
                    <s.SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.ConfirmBtn>
              </s.BtnGroup>
            </>
          )}
          {states.processPaymentStepView === ActionStepView.Success && (
            <SuccessView sentToCustomer={false} successText={customizations.processModalSuccessText} />
          )}
        </Modal>
      )}
      {states.showSendModal && (
        <Modal
          title=""
          cancelBtnText=""
          confirmBtnText=""
          isLoading={states.modalLoading}
          handleCancel={handleCloseModal}
          handleSubmit={handleSubmitForm}
        >
          {states.sendToCustomerStepView === ActionStepView.Confirm && (
            <>
              {states.modalErrorMsg.length > 0 && (
                <Message
                  className="mt-2 mb-0"
                  description={states.modalErrorMsg}
                  success={false}
                  handleClose={handleCloseModalErrorMsg}
                />
              )}
              <s.ModalText>
                {customizations.sendModalTitle} {activePayment?.customerEmail}?
              </s.ModalText>
              <s.BtnGroup>
                <s.CancelBtn onClick={handleCloseModal}>{customizations.modalCancelButton}</s.CancelBtn>
                <s.ConfirmBtn onClick={handleResendInModal} disabled={states.modalLoading}>
                  {customizations.sendModalButton}
                  {states.modalLoading && (
                    <s.SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.ConfirmBtn>
              </s.BtnGroup>
            </>
          )}
          {states.sendToCustomerStepView === ActionStepView.Success && (
            <SuccessView
              sentToCustomer
              successText={customizations.sendModalSuccessText}
              email={activePayment?.customerEmail ?? ''}
              phone={activePayment?.phoneNo ?? ''}
            />
          )}
        </Modal>
      )}
      {states.showCancelModal && (
        <Modal
          title=""
          cancelBtnText=""
          confirmBtnText=""
          isLoading={states.modalLoading}
          handleCancel={handleCloseModal}
          handleSubmit={handleSubmitForm}
        >
          {states.cancelStepView === ActionStepView.Confirm && (
            <>
              {states.modalErrorMsg.length > 0 && (
                <Message
                  className="mt-2 mb-0"
                  description={states.modalErrorMsg}
                  success={false}
                  handleClose={handleCloseModalErrorMsg}
                />
              )}
              <s.ModalText>{customizations.cancelModalTitle}</s.ModalText>
              <s.BtnGroup>
                <s.CancelBtn onClick={handleCloseModal}>{customizations.modalCancelButton}</s.CancelBtn>
                <s.ConfirmBtn isDanger onClick={handleConfirmCancelOrder} disabled={states.modalLoading}>
                  {customizations.cancelModalButton}
                  {states.modalLoading && (
                    <s.SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.ConfirmBtn>
              </s.BtnGroup>
            </>
          )}
          {states.cancelStepView === ActionStepView.Success && (
            <SuccessView sentToCustomer={false} successText={customizations.cancelModalSuccessText} />
          )}
        </Modal>
      )}
    </s.Wrapper>
  );
};

export default ManualPayments;
