import Calendar from 'Components/Calendar/Calendar';
import EmptyList from 'Components/EmptyList/EmptyList';
import ExportData from 'Components/ExportData/ExportData';
import Spinner from 'Components/Spinner/Spinner';
import { LpBox, P, Row } from 'Constants/styles';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import { MouseEvent, ReactElement } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';

import AllTransfersSearch, { SearchOptionType } from './AllTransfersSearch';
import { AllTransferListHeaders } from './Constants';
import { SpinnerWrapper } from './TransferOverview.styles';
import { FeatureIcon, Media } from './styles';
import { TransactionObj } from './types';

type Props = {
  transactionList: TransactionObj[];
  pageCount: number;
  activePage: number;
  handleClickPagination(e: MouseEvent<HTMLDivElement>, t: 'prev' | 'next'): void;
  isLoading: boolean;
  selectedPayoutFreq?: string;
  setActivePage(page: number): void;
  selectedSearch: string;
  selectedSearchOption: SearchOptionType;
  setSelectedSearchOption: (val: SearchOptionType) => void;
  handleSearch: (search: string) => void;
};

const AllTransferList = ({
  transactionList,
  pageCount,
  activePage,
  handleClickPagination,
  isLoading,
  selectedSearchOption,
  setSelectedSearchOption,
  selectedSearch,
  handleSearch,
}: Props): ReactElement => {
  return (
    <>
      <div className="col-sm-12 mb-4">
        <Row>
          <P>Filter by date or keyword to download custom CSV files</P>
        </Row>
        <Row>
          <Calendar opens="right" hideLast3MonthsRange={true} maxSpanDays={59} />
          <AllTransfersSearch
            selectedSearchOption={selectedSearchOption}
            setSelectedSearchOption={setSelectedSearchOption}
            selectedSearch={selectedSearch}
            handleSearch={handleSearch}
          />
          <ExportData pageSource="settlement" />
        </Row>
      </div>
      <LpBox>
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {AllTransferListHeaders.map((header, index) => (
                  <div key={index} className={`item-col item-col-header item-col-title ${header.className}`}>
                    <div className={`${header.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{header.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {isLoading && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            {!isLoading && transactionList.length === 0 && <EmptyList />}
            {!isLoading &&
              transactionList.length > 0 &&
              transactionList.map((order, key) => (
                <li key={order.orderId + key} className="item">
                  <div className="item-row item-row-reset-m">
                    <div className="item-col item-col-order">
                      <Media>
                        <FeatureIcon>
                          <OrderIdIcon className="order-id-icon" />
                        </FeatureIcon>
                        <div className="order-id mt-2">{order.orderId}</div>
                      </Media>
                    </div>
                    <div className="item-col item-col-cxl">
                      <div className="item text-left mt-1">
                        <div>{getDateFormat({ time: order.entryTimestamp }).date}</div>
                      </div>
                    </div>
                    <div className="item-col item-col-cxl">
                      <div className="item text-left mt-1">
                        <div>{order.entryType}</div>
                      </div>
                    </div>
                    <div className="item-col item-col-lgr">
                      <div className="item text-left mt-1">
                        <div>{order.transactionType}</div>
                      </div>
                    </div>
                    <div className="item-col item-transaction-id">
                      <div className="item text-left mt-1">
                        <div>{order.transactionId}</div>
                      </div>
                    </div>
                    <div className="item-col item-col-cxl">
                      <div className="item text-left mt-1">
                        <div className="price">
                          {typeof order.grossAmount === 'number' ? toCurrency(order.grossAmount, order.currency) : '-'}
                        </div>
                      </div>
                    </div>
                    <div className="item-col item-col-cxl">
                      <div className="item text-left mt-1">
                        <div className="price">
                          {typeof order.netAmount === 'number' ? toCurrency(order.netAmount, order.currency) : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {(activePage > 1 || pageCount > activePage) && (
            <div className="pagination">
              {activePage > 1 && (
                <div className="pagination-prev" onClick={(e) => handleClickPagination(e, 'prev')}>
                  Previous
                </div>
              )}
              {pageCount > activePage && (
                <div className="pagination-next" onClick={(e) => handleClickPagination(e, 'next')}>
                  Next
                </div>
              )}
            </div>
          )}
        </div>
      </LpBox>
    </>
  );
};

export default AllTransferList;
