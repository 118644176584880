import styled from '@emotion/styled';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 22px;
  margin-top: 8px;
  color: #6c748a;

  .left {
    width: 16px;
    height: 16px;
    padding-top: 2px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: #505bcc;
    path {
      fill: #505bcc;
    }
  }
`;

export const Title = styled.div`
  color: inherit;
  line-height: 22px;
  font-size: 15px;
`;
