import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .header-wrapper {
    display: flex;
    padding: 0 22px 0 0;
    align-items: center;
    .calendar-wrapper {
      margin-left: auto;
    }
    .header-calendar {
      display: flex;
      align-items: center;
      width: calc(100% - 320px);
    }
  }
  .spinner-wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    .header-wrapper {
      padding: 0 60px 0 0;
    }
  }
  @media (max-width: 960px) {
    .header-title.lp-dashboard-top {
      padding-left: 45px;
    }
    .header-wrapper {
      .calendar-wrapper,
      .order-search {
        padding-top: 23px;
      }
    }
  }
  @media (max-width: 768px) {
    .header-wrapper {
      padding: 0 22px 0 0;
      height: 62px;
      .calendar-wrapper,
      .order-search {
        padding-top: 12px;
        padding-bottom: 11px;
      }
    }
  }
  @media (max-width: 576px) {
    .header-wrapper {
      flex-direction: column;
      height: auto;
      .header-calendar {
        width: 100%;
      }
      .order-search {
        padding-top: 0;
      }
    }
  }
`;
