import './Calendar.scss';

import { FILTER_PERIOD_OBJ, ISO_END_TIME, ISO_START_TIME, daysOfWeek, monthNames } from 'Constants/Constants';
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import moment from 'moment';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { updateSearchDate } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';

type DateProps = {
  format(str: string): string;
};

type CalendarProps = {
  opens?: string;
  hideLast3MonthsRange?: boolean;
  maxSpanDays?: number;
};

interface DatePickerOption {
  autoUpdateInput: boolean;
  autoApply: boolean;
  alwaysShowCalendars: boolean;
  opens: string;
  startDate: string;
  endDate: string;
  locale: {
    format: string;
    daysOfWeek: Array<string>;
    monthNames: Array<string>;
  };
  ranges: {
    'Current week': Array<moment.Moment>;
    'Last 7 days': Array<moment.Moment>;
    'Current month': Array<moment.Moment>;
    'Last 3 months'?: Array<moment.Moment>;
  };
  maxSpan?: {
    days: number;
  };
}

const Calendar = ({ opens = 'left', hideLast3MonthsRange = false, maxSpanDays }: CalendarProps) => {
  const [showHeader, setShowHeader] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch();
  const updateSearchDateCB = useCallback((s: string, e: string) => dispatch(updateSearchDate(s, e)), [dispatch]);

  const { searchStartDate, searchEndDate, filterPeriod, searchTriggeredBy } = useSelector((state: ReduxStateType) => ({
    searchStartDate: state.searchStartDate,
    searchEndDate: state.searchEndDate,
    filterPeriod: state.filterPeriod,
    searchTriggeredBy: state.searchTriggeredBy,
  }));

  useEffect(() => {
    const m = moment();
    let time;
    switch (filterPeriod) {
      case FILTER_PERIOD_OBJ.day:
        time = m.subtract(1, 'days');
        break;
      case FILTER_PERIOD_OBJ.month:
        time = m.subtract(30, 'days');
        break;
      case FILTER_PERIOD_OBJ.year:
        time = m.subtract(365, 'days');
        break;
      case FILTER_PERIOD_OBJ.week:
      default:
        time = m.subtract(7, 'days');
        break;
    }

    if (!searchStartDate || !searchEndDate || searchTriggeredBy !== 'calendar') {
      const startDate = time.format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');
      updateSearchDateCB(startDate + ISO_START_TIME, endDate + ISO_END_TIME);
    }

    const datePickerOption: DatePickerOption = {
      autoUpdateInput: true,
      autoApply: true,
      alwaysShowCalendars: true,
      opens: opens,
      startDate: moment(searchStartDate).format('D MMM YY') || time.format('D MMM YY'),
      endDate: moment(searchEndDate?.slice(0, 10)).format('D MMM YY') || moment().format('D MMM YY'),
      locale: {
        format: 'D MMM YY',
        daysOfWeek,
        monthNames,
      },
      ranges: {
        'Current week': [moment().startOf('week'), moment().endOf('week')],
        'Last 7 days': [moment().subtract(6, 'days'), moment()],
        'Current month': [moment().startOf('month'), moment().endOf('month')],
        'Last 3 months': [moment().subtract(90, 'days'), moment()],
      },
    };

    if (hideLast3MonthsRange) {
      delete datePickerOption.ranges['Last 3 months'];
    }

    if (maxSpanDays) {
      datePickerOption.maxSpan = {
        days: maxSpanDays,
      };
    }

    // @ts-ignore
    window.$('#overviewDateFilter').daterangepicker(datePickerOption, (start: DateProps, end: DateProps): void => {
      updateSearchDateCB(start.format('YYYY-MM-DD') + ISO_START_TIME, end.format('YYYY-MM-DD') + ISO_END_TIME);
    });
  }, [
    updateSearchDateCB,
    filterPeriod,
    searchStartDate,
    searchEndDate,
    searchTriggeredBy,
    opens,
    hideLast3MonthsRange,
    maxSpanDays,
  ]);

  const handleClickBack = (e: MouseEvent<SVGElement | HTMLDivElement>): void => {
    e.preventDefault();
    setShowHeader(false);
  };

  const handleClickIcon = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    const input = document.getElementById('overviewDateFilter');
    input && input.focus();
  };

  return (
    <div className="calendar-wrapper">
      {showHeader && (
        <div className="calendar-header is-mobile">
          <div className="row">
            <div className="col-8">
              <h5 className="modal-title">
                <ArrowLeftIcon className="back" onClick={handleClickBack} />
                Select dates
              </h5>
            </div>
            <div className="col-4 text-right">
              <div className="save" onClick={handleClickBack}>
                Save
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="title-search-block">
        <div className="items-search">
          <form className="form-inline date-selection">
            <div className="input-group">
              <input id="overviewDateFilter" type="text" className="form-control boxed rounded-s" />
              <div className="icon-wrapper" onClick={handleClickIcon}>
                <CalendarIcon className="calendar-icon" />
                <span className="btn-date input-group-btn">{Number(searchStartDate.slice(8, 10))}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
