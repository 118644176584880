import React, { ReactElement } from 'react';

import { Badges, Symbol, Wrapper } from './styles';

export type ConfirmationMessageProps = {
  message: string;
  success: boolean;
};

const ConfirmationMessage = ({ message, success }: ConfirmationMessageProps): ReactElement => {
  if (!message) {
    return <></>;
  }

  return (
    <Wrapper>
      <Badges>
        <Symbol success={success}>{message}</Symbol>
      </Badges>
    </Wrapper>
  );
};

export default ConfirmationMessage;
