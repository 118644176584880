export const AccountSettingsItems = [
  {
    label: 'Account name',
    formName: 'accountName',
  },
  {
    label: 'Address',
    formName: 'accountAddress',
  },
];

export const BusinessInfoItems = [
  {
    label: 'What do you sell',
    formName: 'typeOfProduct',
  },
  {
    label: 'Business Type',
    formName: 'businessType',
  },
  {
    label: 'ABN/ACN',
    formName: 'taxId',
  },
  {
    label: 'Tax Country',
    formName: 'taxCountry',
  },
  {
    label: 'Country',
    formName: 'country',
  },
];

export const PublicInfoItems = [
  {
    label: 'Business name',
    inputProps: {
      name: 'businessName',
      type: 'text',
      placeholder: 'Business name',
    },
  },
  {
    label: 'Trading/brand name',
    inputProps: {
      name: 'brandName',
      type: 'text',
      placeholder: 'trading name',
    },
  },
  {
    label: 'Website',
    inputProps: {
      name: 'website',
      type: 'text',
      placeholder: 'https://',
      title: 'https://www.example.com',
    },
  },
  {
    label: 'Support site',
    inputProps: {
      name: 'supportSite',
      type: 'text',
      placeholder: 'https://',
      title: 'https://www.example.com',
    },
  },
  {
    label: 'Company contact email',
    inputProps: {
      name: 'contactEmail',
      type: 'email',
      placeholder: 'Email',
    },
  },
  {
    label: 'Company support email',
    inputProps: {
      name: 'supportEmail',
      type: 'email',
      placeholder: 'Support email',
    },
  },
  {
    label: 'Phone',
    inputProps: {
      name: 'phoneNo',
      type: 'text',
      placeholder: 'Phone number',
    },
  },
];

export const YourInfoItems = [
  {
    label: 'First name',
    inputProps: {
      name: 'firstName',
      placeholder: 'First name',
    },
  },
  {
    label: 'Surname',
    inputProps: {
      name: 'lastName',
      placeholder: 'Last name',
    },
  },
  {
    label: 'Phone number',
    inputProps: {
      name: 'phoneNo',
      placeholder: 'Phone number',
    },
  },
];

export const TabOptions = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Configurations',
    value: 'configurations',
  },
  {
    text: 'Notifications',
    value: 'notifications',
  },
  {
    text: 'Emails',
    value: 'emails',
  },
];
