import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

type ChildProps = {
  borderWidth: string;
};

export const Child = styled.div<ChildProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  border: ${(props) => `${props.borderWidth} solid #fff`};
  border-color: ${({ theme }) => (theme.isLaddrB2C ? '#00ff00' : 'var(--lp-colors-medium-blue-600)')} transparent
    transparent;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  &:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  &:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  &:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
