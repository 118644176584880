import Alert from 'Components/Alert/Alert';
import SelectCountry from 'Components/SelectCountry';
import Spinner from 'Components/Spinner/Spinner';
import { Content, LpBox, LpDetails } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setAccount, setMerchantActivated } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { CountryCurrency, Currency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import isNumeric from 'utils/isNumeric';
import validateEmail from 'utils/validateEmail';

import * as s from './styles';
import { CountryType, PayoutType } from './types';

const BankDetails = (): ReactElement => {
  const [currency, setCurrency] = useState<Currency>('AUD');
  const [country, setCountry] = useState<CountryType>(CountryType.AU);
  const [bsb, setBsb] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountHolder, setAccountHolder] = useState<string>('');
  const [emails, setEmails] = useState<string>('');
  const [payoutType, setPayoutType] = useState<PayoutType>(PayoutType.Daily);
  const [validEmails, setValidEmails] = useState<boolean>(true);
  const [validBsb, setValidBsb] = useState<boolean>(true);
  const [validAccountNumber, setValidAccountNumber] = useState<boolean>(true);

  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [merchantName] = useState(() => getMerchantName());

  const { apiBaseUri, merchantId, merchantTradingCountry } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
    merchantTradingCountry: state.merchantTradingCountry,
  }));

  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setCountry(merchantTradingCountry);
    setCurrency(CountryCurrency[merchantTradingCountry]);
  }, [merchantTradingCountry]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setIsClicked(true);
    let isReady = true;

    const notificationEmails = emails.split(',').map((e: string) => e.trim());

    if (emails.length > 0 && notificationEmails.some((e) => validateEmail(e) === false)) {
      isReady = false;
      setValidEmails(false);
    }

    const accNumber = accountNumber.replace(/ /g, '').replace(/-/g, '');
    if (!isNumeric(accountNumber)) {
      setValidAccountNumber(false);
      isReady = false;
    }

    const accNumberLength = accNumber.length;
    if (country === CountryType.NZ && (accNumberLength < 15 || accNumberLength > 16)) {
      setValidAccountNumber(false);
      isReady = false;
    }

    const routingNumber = bsb.replace(/ /g, '').replace(/-/g, '');
    if ((routingNumber.length !== 6 || !isNumeric(routingNumber)) && country === CountryType.AU) {
      setValidBsb(false);
      isReady = false;
    }

    if (!apiBaseUri || !merchantId) {
      isReady = false;
    }

    if (!isReady) return;

    const createBankAccount = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/external-bank-account`;
      const body = {
        accountName: accountHolder,
        accountNumber: accNumber,
        country,
        currency,
        routingNumber: country === CountryType.AU ? routingNumber : '',
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
    };

    const setPayoutEmails = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout`;
      const body = {
        notificationEmails: emails.length > 0 ? notificationEmails : [],
      };
      const options = await getFetchOptions('POST', JSON.stringify(body));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
    };

    const setPayoutFrequency = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout-schedule`;
      const options = await getFetchOptions('POST', JSON.stringify(payoutType));
      const res = await fetch(url, options);
      if (!res.ok) {
        await handleApiError(res);
      }
    };

    try {
      setSubmitLoading(true);
      await Promise.all([createBankAccount(), setPayoutEmails(), setPayoutFrequency()]);
      dispatch(setMerchantActivated(true));
      window.location.href = `/${merchantName}`;
    } catch (e) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setErrorMsg(e?.message || 'Failed to save');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleBack = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatch(setAccount(false));
    history.push(`/${merchantName}/account-setup`);
  };

  const handleChangeBsb = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (isClicked) {
      const tempBsb = value.replace(/ /g, '').replace(/-/g, '');
      setValidBsb(tempBsb.length === 6 && isNumeric(tempBsb));
    }
    setBsb(value);
  };

  const handleChangeEmails = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (isClicked) {
      const emailsArray = value.split(',').map((e: string) => e.trim());
      emailsArray.every((e) => validateEmail(e) === true);
      setValidEmails(emailsArray.some((e) => validateEmail(e) === true));
    }
    setEmails(value);
  };

  const handleChangeAccountNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (isClicked) {
      const tempNumber = value.replace(/ /g, '').replace(/-/g, '');
      if (merchantTradingCountry === CountryType.AU) {
        setValidAccountNumber(isNumeric(tempNumber));
      } else if (merchantTradingCountry === CountryType.NZ) {
        setValidAccountNumber(isNumeric(tempNumber) && tempNumber.length >= 15 && tempNumber.length <= 16);
      }
    }

    setAccountNumber(value);
  };

  return (
    <Content>
      <s.HeaderWrapper>
        <s.Title>Bank Details</s.Title>
        <s.Description>
          Please add your bank account details below so we can pay out funds to you on a rolling basis.
        </s.Description>
      </s.HeaderWrapper>

      {errorMsg.length > 0 && <Alert message={errorMsg} />}

      <LpBox>
        <s.SubTitle>Currency</s.SubTitle>

        <form onSubmit={handleSubmit}>
          <LpDetails>
            <div className="row pt-4 pb-0 pb-md-2">
              <div className="col-sm-6">
                <s.Label>Currency</s.Label>
                <s.Select
                  data-testid="businessType"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value as Currency)}
                >
                  <option data-testid="businessTypeOption1" value={'AUD'}>
                    AUD
                  </option>
                  <option data-testid="businessTypeOption2" value={'NZD'}>
                    NZD
                  </option>
                </s.Select>
              </div>
              <div className="col-sm-6">
                <SelectCountry
                  label="Country"
                  country={country}
                  setCountry={setCountry}
                  selectTestId="currencyCountry"
                  option1TestId="currencyCountryAU"
                  option2TestId="currencyCountryNZ"
                />
              </div>
            </div>

            <s.Hr />

            <s.SubTitle>Business bank account</s.SubTitle>

            {country === CountryType.AU && (
              <div className="pt-4 pb-0 pb-md-2">
                <s.Label>BSB</s.Label>
                <s.Input
                  type="text"
                  data-testid="bsb"
                  required
                  value={bsb}
                  onChange={handleChangeBsb}
                  isValid={validBsb}
                />
                {!validBsb && <s.ErrorMsg>Please enter valid BSB</s.ErrorMsg>}
              </div>
            )}
            <div className="pt-4 pb-0 pb-md-2">
              <s.Label>Account number</s.Label>
              <s.Input
                type="text"
                data-testid="accountNumber"
                required
                value={accountNumber}
                onChange={handleChangeAccountNumber}
                isValid={validAccountNumber}
              />
              {!validAccountNumber && <s.ErrorMsg>Please enter valid account number</s.ErrorMsg>}
            </div>
            <div className="pt-4 pb-0 pb-md-2">
              <s.Label>Account holder name</s.Label>
              <s.Input
                type="text"
                data-testid="accountHolder"
                required
                value={accountHolder}
                onChange={(e) => setAccountHolder(e.target.value)}
              />
            </div>

            <s.Hr />

            <s.SubTitle>Automatic payouts</s.SubTitle>
            <div className="pt-4 pb-0 pb-md-2">
              <s.Label>Frequency of automatic payouts</s.Label>
              <s.InputDescription>
                Payouts will be automatically sent to your bank account on the schedule set out below.
              </s.InputDescription>
              <s.Select
                data-testid="payouts"
                value={payoutType}
                onChange={(e) => setPayoutType(e.target.value as PayoutType)}
              >
                <option data-testid="payoutTypeDaily" value={PayoutType.Daily}>
                  Daily
                </option>
                <option data-testid="payoutTypeWeekly" value={PayoutType.Weekly}>
                  Weekly
                </option>
                <option data-testid="payoutTypeMonthly" value={PayoutType.Monthly}>
                  Monthly
                </option>
              </s.Select>
            </div>

            <div className="pt-4 pb-0 pb-md-2">
              <s.Label>Payout report recipients</s.Label>
              <s.InputDescription>
                Payout reports will be sent via email after each payment is processed. Separate multiple email addresses
                with commas.
              </s.InputDescription>
              <s.Input
                type="text"
                data-testid="payoutEmails"
                value={emails}
                onChange={handleChangeEmails}
                isValid={validEmails}
              />
              {!validEmails && <s.ErrorMsg>Invalid email</s.ErrorMsg>}
            </div>

            <s.Hr />

            <div className="pt-4 pb-0 pb-md-2">
              <s.BtnWrapper>
                <s.CancelBtn onClick={handleBack}>Back to account set-up</s.CancelBtn>
                <s.SubmitBtn data-testid="submitBtn" type="submit" disabled={submitLoading}>
                  Save and continue
                  {submitLoading && (
                    <s.SpinnerWrapper>
                      <Spinner height="20px" width="20px" borderWidth="2px" />
                    </s.SpinnerWrapper>
                  )}
                </s.SubmitBtn>
              </s.BtnWrapper>
            </div>
          </LpDetails>
        </form>
      </LpBox>
    </Content>
  );
};

export default BankDetails;
