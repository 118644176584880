export const TableHeaders = [
  {
    text: 'Order ID',
    width: '140px',
  },
  {
    text: 'Date',
    width: '96px',
  },
  {
    text: 'Amount',
    width: '60px',
  },
  {
    text: 'Description',
    width: '140px',
  },
  {
    text: 'Customer',
    width: '96px',
  },
  {
    text: 'Status',
    width: '106px',
  },
  {
    text: '',
    width: '30px',
  },
];
