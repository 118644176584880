import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .modal-footer {
    display: none !important;
  }
  .modal-body.modal-body-reset {
    padding-bottom: 0 !important;
  }
  @media (max-width: 768px) {
    .modal-dialog {
      margin-top: 45px !important;
    }
  }
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: #000;
`;

export const NoPayment = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: #6c7488;
`;

type TableRowType = {
  top?: string;
  bottom?: string;
  showBorder?: boolean;
  desktopOnly?: boolean;
};

export const TableRow = styled.div<TableRowType>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ top }) => top ?? '0'};
  margin-bottom: ${({ bottom }) => bottom ?? '0'};
  padding-top: ${({ showBorder }) => (showBorder ? '18px' : '0')};
  border-top: ${({ showBorder }) => (showBorder ? '1px solid #e6eaee' : 'none')};
  position: relative;
  @media (max-width: 768px) {
    display: ${(props) => (props.desktopOnly ? 'none' : 'flex')};
    flex-direction: column;
  }
`;

type CellType = {
  width: string;
  color?: string;
  order?: number;
  fontSize?: string;
};

export const Cell = styled.div<CellType>`
  font-size: ${({ fontSize }) => fontSize ?? '13px'};
  line-height: 21px;
  color: ${({ color }) => color ?? '#6c7488'};
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;
    order: ${({ order }) => order ?? 0};
    padding-left: 44px;
    &.show-more {
      padding: 0;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: calc(50% - 15px);
      margin-bottom: 0;
    }
  }
`;

export const OrderCell = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: ${({ color }) => color ?? '#6c7488'};
  display: flex;
  align-items: center;
  width: 140px;
  max-width: 140px;
  min-height: 36px;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;
    order: -3;
  }
`;

export const FeatureIcon = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border: 1px solid #989fb2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderId = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  margin-left: 8px;
  width: 96px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

export const Description = styled.div`
  text-overflow: ellipsis;
  width: 136px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  color: #354052;
  font-size: 13px;
  line-height: 36px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: 100%;
    padding-left: 44px;
  }
`;

export const Created = styled.span`
  background: #fef2e4;
  color: #f5ab4c;
  display: inline-block;
  padding: 0 15px;
  height: 27px;
  line-height: 27px;
  font-size: 13px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 0;
    height: 21px;
    line-height: 21px;
  }
`;

export const Cancelled = styled.span`
  background: #f8e1e4;
  color: #d3354a;
  display: inline-block;
  padding: 0 15px;
  height: 27px;
  line-height: 27px;
  font-size: 13px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 0;
    height: 21px;
    line-height: 21px;
  }
`;

export const Dots = styled(DotsIcon)`
  cursor: pointer;
  width: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ArrowRight = styled(ArrowRightIcon)`
  cursor: pointer;
  width: 30px;
  transform: rotate(270deg);
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Ul = styled.ul`
  position: absolute;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  border-radius: 3px;
  left: -180px;
  top: 0;
  z-index: 1000;
  margin: 0;
  padding: 20px 24px;
`;

export const Li = styled.li`
  list-style: none;
  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const LinkBtn = styled.button`
  background-color: transparent;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;
  color: #6c7488;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    text-decoration: none;
  }
`;

export const ModalText = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #354052;
`;

export const BtnGroup = styled.div`
  padding: 16px 0 40px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CancelBtn = styled.button`
  padding: 12px 29px 10px;
  background: #ffffff;
  border: 1px solid #cacdd1;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #5f697c;
  cursor: pointer;
  @media (max-width: 768px) {
    order: 2;
  }
`;

export const ConfirmBtn = styled.button<{ isDanger?: boolean }>`
  padding: 12px 29px 10px;
  margin-left: 15px;
  border: ${(props) =>
    props.isDanger
      ? '1px solid #D3354A'
      : props.theme.isLaddrB2C
      ? '1px solid #00FF00'
      : '1px solid var(--lp-colors-medium-blue-600)'};
  background: ${(props) =>
    props.isDanger ? '#D3354A' : props.theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)'};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:disabled {
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    order: 1;
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

export const ListSpinner = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  padding-top: 2px;
`;

export const Content = styled.article`
  padding: 30px 22px 15px 22px;
  display: block;

  @media (min-width: 1200px) {
    padding: 18px 60px 15px 60px;
  }

  @media (max-width: 768px) {
    padding: 0 0 15px 0;
    position: relative;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;
