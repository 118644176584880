import { BrandingAPIResponse } from 'Components/Settings/types';
import { useEffect } from 'react';

const useFavicon = (branding: BrandingAPIResponse | null, isLaddrB2C: boolean | null) => {
  useEffect(() => {
    const faviconEl = document.getElementById('merchant-dashboard-favicon') as HTMLLinkElement;
    if (branding) {
      faviconEl.href = isLaddrB2C ? '/favicon-laddr.ico' : branding?.iconUri || '/favicon.ico?v=1';
    } else {
      faviconEl.href = '';
    }
  }, [branding, isLaddrB2C]);
};

export default useFavicon;
