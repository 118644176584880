import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 50vh;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #354052;
  font-family: 'Acumin Regular', Arial, sans-serif;
`;
