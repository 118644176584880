import './OrderList.scss';

import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setPaymentDetails } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import getMerchantName from 'utils/getMerchantName';

import { toCurrency } from '../../utils/currency';
import getDateFormat from '../../utils/getDateFormat';
import Alert from '../Alert/Alert';
import Spinner from '../Spinner/Spinner';
import { ITEM_ROWS_OBJ } from './itemRowsObj';
import { OrderIdBtn } from './styles';
import { RefundAPIResponseType } from './types';

export type OrderListProps = {
  orders: RefundAPIResponseType[];
};

const OrderList = ({ orders }: OrderListProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [redirectToPayment, setRedirectToPayment] = useState<boolean>(false);
  const [merchantName] = useState(() => getMerchantName());

  const dispatch: Dispatch<any> = useDispatch();

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  const setPaymentDetailsCB = useCallback((s, a) => dispatch(setPaymentDetails(s, a)), [dispatch]);

  const handleClickOrderBtn = async (e: MouseEvent<HTMLButtonElement>, transactionId: string): Promise<void> => {
    e.preventDefault();

    if (!apiBaseUri || !merchantId) {
      return;
    }

    setIsLoading(true);
    const url = `${apiBaseUri}/dashboard/merchant/${merchantId}/payments/query?sort=-createdAt&transactionIds=${transactionId}`;
    const options = await getFetchOptions();
    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.json();
      })
      .then((response) => {
        if (Array.isArray(response) && response.length === 1) {
          return response[0];
        }
        throw Error();
      })
      .then((response) => {
        setRedirectToPayment(true);
        setPaymentDetailsCB(true, response);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (hasError) {
    return <Alert message="Failed to get the refund details" />;
  }

  if (isLoading) {
    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  if (redirectToPayment) {
    return <Redirect to={`/${merchantName}/payments`} push />;
  }

  return (
    <div className="order-list refund-list">
      <div className="lp-box">
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {ITEM_ROWS_OBJ.map((row, rowIndex) => (
                  <div key={rowIndex} className={`item-col item-col-header item-col-title ${row.class}`}>
                    <div className={`${row.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{row.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {orders.map((order, orderIndex) => (
              <li key={orderIndex} className="item active-item">
                <div className="item-row item-row-reset-m">
                  <div className="item-col item-col-order item-col-xsrr">
                    <div className="media">
                      <div className="feature-icon wicons">
                        <OrderIdIcon className="order-id-icon" />
                      </div>
                      <div className="order-id mt-1">
                        <OrderIdBtn onClick={(e) => handleClickOrderBtn(e, order.transactionId)}>
                          {order.merchantOrderInternalOrderId}
                        </OrderIdBtn>
                      </div>
                    </div>
                  </div>

                  <div className="item-col item-col-sales customer-mail custom-width-mail item-col-lgr">
                    <div className="item text-left mt-1">
                      <h2 className="price pl-m-payment customer-email">{order.customerEmail}</h2>
                    </div>
                  </div>

                  <div className="item-col item-col-date item-col-lgr">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {getDateFormat({ time: order.createdAt }).date}
                        <span className="d-none d-md-inline time">{getDateFormat({ time: order.createdAt }).time}</span>
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-amount">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {typeof order.amount === 'number' ? toCurrency(order.amount, order.currency) : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-date">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {order.payoutArrivalAt ? getDateFormat({ time: order.payoutArrivalAt }).date : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-amount">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {typeof order.payoutAmount === 'number' ? toCurrency(order.payoutAmount, order.currency) : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
