import styled from '@emotion/styled';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  height: 22px;
  margin: 12px 8px 0px 16px;
  color: #6c748a;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #505bcc;
    path {
      fill: #505bcc;
    }
  }
`;

export const Title = styled.div`
  color: inherit;
  line-height: 22px;
  font-size: 15px;
`;
