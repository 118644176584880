import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as OrderIdIcon } from 'assets/svg/order-id.svg';
import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setPaymentDetails } from 'redux/Actions/actions';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import * as s from './OrderList.styles';
import { ITEM_ROWS_OBJ } from './itemRowsObj';
import { PaymentAPIResponseType, TransactionStatus } from './types';

export const getStatusClass = (status: TransactionStatus) => {
  const includes = (array: TransactionStatus[]) => array.includes(status);

  if (includes(['accepted', 'pending', 'not_captured'])) return 'pending';
  if (includes(['paid'])) return 'success';
  if (includes(['failed'])) return 'error';
  if (includes(['disputed'])) return 'warning';

  return 'unpaid';
};

export const getStatusLabel = (status: TransactionStatus) =>
  status === 'pending' ? 'active' : status.replace('_', ' ');

export type OrderListProps = {
  orders: PaymentAPIResponseType[];
};

const OrderList = ({ orders }: OrderListProps): ReactElement => {
  const [activeTransactionId, setActiveTransactionId] = useState<string>('');

  const dispatch: Dispatch<any> = useDispatch();

  const setPaymentDetailsCB = useCallback((s, a) => dispatch(setPaymentDetails(s, a)), [dispatch]);

  const handleClickOutside = () => {
    setActiveTransactionId('');
  };

  const handleClickBtn = (e: MouseEvent<HTMLButtonElement>, transactionId: string): void => {
    e.preventDefault();
    const paymentDetails = orders.find((o) => o.transactionId === transactionId) as PaymentAPIResponseType;
    setPaymentDetailsCB(true, paymentDetails);
    window.scrollTo(0, 0);
  };

  const handleClickIcon = (e: MouseEvent<HTMLDivElement>, transactionId: string): void => {
    e.preventDefault();
    setActiveTransactionId(transactionId);
  };

  useClickOutsideWithoutRef(handleClickOutside, [
    'item-actions-block',
    'item-actions-list-link',
    'dots-icon',
    'arrow-right-icon',
    'payments-link-btn',
  ]);

  return (
    <s.OrderListWrapper>
      <div className="lp-box">
        <div className="card items">
          <ul className="item-list striped">
            <li className="item item-list-header">
              <div className="item-row">
                {ITEM_ROWS_OBJ.map((row, rowIndex) => (
                  <div key={rowIndex} className={`item-col item-col-header item-col-title ${row.class}`}>
                    <div className={`${row.noOverflow ? 'no-overflow' : ''}`}>
                      <span>{row.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
            {orders.map((order) => (
              <li key={order.transactionId} className="item active-item">
                <div className="item-row item-row-reset-m">
                  <div className="item-col item-col-order">
                    <div className="media">
                      <div className="feature-icon wicons">
                        <OrderIdIcon className="order-id-icon" />
                      </div>
                      <div className="order-id mt-1">
                        <s.OrderIdBtn onClick={(e) => handleClickBtn(e, order.transactionId)}>
                          {order.merchantOrderInternalOrderId}
                        </s.OrderIdBtn>
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-xsrr">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {getDateFormat({ time: order.createdAt }).date}
                        <span className="d-none d-md-inline time-span">
                          {getDateFormat({ time: order.createdAt }).time}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-xsrr item-col-amount">
                    <div className="item text-left mt-1">
                      <div className="price">
                        {typeof order.transactionAmount === 'number'
                          ? toCurrency(order.transactionAmount, order.currency)
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="item-col item-col-xsrr">
                    <div className="item text-left mt-1">
                      <div className="price">{!!order.transactionPayType.payCardId ? 'Full' : 'Split'} payment</div>
                    </div>
                  </div>
                  <div className="item-col item-col-xsrr">
                    <div className="item text-left mt-1">
                      <div className="price">{order.merchantOrderType}</div>
                    </div>
                  </div>
                  <div className="item-col item-col-lgr">
                    <div className="item text-left mt-1">
                      <div className="price">{order.merchantOrderBilling?.name || order.customerFullName}</div>
                    </div>
                  </div>
                  <div className="item-col item-col-lgr">
                    <div className="item text-left mt-1">
                      <div className="price">{order.customerInternalCustomerId}</div>
                    </div>
                  </div>
                  <div className="item-col item-col-cxl item-col-status">
                    <div className="item text-left mt-1">
                      <div className={`price status ${getStatusClass(order.transactionStatus)}`}>
                        {getStatusLabel(order.transactionStatus)}
                      </div>
                    </div>
                  </div>
                  <div className="item-col fixed item-col-actions-dropdown">
                    <div className="item-actions-dropdown">
                      <div className="icon-wrapper" onClick={(e) => handleClickIcon(e, order.transactionId)}>
                        <DotsIcon className="dots-icon" />
                        <ArrowDownIcon className="arrow-right-icon" />
                      </div>
                      <div
                        className={`item-actions-block ${activeTransactionId === order.transactionId ? 'active' : ''}`}
                      >
                        <s.ListActionsUl>
                          <s.ListActionsLi>
                            <s.LinkBtn
                              className="payments-link-btn"
                              onClick={(e) => handleClickBtn(e, order.transactionId)}
                            >
                              View details
                            </s.LinkBtn>
                          </s.ListActionsLi>
                          {order.transactionStatus !== 'not_captured' && (
                            <s.ListActionsLi>
                              <s.LinkBtn
                                className="payments-link-btn"
                                onClick={(e) => handleClickBtn(e, order.transactionId)}
                              >
                                Refund payment
                              </s.LinkBtn>
                            </s.ListActionsLi>
                          )}
                          {order.transactionStatus === 'not_captured' && (
                            <s.ListActionsLi>
                              <s.LinkBtn
                                className="payments-link-btn"
                                onClick={(e) => handleClickBtn(e, order.transactionId)}
                              >
                                Capture pre-authorised payment
                              </s.LinkBtn>
                            </s.ListActionsLi>
                          )}
                          {order.transactionStatus === 'not_captured' && (
                            <s.ListActionsLi>
                              <s.LinkBtn
                                className="payments-link-btn"
                                onClick={(e) => handleClickBtn(e, order.transactionId)}
                              >
                                Cancel pre-authorised payment
                              </s.LinkBtn>
                            </s.ListActionsLi>
                          )}
                        </s.ListActionsUl>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </s.OrderListWrapper>
  );
};

export default OrderList;
