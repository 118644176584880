import HeaderTile from 'Components/HeaderTitle/HeaderTitle';
import MessageWithModal from 'Components/MessageWithModal';
import TabContent from 'Components/Tabs/TabContent';
import Tabs from 'Components/Tabs/Tabs';
import TransferSettings from 'Components/Transfer/TransferSettings';
import { APPLE_DOMAIN_ASSOCIATION_FILE_URL } from 'Constants/Constants';
import { Content } from 'Constants/styles';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import { hasPayoutAccess } from 'utils/userPermissions';

import AccountSettings from './AccountSettings';
import ApplepayConfigurationModal from './ApplepayConfigurationModal';
import Branding from './Branding';
import BusinessInformation from './BusinessInformation';
import CheckoutPaymentOptions from './CheckoutPaymentOptions';
import { TabOptions } from './Constants';
import CustomContent from './CustomContent';
import Emails from './Emails';
import OrderNotifications from './OrderNotifications';
import PayoutReports from './PayoutReports';
import PublicInformation from './PublicInformation';
import You from './You';
import { HeaderWrapper, SettingsTab } from './styles';

const Settings = () => {
  const [successMsg, setSuccessMsg] = useState<boolean>(false);
  const [showApplepayConfigurationModal, setShowApplepayConfigurationModal] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);

  const { merchantId, apiBaseUri, isLaddrB2C, userPermissions, userRole } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    isLaddrB2C: state.isLaddrB2C,
    abacusEnabled: state.abacusEnabled,
    userPermissions: state.userPermissions,
    userRole: state.userRole,
  }));

  const handleApplePayConfigureCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowApplepayConfigurationModal(false);
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const handleApplePayConfigureSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    window.open(APPLE_DOMAIN_ASSOCIATION_FILE_URL, '_blank');

    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  useEffect(() => {
    document.title = 'Settings - April';
  }, []);

  const apiInfo = {
    merchantId,
    apiBaseUri,
  };

  if (!apiBaseUri || !merchantId) {
    return <></>;
  }

  return (
    <SettingsTab>
      <HeaderWrapper>
        <HeaderTile data-testid="Settings" title="Settings" />
      </HeaderWrapper>
      <Tabs tabOptions={TabOptions}>
        <TabContent>
          <Content data-testid="settings-general-tab">
            <AccountSettings {...apiInfo} />
            {isLaddrB2C && <TransferSettings />}
            <PublicInformation {...apiInfo} />
            <BusinessInformation {...apiInfo} />
            <You {...apiInfo} />
          </Content>
        </TabContent>
        <TabContent>
          <Content data-testid="settings-custom-content-tab">
            {successMsg && (
              <MessageWithModal
                title={'Checkout payment options were updated successfully'}
                description={`Further action may be required if you have enabled Apple Pay.`}
                success={true}
                backgroundColor="#e9f6dc"
                borderColor="#9fd869"
                handleClose={() => setSuccessMsg(false)}
                modalText="Learn more"
                modalToggle={setShowApplepayConfigurationModal}
              />
            )}
            {!isLaddrB2C && <Branding {...apiInfo} />}
            {!isLaddrB2C && <CheckoutPaymentOptions {...apiInfo} setSuccessMsg={setSuccessMsg} />}
            <CustomContent {...apiInfo} />
          </Content>
        </TabContent>
        <TabContent>
          <Content data-testid="settings-notifications-tab">
            {hasPayoutAccess(userRole, userPermissions) && <PayoutReports {...apiInfo} />}
            <OrderNotifications {...apiInfo} />
          </Content>
        </TabContent>
        <TabContent>
          <Content data-testid="settings-emails-tab">
            <Emails {...apiInfo} />
          </Content>
        </TabContent>
      </Tabs>
      {showApplepayConfigurationModal && (
        <ApplepayConfigurationModal
          apiBaseUri={apiBaseUri}
          merchantId={merchantId}
          handleApplePayConfigureSubmit={handleApplePayConfigureSubmit}
          handleApplePayConfigureCancel={handleApplePayConfigureCancel}
          modalErrorMsg={modalErrorMsg}
          modalIsLoading={modalIsLoading}
        />
      )}
    </SettingsTab>
  );
};

export default Settings;
