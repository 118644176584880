const convertUTCtoLocal = (time: string): string => {
  const date = new Date(time);
  const tzo = -date.getTimezoneOffset();
  const hours = String(Math.floor(Math.abs(tzo / 60))).padStart(2, '0');
  const mins = String(Math.floor(Math.abs(tzo % 60))).padStart(2, '0');
  const diff = tzo >= 0 ? '+' : '-';

  const result = time.slice(0, 19) + `${diff}${hours}:${mins}`;
  return encodeURIComponent(result);
};

export default convertUTCtoLocal;
