import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;

export const Small = styled.small<{ isValid: boolean }>`
  display: block;
  position: absolute;
  margin-top: 4px;
  color: ${({ isValid }) => (isValid ? '#6c7488' : 'red')};
`;
