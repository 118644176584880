export type EmailValueType = {
  title: string;
  blob: string;
  loading: boolean;
  leading: string;
  trailing: string;
  errorMsg: string;
  isEmailValid: boolean;
  email: string;
};
const ReminderText = 'payment reminder';
const SuccessText = 'payment success';
const LeadingText = 'email leading text.';
const TrailingText = 'email trailing text.';

const Leading = [
  `Second ${ReminderText} ${LeadingText}`,
  `Second ${SuccessText} ${LeadingText}`,
  `Third ${ReminderText} ${LeadingText}`,
  `Third ${SuccessText} ${LeadingText}`,
  `Final ${ReminderText} ${LeadingText}`,
  `Final ${SuccessText} ${LeadingText}`,
];

const Trailing = [
  `Second ${ReminderText} ${TrailingText}`,
  `Second ${SuccessText} ${TrailingText}`,
  `Third ${ReminderText} ${TrailingText}`,
  `Third ${SuccessText} ${TrailingText}`,
  `Final ${ReminderText} ${TrailingText}`,
  `Final ${SuccessText} ${TrailingText}`,
];

const Title = [
  `Second ${ReminderText}`,
  `Second ${SuccessText}`,
  `Third ${ReminderText}`,
  `Third ${SuccessText}`,
  `Final ${ReminderText}`,
  `Final ${SuccessText}`,
];

const ReminderBlob = 'Reminder email on upcoming';
const SuccessBlob = 'Email to notify success of';

const Blob = [
  `${ReminderBlob} second payment.`,
  `${SuccessBlob} second payment.`,
  `${ReminderBlob} third payment.`,
  `${SuccessBlob} third payment.`,
  `${ReminderBlob} final payment.`,
  `${SuccessBlob} final payment.`,
];

export const setEmailInitValues = (): EmailValueType[] => {
  const array: EmailValueType[] = [];

  for (let i = 0; i < 6; i++) {
    const obj = {
      title: Title[i],
      blob: Blob[i],
      loading: false,
      leading: Leading[i],
      trailing: Trailing[i],
      errorMsg: '',
      isEmailValid: false,
      email: '',
    };
    array.push({ ...obj });
  }

  return array;
};

type InstalmentObjType = {
  [key: string]: number;
};
const InstalmentObj: InstalmentObjType = {
  0: 2,
  1: 2,
  2: 3,
  3: 3,
  4: 4,
  5: 4,
};

export const getInstalmentNumber = (index: number): number => InstalmentObj[index];
