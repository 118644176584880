import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setBranding } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { getInitialBrandInfo } from './getInitialState';
import { EditBtn, InvalidText, LongWord } from './styles';
import { BrandingAPIResponse } from './types';

type BrandingProps = {
  apiBaseUri: string;
  merchantId: string;
};

const Branding = ({ apiBaseUri, merchantId }: BrandingProps): ReactElement => {
  const [unconfirmedBrandInfo, setUnconfirmedBrandInfo] = useState<BrandingAPIResponse>(() => getInitialBrandInfo());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [fetchTime, setFetchTime] = useState<number>(0);
  const [validLogoUri, setValidLogoUri] = useState<boolean>(true);
  const [validIconUri, setValidIconUri] = useState<boolean>(true);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const branding = useSelector((state: ReduxStateType) => state.branding);

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    setIsLoading(true);
    const fetchData = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/branding`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response: BrandingAPIResponse) => {
          if (isMounted) {
            dispatch(setBranding(response));
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message);
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, fetchTime, dispatch]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setIsSubmitted(true);
    let isReady = true;
    if (!unconfirmedBrandInfo.iconUri || !unconfirmedBrandInfo.iconUri.includes('https://')) {
      setValidIconUri(false);
      isReady = false;
    }

    if (!unconfirmedBrandInfo.logoUri || !unconfirmedBrandInfo.logoUri.includes('https://')) {
      setValidLogoUri(false);
      isReady = false;
    }

    if (!isReady) {
      return;
    }

    const body = JSON.stringify(unconfirmedBrandInfo);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/branding`;
    const options = await getFetchOptions('POST', body);

    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setBodyOverflow('auto');
        setShowModal(false);
        setFetchTime(Date.now());
      })
      .catch((e) => {
        setModalErrorMsg(e.message);
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalErrorMsg('');
    setValidLogoUri(true);
    setValidIconUri(true);
    setUnconfirmedBrandInfo({
      logoAltText: branding?.logoAltText ?? '',
      logoUri: branding?.logoUri ?? '',
      iconUri: branding?.iconUri ?? '',
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (isSubmitted && name === 'logoUri') {
      setValidLogoUri(value.includes('https://'));
    }
    if (isSubmitted && name === 'iconUri') {
      setValidIconUri(value.includes('https://'));
    }
    setUnconfirmedBrandInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="BrandingSettings">
            Branding Settings
          </H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {errorMsg.length === 0 && (
            <EditBtn data-testid="edit-branding-btn" onClick={toggleModal}>
              Edit branding
            </EditBtn>
          )}
        </div>
      </div>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <LpDetails>
          <div className="row pt-4">
            <div className="col-sm-4 col-xl-3">
              <Label>Favicon uri</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">{branding?.iconUri ?? ''}</LongWord>
          </div>
          <div className="row pt-4">
            <div className="col-sm-4 col-xl-3">
              <Label>Logo uri</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">{branding?.logoUri ?? ''}</LongWord>
          </div>
          <div className="row pt-4">
            <div className="col-sm-4 col-xl-3">
              <Label>Logo alt text</Label>
            </div>
            <LongWord className="col-sm-8 col-xl-9">{branding?.logoAltText ?? ''}</LongWord>
          </div>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit branding settings"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && (
            <LpDetails className="pt-2 pb-0">
              <div className="form-item">
                <div className="form-field">
                  <Label>Favicon uri</Label>
                  <Input
                    type="text"
                    name="iconUri"
                    value={unconfirmedBrandInfo.iconUri ?? ''}
                    onChange={handleChange}
                    required
                    placeholder="https://www.example.com/icon.png"
                    data-testid="favicon-uri-input"
                  />
                  {!validIconUri && <InvalidText>Invalid favicon uri. Please starts with https://</InvalidText>}
                </div>
              </div>

              <div className="form-item">
                <div className="form-field">
                  <Label>Logo uri</Label>
                  <Input
                    type="text"
                    name="logoUri"
                    value={unconfirmedBrandInfo.logoUri ?? ''}
                    onChange={handleChange}
                    required
                    placeholder="https://www.example.com/logo.png"
                    data-testid="logo-uri-input"
                  />
                  {!validLogoUri && <InvalidText>Invalid logo uri. Please starts with https://</InvalidText>}
                </div>
              </div>

              <div className="form-item">
                <div className="form-field">
                  <Label>Logo alt text</Label>
                  <Input
                    type="text"
                    name="logoAltText"
                    value={unconfirmedBrandInfo.logoAltText ?? ''}
                    onChange={handleChange}
                    placeholder="Logo alt text"
                    data-testid="logo-alt-text-input"
                  />
                </div>
              </div>
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default Branding;
