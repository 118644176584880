import { InputHTMLAttributes } from 'react';

import styled from '@emotion/styled';

export const Label = styled.label`
  color: #191e33;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  display: block;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  padding-top: 17px;
  margin-bottom: 17px;
  @media (max-width: 960px) {
    padding-top: 0;
    padding-left: 45px;
  }
  @media (max-width: 768px) {
    padding: 20px 15px 0 50px;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  margin-bottom: 17px;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  line-height: 21px;
`;

export const Description = styled.div`
  position: relative;
  color: #6c7488;
  font-size: 16px;
  line-height: 19px;
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
}

export const Input = styled.input<InputProps>`
  padding: 13px 17px 8px;
  outline: none;
  background-color: #fff;
  border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #dce3eb')};
  border-radius: 3px;
  width: 100%;
  color: #000;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.3);
  box-sizing: border-box;
  &:focus {
    border: ${({ isValid }) => (isValid === false ? '1px solid red' : '1px solid #505bcc')};
    box-shadow: none;
  }
`;

export const InputDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #6c7488;
  margin-bottom: 8px;
`;

export const Select = styled.select`
  height: 44px;
  font-size: 15px;
  display: block;
  width: 100%;
  padding: 6px 12px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dce3eb;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
`;

export const ErrorMsg = styled.div`
  color: red;
  text-align: right;
  margin-top: 4px;
  font-size: 14px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CancelBtn = styled.button`
  height: 45px;
  border: 1px solid #d1d3d7;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.56);
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 35px 9px 40px;

  &:focus {
    outline: none;
  }
`;

export const SubmitBtn = styled.button`
  height: 45px;
  display: flex;
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  background: ${({ theme }) => (theme.isLaddrB2C === true ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  align-items: center;
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 35px 9px 40px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:focus {
    outline: none;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
`;

export const Hr = styled.hr`
  margin: 40px 0;
  border-top: 1px solid #e5e5e5;
`;
