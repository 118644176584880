import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';

import OrderDetails from './OrderDetails';
import Payments from './Payments';
import { Wrapper } from './styles';

const PaymentsContainer = () => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const { searchStartDate, searchEndDate, showPaymentDetailsView } = useSelector((state: ReduxStateType) => ({
    searchStartDate: state.searchStartDate,
    searchEndDate: state.searchEndDate,
    showPaymentDetailsView: state.showPaymentDetailsView,
  }));

  useEffect(() => {
    document.title = 'Payments - April';
  }, []);

  /* when dates are changed, need to make sure activePage is 1 in the url */
  useEffect(() => {
    if (!searchStartDate || !searchEndDate) {
      return;
    }
    setStartDate(searchStartDate);
    setEndDate(searchEndDate);
    setActivePage(1);
  }, [searchStartDate, searchEndDate]);

  return (
    <Wrapper>
      {!showPaymentDetailsView && (
        <Payments
          pageCount={pageCount}
          setPageCount={setPageCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pageStartIndex={pageStartIndex}
          setPageStartIndex={setPageStartIndex}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {showPaymentDetailsView && <OrderDetails />}
    </Wrapper>
  );
};

export default PaymentsContainer;
