import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { BusinessInfoItems } from './Constants';
import { getInitialBusinessInfo } from './getInitialState';
import { EditBtn } from './styles';
import { BusinessInfoSettingsAPIResponse } from './types';

type BusinessInfoProps = {
  apiBaseUri: string;
  merchantId: string;
};

const BusinessInformation = ({ apiBaseUri, merchantId }: BusinessInfoProps): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [businessInfo, setBusinessInfo] = useState<BusinessInfoSettingsAPIResponse>(() => getInitialBusinessInfo());
  const [unconfirmedBusinessInfo, setUnconfirmedBusinessInfo] = useState<BusinessInfoSettingsAPIResponse>(() =>
    getInitialBusinessInfo(),
  );
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/business`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          if (isMounted) {
            setBusinessInfo(response);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to fetch business information');
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, time]);

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalErrorMsg('');
    setUnconfirmedBusinessInfo({ ...businessInfo });
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
    setUnconfirmedBusinessInfo(() => getInitialBusinessInfo());
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const body = JSON.stringify(unconfirmedBusinessInfo);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/business`;
    const options = await getFetchOptions('POST', body);

    setModalIsLoading(true);

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setTime(Date.now());
        setBodyOverflow('auto');
        setShowModal(false);
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to update business information');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUnconfirmedBusinessInfo((prev) => ({
      ...prev,
      typeOfProduct: e.target.value,
    }));
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="BusinessInformation">
            Business information
          </H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {errorMsg.length === 0 && <EditBtn onClick={toggleModal}>Edit business information</EditBtn>}
        </div>
      </div>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {errorMsg.length === 0 && !isLoading && (
        <LpDetails className="lp-details">
          {BusinessInfoItems.map((item, index) => {
            if (item.formName === 'taxId') {
              return (
                <div className="row pt-4" key={index}>
                  <div className="col-sm-4 col-xl-3">
                    <Label>{businessInfo.taxCountry === 'NZ' ? 'NZBN' : 'ABN/ACN'}</Label>
                  </div>
                  <div className="col-sm-8 col-xl-9" data-testid="business-info-taxId">
                    {businessInfo.taxId}
                  </div>
                </div>
              );
            }
            return (
              <div className="row pt-4" key={index}>
                <div className="col-sm-4 col-xl-3">
                  <Label>{item.label}</Label>
                </div>
                <div className="col-sm-8 col-xl-9" data-testid={`business-info-${item.formName}`}>
                  {(businessInfo as any)[item.formName]}
                </div>
              </div>
            );
          })}
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit Business Information"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && (
            <div className="lp-details pt-2 pb-0">
              <div className="form-item">
                <div className="form-field">
                  <Label>What do you sell</Label>
                  <Input
                    type="text"
                    name="typeOfProduct"
                    value={unconfirmedBusinessInfo.typeOfProduct ?? ''}
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default BusinessInformation;
