import React, { ReactElement } from 'react';

import { Child, Wrapper } from './Spinner.style';

export type SpinnerType = {
  width?: string;
  height?: string;
  borderWidth?: string;
};

const array = [0, 1, 2, 3];

const Spinner = ({ width = '40px', height = '40px', borderWidth = '4px' }: SpinnerType): ReactElement => (
  <Wrapper style={{ width, height }}>
    {array.map((item) => (
      <Child key={item} borderWidth={borderWidth} />
    ))}
  </Wrapper>
);

export default Spinner;
