import { PrimaryBtn } from 'Constants/styles';

import styled from '@emotion/styled';

export const DesktopPrimaryBtn = styled(PrimaryBtn)`
  display: inline-block;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const MobilePrimaryBtn = styled(PrimaryBtn)`
  display: none;
  width: 100%;
  @media (max-width: 480px) {
    display: block;
  }
`;

export const EditBtn = styled.button`
  cursor: pointer;
  padding: 13px 38px 10px 38px;
  background-color: #fff;
  border: 1px solid #d1d3d7;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #6c7488;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  &:hover {
    color: #212529;
  }
`;

export const Wrapper = styled.div`
  .bank-icon {
    height: 15px;
    width: 15px;
  }
  .d-none {
    display: none;
  }
  @media (min-width: 768px) {
    .d-md-inline {
      display: inline;
    }
  }
`;

export const Primary = styled.span`
  background-color: rgba(39, 203, 204, 0.2);
  color: #017f7f;
  padding: 6px 15px 1px;
  text-align: center;
  line-height: 19px;
  font-size: 15px;
  text-transform: uppercase;
  margin-left: 3%;
  @media (min-width: 769px) {
    margin-left: 16px;
  }
`;
