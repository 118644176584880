import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setFetchTimestamp } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import setBodyOverflow from 'utils/setBodyOverflow';

import { AccountsEditOptions } from './Constants';
import { ModalSpinner, Select } from './styles';

type Props = {
  setShowEditBankModal(s: boolean): void;
  editAccId: string;
};

const EditBankModal = ({ setShowEditBankModal, editAccId }: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isChangedPrimary, setIsChangedPrimary] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch();

  const setFetchTimestampCB = useCallback((t) => dispatch(setFetchTimestamp(t)), [dispatch]);

  const { merchantId, apiBaseUri } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
  }));

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowEditBankModal(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!isChangedPrimary) {
      setBodyOverflow('auto');
      setShowEditBankModal(false);
      return;
    }

    const options = await getFetchOptions('POST');
    const url = `${apiBaseUri}/merchants/${merchantId}/external-bank-account/${editAccId}/primary`;
    setIsLoading(true);
    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        setShowEditBankModal(false);
        setBodyOverflow('auto');
        setFetchTimestampCB(Date.now());
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePrimary = (e: ChangeEvent<HTMLSelectElement>): void => {
    setIsChangedPrimary(e.target.value === AccountsEditOptions[0].value);
  };

  return (
    <Modal
      title="Bank account"
      cancelBtnText="Cancel"
      confirmBtnText="Update"
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      disableConfirmBtn={hasError || isLoading}
    >
      {hasError && <Alert message="Failed to edit bank account" />}
      {isLoading && !hasError && (
        <ModalSpinner>
          <Spinner />
        </ModalSpinner>
      )}
      {!isLoading && !hasError && (
        <div className="lp-details pt-2 pb-2 mt-3 mb-3">
          <div className="form-item">
            <Select
              data-testid="edit-bank-acc-select"
              defaultValue={AccountsEditOptions[1].value}
              onChange={handleChangePrimary}
            >
              {AccountsEditOptions.map((option) => (
                <option
                  data-testid={`bank-account-${option.value}`}
                  className="customer-select-option"
                  value={option.value}
                  key={option.value}
                >
                  {option.text}
                </option>
              ))}
            </Select>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EditBankModal;
