import { loginWithToken } from 'APIs/identity';
import Spinner from 'Components/Spinner/Spinner';
import LogoLaddrPay from 'assets/images/LaddrPayLogo.png';
import { ReactComponent as LogoIcon } from 'assets/svg/april-logo.svg';
import { ReactComponent as PasswordIcon } from 'assets/svg/password.svg';
import qs from 'query-string';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import useFavicon from 'utils/useFavicon';

import * as s from './styles';

const ResetPwd = () => {
  const [newPwd, setNewPwd] = useState<string>('');
  const [confirmPwd, setConfirmPwd] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchantName] = useState(() => getMerchantName());
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [supportEmail, setSupportEmail] = useState<string>('support@meetapril.com');

  const { apiBaseUri, branding, tenantId, merchantId, isLaddrB2C } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    branding: state.branding,
    tenantId: state.tenantId,
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
  }));

  const { ref } = qs.parse(window.location.search);

  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);

  useFavicon(branding, isLaddrB2C);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  useEffect(() => {
    document.title = isLaddrB2C ? 'Set password - Laddr' : 'Set password - April';
    setLoginPendingCB(true);
    if (isLaddrB2C) {
      setSupportEmail('support@limepayladdr.zendesk.com');
    }
  }, [setLoginPendingCB, isLaddrB2C]);

  const handleChangeNewPwd = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPwd(e.target.value);
  };

  const handleChangeConfirmPwd = (e: ChangeEvent<HTMLInputElement>): void => {
    setConfirmPwd(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPwd !== confirmPwd) {
      setErrorMessage('Two passwords are not matched');
      return;
    }

    setErrorMessage('');
    setIsLoading(true);
    const url = `${apiBaseUri}/authn/reset-password`;
    const options = {
      method: 'POST',
      headers: {
        'Limepay-Token': ref as string,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: newPwd }),
    };

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      })
      .then(async ({ customToken }) => {
        await loginWithToken(tenantId, customToken);
        window.location.href = `/${merchantName}`;
      })
      .catch((e) => {
        setErrorMessage(e?.message || 'Failed to set password');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isPageLoading) {
    return (
      <s.LoadingWrapper>
        <Spinner />
      </s.LoadingWrapper>
    );
  }

  return (
    <s.Wrapper>
      <s.Header>
        <s.Nav>
          <s.LogoWrapper>
            {merchantId && isLaddrB2C && <img src={LogoLaddrPay} alt="LaddrPay" className="logo" />}
            {merchantId && !isLaddrB2C && <LogoIcon className="logo" />}
          </s.LogoWrapper>
        </s.Nav>
      </s.Header>
      <s.Content>
        <s.ResetPwdForm onSubmit={handleSubmit}>
          <s.FormHeader className="form-header">
            <s.FormTitle>Set your password</s.FormTitle>
            {errorMessage.length > 0 && <s.MessageBar>{errorMessage}</s.MessageBar>}
          </s.FormHeader>

          <s.InputWrapper>
            <s.Label htmlFor="newPwd">
              <s.IconWrapper>
                <PasswordIcon className="password-icon" />
              </s.IconWrapper>
              <s.Input
                type="password"
                id="newPwd"
                name="newPwd"
                autoFocus
                value={newPwd}
                placeholder="NEW PASSWORD"
                onChange={handleChangeNewPwd}
                autoComplete="new-password"
              />
            </s.Label>
          </s.InputWrapper>
          <s.InputWrapper>
            <s.Label htmlFor="confirmPwd">
              <s.IconWrapper>
                <PasswordIcon className="password-icon" />
              </s.IconWrapper>
              <s.Input
                type="password"
                id="confirmPwd"
                name="confirmPwd"
                value={confirmPwd}
                placeholder="CONFIRM PASSWORD"
                onChange={handleChangeConfirmPwd}
                autoComplete="confirm-password"
              />
            </s.Label>
          </s.InputWrapper>
          <s.InputSubmit>
            <s.Small>
              <s.HelpLink href={`mailto:${supportEmail}`} tabIndex={-1}>
                Need help? Contact us
              </s.HelpLink>
              <s.SubmitBtn type="submit" disabled={newPwd.length === 0 || confirmPwd.length === 0 || isLoading}>
                Set password
                {isLoading && (
                  <s.SpinnerWrapper>
                    <Spinner />
                  </s.SpinnerWrapper>
                )}
              </s.SubmitBtn>
            </s.Small>
          </s.InputSubmit>
        </s.ResetPwdForm>
      </s.Content>
    </s.Wrapper>
  );
};

export default ResetPwd;
