import { ChangeEvent } from 'react';

import { LightText } from './CustomContent.styles';
import { Cno, LpRadio, RadioLi, RadioUl } from './styles';

type RadioButtonsType = {
  className?: string;
  boldTitles: string[];
  lightTitles: string[];
  name: string;
  values: unknown[];
  variable: unknown;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
};

const RadioButtons = ({ className, boldTitles, lightTitles, values, variable, name, onChange }: RadioButtonsType) => (
  <RadioUl className={className}>
    {values.map((_, id) => (
      <RadioLi key={id}>
        <LpRadio>
          <Cno data-testid={`radio-text-${boldTitles[id]}`}>
            {boldTitles[id] && <b>{boldTitles[id]}</b>}
            {lightTitles[id] && <LightText>{lightTitles[id]}</LightText>}
          </Cno>
          <input
            type="radio"
            name={name}
            value={values[id] as string}
            onChange={onChange}
            checked={variable === values[id]}
            data-testid={`radio-option-${values[id]}`}
          />
          <span className="check-mark" />
        </LpRadio>
      </RadioLi>
    ))}
  </RadioUl>
);

export default RadioButtons;
