import React, { MouseEvent, ReactElement } from 'react';

import { Wrapper } from './Pagination.styles';

type Props = {
  pageCount: number;
  activePage: number;
  setActivePage(page: number): void;
  noPadding?: boolean;
  pageStartIndex: number;
  setPageStartIndex(page: number | Function): void;
};

const Pagination = ({
  pageCount,
  activePage,
  setActivePage,
  noPadding = false,
  pageStartIndex,
  setPageStartIndex,
}: Props): ReactElement => {
  const paginationRange = [...Array(pageCount + 1)].map((_, i) => i).slice(pageStartIndex, pageStartIndex + 3);

  const handleClickPage = (e: MouseEvent<HTMLButtonElement>, page: number): void => {
    e.preventDefault();
    setActivePage(page);
    /* click on the first btn, if it is greater than 1, reduce the start index by 1
     * click on the last btn, if it is less than pageCount, increase the start index by 1
     */
    if (page === pageStartIndex && pageStartIndex > 1) {
      setPageStartIndex((prev: number) => prev - 1);
    } else if (page === pageStartIndex + 2 && page < pageCount) {
      setPageStartIndex((prev: number) => prev + 1);
    }
  };

  if (pageCount <= 1) {
    return <></>;
  }

  return (
    <Wrapper noPadding={noPadding}>
      <nav aria-label="page navigation">
        <ul className="pagination">
          {paginationRange.map((page) => (
            <li className={`page-item ${page === activePage ? 'active' : ''}`} key={page}>
              <button className="page-link" onClick={(e) => handleClickPage(e, page)}>
                {page}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </Wrapper>
  );
};

export default Pagination;
