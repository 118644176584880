import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const HiddenSwitch = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
  position: absolute;

  + label {
    position: relative;
  }

  &:checked + label {
    background-color: #424eaf;
  }

  &:checked + label::before {
    display: none;
  }

  &:checked + label::after {
    -webkit-transform: translateX(14px);
    -moz-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }

  &:disabled + label {
    opacity: 0.5;
    cursor: default;
  }
`;

const StyledSwitch = styled.label<{ checked: boolean }>`
  width: 34px;
  min-width: 34px;
  max-width: 34px;
  height: 20px;
  outline: none;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  position: relative;
  background-color: #b3b8df;
  border-radius: 60px;
  transition: all 150ms;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transition: all 150ms;
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    -webkit-transition: background 0.4s;
    -moz-transition: background 0.4s;
    -o-transition: background 0.4s;
    transition: background 0.4s;
  }

  &::after {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    -o-transition: -o-transform 0.4s;
    transition: transform 0.4s;
  }
`;

const Text = styled.div`
  margin-top: -1px;
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #555c7c;
`;

type SwitchType = {
  className?: string;
  checked: boolean;
  text: string;
  testid: string;
  handleChange(): void;
  disabled?: boolean;
};

const Switch = ({ className = '', checked, handleChange, text, ...inputProps }: SwitchType) => (
  <Wrapper className={className}>
    <HiddenSwitch
      type="checkbox"
      id={inputProps.testid}
      onChange={handleChange}
      {...inputProps}
      className="checkbox-toggle"
      checked={checked}
    />
    <StyledSwitch htmlFor={inputProps.testid} checked={checked}></StyledSwitch>
    <Text>{text}</Text>
  </Wrapper>
);

export default Switch;
