import Message from 'Components/Message';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import DepositedTo from './DepositedTo';
import PayoutSchedule from './PayoutSchedule';
import { Wrapper } from './TransferSettings.styles';
import { AccountInterface } from './types';

interface Props {
  selectedPayoutFreqHandler?: (val: string) => void;
}

const TransferSettings = ({ selectedPayoutFreqHandler }: Props) => {
  const [accounts, setAccounts] = useState<AccountInterface[]>([]);
  const [isAccountsLoading, setIsAccountsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { merchantId, apiBaseUri, timestamp, isLaddrB2C } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    isLaddrB2C: state.isLaddrB2C,
    timestamp: state.timestamp,
  }));

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    setIsAccountsLoading(true);
    const fetchData = async () => {
      const options = await getFetchOptions();
      const url = `${apiBaseUri}/merchants/${merchantId}/external-bank-account/query?t=${timestamp}`;
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response: AccountInterface[]) => {
          response.sort((a) => (a.isDefaultForCurrency ? -1 : 1));
          if (isMounted) {
            setAccounts(response);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to fetch bank accounts');
        })
        .finally(() => {
          isMounted && setIsAccountsLoading(false);
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, timestamp]);

  return isLaddrB2C ? (
    <Wrapper style={{ padding: 0 }}>
      {errorMsg.length > 0 && <Message description={errorMsg} success={false} hasClose={false} />}
      <PayoutSchedule />
      <DepositedTo accounts={accounts} isLoading={isAccountsLoading} />
    </Wrapper>
  ) : (
    <Wrapper role="tabpanel" aria-labelledby="payouts settings">
      {errorMsg.length > 0 && <Message description={errorMsg} success={false} hasClose={false} />}
      <PayoutSchedule selectedPayoutFreqHandler={selectedPayoutFreqHandler} />
      <DepositedTo accounts={accounts} isLoading={isAccountsLoading} />
    </Wrapper>
  );
};

export default TransferSettings;
