import 'react-phone-number-input/style.css';

import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails, P } from 'Constants/styles';
import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { PublicInfoItems } from './Constants';
import { getInitialPublicInfo } from './getInitialState';
import isValidCardStatementName from './isValidCardStatementName';
import { EditBtn, InvalidText, PhoneInputWrapper } from './styles';
import { PublicInfoAPIResponseType } from './types';

type PublicInfoProps = {
  apiBaseUri: string;
  merchantId: string;
};

const PublicInformation = ({ apiBaseUri, merchantId }: PublicInfoProps): ReactElement => {
  const [publicInfo, setPublicInfo] = useState<PublicInfoAPIResponseType>(() => getInitialPublicInfo());
  const [unconfirmedPublicInfo, setUnconfirmedPublicInfo] = useState<PublicInfoAPIResponseType>(() =>
    getInitialPublicInfo(),
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [timestamp, setTimestamp] = useState<number>(0);
  const [validPhoneNo, setValidPhoneNo] = useState<boolean>(true);
  const [validCardStatementName, setValidCardStatementName] = useState<boolean>(true);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          if (isMounted) {
            setPublicInfo(response);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to fetch public information');
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, timestamp]);

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('hidden');
    setShowModal(true);
    setModalErrorMsg('');
    setModalIsLoading(false);
    setValidPhoneNo(true);
    setUnconfirmedPublicInfo({ ...publicInfo });
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowModal(false);
    setUnconfirmedPublicInfo(() => getInitialPublicInfo());
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    let isReady = true;
    const phoneNo = parsePhoneNumber(unconfirmedPublicInfo.phoneNo ?? '');
    if (!phoneNo || !phoneNo.country) {
      setValidPhoneNo(false);
      isReady = false;
    }

    if (!validCardStatementName) {
      isReady = false;
    }

    if (!isReady) {
      return;
    }

    const url = `${apiBaseUri}/merchants/${merchantId}/settings/public-info`;
    const options = await getFetchOptions('POST', JSON.stringify(unconfirmedPublicInfo));

    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setBodyOverflow('auto');
        setShowModal(false);
        setTimestamp(Date.now());
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to update public information');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUnconfirmedPublicInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePhoneNo = (value: string = ''): void => {
    const isValid = isPossiblePhoneNumber(value);
    setValidPhoneNo(isValid);
    setUnconfirmedPublicInfo((prev) => ({
      ...prev,
      phoneNo: value,
    }));
  };

  const handleChangeCardStatementName = (e: ChangeEvent<HTMLInputElement>): void => {
    const isValid = isValidCardStatementName(e.target.value);
    setValidCardStatementName(isValid);
    setUnconfirmedPublicInfo((prev) => ({
      ...prev,
      cardStatementName: e.target.value,
    }));
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="PublicInformation">
            Public information
          </H2>
          <P className="mb-0 mb-md-3">This information is visible to your customers.</P>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {errorMsg.length === 0 && <EditBtn onClick={toggleModal}>Edit public information</EditBtn>}
        </div>
      </div>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <LpDetails>
          {PublicInfoItems.map((item, index) => (
            <div className="row pt-4" key={index}>
              <div className="col-sm-4 col-xl-3">
                <Label>{item.label}</Label>
              </div>
              <div className="col-sm-8 col-xl-9" data-testid={`public-info-${item.inputProps.name}`}>
                {(publicInfo as any)[item.inputProps.name]}
              </div>
            </div>
          ))}

          <div className="row pt-4">
            <div className="col-sm-4 col-xl-3">
              <Label>Address</Label>
            </div>
            <div className="col-sm-8 col-xl-9">
              <div>{publicInfo.addressLine1}</div>
              <div>{publicInfo.addressLine2}</div>
              <span className="mr-1">{publicInfo.city}</span>
              <span className="mr-1">{publicInfo.state}</span>
              <span className="mr-1">{publicInfo.postalCode}</span>
            </div>
          </div>
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit public information"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading || !validPhoneNo}
        >
          {modalIsLoading && modalErrorMsg.length === 0 && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          <div className="lp-details pt-2 pb-0">
            {PublicInfoItems.map((item, index) => {
              if (item.inputProps.name === 'phoneNo') {
                return (
                  <div className="form-item" key={index}>
                    <PhoneInputWrapper className="form-field">
                      <Label>{item.label}</Label>
                      <PhoneInput
                        defaultCountry="AU"
                        international
                        value={unconfirmedPublicInfo.phoneNo ?? ''}
                        onChange={handleChangePhoneNo}
                      />
                      {!validPhoneNo && <InvalidText>Please enter a valid phone number</InvalidText>}
                    </PhoneInputWrapper>
                  </div>
                );
              }

              if (item.inputProps.name === 'cardStatementName') {
                return (
                  <div className="form-item" key={index}>
                    <Label>{item.label}</Label>
                    <Input
                      value={unconfirmedPublicInfo.cardStatementName ?? ''}
                      required
                      onChange={handleChangeCardStatementName}
                      {...item.inputProps}
                    />
                    {!validCardStatementName && (
                      <InvalidText>Should have latin letters only, and between 5 and 13 chars</InvalidText>
                    )}
                  </div>
                );
              }

              return (
                <div className="form-item" key={index}>
                  <Label>{item.label}</Label>
                  <Input
                    value={(unconfirmedPublicInfo as any)[item.inputProps.name]}
                    required
                    onChange={handleChange}
                    {...item.inputProps}
                  />
                </div>
              );
            })}
            <div className="form-item">
              <Label>Address</Label>
              <Input
                type="text"
                name="addressLine1"
                value={unconfirmedPublicInfo.addressLine1 ?? ''}
                required
                placeholder="Address line 1"
                onChange={handleChange}
              />
              <Input
                type="text"
                name="addressLine2"
                className="mt-1"
                value={unconfirmedPublicInfo.addressLine2 ?? ''}
                placeholder="Address line 2"
                onChange={handleChange}
              />
              <div className="row mt-1">
                <div className="col-md-3">
                  <Input
                    type="text"
                    name="city"
                    value={unconfirmedPublicInfo.city ?? ''}
                    placeholder="City"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="state"
                    value={unconfirmedPublicInfo.state ?? ''}
                    placeholder="State"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    name="postalCode"
                    value={unconfirmedPublicInfo.postalCode ?? ''}
                    placeholder="Post code"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </LpBox>
  );
};

export default PublicInformation;
