import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 320px;
  margin-left: 3px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @media (max-width: 960px) {
    padding-top: 23px;
  }
  @media (max-width: 768px) {
    padding-top: 0;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding-top: 0;
    padding-left: 17px;
  }
`;

export const LpRadio = styled.label`
  position: relative;
  cursor: pointer;
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }
  input:checked ~ .check-mark {
    background-color: #27cbcc;
    border: 1px solid #13bebe;
    font-weight: bold;

    &:after {
      display: block;
    }
  }
  .check-mark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;

export const FormControl = styled.input`
  padding: 18px 30px 18px 33px;
  border-radius: 3px;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  height: 38px;
  min-width: 120px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dce3eb;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
`;

export const SearchIconWrapper = styled.span`
  left: 10px;
  top: 9px;
  position: absolute;
`;

export const DownSearch = styled.div`
  position: absolute;
  right: 8px;
  top: 9px;
  padding-right: 11px;
  text-align: left;
  width: 84px;
`;

export const SearchOptionBtn = styled.button`
  width: 90px;
  height: 22px;
  text-align: right;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  outline: none;
  border: 0;
  display: flex;
  justify-content: flex-end;

  .arrow-down {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease-in-out 0s;

    &.up {
      transform: rotate(180deg);
    }
  }
`;

export const OptionText = styled.div`
  line-height: 22px;
  color: #6c7488;
  font-size: 15px;
  margin-top: 1px;
`;

export const Ul = styled.ul`
  padding: 5px 20px 9px;
  left: -156px;
  top: 35px;
  width: 240px;
  list-style: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
  border: 1px solid rgba(126, 142, 159, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 88;
  margin: 0;
`;

export const Li = styled.li`
  padding: 12px 0;
`;

export const Cno = styled.span`
  padding-left: 30px;
  font-weight: 400;
  color: #000;
`;
