import styled from '@emotion/styled';

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#6F6C50' : 'var(--lp-colors-medium-blue-600)')};
  font-family: Arial;

  .email-icon,
  .password-icon {
    width: 22px;
    height: 22px;
  }
`;

export const Header = styled.header`
  position: absolute;
  width: 100%;
  margin-bottom: 50px;
  z-index: 2;
`;

export const Nav = styled.nav`
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px auto;
  max-width: 1600px;
  padding: 50px 100px;
`;

export const LogoWrapper = styled.div`
  min-width: 142px;
  max-width: 142px;
  margin-right: auto;
  flex: 0 1 0%;
  z-index: 1;
  .logo {
    max-width: 142px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  max-width: 1600px;
  padding: 90px 100px 80px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    .content {
      flex-direction: column;
      padding: 90px 20px 80px;
    }
  }
`;

export const ExpiredLinkForm = styled.form`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  max-width: 550px;
  width: 100%;
  height: auto;
  z-index: 2;
  padding-bottom: 20px;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 136px;
`;

export const FormTitle = styled.h3`
  margin: 0;
  padding: 30px;
  display: block;
  color: rgb(85, 91, 125);
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.38px;
`;

export const FormDescription = styled.div`
  height: 55px;
  width: 100%;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.32px;
`;

export const HelpLink = styled.a`
  color: #0a173a;
  text-decoration: underline;
`;
