import './APIKeys.scss';

import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import { Content } from 'Constants/styles';
import React, { useEffect } from 'react';

import InstallationGuides from './InstallationGuides';
import YourAPIKeys from './YourAPIKeys';
import { HeaderWrapper } from './styles';

const APIKeys = () => {
  useEffect(() => {
    document.title = 'API - April';
  }, []);

  return (
    <div className="api-keys-page">
      <HeaderWrapper>
        <HeaderTitle title="API Keys" />
      </HeaderWrapper>
      <Content>
        <YourAPIKeys />
        <InstallationGuides />
      </Content>
    </div>
  );
};

export default APIKeys;
