import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { AccountSettingsItems } from './Constants';
import { getInitialAccountInfo } from './getInitialState';
import { EditBtn } from './styles';
import { AccountSettingsAPIResponse } from './types';

type AccountSettingsProps = {
  apiBaseUri: string;
  merchantId: string;
};

const AccountSettings = ({ apiBaseUri, merchantId }: AccountSettingsProps): ReactElement => {
  const [accountInfo, setAccountInfo] = useState<AccountSettingsAPIResponse>(() => getInitialAccountInfo());
  const [unconfirmedAccountInfo, setUnconfirmedAccountInfo] = useState<AccountSettingsAPIResponse>(() =>
    getInitialAccountInfo(),
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/account`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          if (isMounted) {
            setAccountInfo(response);
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to get account information');
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId, time]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
    setModalErrorMsg('');
    setModalIsLoading(false);
    setUnconfirmedAccountInfo(() => getInitialAccountInfo());
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const body = JSON.stringify(unconfirmedAccountInfo);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/account`;
    const options = await getFetchOptions('POST', body);

    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setTime(Date.now());
        setBodyOverflow('auto');
        setShowModal(false);
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to save account information');
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalErrorMsg('');
    setUnconfirmedAccountInfo({ ...accountInfo });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUnconfirmedAccountInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="AccountSettings">
            Account Settings
          </H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {errorMsg.length === 0 && <EditBtn onClick={toggleModal}>Edit account settings</EditBtn>}
        </div>
      </div>
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && errorMsg.length === 0 && (
        <LpDetails>
          {AccountSettingsItems.map((item, index) => (
            <div className="row pt-4" key={index}>
              <div className="col-sm-4 col-xl-3">
                <Label>{item.label}</Label>
              </div>
              <div className="col-sm-8 col-xl-9" data-testid={item.formName}>
                {(accountInfo as any)[item.formName]}
              </div>
            </div>
          ))}
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit account settings"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={modalIsLoading}
        >
          {modalErrorMsg.length > 0 && <Alert message={modalErrorMsg} />}
          {modalIsLoading && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && (
            <LpDetails className="pt-2 pb-0">
              {AccountSettingsItems.map((item, index) => (
                <div className="form-item" key={index}>
                  <div className="form-field">
                    <Label>{item.label}</Label>
                    <Input
                      type="text"
                      name={item.formName}
                      value={(unconfirmedAccountInfo as any)[item.formName] ?? ''}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ))}
            </LpDetails>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default AccountSettings;
