import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-bottom: 30px;

  .jodit-ui-group_group_script,
  .jodit-ui-group_group_clipboard,
  .jodit-ui-group_group_info,
  .jodit-ui-group_group_search,
  .jodit-ui-group__classSpan,
  .jodit-toolbar-button_find,
  .jodit-toolbar-button_eraser,
  .jodit-toolbar-button_table,
  .jodit-toolbar-button_video,
  .jodit-toolbar-button_dots,
  .jodit-toolbar-button_print,
  .jodit-toolbar-button_preview,
  .jodit-toolbar-button_hr,
  .jodit-toolbar-button_copyformat,
  .jodit-toolbar-button_file,
  .jodit-status-bar {
    display: none;
  }

  .jodit-container {
    min-height: 300px !important;
  }
`;

export const Preview = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const PreviewEmail = styled.div`
  width: calc(50% - 15px);
  height: 58px;
  margin-right: 30px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const BottomBar = styled.div`
  background-color: #f9f9f9;
  border: 1px #dfe3e9 solid;
  padding: 26px 45px;
  overflow: hidden;
  position: fixed;
  width: calc(100% - 240px);
  right: 0;
  bottom: 0;
  z-index: 1111;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
  padding-top: 2px;
`;

export const PreviewBtn = styled.button`
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  display: flex;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 13px 38px 10px;
  cursor: pointer;
  height: 44px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  @media (max-width: 992px) {
    margin-left: auto;
  }
`;

export const SubmitBtn = styled.button`
  background-color: ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  border: 1px solid ${({ theme }) => (theme.isLaddrB2C ? '#00FF00' : 'var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  display: flex;
  width: auto;
  letter-spacing: 0.35px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 13px 38px 10px;
  cursor: pointer;
  height: 44px;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const invalidEmail = styled.div`
  font-size: 12px;
  text-align: right;
  color: red;
`;

export const FetchSpinner = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
