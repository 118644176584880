import Spinner from 'Components/Spinner/Spinner';
import LogoLaddrPay from 'assets/images/LaddrPayLogo.png';
import { ReactComponent as LogoIcon } from 'assets/svg/april-logo.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/email.svg';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getApiErrorMessage from 'utils/getApiErrorMsg';
import getMerchantName from 'utils/getMerchantName';
import useFavicon from 'utils/useFavicon';
import validateEmail from 'utils/validateEmail';

import * as s from './styles';

const ForgetPwd = () => {
  const [email, setEmail] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchantName] = useState(() => getMerchantName());
  const [supportEmail, setSupportEmail] = useState<string>('support@meetapril.com');

  const { apiBaseUri, merchantId, isLaddrB2C, branding } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
    branding: state.branding,
  }));

  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);
  useFavicon(branding, isLaddrB2C);

  useEffect(() => {
    document.title = isLaddrB2C ? 'Forget password - Laddr' : 'Forget password - April';
    setLoginPendingCB(true);
    if (isLaddrB2C) {
      setSupportEmail('support@limepayladdr.zendesk.com');
    }
  }, [setLoginPendingCB, isLaddrB2C]);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setEmail(value);
    setIsValidEmail(validateEmail(value));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    const url = `${apiBaseUri}/authn/send-reset-password-email`;
    const body = {
      slug: merchantName,
      userType: 'merchantStaff',
      emailAddress: email,
    };
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
    };

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          const text = await res.text();
          let msg = text;
          try {
            msg = getApiErrorMessage(JSON.parse(text));
          } finally {
            throw Error(msg);
          }
        }
        setIsSuccess(true);
        setMessage('Reset password link sent, please check your inbox.');
      })
      .catch((e) => {
        setHasError(true);
        setMessage(e?.message || 'Failed to reset password');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isPageLoading) {
    return (
      <s.LoadingWrapper>
        <Spinner />
      </s.LoadingWrapper>
    );
  }

  return (
    <s.Wrapper>
      <s.Header>
        <s.Nav>
          <s.LogoWrapper>
            {merchantId && isLaddrB2C && <img src={LogoLaddrPay} alt="LaddrPay" className="logo" />}
            {merchantId && !isLaddrB2C && <LogoIcon className="logo" />}
          </s.LogoWrapper>
        </s.Nav>
      </s.Header>
      <s.Content>
        <s.ResetPwdForm onSubmit={handleSubmit}>
          <s.FormHeader className="form-header">
            <s.FormTitle>Forget your password?</s.FormTitle>
            <s.FormDescription>Please click the button below to request a reset password link.</s.FormDescription>
            {(hasError || isSuccess) && <s.MessageBar hasError={hasError}>{message}</s.MessageBar>}
          </s.FormHeader>
          <s.InputWrapper>
            <s.Label htmlFor="email">
              <s.IconWrapper>
                <EmailIcon className="email-icon" />
              </s.IconWrapper>
              <s.Input
                type="email"
                id="email"
                name="email"
                autoFocus
                value={email}
                placeholder="EMAIL ADDRESS"
                onChange={handleChangeEmail}
                autoComplete="username"
              />
            </s.Label>
          </s.InputWrapper>
          <s.InputSubmit>
            <s.Small>
              <s.HelpLink href={`mailto:${supportEmail}`} tabIndex={-1}>
                Need help? Contact us
              </s.HelpLink>
              <s.SubmitBtn type="submit" disabled={isLoading || email.length === 0 || !isValidEmail}>
                Request a reset password link
                {isLoading && (
                  <s.SpinnerWrapper>
                    <Spinner width="30px" height="30px" borderWidth="3px" />
                  </s.SpinnerWrapper>
                )}
              </s.SubmitBtn>
            </s.Small>
          </s.InputSubmit>
        </s.ResetPwdForm>
      </s.Content>
    </s.Wrapper>
  );
};

export default ForgetPwd;
