import styled from '@emotion/styled';

export const Text = styled.span`
  font-size: 16px;
  display: inline-block;
  line-height: 19px;
`;

export const Icon = styled.span`
  display: inline-block;
  width: 10px;
  margin-right: 8px;
  text-align: center;
`;
