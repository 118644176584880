export enum FilterStatus {
  allStatuses = '',
  paid = 'paid',
  disputed = 'disputed',
  partiallyRefunded = 'partially_refunded',
  refunded = 'refunded',
  failed = 'failed',
  pending = 'pending',
  cancelled = 'cancelled',
  notCaptured = 'not_captured',
  incomplete = 'incomplete',
  accepted = 'accepted',
}

export const filterStatusArray = [
  {
    text: 'All statuses',
    inputName: 'filterAllStatuses_menu',
    value: FilterStatus.allStatuses,
  },
  {
    text: 'Paid',
    inputName: 'filterStatusPaid_menu',
    value: FilterStatus.paid,
  },
  {
    text: 'Disputed',
    inputName: 'filterStatusDisputed_menu',
    value: FilterStatus.disputed,
  },
  {
    text: 'Partially refunded',
    inputName: 'filterStatusPartiallyRefunded_menu',
    value: FilterStatus.partiallyRefunded,
  },
  {
    text: 'Fully Refunded',
    inputName: 'filterStatusRefunded_menu',
    value: FilterStatus.refunded,
  },
  {
    text: 'Failed',
    inputName: 'filterStatusFailed_menu',
    value: FilterStatus.failed,
  },
  {
    text: 'Active',
    inputName: 'filterStatusActive_menu',
    value: FilterStatus.pending,
  },
  {
    text: 'Cancelled',
    inputName: 'filterStatusCancelled_menu',
    value: FilterStatus.cancelled,
  },
  {
    text: 'Not captured',
    inputName: 'filterStatusNotCaptured_menu',
    value: FilterStatus.notCaptured,
  },
  {
    text: 'Incomplete',
    inputName: 'filterStatusIncomplete_menu',
    value: FilterStatus.incomplete,
  },
  {
    text: 'Accepted',
    inputName: 'filterStatusIncomplete_menu',
    value: FilterStatus.accepted,
  },
];

export const filterTypeArray = [
  {
    text: 'Full Payment',
    inputName: 'filterTypeFull_menu',
    value: 'fullPayment',
  },
  {
    text: 'Split Payment',
    inputName: 'filterTypeSplit_menu',
    value: 'splitPayment',
  },
];
