import { CountryType } from 'Components/SelectCountry';

import * as Actions from '../Constants/actionTypes';
import { ActionType, ReduxStateType } from '../Constants/types';

const initialState: ReduxStateType = {
  searchStartDate: '',
  searchEndDate: '',
  searchTriggeredBy: 'calendar',
  merchantId: '',
  isLaddrB2C: null,
  isLaddrTF: null,
  abacusEnabled: false,
  sourceVerificationEnabled: false,
  // true for most merchants, false for laddr
  manualPaymentIncludeGst: true,
  merchantName: '',
  tenantId: '',
  apiBaseUri: '',
  isLoggedIn: null,
  /* to fix the issue sidebar and login form might be shown together */
  isLoginPending: false,
  activePayment: null,
  showPaymentDetailsView: false,
  activeCustomer: null,
  showCustomerDetailsView: false,
  activeTransfer: null,
  showTransferDetailsView: false,
  filterPeriod: 'last_week',
  merchantEmail: '',
  publicInfo: null,
  initials: '',
  timestamp: 0,
  showSidebar: true,
  paymentDetailsFrom: 'payments',
  isMerchantActivated: null,
  merchantDashboardBranding: null,
  branding: null,
  personalInfo: {
    firstName: '',
    lastName: '',
    phoneNo: '',
  },
  isAccountSet: false,
  orderBaseUri: '',
  userPermissions: [],
  userRole: '',
  // set when onboarding
  taxCountry: CountryType.AU,
  // get tax country from config endpoint
  merchantTaxCountry: CountryType.AU,
  merchantTradingCountry: CountryType.AU,
  merchantPublicKey: '',
};

const reducer = (state: ReduxStateType = initialState, action: ActionType): ReduxStateType => {
  switch (action.type) {
    case Actions.UPDATE_SEARCH_DATE:
      return {
        ...state,
        searchStartDate: action.payload.startDate,
        searchEndDate: action.payload.endDate,
        searchTriggeredBy: 'calendar',
      };

    case Actions.SET_MERCHANT_EMAIL:
      return {
        ...state,
        merchantEmail: action.payload.merchantEmail,
      };

    case Actions.UPDATE_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };

    case Actions.SET_LOGIN_PENDING:
      return {
        ...state,
        isLoginPending: action.payload.isLoginPending,
      };

    case Actions.SET_CONFIG:
      const isLaddrB2C = action.payload.marketplaceTags.includes('Laddr');
      const isLaddrTF = action.payload.merchantTags.includes('LaddrToolsFinance');

      return {
        ...state,
        apiBaseUri: action.payload.apiBaseUri,
        tenantId: action.payload.tenantId,
        merchantId: action.payload.merchantId,
        isLaddrB2C,
        isLaddrTF,
        manualPaymentIncludeGst: isLaddrTF ? true : !isLaddrB2C,
        orderBaseUri: action.payload.orderBaseUri,
        merchantDashboardBranding: action.payload.branding,
        merchantTaxCountry: action.payload.merchantTaxCountry,
        merchantTradingCountry: action.payload.merchantTradingCountry,
        branding: action.payload.branding,
        merchantPublicKey: action.payload.merchantPublicKey,
        abacusEnabled: action.payload.abacusEnabled,
        sourceVerificationEnabled: action.payload.sourceVerificationEnabled,
      };

    case Actions.SET_PAYMENT_DETAILS:
      return {
        ...state,
        activePayment: action.payload.activePayment,
        showPaymentDetailsView: action.payload.showPaymentDetailsView,
        showSidebar: !action.payload.showPaymentDetailsView,
      };

    case Actions.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        showCustomerDetailsView: action.payload.showCustomerDetailsView,
        activeCustomer: action.payload.activeCustomer,
        showSidebar: !action.payload.showCustomerDetailsView,
      };

    case Actions.SET_TRANSFER_DETAILS:
      return {
        ...state,
        showTransferDetailsView: action.payload.showTransferDetailsView,
        activeTransfer: action.payload.activeTransfer,
        showSidebar: !action.payload.showTransferDetailsView,
      };

    case Actions.SET_FILTER_PERIOD:
      return {
        ...state,
        filterPeriod: action.payload.filterPeriod,
        searchTriggeredBy: 'overview',
      };

    case Actions.SET_PUBLIC_INFO:
      const { publicInfo } = action.payload;
      const initials = publicInfo?.brandName?.charAt(0) || publicInfo?.businessName?.charAt(0) || '';
      return {
        ...state,
        publicInfo,
        initials: initials.toUpperCase(),
      };

    case Actions.SET_FETCH_TIMESTAMP:
      return {
        ...state,
        timestamp: action.payload.timestamp,
      };

    case Actions.SET_MERCHANT_ACTIVATED: {
      return {
        ...state,
        isMerchantActivated: action.payload.isMerchantActivated,
      };
    }

    case Actions.SET_BRANDING: {
      return {
        ...state,
        branding: action.payload.branding,
      };
    }

    case Actions.SET_PERSONAL_INFO: {
      return {
        ...state,
        personalInfo: action.payload.personalInfo,
      };
    }

    case Actions.SET_ACCOUNT: {
      return {
        ...state,
        isAccountSet: action.payload.isAccountSet,
      };
    }

    case Actions.SET_USER_PERMISSION: {
      return {
        ...state,
        userRole: action.payload.userRole,
        userPermissions: action.payload.userPermissions,
      };
    }

    case Actions.SET_TAX_COUNTRY: {
      return {
        ...state,
        taxCountry: action.payload.taxCountry,
      };
    }

    default:
      return state;
  }
};

export default reducer;
