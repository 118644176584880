const formatChargeFailure = (chargeFailure: null | string): string => {
  if (!chargeFailure) {
    return 'Unknown charge error';
  }

  const str = chargeFailure.replace(/_/g, ' ');

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default formatChargeFailure;
