const tradeTypes = [
  'Air conditioning and refrigeration',
  'Bricklaying',
  'Building',
  'Carpentry',
  'Decorating',
  'Disconnection and reconnection of fixed electrical equipment',
  'Dry plastering',
  'Electrical',
  'Erection of pre-fabricated metal framed homes',
  'Excavating',
  'Fencing',
  'General concreting',
  'Glazing',
  'Installation of security grilles and equipment',
  'Joinery',
  'Kitchen, bathroom and laundry renovation',
  'Metal fabrication',
  'Minor maintenance and cleaning',
  'Minor trade work',
  'Painting',
  'Plumbing, draining and gasfitting',
  'Professional Engineers',
  'Roof plumbing',
  'Roof slating',
  'Roof tiling',
  'Stonemasonry',
  'Structural landscaping',
  'Swimming pool building',
  'Swimming pool repairs and servicing',
  'Underpinning and piering',
  'Wall and floor tiling',
  'Waterproofing',
  'Wet plastering',
  'Other',
];

export default tradeTypes;
