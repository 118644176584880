import { ReactComponent as TickIcon } from 'assets/svg/tick-green.svg';

import * as s from './SuccessView.styles';

type SuccessViewType = {
  email?: string;
  phone?: string;
  sentText?: string;
  successText?: string;
  sentToCustomer: boolean;
};

const SuccessView = ({
  email = '',
  phone = '',
  sentText = 'Request for payment has been sent to:',
  successText = 'Manual payment has been created',
  sentToCustomer,
}: SuccessViewType) => (
  <s.SuccessViewWrapper>
    <s.Tick>
      <TickIcon />
    </s.Tick>
    <s.SuccessText data-testid="SuccessViewText">{successText}</s.SuccessText>
    {sentToCustomer && (
      <>
        <s.SentText data-testid="sentText">{sentText}</s.SentText>
        <s.SentText data-testid="SuccessViewEmail">{email}</s.SentText>
        {phone.length > 0 && <s.SentText data-testid="SuccessViewPhone">{phone}</s.SentText>}
      </>
    )}
  </s.SuccessViewWrapper>
);

export default SuccessView;
