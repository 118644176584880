import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import TabContent from 'Components/Tabs/TabContent';
import Tabs from 'Components/Tabs/Tabs';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import AllTransfers from './AllTransfers';
import { TabOptions } from './Constants';
import PayoutDetails from './PayoutDetails';
import TransferOverview from './TransferOverview';
import TransferSettings from './TransferSettings';
import { HeaderWrapper, Wrapper } from './styles';

const Transfer = (): ReactElement => {
  const { showTransferDetailsView, activeTransfer, merchantId, apiBaseUri, abacusEnabled, isLaddrB2C } = useSelector(
    (state: ReduxStateType) => ({
      merchantId: state.merchantId,
      apiBaseUri: state.apiBaseUri,
      abacusEnabled: state.abacusEnabled,
      showTransferDetailsView: state.showTransferDetailsView,
      activeTransfer: state.activeTransfer,
      isLaddrB2C: state.isLaddrB2C,
    }),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPayoutFreq, setSelectedPayoutFreq] = useState<string>('daily');
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    if (!apiBaseUri || !merchantId) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/merchants/${merchantId}/settings/payout-schedule`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const freq = await res.json();
        if (isMounted) {
          setSelectedPayoutFreq(freq);
          setErrorMsg('');
        }
      } catch (e) {
        isMounted && setErrorMsg(e.message || 'Failed to fetch payout schedule');
      } finally {
        isMounted && setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    document.title = 'Payouts - April';
  }, []);

  let tabOptions = [];
  tabOptions.push({
    text: `${selectedPayoutFreq.charAt(0).toUpperCase() + selectedPayoutFreq.slice(1)} overview`,
    value: 'overview',
  });

  if (abacusEnabled) {
    tabOptions.push({
      text: 'All transfers',
      value: 'all_transfers',
    });
  }

  tabOptions = isLaddrB2C ? [...tabOptions] : [...tabOptions, ...TabOptions];

  const tabContent2 = abacusEnabled ? (
    <AllTransfers />
  ) : isLaddrB2C ? (
    <React.Fragment />
  ) : (
    <TransferSettings selectedPayoutFreqHandler={setSelectedPayoutFreq} />
  );
  const tabContent3 =
    abacusEnabled && !isLaddrB2C ? (
      <TransferSettings selectedPayoutFreqHandler={setSelectedPayoutFreq} />
    ) : (
      <React.Fragment />
    );
  return (
    <Wrapper>
      {!showTransferDetailsView && (
        <>
          <HeaderWrapper>
            <HeaderTitle title="Transfers" />
          </HeaderWrapper>
          <Tabs tabOptions={[...tabOptions]}>
            <TabContent>
              <TransferOverview
                payoutFetchLoader={loading}
                selectedPayoutFreq={selectedPayoutFreq}
                payoutErrorMsg={errorMsg}
              />
            </TabContent>
            <TabContent>{tabContent2}</TabContent>
            <TabContent>{tabContent3}</TabContent>
          </Tabs>
        </>
      )}
      {showTransferDetailsView && <PayoutDetails payout={activeTransfer} />}
    </Wrapper>
  );
};

export default Transfer;
