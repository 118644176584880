import EmptyList from 'Components/EmptyList/EmptyList';
import { LpBox } from 'Constants/styles';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setCustomerDetails } from 'redux/Actions/actions';
import getDateFormat from 'utils/getDateFormat';
import useClickOutsideWithoutRef from 'utils/useClickOutsideWithoutRef';

import { ListHeaders } from './Constants';
import * as s from './Customers.styles';
import { CustomerAPIResponseType } from './types';

const getCustomerName = (customer: CustomerAPIResponseType): string => {
  if (customer.organisationName) {
    return customer.organisationName;
  }

  if (customer.firstName || customer.lastName) {
    const fullName = `${customer.firstName} ${customer.lastName}`;
    return fullName.trim();
  }

  return 'Guest';
};

export type CustomersProps = {
  customerList: CustomerAPIResponseType[];
};

const Customers = ({ customerList }: CustomersProps): ReactElement => {
  const [activeCustomerId, setActiveCustomerId] = useState<string>('');

  const dispatch: Dispatch<any> = useDispatch();

  const setCustomerDetailsCB = useCallback((s, a) => dispatch(setCustomerDetails(s, a)), [dispatch]);

  const toggleDropdown = (e: MouseEvent<HTMLDivElement>, customerId: string): void => {
    e.preventDefault();
    setActiveCustomerId(customerId);
  };

  const handleClickOutside = () => {
    setActiveCustomerId('');
  };

  useClickOutsideWithoutRef(handleClickOutside, [
    'item-actions-block',
    'item-actions-list',
    'view-details-btn',
    'dots-icon',
    'arrow-right',
  ]);

  const handleClickBtn = (e: MouseEvent<HTMLButtonElement>, customerId: string): void => {
    e.preventDefault();
    const customer = customerList.find((c) => c.customerId === customerId) as CustomerAPIResponseType;
    setCustomerDetailsCB(true, customer);
  };

  return (
    <div className="customers-page">
      <s.Content>
        <LpBox>
          <s.Card>
            <s.ItemList className="item-list">
              <s.Item className="item-list-header">
                <s.ItemRow>
                  {ListHeaders.map((header, index) => (
                    <s.ItemCol key={index} className={`item-col-header ${header.className}`}>
                      <span>{header.text}</span>
                    </s.ItemCol>
                  ))}
                </s.ItemRow>
              </s.Item>
              {customerList.length === 0 && <EmptyList />}
              {customerList.length > 0 &&
                customerList.map((customer) => (
                  <s.Item className="active-item" key={customer.customerId}>
                    <s.ItemRow>
                      <s.ItemCol className="item-col-sales item-col-lgr">
                        <s.Media>
                          <s.FeatureIcon>
                            <UserIcon />
                          </s.FeatureIcon>
                          <s.OrderId>
                            <s.NameBtn onClick={(e) => handleClickBtn(e, customer.customerId)}>
                              {getCustomerName(customer)}
                            </s.NameBtn>
                          </s.OrderId>
                        </s.Media>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-sales custom-col-xsr">
                        <s.Item className="item text-left mt-1 pl-m">
                          <s.Media>
                            <s.CustomEmailTruncate>
                              <s.EmailBtn onClick={(e) => handleClickBtn(e, customer.customerId)}>
                                {customer.email}
                              </s.EmailBtn>
                            </s.CustomEmailTruncate>
                          </s.Media>
                        </s.Item>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-sales custom-col-xsrr">
                        <s.Item className="item text-left mt-1">
                          <s.Price>{getDateFormat({ time: customer.createdAt }).formatted}</s.Price>
                        </s.Item>
                      </s.ItemCol>
                      <s.ItemCol className="item-col-actions-dropdown">
                        <s.ItemActionsDropdown onClick={(e) => toggleDropdown(e, customer.customerId)}>
                          <DotsIcon className="dots-icon" />
                          <ArrowDownIcon className="arrow-right-icon" />
                        </s.ItemActionsDropdown>
                        <s.ItemActionsBlock
                          isActive={activeCustomerId === customer.customerId}
                          className="item-actions-block"
                        >
                          <s.ItemActionsList className="item-actions-list">
                            <s.ViewDetailsBtn
                              className="view-details-btn"
                              onClick={(e) => handleClickBtn(e, customer.customerId)}
                            >
                              View details
                            </s.ViewDetailsBtn>
                          </s.ItemActionsList>
                        </s.ItemActionsBlock>
                      </s.ItemCol>
                    </s.ItemRow>
                  </s.Item>
                ))}
            </s.ItemList>
          </s.Card>
        </LpBox>
      </s.Content>
    </div>
  );
};

export default Customers;
