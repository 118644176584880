import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 22px;

  .calendar-wrapper {
    margin-left: auto;
  }

  @media (max-width: 960px) {
    .calendar-wrapper {
      padding-top: 23px;
    }
  }

  @media (min-width: 1200px) {
    padding-right: 60px;
  }

  @media (max-width: 768px) {
    border-bottom: 1px solid #e7e7e7;
    .calendar-wrapper {
      padding-top: 12px;
      padding-bottom: 11px;
    }
  }
`;

type LinkBtnType = {
  isActive: boolean;
};

export const LinkBtn = styled.button<LinkBtnType>`
  background-color: transparent;
  border: none;
  padding: 0 0 5px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#303639' : '#6c7488')};
  font-size: 15px;
  border-bottom: ${({ isActive }) => (isActive ? '3px var(--lp-colors-medium-blue-600) solid' : 'none')};

  &:focus {
    outline: none;
  }
  &:hover {
    color: #4f5f6f;
    border-bottom: 3px var(--lp-colors-medium-blue-600) solid;
  }
`;
