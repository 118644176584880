import 'react-phone-number-input/style.css';

import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import Spinner from 'Components/Spinner/Spinner';
import { H2, Input, Label, LpBox, LpDetails } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setPersonalInfo } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';

import { YourInfoItems } from './Constants';
import { getInitialPersonalInfo } from './getInitialState';
import { EditBtn, InvalidText, PhoneInputWrapper } from './styles';
import { PersonalInfoSettingsAPIResponse } from './types';

type PersonalInfoProps = {
  apiBaseUri: string;
  merchantId: string;
};

const You = ({ apiBaseUri, merchantId }: PersonalInfoProps): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalHasError, setModalHasError] = useState<boolean>(false);
  const [modalErrorMsg, setModalErrorMsg] = useState<string>('');
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalInputInvalid, setModalInputInvalid] = useState<boolean[]>(() =>
    new Array(YourInfoItems.length).fill(false),
  );
  const [unconfirmedPersonalInfo, setUnconfirmedPersonalInfo] = useState<PersonalInfoSettingsAPIResponse>(() =>
    getInitialPersonalInfo(),
  );
  const [timestamp, setTimestamp] = useState<number>(0);

  const { personalInfo, merchantTradingCountry } = useSelector((state: ReduxStateType) => ({
    personalInfo: state.personalInfo,
    merchantTradingCountry: state.merchantTradingCountry,
  }));

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!apiBaseUri || !merchantId || timestamp === 0) {
      return;
    }

    let isMounted = true;
    const fetchData = async () => {
      const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
      const options = await getFetchOptions();
      setIsLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          if (isMounted) {
            dispatch(setPersonalInfo(response));
            setErrorMsg('');
          }
        })
        .catch((e) => {
          isMounted && setErrorMsg(e.message || 'Failed to get personal information');
        })
        .finally(() => {
          isMounted && setIsLoading(false);
        });
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [apiBaseUri, dispatch, merchantId, timestamp]);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(false);
    setBodyOverflow('auto');
    setUnconfirmedPersonalInfo(() => getInitialPersonalInfo());
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const phoneNo = parsePhoneNumber(unconfirmedPersonalInfo.phoneNo ?? '');
    if (!phoneNo || !phoneNo.country) {
      setModalInputInvalid((prev) => [...prev.slice(0, 3), true, ...prev.slice(4)]);
      return;
    }

    const body = JSON.stringify(unconfirmedPersonalInfo);
    const url = `${apiBaseUri}/merchants/${merchantId}/settings/personal`;
    const options = await getFetchOptions('PATCH', body);

    setModalIsLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setTimestamp(Date.now());
        setBodyOverflow('auto');
        setShowModal(false);
      })
      .catch((e) => {
        setModalErrorMsg(e.message || 'Failed to update personal information');
        setModalHasError(true);
      })
      .finally(() => {
        setModalIsLoading(false);
      });
  };

  const toggleModal = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModal(true);
    setBodyOverflow('hidden');
    setModalHasError(false);

    setUnconfirmedPersonalInfo(personalInfo || getInitialPersonalInfo());

    setModalInputInvalid(new Array(YourInfoItems.length).fill(false));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    const { name, value } = e.target;
    const { length } = value;

    // user input limitation is 128 chars
    setModalInputInvalid((prev) => [...prev.slice(0, index), length >= 128, ...prev.slice(index + 1)]);
    setUnconfirmedPersonalInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePhoneNo = (value: string = ''): void => {
    const isValid = isPossiblePhoneNumber(value);
    const index = 3;
    setModalInputInvalid((prev) => [...prev.slice(0, index), !isValid, ...prev.slice(index + 1)]);
    setUnconfirmedPersonalInfo((prev) => ({
      ...prev,
      phoneNo: value,
    }));
  };

  const disableConfirmBtn = modalHasError || modalIsLoading || modalInputInvalid.some((m) => m);

  if (!personalInfo) {
    return <></>;
  }

  return (
    <LpBox>
      <div className="row">
        <div className="col-sm-7">
          <H2 className="mb-0" data-testid="PersonalInformation">
            You
          </H2>
        </div>
        <div className="col-sm-5 text-left text-md-right text-sm-right text-xl-right">
          {!errorMsg && <EditBtn onClick={toggleModal}>Edit your details</EditBtn>}
        </div>
      </div>
      {errorMsg && <Alert message={errorMsg} />}
      {isLoading && !errorMsg && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!errorMsg && !isLoading && (
        <LpDetails>
          {YourInfoItems.map((item, index) => (
            <div className="row pt-4" key={index}>
              <div className="col-sm-4 col-xl-3">
                <Label>{item.label}</Label>
              </div>
              <div className="col-sm-8 col-xl-9" data-testid={`personal-info-${item.inputProps.name}`}>
                {(personalInfo as any)[item.inputProps.name]}
              </div>
            </div>
          ))}
        </LpDetails>
      )}
      {showModal && (
        <Modal
          title="Edit your information"
          cancelBtnText="Cancel"
          confirmBtnText="Save changes"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isLoading={modalIsLoading}
          disableConfirmBtn={disableConfirmBtn}
        >
          {modalHasError && <Alert message={modalErrorMsg} />}
          {modalIsLoading && !modalHasError && (
            <div className="text-center">
              <Spinner />
            </div>
          )}
          {!modalIsLoading && (
            <div className="lp-details pt-2 pb-0">
              {YourInfoItems.map((item, index) => {
                if (item.inputProps.name === 'phoneNo') {
                  return (
                    <div className="form-item" key={index}>
                      <PhoneInputWrapper className="form-field">
                        <Label>{item.label}</Label>
                        <PhoneInput
                          defaultCountry={merchantTradingCountry}
                          international
                          value={unconfirmedPersonalInfo.phoneNo ?? ''}
                          onChange={handleChangePhoneNo}
                        />
                        {modalInputInvalid[index] && <InvalidText>Please enter a valid phone number</InvalidText>}
                      </PhoneInputWrapper>
                    </div>
                  );
                }

                return (
                  <div className="form-item" key={index}>
                    <div className="form-field">
                      <Label>{item.label}</Label>
                      <Input
                        type="text"
                        value={(unconfirmedPersonalInfo as any)[item.inputProps.name] ?? ''}
                        required
                        onChange={(e) => handleChange(e, index)}
                        {...item.inputProps}
                      />
                      {modalInputInvalid[index] && <InvalidText>invalid input</InvalidText>}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal>
      )}
    </LpBox>
  );
};

export default You;
