import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0 20px 0;
  @media (min-width: 1200px) {
    padding: 16px 60px 11px;
  }
  @media (max-width: 768px) {
    display: none;
  }

  .arrow-down-status,
  .arrow-down-type {
    width: 20px;
    fill: #6c7488;
    transition: transform 0.3s ease-in-out 0s;
    &.up {
      transform: rotate(180deg);
    }
  }
`;

export const NavWrapper = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  width: 100%;
`;

export const Nav = styled.ul`
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin: 16px 0 0;
`;

export const NavItem = styled.li`
  position: relative;
`;

export const OuterBtn = styled.button`
  display: flex;
  outline: none;
  border: none;
  background: #fff;
  cursor: pointer;
  align-items: center;
`;

export const TcLink = styled.div`
  text-decoration: none;
  outline: none;
  border: 0;
  font-size: 15px;
  color: #6c7488;
  position: relative;
`;

export const DropdownMenu = styled.ul`
  padding: 11px 20px 10px;
  width: 230px;
  list-style: none;
  position: absolute;
  top: 30px;
  background-color: #fff;
  -webkit-box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
  box-shadow: 2px 3px 6px 0 rgba(126, 142, 159, 0.1);
  border: 1px solid rgba(126, 142, 159, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 88;
  left: 0;
`;

export const DropdownLi = styled.li`
  padding: 7px 0;
  font-size: 15px;
`;

export const LpOption = styled.label`
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 4px;
  cursor: pointer;
  user-select: none;
  max-width: 236px;
  color: #000;
  font-weight: 500;
  font-family: 'Acumin Pro', Arial, sans-serif;
`;

export const Input = styled.input`
  padding: 17px 17px 12px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const RadioCheck = styled.span`
  border-radius: 50%;
  top: 0;
  left: 0;
  position: absolute;
  height: 19px;
  width: 19px;
  box-shadow: rgb(220 227 235 / 56%) 0.5px 0.5px 3px 0.6px;
  border: 1px solid rgb(192, 192, 192);

  &.checked {
    background-color: rgb(39, 203, 204);
    border: 1px solid rgb(19, 188, 190);
    font-weight: bold;
  }
  &:after {
    display: block;
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
  }
`;

export const CheckMark = styled.span`
  top: -3px;
  left: 0;
  position: absolute;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);

  &.checked {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURQBigP///y5+loCwv+vy9YKywOzz9TB/lwxphViYq+jw8xhwi3Spufb5+lHY4DEAAAAzSURBVAjXY2AgFjBPgDICV4DIDQysIgog4XSGQCewvFgpWICBwfCiE1QLVICB4RCxtgAA1iYGclVESY8AAAAASUVORK5CYII=');
  }
`;
