import { H4, PrimaryBtn } from 'Constants/styles';
import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { readString } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';
import convertUTCtoLocal from 'utils/convertUTCtoLocal';
import { CountryCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import useClickOutside from 'utils/useClickOutside';

import { Wrapper } from './ExportData.style';

const UrlTypeObj = {
  payments: 'transaction',
  refunds: 'refund',
  settlement: 'settlement',
};

const TextObj = {
  payments: {
    title: 'Download transactions as CSV',
    description: 'This will download customer transactions as a CSV file.',
  },
  refunds: {
    title: 'Download refunds as CSV',
    description: 'This will download refund transactions as a CSV file.',
  },
  settlement: {
    title: 'Download all transactions as CSV',
    description: 'This will download all transactions as a CSV file.',
  },
};

type Props = {
  className?: string;
  pageSource: 'payments' | 'refunds' | 'settlement';
};

const ExportData = ({ className = '', pageSource }: Props) => {
  const [showExportData, setShowExportData] = useState<boolean>(false);
  const [downloadedData, setDownloadedData] = useState<any>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);

  const exportRef = useRef<HTMLDivElement>(null);
  const csvRef = useRef<any>(null);

  const { merchantId, apiBaseUri, searchStartDate, searchEndDate, merchantTradingCountry } = useSelector(
    (state: ReduxStateType) => ({
      merchantId: state.merchantId,
      apiBaseUri: state.apiBaseUri,
      searchStartDate: state.searchStartDate,
      searchEndDate: state.searchEndDate,
      merchantTradingCountry: state.merchantTradingCountry,
    }),
  );

  const handleToggleExportData = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowExportData((prev) => !prev);
  };

  const handleClickOutsideExportBtn = () => {
    setShowExportData(false);
  };

  useClickOutside(exportRef, handleClickOutsideExportBtn, ['outer-btn', 'tclick', 'arrow-down']);

  useEffect(() => {
    if (csvRef?.current && downloadReady) {
      csvRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  const handleDownload = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    const type = UrlTypeObj[pageSource];
    const localStartDate = convertUTCtoLocal(searchStartDate);
    const localEndDate = convertUTCtoLocal(searchEndDate);
    let url = `${apiBaseUri}/reports/merchants/${merchantId}/${type}`;
    if (type === UrlTypeObj.settlement) {
      url += `/${CountryCurrency[merchantTradingCountry]}`;
    }
    url += `/csv?from=${localStartDate}&to=${localEndDate}`;
    const options = await getFetchOptions();

    setDownloadedData(null);
    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.text();
      })
      .then((res) => {
        readString(res, {
          worker: true,
          complete: ({ data }) => {
            const noneEmptyData = data.filter((d) => Array.isArray(d) && d.length > 1);
            setDownloadedData(noneEmptyData);
            setDownloadReady(true);
          },
        });
      })
      .catch(() => {
        console.error('Failed to download');
      });
  };

  return (
    <Wrapper className={className} role="tablist">
      <li className="nav-item">
        <button className="outer-btn" onClick={handleToggleExportData}>
          <div className="payout-schedule tclick">Export Data</div>
          <ArrowDown className={`arrow-down ${showExportData ? 'up' : ''}`} />
        </button>
        <div ref={exportRef} className={`payout-schedule-menu export-data ${showExportData ? 'open' : ''}`}>
          <H4>{TextObj[pageSource].title}</H4>
          <p>{TextObj[pageSource].description}</p>
          <div className="date pb-2">
            <div className="date-color">
              {getDateFormat({ time: searchStartDate, showComma: false, showDaySuffix: false }).date}
              <span className="ml-1 mr-1">-</span>
              {getDateFormat({ time: searchEndDate?.slice(0, 10), showComma: false, showDaySuffix: false }).date}
            </div>
          </div>
          <PrimaryBtn onClick={handleDownload}>Download</PrimaryBtn>
        </div>
      </li>
      {downloadedData ? (
        <CSVLink data={downloadedData} ref={csvRef} target="_self" filename={Date.now() + '.csv'} />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default ExportData;
