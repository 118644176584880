import styled from '@emotion/styled';

type WrapperType = {
  backgroundColor: string;
  success: boolean;
  borderColor: string;
};

export const Wrapper = styled.div<WrapperType>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  border: ${(props) =>
    props.borderColor ? `2px solid ${props.borderColor}` : props.success ? '2px solid #bfe0d2' : '2px solid #d1374d'};
  padding: 20px 40px;
  background-color: ${({ backgroundColor, success }) =>
    backgroundColor ? backgroundColor : success ? '#f8f8fb' : '#f5d6db'};
  border-radius: 8px;
  position: relative;

  .MessageTitle {
    color: #333333;
    font-weight: 700 !important;
    font-family: 'Acumin Pro', Arial, sans-serif !important;
  }

  .link-learn {
    padding: 0;
    border: 0 none;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    border-bottom: 1px solid currentColor;
  }
`;

export const IconContainer = styled.div<{ success: boolean; svgFillColor: string }>`
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${(props) => (props.success ? '#91cf57' : 'transparent')};
  border-radius: 50%;
  position: absolute;
  color: #e9f6dd;

  svg {
    path {
      fill: ${(props) => (props.svgFillColor ? `${props.svgFillColor} !important` : 'currentColor')};
    }
  }
`;

export const Content = styled.div<{ success: boolean }>`
  padding-left: 15px;
  color: ${(props) => (props.success ? '#555C7C' : '#B32D3F')};
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700 !important;
  font-family: 'Acumin Pro', Arial, sans-serif !important;
`;

export const Description = styled.div`
  color: #333;
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const CloseBtn = styled.button<{ success: boolean }>`
  top: 16px;
  right: 14px;
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  color: #91cf57;

  &:focus {
    outline: none;
  }
  path {
    fill: ${(props) => (props.success ? '#6db42a' : '#d1374d')};
  }
`;
