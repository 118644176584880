import ConfirmationMessage from 'Components/ConfirmationMessage/ConfirmationMessage';
import { Content } from 'Constants/styles';
import React, { useEffect, useState } from 'react';

import HeaderTitle from '../HeaderTitle/HeaderTitle';
import Security from './Security';
import YourDetails from './YourDetails';
import { HeaderWrapper } from './styles';

const MyAccount = () => {
  const [confirmMsg, setConfirmMsg] = useState<string>('');

  useEffect(() => {
    document.title = 'My account - April';
  }, []);

  return (
    <div className="my-account">
      <HeaderWrapper>
        <HeaderTitle title="My account" />
      </HeaderWrapper>
      <Content>
        <ConfirmationMessage message={confirmMsg} success />
        <YourDetails />
        <Security setConfirmMsg={setConfirmMsg} />
      </Content>
    </div>
  );
};

export default MyAccount;
