import Alert from 'Components/Alert/Alert';
import Modal from 'Components/Modal/Modal';
import SelectCountry, { CountryType } from 'Components/SelectCountry';
import { LpDetails, Row } from 'Constants/styles';
import { ChangeEvent, FormEvent, MouseEvent, ReactElement, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setFetchTimestamp } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import { Currency } from 'utils/currency';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import isNumeric from 'utils/isNumeric';
import setBodyOverflow from 'utils/setBodyOverflow';
import useClickOutside from 'utils/useClickOutside';

import * as s from './AddBankModal.styles';
import { CurrencyOptions } from './Constants';

type Props = {
  setShowAddBankModal(s: boolean): void;
};

const AddBankModal = ({ setShowAddBankModal }: Props): ReactElement => {
  const [openCurrency, setOpenCurrency] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>('AUD');
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(CountryType.AU);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [bsb, setBsb] = useState<string>('');
  const [accountHolder, setAccountHolder] = useState<string>('');
  const [validAccNumber, setValidAccNumber] = useState<boolean>(true);
  const [validBsb, setValidBsb] = useState<boolean>(true);

  const dispatch: Dispatch<any> = useDispatch();

  const setFetchTimestampCB = useCallback((t) => dispatch(setFetchTimestamp(t)), [dispatch]);

  const { merchantId, apiBaseUri, isLaddrB2C } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    apiBaseUri: state.apiBaseUri,
    isLaddrB2C: state.isLaddrB2C,
  }));

  const currencyRef = useRef<HTMLDivElement>(null);

  const toggleShowDropdown = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setOpenCurrency((prev) => !prev);
  };

  const handleClickOption = (e: MouseEvent<HTMLLIElement>, value: string) => {
    e.preventDefault();
    setSelectedCurrency(value as Currency);
    setOpenCurrency(false);
  };

  const handleClickOutsideCurrency = () => {
    setOpenCurrency(false);
  };

  useClickOutside(currencyRef, handleClickOutsideCurrency, ['select', 'currency-text', 'arrow-down-currency']);

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setBodyOverflow('auto');
    setShowAddBankModal(false);
  };

  const handleChangeBsb = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setBsb(value);
    const _bsb = value.replace(/ /g, '').replace(/-/g, '');
    setValidBsb(
      (isNumeric(_bsb) && _bsb.length === 6 && selectedCountry === CountryType.AU) ||
        selectedCountry === CountryType.NZ,
    );
  };

  const handleChangeAccountNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setAccountNumber(value);
    const _accountNumber = value.replace(/ /g, '').replace(/-/g, '');
    setValidAccNumber(
      selectedCountry === CountryType.AU ||
        (selectedCountry === CountryType.NZ && _accountNumber.length >= 15 && _accountNumber.length <= 16),
    );
  };

  const handleChangeHolder = (e: ChangeEvent<HTMLInputElement>): void => {
    setAccountHolder(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const body = {
      accountName: accountHolder,
      accountNumber: accountNumber.replace(/ /g, '').replace(/-/g, ''),
      country: selectedCountry,
      currency: selectedCurrency,
      routingNumber: selectedCountry === CountryType.AU ? bsb : '',
    };

    const options = await getFetchOptions('POST', JSON.stringify(body));

    const url = `${apiBaseUri}/merchants/${merchantId}/external-bank-account`;

    setIsLoading(true);

    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        setShowAddBankModal(false);
        setErrorMsg('');
        setBodyOverflow('auto');
        setFetchTimestampCB(Date.now());
      })
      .catch((e) => {
        setErrorMsg(e.message || 'Failed to add bank account');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title="Bank account"
      cancelBtnText="Cancel"
      confirmBtnText="Add"
      confirmBtnColour={isLaddrB2C ? '#00ff00' : 'var(--lp-colors-medium-blue-600)'}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      disableConfirmBtn={
        isLoading ||
        (!validBsb && selectedCountry === CountryType.AU) ||
        (!validAccNumber && selectedCountry === CountryType.NZ)
      }
    >
      {errorMsg.length > 0 && <Alert message={errorMsg} />}
      <LpDetails className="pt-2 pb-2">
        <s.FormItem>
          <Row>
            <div className="col-sm-6 pb-3">
              <label>Currency</label>
              <s.DropdownList className="dropdown-list">
                <s.Select onClick={toggleShowDropdown}>
                  <s.CurrencyText>{selectedCurrency}</s.CurrencyText>
                  <s.ArrowDown className={`${openCurrency ? 'up' : ''}`} />
                </s.Select>
                <s.DropdownMenu className={`dropdown-menu ${openCurrency ? 'open' : ''}`} ref={currencyRef}>
                  {CurrencyOptions.map((option) => (
                    <s.DropdownOption key={option.value} onClick={(e) => handleClickOption(e, option.value)}>
                      {option.text}
                    </s.DropdownOption>
                  ))}
                </s.DropdownMenu>
              </s.DropdownList>
            </div>
            <div className="col-sm-6">
              <SelectCountry
                className="form-item pb-0"
                label="Country"
                country={selectedCountry}
                setCountry={setSelectedCountry}
                selectTestId="country"
                option1TestId="countryOption1"
                option2TestId="countryOption2"
              />
            </div>
          </Row>
        </s.FormItem>
        {selectedCountry === CountryType.AU && (
          <s.FormItem className="form-item">
            <label>BSB</label>
            <s.Input
              data-testid="bsb"
              type="text"
              required
              placeholder="BSB"
              name="routingNumber"
              value={bsb}
              onChange={handleChangeBsb}
              invalid={!validBsb}
              autoComplete="off"
            />
            {!validBsb && <s.ErrorMsg>Please enter valid bsb</s.ErrorMsg>}
          </s.FormItem>
        )}
        <s.FormItem>
          <label>Account number</label>
          <s.Input
            data-testid="accountNumber"
            type="text"
            required
            placeholder="Account number"
            name="accountNumber"
            value={accountNumber}
            onChange={handleChangeAccountNumber}
            invalid={!validAccNumber}
            autoComplete="off"
          />
          {!validAccNumber && <s.ErrorMsg>Please enter valid account number</s.ErrorMsg>}
        </s.FormItem>
        <s.FormItem>
          <label>Account holder name</label>
          <s.Input
            className="account-info"
            type="text"
            required
            placeholder="Account holder name"
            name="accountName"
            value={accountHolder}
            onChange={handleChangeHolder}
            autoComplete="off"
          />
        </s.FormItem>
      </LpDetails>
    </Modal>
  );
};

export default AddBankModal;
