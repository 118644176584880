import ExportData from 'Components/ExportData/ExportData';
import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import { MouseEvent, ReactElement, useRef, useState } from 'react';
import useClickOutside from 'utils/useClickOutside';

import { FilterStatus, filterStatusArray, filterTypeArray } from './FilterConstants';
import * as s from './OrderFilter.styles';

type Props = {
  selectedStatus: FilterStatus;
  typeOptions: boolean[];
  handleStatusOptionClick(e: MouseEvent<HTMLElement>, status: FilterStatus): void;
  handleTypeOptionClick(e: MouseEvent<HTMLElement>, index: number): void;
};

const OrderFilter = ({
  selectedStatus,
  typeOptions,
  handleStatusOptionClick,
  handleTypeOptionClick,
}: Props): ReactElement => {
  const [showStatusOptions, setShowStatusOptions] = useState<boolean>(false);
  const [showTypeOptions, setShowTypeOptions] = useState<boolean>(false);

  const statusRef = useRef<HTMLUListElement>(null);
  const typeRef = useRef<HTMLUListElement>(null);

  const handleClickOutsideStatusBtn = () => {
    setShowStatusOptions(false);
  };

  const handleClickOutsideTypeBtn = () => {
    setShowTypeOptions(false);
  };

  useClickOutside(statusRef, handleClickOutsideStatusBtn, ['btn-status', 'tclick-status', 'arrow-down-status']);
  useClickOutside(typeRef, handleClickOutsideTypeBtn, ['btn-type', 'tclick-type', 'arrow-down-type']);

  const handleStatusClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowStatusOptions((prev) => !prev);
  };

  const handleTypeClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowTypeOptions((prev) => !prev);
  };

  return (
    <s.Wrapper>
      <s.NavWrapper>
        <s.Nav id="pills-tab" role="tablist">
          <s.NavItem>Filter by:</s.NavItem>
          <s.NavItem>
            <s.OuterBtn className="btn-status" onClick={handleStatusClick}>
              <s.TcLink className="tclick-status">Status</s.TcLink>
              <ArrowDown className={`arrow-down-status ${showStatusOptions ? 'up' : ''}`} />
            </s.OuterBtn>
            {showStatusOptions && (
              <s.DropdownMenu ref={statusRef}>
                {filterStatusArray.map((filter) => (
                  <s.DropdownLi key={filter.text}>
                    <s.LpOption onClick={(event) => handleStatusOptionClick(event, filter.value)}>
                      {filter.text}
                      <s.Input type="radio" name={filter.inputName} value={filter.value} />
                      <s.RadioCheck className={`${filter.value === selectedStatus ? 'checked' : ''}`} />
                    </s.LpOption>
                  </s.DropdownLi>
                ))}
              </s.DropdownMenu>
            )}
          </s.NavItem>
          <s.NavItem>
            <s.OuterBtn className="btn-type" onClick={handleTypeClick}>
              <s.TcLink className="tclick-type">Type</s.TcLink>
              <ArrowDown className={`arrow-down-type ${showTypeOptions ? 'up' : ''}`} />
            </s.OuterBtn>
            {showTypeOptions && (
              <s.DropdownMenu ref={typeRef}>
                {filterTypeArray.map((filter, index) => (
                  <s.DropdownLi key={filter.text}>
                    <s.LpOption onClick={(event) => handleTypeOptionClick(event, index)}>
                      {filter.text}
                      <s.Input type="checkbox" name={filter.inputName} value={filter.value} />
                      <s.CheckMark className={`${typeOptions[index] ? 'checked' : ''}`} />
                    </s.LpOption>
                  </s.DropdownLi>
                ))}
              </s.DropdownMenu>
            )}
          </s.NavItem>
        </s.Nav>
        <ExportData pageSource="payments" />
      </s.NavWrapper>
    </s.Wrapper>
  );
};

export default OrderFilter;
