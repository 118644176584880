import Spinner from 'Components/Spinner/Spinner';
import LogoLaddrPay from 'assets/images/LaddrPayLogo.png';
import { ReactComponent as LogoIcon } from 'assets/svg/april-logo.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions/actions';
import { ReduxStateType } from 'redux/Constants/types';
import useFavicon from 'utils/useFavicon';

import * as s from './styles';

const ResendEmail = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [supportEmail, setSupportEmail] = useState<string>('support@meetapril.com');

  const { merchantId, isLaddrB2C, branding } = useSelector((state: ReduxStateType) => ({
    merchantId: state.merchantId,
    isLaddrB2C: state.isLaddrB2C,
    branding: state.branding,
  }));

  useFavicon(branding, isLaddrB2C);

  useEffect(() => {
    if (merchantId) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, merchantId]);

  useEffect(() => {
    document.title = isLaddrB2C ? 'Expired link - Laddr' : 'Expired link - April';
    setLoginPendingCB(true);
    if (isLaddrB2C) {
      setSupportEmail('support@limepayladdr.zendesk.com');
    }
  }, [setLoginPendingCB, isLaddrB2C]);

  if (isPageLoading) {
    return (
      <s.LoadingWrapper>
        <Spinner />
      </s.LoadingWrapper>
    );
  }

  return (
    <s.Wrapper>
      <s.Header>
        <s.Nav>
          <s.LogoWrapper>
            {merchantId && isLaddrB2C && <img src={LogoLaddrPay} alt="LaddrPay" className="logo" />}
            {merchantId && !isLaddrB2C && <LogoIcon className="logo" />}
          </s.LogoWrapper>
        </s.Nav>
      </s.Header>
      <s.Content>
        <s.ExpiredLinkForm>
          <s.FormHeader>
            <s.FormTitle>This link has expired</s.FormTitle>
            <s.FormDescription>
              Your onboarding link has expired. Please contact{' '}
              <s.HelpLink href={`mailto:${supportEmail}`}>{supportEmail}</s.HelpLink> to generate a new link.
            </s.FormDescription>
          </s.FormHeader>
        </s.ExpiredLinkForm>
      </s.Content>
    </s.Wrapper>
  );
};

export default ResendEmail;
