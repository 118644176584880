import { fromCurrency } from 'utils/currency';
import { Currency } from 'utils/currency';

import { ItemInterface, ItemType } from './HeaderTypes';
import { ActionStepView, StatesType } from './types';

type InitialStatesType = {
  showSendModal?: boolean;
  showCancelModal?: boolean;
  showProcessModal?: boolean;
};

export const getInitialStates = ({
  showSendModal = false,
  showCancelModal = false,
  showProcessModal = false,
}: InitialStatesType): StatesType => ({
  showSendModal,
  showCancelModal,
  showProcessModal,
  isLoading: false,
  errorMsg: '',
  showErrorMsg: false,
  modalLoading: false,
  modalErrorMsg: '',
  activeOrderId: '',
  canShowActionList: false,
  cancelStepView: ActionStepView.Confirm,
  sendToCustomerStepView: ActionStepView.Confirm,
  processPaymentStepView: ActionStepView.Confirm,
});

export const convertItems = (items: ItemType[], currency: Currency): ItemInterface[] => {
  const results: ItemInterface[] = [];

  items.forEach((item) => {
    const obj: ItemInterface = {
      amount: fromCurrency(item.cost),
      currency,
      description: item.name,
      sku: '',
      quantity: Number(item.quantity),
      imageUrl: '',
    };
    results.push({ ...obj });
  });

  return results;
};

export const getCustomizationsForList = (isLaddrB2C: boolean | null) => {
  if (isLaddrB2C) {
    return {
      processModalTitle: 'Are you sure you want to process a payment for this order manually?',
      processModalButton: 'Process payment now',
      processModalSuccessText: 'Payment has been processed',
      sendModalTitle: 'Are you sure you want to send an order link to',
      sendModalButton: 'Send order',
      sendModalSuccessText: 'Order has been sent',
      cancelModalTitle: 'Are you sure you want to cancel this order?',
      cancelModalButton: 'Cancel order',
      cancelModalSuccessText: 'Order has been cancelled',
      modalCancelButton: 'Back',
      listTitle: 'Unpaid Orders',
      emptyList: 'There are currently no outstanding orders.',
    };
  }

  return {
    processModalTitle: 'Are you sure you want to process this manual payment?',
    processModalButton: 'Process now',
    processModalSuccessText: 'Manual payment has been processed',
    sendModalTitle: 'Are you sure you want to send a payment link to',
    sendModalButton: 'Confirm and send',
    sendModalSuccessText: 'Manual payment has been sent',
    cancelModalTitle: 'Are you sure you want to cancel this manual payment?',
    cancelModalButton: 'Confirm and cancel',
    cancelModalSuccessText: 'Manual payment has been cancelled',
    modalCancelButton: 'Cancel',
    listTitle: 'Unpaid virtual terminal requests',
    emptyList: 'There are currently no outstanding manual payment requests.',
  };
};
