export const getSideBarLinks = (name: string) => [
  {
    label: 'Overview',
    href: `/${name}/home`,
  },
  {
    label: 'Payments',
    href: `/${name}/payments`,
  },
  {
    label: 'Refunds',
    href: `/${name}/refunds`,
  },
  {
    label: 'Transfers',
    href: `/${name}/payouts`,
  },
  {
    label: 'Balance',
    href: `/${name}/balance`,
  },
  {
    label: 'Virtual Terminal',
    href: `/${name}/virtual-terminal`,
  },
  {
    label: 'Customers',
    href: `/${name}/customers`,
  },
  {
    label: 'API Keys',
    href: `/${name}/api`,
  },
  {
    label: 'Settings',
    href: `/${name}/settings`,
  },
];

export const getLaddrLinks = (name: string) => [
  {
    label: 'Orders',
    href: `/${name}/payment-requests`,
  },
  {
    label: 'Completed payments',
    href: `/${name}/payments`,
  },
  {
    label: 'Transfers',
    href: `/${name}/payouts`,
  },
  {
    label: 'Refunds',
    href: `/${name}/refunds`,
  },
  {
    label: 'Settings',
    href: `/${name}/settings`,
  },
];
