import { BrandingAPIResponse, BusinessInfoSettingsAPIResponse, CheckoutApplePayAPIResponse } from './types';

export const getInitialAccountInfo = () => ({
  accountName: '',
  accountAddress: '',
  timeZone: '',
});

export const getInitialPublicInfo = () => ({
  addressLine1: '',
  addressLine2: '',
  brandName: '',
  businessName: '',
  cardStatementName: '',
  city: '',
  contactEmail: '',
  customerDashboard: '',
  phoneNo: '',
  postalCode: '',
  state: '',
  supportEmail: '',
  supportSite: null,
  website: '',
});

export const getInitialBusinessInfo = (): BusinessInfoSettingsAPIResponse => ({
  typeOfProduct: '',
  taxId: '',
  businessType: '',
  taxCountry: 'AU',
  country: 'AU',
});

export const getInitialPersonalInfo = () => ({
  firstName: '',
  lastName: '',
  phoneNo: '',
});

export const getInitialBrandInfo = (): BrandingAPIResponse => ({
  iconUri: '',
  logoUri: '',
  logoAltText: '',
});

export const getInitialCheckoutInfo = (): CheckoutApplePayAPIResponse => ({
  domainNames: [],
  encryptTo: '',
  merchantUrl: '',
  partnerInternalMerchantIdentifier: '',
  partnerMerchantName: '',
});
