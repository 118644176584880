import Spinner from 'Components/Spinner/Spinner';
import { H2, LpBox, LpDetails, Row } from 'Constants/styles';
import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import { MouseEvent, ReactElement, useState } from 'react';
import setBodyOverflow from 'utils/setBodyOverflow';

import AddBankModal from './AddBankModal';
import { DesktopPrimaryBtn, EditBtn, MobilePrimaryBtn, Primary, Wrapper } from './DepositedTo.styles';
import EditBankModal from './EditBankModal';
import { SpinnerWrapper } from './TransferOverview.styles';
import { FeatureIcon, Media } from './styles';
import { AccountInterface } from './types';

type DepositedToType = {
  accounts: AccountInterface[];
  isLoading: boolean;
};

const DepositedTo = ({ accounts, isLoading }: DepositedToType): ReactElement => {
  const [showAddBankModal, setShowAddBankModal] = useState<boolean>(false);
  const [showEditBankModal, setShowEditBankModal] = useState<boolean>(false);
  const [editAccId, setEditAccId] = useState<string>('');

  const handleShowModal = (e: MouseEvent<HTMLButtonElement>, type: string, accId: string = ''): void => {
    e.preventDefault();
    setBodyOverflow('hidden');

    switch (type) {
      case 'add':
        setShowAddBankModal(true);
        break;
      case 'edit':
        setEditAccId(accId);
        setShowEditBankModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <LpBox>
        <Row style={{ marginBottom: '20px' }}>
          <div className="col-6">
            <H2 className="mb-3">Deposited to</H2>
          </div>
          <div className="col-6 text-right">
            <DesktopPrimaryBtn className="custom-AP-link" onClick={(e) => handleShowModal(e, 'add')}>
              Add a bank account
            </DesktopPrimaryBtn>
          </div>
        </Row>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {!isLoading &&
          accounts.map((account, index) => (
            <LpDetails key={account.externalAccountId}>
              <Row className="pt-3 text-left">
                <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                  <Media>
                    <FeatureIcon>
                      <BankIcon className="bank-icon" />
                    </FeatureIcon>
                    <div className="media-body">
                      <H2 className="pt-2 pb-2 pb-xl-0 pt-md-2" data-testid={`payouts-account-${index}`}>
                        Bank account ***
                        <span data-testid={`payouts-account-${index}-last4`}>{account.accountNumberLast4}</span>
                        {account.isDefaultForCurrency && <Primary>Primary</Primary>}
                      </H2>
                    </div>
                  </Media>
                </div>
                <div className="col-5 col-sm-5 col-lg-5 col-xl-5 text-right">
                  {!account.isDefaultForCurrency && (
                    <EditBtn onClick={(e) => handleShowModal(e, 'edit', account.externalAccountId)}>
                      Edit<span className="d-none d-md-inline"> bank account</span>
                    </EditBtn>
                  )}
                </div>
              </Row>
            </LpDetails>
          ))}
        <MobilePrimaryBtn data-testid="add-account-btn" onClick={(e) => handleShowModal(e, 'add')}>
          Add a bank account
        </MobilePrimaryBtn>
      </LpBox>
      {showAddBankModal && <AddBankModal setShowAddBankModal={setShowAddBankModal} />}
      {showEditBankModal && <EditBankModal setShowEditBankModal={setShowEditBankModal} editAccId={editAccId} />}
    </Wrapper>
  );
};

export default DepositedTo;
