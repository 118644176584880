import styled from '@emotion/styled';

export const CancelBtn = styled.button<{ doubleAction?: boolean }>`
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: ${(doubleAction) => (doubleAction ? '13px 20px 10px 20px' : '13px 38px 10px 38px')};
  background-color: #fff;
  border: 1px solid #d1d3d7;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  width: auto;
  letter-spacing: 0.35px;
  margin-right: 4px;
  @media (max-width: 768px) {
    padding: 12px 29px 10px 29px;
  }
`;

type ConfirmBtnProps = {
  confirmBtnColour: string | null;
  doubleAction?: boolean;
};

export const ConfirmBtn = styled.button<ConfirmBtnProps>`
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
  width: auto;
  letter-spacing: 0.35px;
  padding: ${(doubleAction) => (doubleAction ? '13px 16px 10px 16px' : '13px 38px 10px 38px')};
  color: ${({ theme }) => (theme.isLaddrB2C ? '#000' : '#fff')};
  background-color: ${(props) =>
    props.theme.isLaddrB2C ? '#00ff00' : props.confirmBtnColour || 'var(--lp-colors-medium-blue-600)'};
  border: ${(props) =>
    `1px solid ${props.theme.isLaddrB2C ? '#00ff00' : props.confirmBtnColour || 'var(--lp-colors-medium-blue-600)'}`};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
  @media (max-width: 768px) {
    padding: 12px 29px 10px 29px;
  }
`;

export const ModalInner = styled.div`
  width: 100%;
  margin: 40px auto;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const Wrapper = styled.div`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: block;
    width: 100%;
    height: 100%;
    overflow: scroll;
    outline: 0;
  }
  .fade {
    background: rgba(255, 255, 255, 0.85);
    transition: opacity 0.15s linear;
  }
  .modal-dialog {
    max-width: 571px;
    position: relative;
    width: auto;
    margin: 0 16px;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 16px);
  }
  .modal-content {
    border-radius: 3px;
    box-shadow: 0 10.5px 35px 25px rgba(0, 0, 0, 0.07);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
  }
  .modal-header {
    color: #3a3e46;
    border-bottom: 0;
    padding: 35px 43px 0 43px;
    display: flex;
    align-items: flex-start;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .modal-title {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    color: #3a3e46;
    font-family: 'Acumin Regular', Arial, sans-serif;
  }
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 16px;
    &.modal-body-reset {
      padding: 0 43px 5px;
    }
  }
  .modal-footer {
    padding: 26px 45px 26px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .btn {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: 400;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-reset {
    padding: 13px 38px 10px 38px;
    background-color: #fff;
    border: 1px solid #d1d3d7;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.56);
    width: auto;
    letter-spacing: 0.35px;
    &.blue {
      padding: 13px 40px 10px 40px;
      background-color: var(--lp-colors-medium-blue-600);
      color: #fff;
      border: 1px solid var(--lp-colors-medium-blue-600);
      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled {
        opacity: 0.65;
        cursor: default;
      }
    }
  }
  .close {
    background-color: #9ea7ac;
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: none;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    outline: none;
  }
  .close-icon {
    width: 12px;
    height: 12px;
    path {
      fill: #fff;
    }
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    max-width: 100vw;
    bottom: 0;
    .fade {
      background-color: #fff;
      transition: opacity 0.15s linear;
    }
    .modal-content {
      box-shadow: none;
    }
    .modal-header {
      padding: 19px 30px 19px 20px;
      height: 64px;
      background-color: #fff;
      display: flex;
      justify-content: flex-start;
    }
    .modal-title {
      font-size: 19px;
      line-height: 28px;
      color: #354052;
    }
    .modal-dialog {
      padding: 70px 0 160px;
      align-items: flex-start;
    }
    .modal-body.modal-body-reset {
      padding: 0 20px;
      max-width: 100vw;
    }
    .custom-reset-btn {
      display: none !important;
    }
    .modal-footer {
      padding: 15px 16px;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      margin: 28px auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - 56px);
    }
  }
`;

export const ActionBtns = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 4px;
`;
