import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import React, { FormEvent, MouseEvent, ReactElement, ReactNode } from 'react';

import { ActionBtns, CancelBtn, ConfirmBtn, ModalInner, SpinnerWrapper, Wrapper } from './Modal.style';

export type ModalProps = {
  title: string;
  children?: ReactNode;
  cancelBtnText: string;
  confirmBtnText: string;
  disableConfirmBtn?: boolean;
  isLoading?: boolean;
  confirmBtnColour?: string;
  handleCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  confirmBtnTestId?: string;
  cancelBtnTestId?: string;
};

export type DoubleActionModalProps = {
  title: string;
  children?: ReactNode;
  cancelBtnText: string;
  cta1BtnText: string;
  cta2BtnText: string;
  cta1BtnIcon?: ReactElement;
  cta2BtnIcon?: ReactElement;
  disableConfirmBtn?: boolean;
  isLoading?: boolean;
  cta1BtnColour?: string;
  cta2BtnColour?: string;
  handleCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  handleCTA1Submit: (e: MouseEvent<HTMLButtonElement>) => void;
  handleCTA2Submit: (e: MouseEvent<HTMLButtonElement>) => void;
  cta1BtnTestId?: string;
  cta2BtnTestId?: string;
  cancelBtnTestId?: string;
};

const Modal = ({
  title,
  children,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Update',
  disableConfirmBtn,
  isLoading = false,
  confirmBtnColour = '',
  handleCancel,
  handleSubmit,
  confirmBtnTestId = 'modal-confirm-btn',
  cancelBtnTestId = 'modal-cancel-btn',
}: ModalProps): ReactElement => (
  <Wrapper>
    <div className="modal fade" tabIndex={-1} role="dialog" aria-modal="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <ModalInner>
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title title-lg">{title}</h5>
                <button className="close" onClick={handleCancel}>
                  <CloseIcon className="close-icon" />
                </button>
              </div>
              <div className="modal-body modal-body-reset">{children}</div>
              <div className="modal-footer modal-reverse-grid">
                <CancelBtn data-testid={cancelBtnTestId} onClick={handleCancel}>
                  {cancelBtnText}
                </CancelBtn>
                <ConfirmBtn
                  data-testid={confirmBtnTestId}
                  confirmBtnColour={confirmBtnColour}
                  type="submit"
                  disabled={disableConfirmBtn || isLoading}
                >
                  {confirmBtnText}
                  {isLoading && (
                    <SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </SpinnerWrapper>
                  )}
                </ConfirmBtn>
              </div>
            </form>
          </div>
        </ModalInner>
      </div>
    </div>
  </Wrapper>
);

export const DoubleActionModal = ({
  title,
  children,
  cancelBtnText = 'Cancel',
  cta1BtnText = 'No Update',
  cta2BtnText = 'Update',
  cta1BtnIcon,
  cta2BtnIcon,
  disableConfirmBtn,
  isLoading = false,
  cta1BtnColour = '',
  cta2BtnColour = '',
  handleCancel,
  handleCTA1Submit,
  handleCTA2Submit,
  cta1BtnTestId = 'modal-cta-1-btn',
  cta2BtnTestId = 'modal-cta-2-btn',
  cancelBtnTestId = 'modal-cancel-btn',
}: DoubleActionModalProps): ReactElement => (
  <Wrapper>
    <div className="modal fade" tabIndex={-1} role="dialog" aria-modal="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <ModalInner>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title title-lg">{title}</h5>
              <button className="close" onClick={handleCancel}>
                <CloseIcon className="close-icon" />
              </button>
            </div>
            <div className="modal-body modal-body-reset">{children}</div>
            <div className="modal-footer modal-reverse-grid">
              <CancelBtn data-testid={cancelBtnTestId} onClick={handleCancel} doubleAction>
                {cancelBtnText}
              </CancelBtn>
              <ActionBtns>
                <ConfirmBtn
                  data-testid={cta1BtnTestId}
                  confirmBtnColour={cta1BtnColour}
                  onClick={handleCTA1Submit}
                  disabled={disableConfirmBtn || isLoading}
                  doubleAction
                >
                  {cta1BtnIcon && <div className="mr-2">{cta1BtnIcon}</div>}
                  <span>{cta1BtnText}</span>
                  {isLoading && (
                    <SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </SpinnerWrapper>
                  )}
                </ConfirmBtn>
                <ConfirmBtn
                  data-testid={cta2BtnTestId}
                  confirmBtnColour={cta2BtnColour}
                  onClick={handleCTA2Submit}
                  disabled={disableConfirmBtn || isLoading}
                  doubleAction
                >
                  {cta2BtnIcon && <div className="mr-2">{cta2BtnIcon}</div>}
                  <span>{cta2BtnText}</span>
                  {isLoading && (
                    <SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </SpinnerWrapper>
                  )}
                </ConfirmBtn>
              </ActionBtns>
            </div>
          </div>
        </ModalInner>
      </div>
    </div>
  </Wrapper>
);

export const ConfirmModal = ({
  title,
  children,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Confirm',
  disableConfirmBtn,
  isLoading = false,
  confirmBtnColour = '',
  handleCancel,
  handleSubmit,
  confirmBtnTestId = 'modal-confirm-btn',
  cancelBtnTestId = 'modal-cancel-btn',
}: ModalProps): ReactElement => (
  <Wrapper>
    <div className="modal fade" tabIndex={-1} role="dialog" aria-modal="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <ModalInner>
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title title-lg">{title}</h5>
                <button className="close" onClick={handleCancel}>
                  <CloseIcon className="close-icon" />
                </button>
              </div>
              <div className="modal-body modal-body-reset">{children}</div>
              <div className="modal-footer modal-reverse-grid">
                <CancelBtn data-testid={cancelBtnTestId} onClick={handleCancel}>
                  {cancelBtnText}
                </CancelBtn>
                <ConfirmBtn
                  data-testid={confirmBtnTestId}
                  confirmBtnColour={confirmBtnColour}
                  type="submit"
                  disabled={disableConfirmBtn || isLoading}
                >
                  {confirmBtnText}
                  {isLoading && (
                    <SpinnerWrapper>
                      <Spinner width="20px" height="20px" borderWidth="2px" />
                    </SpinnerWrapper>
                  )}
                </ConfirmBtn>
              </div>
            </form>
          </div>
        </ModalInner>
      </div>
    </div>
  </Wrapper>
);

export default Modal;
